<div class="card">
    <h5 class="card-header bg-secondary">Información General del Área Minera</h5>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-6">
              <div *ngIf="vigencia;else contenido_denominacion_area_minera">
                <div class="card">
                    <h5 class="card-header bg-success">Denominación: {{ denominacionAreaMinera }}</h5>
                </div>
              </div>
              <ng-template #contenido_denominacion_area_minera>
                <div class="card">
                    <h5 class="card-header bg-danger">Denominación: {{ denominacionAreaMinera }}</h5>
                </div>
              </ng-template> 
              <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Número Inscripción: {{ numeroInscripcion }}</h5>
                    <p class="card-text">Fecha Otorgación: {{ fechaOtorgacion }}</p>
                </div>
              </div>  
              <div *ngIf="vigencia;else contenido_cantidad_asignada">
                <div class="card">
                    <h5 class="card-header bg-success">Cantidad Asignada: {{ cantidadAsignada }}</h5>
                </div>
              </div>
              <ng-template #contenido_cantidad_asignada>
                <div class="card">
                    <h5 class="card-header bg-danger">Cantidad Asignada: {{ cantidadAsignada }}</h5>
                </div>
              </ng-template> 
            </div>
            <div class="col-sm-6">
              <div *ngIf="vigencia;else contenido_titular">
                <div class="card">
                    <h5 class="card-header bg-success">{{ titular }}</h5>
                </div>
              </div>
              <ng-template #contenido_titular>
                <div class="card">
                    <h5 class="card-header bg-danger">{{ titular }}</h5>
                </div>
              </ng-template>
              <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Padrón Nacional: {{ padronNacional }}</h5>
                    <p class="card-text">Clasificación del Área: {{ clasificacionAreaMinera }}</p>
                </div>
              </div>
              <div *ngIf="vigencia;else contenido_vigencia">
                <div class="card">
                    <h5 class="card-header bg-success">ACTUALMENTE EN VIGENCIA</h5>
                </div> 
              </div>
              <ng-template #contenido_vigencia>
                <div class="card">
                    <h5 class="card-header bg-danger">FUERA DE VIGENCIA</h5>
                  </div> 
              </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <h5 class="card-header bg-secondary">Pagos de Patentes</h5>
    <div class="card-body">
        <div class="row">
            <div>
                <div class="table table-bordered" style="font-size:12px" >
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th style="width:5px">#</th>
                                <th style="width:5px">Gestión</th>
                                <th style="width:5px">Antiguedad</th>
                                <th style="width:5px">Progresivo</th>
                                <th style="width:5px">Monto Establecido</th>
                                <th style="width:5px">Monto Calculado</th>
                                <th style="width:20px">Formulario/CPT</th>
                                <th style="width:5px">Fecha de Emisión</th>
                                <th style="width:5px">Comprobante</th>
                                <th style="width:5px">Fecha de Pago</th>
                                <th style="width:5px">Debe</th>
                                <th style="width:5px">Haber</th>
                                <th style="width:5px">Observación Revisión</th>
                                <!-- <th style="width:5px">Registro el Comprobante Bancario</th> -->
                                <th style="width:5px">Opciones</th>
                                <th style="width:50px">Observaciones por FCC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista; let indice=index;">
                                <td class="align-middle" style="width:5px">{{ indice + 1 }}</td>
                                <td class="align-middle" style="width:5px">{{ item.gestion_emision }}</td>
                                <td class="align-middle" style="width:5px">
                                    {{ generaAntiguedad(item.gestion_emision, fechaOtorgacion) }}
                                </td>
                                <td class="align-middle" style="width:5px">
                                    <p *ngIf="item.progresivo; else tmp_progresivo">SI</p>
                                    <ng-template #tmp_progresivo><p>NO</p></ng-template>
                                </td>
                                <td class="align-middle" style="width:5px">
                                    {{ montoEstablecido(item.cantidad_en_cuadriculas, item.monto_hasta_30_cuadriculas, item.monto_31_40_cuadriculas, item.monto_41_mas_cuadriculas) }}
                                </td>
                                <td class="align-middle" style="width:5px">
                                    {{ montoCalculado(item.cantidad_en_cuadriculas, item.monto_hasta_30_cuadriculas, item.monto_31_40_cuadriculas, item.monto_41_mas_cuadriculas, item.progresivo, item.multiplicador_antiguedad, item.id_ufv) }}
                                </td>
                                <td class="align-middle" style="width:20px">{{ item.numero_formulario }}</td>
                                <!-- <td class="align-middle" style="width:30px">{{ item.fecha_emision }}</td> -->
                                <td class="align-middle" style="width:5px">
                                    {{ fechaEmision(item.fecha_emision) }}
                                </td>
                                <td class="align-middle" style="width:5px">{{ item.nro_comprobante_bancario }}</td>
                                <!-- <td class="align-middle" style="width:30px">{{ item.fecha_pago }}</td> -->
                                <td class="align-middle" style="width:5px">
                                    {{ fechaPago(item.fecha_pago) }}
                                </td>
                                <td class="align-middle" style="width:5px">{{ item.importe_registro_debe }}</td>
                                <td class="align-middle" style="width:5px">{{ item.importe_registro_haber }}</td>
                                <!-- <td class="align-middle" style="width:30px">{{ item.fecha_pago }}</td> -->
                                <td class="align-middle" style="width:5px">{{ item.observacion_revision }}</td>
                                <!-- <td class="align-middle" style="width:5px">{{ item.fk_asca_registra_comprobante }}</td> -->
                                <td class="align-middle" style="width:5px"></td>
                                <td class="align-middle" style="width:50px">{{ item.observacion }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-left mt-3">
                    <button [routerLink]="['/correspondencia/vistas-areas-mineras']" type="button" class="btn btn-dark">
                        <i class="fas fa-arrow-circle-left"></i>
                        Volver
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #modalBuzon let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-buzon-usuario-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-buzon-usuario-formulario>
            <app-correspondencia-buzon-usuario-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-buzon-usuario-detalle>
            <app-correspondencia-buzon-usuario-formulario *ngSwitchCase="'modificar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-buzon-usuario-formulario>
            <app-correspondencia-buzon-usuario-detalle *ngSwitchCase="'eliminar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-buzon-usuario-detalle>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template> -->