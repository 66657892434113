import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { VistaAreasMineras } from '../../modelos';
import { VistaAreasMinerasFilter } from '../../modelos/filtros';

export const establecerFiltroVistaAreasMineras = createAction(
  '[CORRESPONDENCIA] Establecer filtro VISTA_AREAS_MINERAS',
  props<{ filtro: VistaAreasMinerasFilter }>()
);
export const establecerListaVistaAreasMineras = createAction(
  '[CORRESPONDENCIA] Establecer lista VISTA_AREAS_MINERAS',
  props<{ lista: VistaAreasMineras[]; paginado: Paginado }>()
);
export const establecerVistaAreasMineras = createAction(
  '[CORRESPONDENCIA] Establecer objeto VISTA_AREAS_MINERAS',
  props<{ objeto: VistaAreasMineras }>()
);
