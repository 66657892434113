<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="table table-bordered" style="font-size:11px" >
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th style="width:5px">Gestión</th>
                                <th style="width:5px">Importe (Bs)</th>
                                <th style="width:5px">Nro Formulario/Boleta</th>
                                <th style="width:5px">Fecha Pago</th>
                                <th style="width:5px">Solicitante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista; let indice=index;">
                                <td class="align-middle" style="width:5px">{{ item.id_subcuenta }}</td>
                                <td class="align-middle" style="width:5px">{{ item.debe }}</td>
                                <td class="align-middle" style="width:5px">{{ item.numero_formulario }}</td>
                                <td class="align-middle" style="width:5px">{{ item.fecha_pago }}</td>
                                <td class="align-middle" style="width:5px">{{ item.solicitante }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>