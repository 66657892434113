export class OrdenServicioFilter {
  fk_servicio?: number;
  correlativo_servicio?: string;
  hoja_ruta_servicio?: string;
  requisitos?: string;
  tiene_area_minera?: boolean;
  fk_area_minera?: number;
  zona?: number;
  este?: number;
  norte?: number;
  escala?: string;
  orientacion_hoja?: string;
  datos_adicionales?: string;
  costo_unitario?: number;
  cantidad?: number;
  subtotal?: number;
  monto_total?: number;
  ativo?: boolean;
  aud_asca_creador?: number;
  aud_fecha_creacion?: Date;
  aud_asca_modificador?: number;
  aud_fecha_modificacion?: Date;
  aud_tipo_operacion?: string;
  descripcion_servicio?: string;
  descripcion_area_minera?: string;
  grupo?: string;
  plan_trabajo?: boolean;
  escala_variable?: boolean;
  orientacion_variable?: boolean;
}
