import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { DerivacionHrOsFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroDerivacionHrOs(state: AppState): DerivacionHrOsFilter {
  return state.correspondencia.listaDerivacionHrOs?.filtro;
}

function seleccionarPaginadoDerivacionHrOs(state: AppState): Paginado {
  return state.correspondencia.listaDerivacionHrOs?.paginado;
}

export const filtroDerivacionHrOsSelector = createSelector(
  obtenerState,
  seleccionarFiltroDerivacionHrOs
);
export const paginadoDerivacionHrOsSelector = createSelector(
  obtenerState,
  seleccionarPaginadoDerivacionHrOs
);
