export * from './bandeja.service';
export * from './buzon.service';
export * from './buzon-usuario.service';
export * from './clasificacion.service';
export * from './contacto.service';
export * from './documento.service';
export * from './hoja-ruta.service';
export * from './parametro.service';
export * from './participante.service';
export * from './plantilla.service';
export * from './tipo-documento.service';
export * from './uni-organizacional.service';
export * from './seguimiento.service';
export * from './grupo.service';
export * from './grupo-buzon.service';
export * from './documento-hoja-ruta.service';
export * from './rol.service';
export * from './usuario.service';
export * from './cuenta.service';
export * from './vista-areas-mineras.service';
export * from './vista-servicios-registrados.service';
export * from './orden-servicio.service';
export * from './boleta-pago-servicio.service';
export * from './correlativo-os.service';
export * from './derivacion-hr-os.service';
export * from './servicios.service';
export * from './solicitante-orden-servicio.service';
export * from './requisitos-notas.service';
export * from './menu.service';
export * from './vista-patentes-mineras.service';
export * from './actualizacion-patente.service';
export * from './boleta-escaneada.service';
export * from './registra-reactivacion.service';
export * from './respaldo-boleta-escaneado.service';
export * from './formulario-recepcion.service';
export * from './genera-certificado-vigencia.service';
export * from './vista-patentes-mineras-inactivos.service';
export * from './vista-registro-diario-sipago.service';
export * from './reporte.service';
export * from './area-minera.service';
