export * from './archivado/bandeja-archivado.component';
export * from './borrador/bandeja-borrador.component';
export * from './entrada/bandeja-entrada.component';
export * from './enviado/bandeja-enviado.component';
export * from './salida/bandeja-salida.component';
export * from './buscador/bandeja-buscador.component';

export * from './detalle/enviar/bandeja-detalle-enviar.component';
export * from './detalle/aprobar/bandeja-detalle-aprobar.component';
export * from './detalle/derivar/bandeja-detalle-derivar.component';
export * from './detalle/ver/bandeja-detalle-ver.component';
export * from './detalle/archivar/bandeja-detalle-archivar.component';
