import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { BoletaEscaneada } from '../../modelos';
import { BoletaEscaneadaFilter } from '../../modelos/filtros';

export const establecerFiltroBoletaEscaneada = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer filtro BOLETA ESCANEADA',
  props<{ filtro: BoletaEscaneadaFilter }>()
);
export const establecerListaBoletaEscaneada = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer lista BOLETA ESCANEADA',
  props<{ lista: BoletaEscaneada[]; paginado: Paginado }>()
);
export const establecerBoletaEscaneada = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer objeto BOLETA ESCANEADA',
  props<{ objeto: BoletaEscaneada }>()
);
