export class ServiciosFilter {
  codigo?: string;
  descripcion?: string;
  costo?: number;
  grupo?: string;
  grupo_catalogo?: string;
  activo?: boolean;
  aud_asca_creador?: number;
  aud_fecha_creacion?: Date;
  aud_asca_modificador?: number;
  aud_fecha_modificacion?: Date;
  aud_tipo_operacion?: string;
}
