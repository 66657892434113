<style type="text/css">
    /*stylo CCCS 15/06/2016*/
    .tabla_servicios {
        border-collapse: collapse;
        width: 100%;
    }

    .tabla_servicios td, .tabla_servicios th {
        border: 1px solid black;
        padding: 8px;
    }

    .tabla_servicios_botones {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_botones td, .tabla_servicios_botones th {
        border: 0px solid black;
        padding: 8px;
    }
    .tabla_servicios_total {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_total td, .tabla_servicios_total th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .input_boleta{
        margin-left:10px;
        float:left;
        width:150px;
    }
    .input_depositante{
        text-transform:uppercase;
        width:800px;
    }
    .light{
        color: white;
        font-weight: bolder;
    }
    .modal-body {
        height: 287px;
    }
    /*Fin stylo CCCS 15/06/2016*/
    .box.box-black>.box-title
    {
        background: rgb(4,3,23);
        background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(24,203,203,1) 100%, rgba(13,159,159,1) 100%);
        font-family: Times New Roman;
        font-size: 20px;
        letter-spacing: 0px;
        word-spacing: 0px;
        font-weight: bold;
        text-decoration: none;
        font-style: oblique;
        font-variant: normal;
        text-transform: none;
        color:white;
    }
    /*Estilo para div lado a lado*/
    #div1{
            width: 50%;
            float: left;
            position: relative;
    }
    #div2{
            width: 50%;
            float: right;
            position: relative;
    }
    table thead {
        color: #fff;
        background-color: rgb(44, 62, 88);
    }

    #div4{
        width: 40%;
    }
    #div5{
        width: 100%;
        float: left;
        position: relative;     
    }
    #div6{
        width: 40%;
        float: right;
        position: relative;
    }

    input[type=text] {
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    }

    canvas{
    /*prevent interaction with the canvas*/
    pointer-events:none;
    }
</style>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>


<div class="page-service" style="background-color: #212529;">

    <div style="height:100px;"></div>

    <div class="card card-outline card-primary container-lg">
        <div class="card-header text-center">
            <img class="img-fluid" src="assets/images/image_logo2.jpg" alt="logo">
        </div>
        <div class="card-header p-4 text-center">
            <div class="box box-black">
                <div class="box-title" *ngIf="registra_operacion==='PERSONA NATURAL'" style="height:150px; align-items: center; display: flex; justify-content: center;">
                    <h2><b>Solicitud para Persona Natural</b></h2>
                </div>
                <div class="box-title" *ngIf="registra_operacion==='ACTOR PRODUCTIVO MINERO'" style="height:150px; align-items: center; display: flex; justify-content: center;">
                    <h2><b>Solicitud para Actor Productivo Minero</b></h2>
                </div>
                <div class="box-title" *ngIf="registra_operacion === 'SERVICIOS PERSONA NATURAL' || registra_operacion === 'SERVICIOS ACTOR PRODUCTIVO MINERO'" style="height:150px; align-items: center; display: flex; justify-content: center;">
                    <h2><b>Realizar la Solicitud de Servicios</b></h2>
                </div>
            </div>
        </div>
        <div class="card-body" style="width:100%;">
            <p class="login-box-msg"></p>

                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="formVistaServiciosRegistrados" autocomplete="off" class="text-default">
                                <div class="box">

                                    <div class="box-content" *ngIf="registra_operacion==='PERSONA NATURAL'">
                                        <hr>
                                        <label class="control-label"> Dirección Departamental o Regional donde se entregará el servicio: </label>
                                        <div class="controls">
                                            <ng-select formControlName="sucursal_pn">
                                                <ng-option value="">[Elija una opción]</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA</ng-option>
                                            </ng-select>
                                        </div>
                                        <hr>
                                        <label class="control-label"> Nombre completo: </label>
                                        <div class="controls">
                                            <input type="text" class="form-control" formControlName="nombre_completo_pn" oninput="this.value = this.value.toUpperCase()" />
                                        </div>
                                        <hr>
                                        <label class="control-label"> CI: </label>
                                        <div class="controls">
                                            <input type="text" class="form-control" formControlName="numero_ci_pn" maxlength="13" oninput="this.value = this.value.toUpperCase()" />
                                        </div>
                                        <hr>
                                        <label class="control-label"> Expedido: </label>
                                        <div class="controls">
                                            <ng-select formControlName="expedido_pn">
                                                <ng-option value="">[Elija una opción]</ng-option>
                                                <ng-option value="LA PAZ">LA PAZ</ng-option>
                                                <ng-option value="COCHABAMBA">COCHABAMBA</ng-option>
                                                <ng-option value="SANTA CRUZ">SANTA CRUZ</ng-option>
                                                <ng-option value="PANDO">PANDO</ng-option>
                                                <ng-option value="BENI">BENI</ng-option>
                                                <ng-option value="ORURO">ORURO</ng-option>
                                                <ng-option value="POTOSI">POTOSI</ng-option>
                                                <ng-option value="CHUQUISACA">CHUQUISACA</ng-option>
                                                <ng-option value="TARIJA">TARIJA</ng-option>
                                                <ng-option value="EXTERNO">EXTERNO</ng-option>
                                            </ng-select>
                                        </div>
                                        <hr>
                                            <!-- <table class="table table-condensed">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> -->
                                    </div>

                                    <div class="box-content" *ngIf="registra_operacion==='ACTOR PRODUCTIVO MINERO'">
                                        <hr>
                                        <label class="control-label"> Dirección Departamental o Regional donde se entregará el servicio: </label>
                                        <div class="controls">
                                            <ng-select formControlName="sucursal_apm">
                                                <ng-option value="">[Elija una opción]</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA</ng-option>
                                            </ng-select>
                                        </div>
                                        <hr>
                                        <label class="control-label"> Denominacion Titular: </label>
                                        <div class="controls">
                                            <input type="text" class="form-control" formControlName="denominacion_titular_apm" oninput="this.value = this.value.toUpperCase()" />
                                        </div>
                                        <hr>
                                        <label class="control-label"> Nombre completo: </label>
                                        <div class="controls">
                                            <input type="text" class="form-control" formControlName="nombre_completo_apm" oninput="this.value = this.value.toUpperCase()" />
                                        </div>
                                        <hr>
                                        <label class="control-label"> CI: </label>
                                        <div class="controls">
                                            <input type="text" class="form-control" formControlName="numero_ci_apm" maxlength="13" oninput="this.value = this.value.toUpperCase()" />
                                        </div>
                                        <hr>
                                        <label class="control-label"> Expedido: </label>
                                        <div class="controls">
                                            <ng-select formControlName="expedido_apm">
                                                <ng-option value="">[Elija una opción]</ng-option>
                                                <ng-option value="LA PAZ">LA PAZ</ng-option>
                                                <ng-option value="COCHABAMBA">COCHABAMBA</ng-option>
                                                <ng-option value="SANTA CRUZ">SANTA CRUZ</ng-option>
                                                <ng-option value="PANDO">PANDO</ng-option>
                                                <ng-option value="BENI">BENI</ng-option>
                                                <ng-option value="ORURO">ORURO</ng-option>
                                                <ng-option value="POTOSI">POTOSI</ng-option>
                                                <ng-option value="CHUQUISACA">CHUQUISACA</ng-option>
                                                <ng-option value="TARIJA">TARIJA</ng-option>
                                                <ng-option value="EXTERNO">EXTERNO</ng-option>
                                            </ng-select>
                                        </div>
                                        <hr>

                                        <!-- <table class="table table-condensed">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table> -->
                                    </div>

                                    <div class="box-content" *ngIf="registra_operacion === 'SERVICIOS PERSONA NATURAL' || registra_operacion === 'SERVICIOS ACTOR PRODUCTIVO MINERO'">
                                        <div class="div1">
                                            <label class="control-label">Fecha y Hora de Solicitud del Servicio:</label>
                                        </div>
                                        <div class="div2">
                                        <input type="input" class="form-control" formControlName="fecha" [ngModel]='fecha | date:"dd/MM/yyyy HH:mm:ss"' readonly/>
                                        </div>

                                        <div class="div1">
                                            <label class="control-label">Dirección Departamental o Regional donde se entregará el servicio:</label>
                                        </div>
                                        <div class="div2">
                                            <ng-select formControlName="sucursal">
                                                <ng-option value="">[Elija una opción]</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA</ng-option>
                                                <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA</ng-option>
                                            </ng-select>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-title">
                                                        <i class="bi bi-calendar2-week"></i> DATOS PERSONALES DEL SOLICITANTE
                                                    </div>
                                                    <div class="box-content">
                                                        <div class="row">
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Nombre Completo: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="nombre_completo" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> CI: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="numero_ci" maxlength="20" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Expedido: </label>
                                                                <div class="controls">
                                                                    <ng-select formControlName="expedido" [clearable]="false">
                                                                        <ng-option value="">[Elija una opción]</ng-option>
                                                                        <ng-option value="LA PAZ">LA PAZ</ng-option>
                                                                        <ng-option value="COCHABAMBA">COCHABAMBA</ng-option>
                                                                        <ng-option value="SANTA CRUZ">SANTA CRUZ</ng-option>
                                                                        <ng-option value="PANDO">PANDO</ng-option>
                                                                        <ng-option value="BENI">BENI</ng-option>
                                                                        <ng-option value="ORURO">ORURO</ng-option>
                                                                        <ng-option value="POTOSI">POTOSI</ng-option>
                                                                        <ng-option value="CHUQUISACA">CHUQUISACA</ng-option>
                                                                        <ng-option value="TARIJA">TARIJA</ng-option>
                                                                        <ng-option value="EXTERNO">EXTERNO</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Telefono/Celular: </label>
                                                                <div class="controls">
                                                                    <input type="text" (keypress)="validateFormat($event)" class="form-control" formControlName="telefono" maxlength="8"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Representa a: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="representa" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3" *ngIf="registra_operacion === 'SERVICIOS PERSONA NATURAL'">
                                                                <label class="control-label"> Tipo Actor Minero: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="tipo_actor_minero2" value="PERSONA NATURAL" readonly/>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3" *ngIf="registra_operacion === 'SERVICIOS ACTOR PRODUCTIVO MINERO'" >
                                                                <label class="control-label"> Tipo Actor Minero: </label>
                                                                <div class="controls">
                                                                    <ng-select formControlName="tipo_actor_minero" [clearable]="false">
                                                                        <ng-option value="">[Elija una opción]</ng-option>
                                                                        <ng-option value="COOPERATIVA">COOPERATIVA</ng-option>
                                                                        <ng-option value="EMPRESA">EMPRESA</ng-option>
                                                                        <ng-option value="ESTATAL">ESTATAL</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Correo Electrónico: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="correo_electronico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 mt-3">
                                                                <label class="control-label"> Dirección: </label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="direccion" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- <table class="table table-condensed">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td >
                                                                        
                                                                    </td>
                                                                    <td >
                                                                        
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td colspan="2">
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-title">
                                                        <i class="icon-table"></i> DATOS PARA EFECTOS DE FACTURACIÓN
                                                    </div>
                                                    <div class="box-content">
                                                        <div class="row">
                                                            <div class="col-md-6 mt-3">
                                                                <label class="control-label">Nombre/Razón Social:</label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="razon_social" style="width: 100%;" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label class="control-label">N.I.T.:</label>
                                                                <div class="controls">
                                                                    <input type="text" class="form-control" formControlName="nit" maxlength="13" oninput="this.value = this.value.toUpperCase()" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-title">
                                                        <i class="icon-table"></i> SERVICIOS DEL CATASTRO
                                                    </div>
                                                    <div class="box-content">
                                                        <ng-container>
                                                            <div class="col-md-12 mt-3">
                                                                <label>Escriba y selecciones el/los servicio/s requeridos/s:</label>
                                                                <div class="form-group">
                                                                    <select class="form-control" formControlName="serviciosSeleccionado" id="serviciosSeleccionado" (change)="onChangeSeleccion($event)">
                                                                        <option value=""></option>
                                                                        <ng-container *ngFor="let item of listaServiciosCodificador">
                                                                            <option *ngIf='item.nombre.split("&&")[1] === registra_operacion' [value]="item.id">{{ item.nombre.split("&&")[0] }}</option>
                                                                            <option *ngIf='item.nombre.split("&&")[1] === "AMBOS"' [value]="item.id">{{ item.nombre.split("&&")[0] }}</option>
                                                                        </ng-container>
                                                                    </select>
                                                                    <!-- <ng-select (change)="ejecutarOperacion({operacion: 'seleccionar-servicios', id: $event})"
                                                                        [clearable]="true" formControlName="serviciosSeleccionado" id="serviciosSeleccionado">
                                                                        <ng-option value="">[Elija una opción]</ng-option>
                                                                        <ng-container *ngFor="let item of listaServiciosCodificador">
                                                                            <ng-option *ngIf='item.nombre.split("&&")[1] === registra_operacion' [value]="item.id">
                                                                                {{ item.nombre.split("&&")[0] }}
                                                                            </ng-option>
                                                                            <ng-option *ngIf='item.nombre.split("&&")[1] === "AMBOS"' [value]="item.id">
                                                                                {{ item.nombre.split("&&")[0] }}
                                                                            </ng-option>
                                                                        </ng-container>
                                                                    </ng-select> -->
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div id="parametrosServicio" *ngIf="registra_servicio === true">
                                                            <div class="table-responsive">
                                                                <table class="tabla_servicios">
                                                                    <tr>
                                                                        <th width="">A seleccionado el siguiente servicio: </th>
                                                                        <td>
                                                                            <span id="nombre_servicio" style="color:blue">
                                                                                {{ codigo_descripcion }}
                                                                                <input #servicio_grupo type="hidden" class="form-control" name="servicioGrupo" style="width:100px;" value="Servicio: {{ codigo_descripcion }} - Grupo: {{ grupo_servicio }}"/>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <input #id_servicio_sel type="hidden" class="form-control" name="id_servicio_sel" style="width:100px;" value="{{ id_servicio }}"/>
                                                                    <input #grupo type="hidden" class="form-control" name="grupo" style="width:100px;" value="{{ grupo_servicio }}"/>
                                                                    <tr>
                                                                        <th>Y tiene el siguiente Costo Unitario: </th>
                                                                        <td>
                                                                            <span id="costo_unitario" style="color:green">
                                                                                {{ costo_servicio }}
                                                                                <input #costo_serv type="hidden" class="form-control" name="costo_serv" style="width:100px;" value="{{ costo_servicio }}"/>
                                                                                <input #costo_servicio_numero type="hidden" class="form-control" name="costo_servicio_numero" style="width:100px;" value="{{ costo_servicio_numero_n }}"/>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Escriba la cantidad requerida del Servicio: </th>
                                                                        <td>
                                                                            <input #cantidad type="text" (keyup)="onKeyUp($event)" (keypress)="validateFormat($event)" class="form-control" formControlName="cantidad" name="cantidad" style="width:100px;" value="1"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Calculado:</th>
                                                                        <td>
                                                                            <span id="costo_calculado" style="color:red">
                                                                                {{ costo_servicio_selec }}
                                                                                <input #costo_serv_sel type="hidden" class="form-control" name="costo_serv_sel" style="width:100px;" value="{{ costo_servicio_selec }}"/>
                                                                                <input #costo_serv_sel_numero type="hidden" class="form-control" name="costo_serv_sel_numero" style="width:100px;" value="{{ costo_servicio_selec_numero }}"/>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Requisitos: </th>
                                                                        <td>
                                                                            <div *ngIf="grupo_servicio === 'GRUPO 1' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Contar con coordenadas UTM y/o código de cuadrícula minera y/o código único.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;" width="50px">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Contar con coordenadas UTM y/o código de cuadrícula minera y/o código único." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia de C.I. del solicitante.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia de carnet del solicitante." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="grupo_servicio === 'GRUPO 2'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del estado de cuenta o proforma de pago correspondiente o fotocopia del formulario de pago de Patente Minera (Correspondiente a la última gestión).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Fotocopia del estado de cuenta o proforma de pago correspondiente o fotocopia del formulario de pago de Patente Minera (Correspondiente a la última gestión)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia de C.I. del titular, representante legal o apoderado.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Fotocopia de C.I. del titular, representante legal o apoderado." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del testimonio de poder (si corresponde).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Fotocopia del testimonio de poder (si corresponde)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="codigo_servicio === 'C-01' || codigo_servicio === 'C-02'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Certificado de constancia de pago de patente minera (Correspondiente a la ultima gestión).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Certificado de constancia de pago de patente minera (Correspondiente a la ultima gestión)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia de C.I. del titular, representante legal o apoderado.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Fotocopia de C.I. del titular, representante legal o apoderado." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del testimonio de poder (si corresponde).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]"  value="Fotocopia del testimonio de poder (si corresponde)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="codigo_servicio === 'SC-01' || codigo_servicio === 'SC-02'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Certificado de Inscripción en Registro Minero y/o Fotocopia del Certificado de Trámite de Adecuación.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Certificado de Inscripción en Registro Minero y/o Fotocopia del Certificado de Trámite de Adecuación." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia de C.I. del titular, representante Legal o apoderado.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia de C.I. del titular, representante Legal o apoderado." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Testimonio de Poder (si corresponde).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Testimonio de Poder (si corresponde)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="codigo_servicio === 'G-01' || codigo_servicio === 'G-02' || codigo_servicio === 'G-03'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Certificado de Trámite de Adecuación.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Certificado de Trámite de Adecuación." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia de C.I. del titular, representante legal o apoderado.</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia de C.I. del titular, representante legal o apoderado." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Fotocopia del Testimonio de Poder (si corresponde).</span>
                                                                                                </td>
                                                                                                <td style="text-align: center;">
                                                                                                    <input type="checkbox" name="checkbox[]" value="Fotocopia del Testimonio de Poder (si corresponde)." checked="true" disabled>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="codigo_servicio === 'M-01'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Especificar la fecha de la Gaceta.</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input type="date" class="form-control" formControlName="fecha_gaceta" style="width:100%;"/>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <div *ngIf="codigo_servicio === 'M-02'">
                                                                                <span id="requisitos">
                                                                                    <table width="100%" cellpadding="8" border="2">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Especificar la fecha de la Gaceta.</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input type="date" class="form-control" formControlName="fecha_gaceta" style="width:100%;"/>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Especificar el departamento.</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <ng-select formControlName="departamento_gaceta" style="width:100%;">
                                                                                                        <ng-option value="">[Elija una opción]</ng-option>
                                                                                                        <ng-option value="LA PAZ">LA PAZ</ng-option>
                                                                                                        <ng-option value="COCHABAMBA">COCHABAMBA</ng-option>
                                                                                                        <ng-option value="SANTA CRUZ">SANTA CRUZ</ng-option>
                                                                                                        <ng-option value="PANDO">PANDO</ng-option>
                                                                                                        <ng-option value="BENI">BENI</ng-option>
                                                                                                        <ng-option value="ORURO">ORURO</ng-option>
                                                                                                        <ng-option value="POTOSI">POTOSI</ng-option>
                                                                                                        <ng-option value="CHUQUISACA">CHUQUISACA</ng-option>
                                                                                                        <ng-option value="TARIJA">TARIJA</ng-option>
                                                                                                    </ng-select>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Especificar Tomo de la Gaceta.</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <ng-select formControlName="tomo" style="width:100%;">
                                                                                                        <ng-container *ngFor="let item_num of items_numbers">
                                                                                                            <ng-option [value]="item_num">{{item_num}}</ng-option>
                                                                                                        </ng-container>
                                                                                                    </ng-select>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="Estilo4">Especificar la Denominacion del Área Minera.</span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input type="text" class="form-control" name="denominacion_area_minera_gaceta" id="denominacion_area_minera_gaceta" formControlName="denominacion_area_minera_gaceta" style="width:100%;" oninput="this.value = this.value.toUpperCase()" />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </span>
                                                                            </div>
                                                                            <input #requisitos_sel type="hidden" name="requisitos_sel" id="requisitos_sel" value="{{ requisitos }}">
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="codigo_servicio === 'M-01' || codigo_servicio === 'M-02'">
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota: </strong>Toda publicación de la Gaceta Nacional Minera (ediciones pasadas) tendrá el costo definido en este Catálogo de Servicios. Verifique las publicaciones, accediendo a: <a href="https://www.autoridadminera.gob.bo/public/index.php/informacion_institucional/content,1407.html" target="_blank">Gaceta Minera</a>.</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12'">
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota 1: </strong>Las coordenadas UTM WGS-84 o código de cuadrícula o denominación del área minera, corresponderá al centro de hoja.</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12'">
                                                                        <th>Selecciona una de las opciones: </th>
                                                                        <td>
                                                                            <table width="100%" cellpadding="8" border="2">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style="text-align: center;">
                                                                                            <div id="cbox_a1">
                                                                                                <label>Informativo: </label>
                                                                                                <div style="width:100%;"></div>
                                                                                                <input type="checkbox" id="cbox_tiene_a1" name="cbox_tiene_a1" value="second_checkbox_a1" (change)="cambioEstadoA1($event)">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <div id="cbox_a2">
                                                                                                <label>Plan de Trabajo: </label>
                                                                                                <div style="width:100%;"></div>
                                                                                                <input type="checkbox" id="cbox_tiene_a2" name="cbox_tiene_a2" value="second_checkbox_a2" (change)="cambioEstadoA2($event)">
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verInformativo === 'TieneVisualizacion' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                        <th>Selecciona una de las opciones con las que cuenta: </th>
                                                                        <td>
                                                                            <table width="100%" cellpadding="8" border="2">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style="text-align: center;">
                                                                                            <div id="cbox_a1">
                                                                                                <label>Coordenadas UTM: </label>
                                                                                                <div style="width:100%;"></div>
                                                                                                <input type="checkbox" id="cbox_tiene_b1" name="cbox_tiene_b1" value="second_checkbox_b1" (change)="cambioEstadoB1($event)">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <div id="cbox_a2">
                                                                                                <label>Código de Cuadrícula: </label>
                                                                                                <div style="width:100%;"></div>
                                                                                                <input type="checkbox" id="cbox_tiene_b2" name="cbox_tiene_b2" value="second_checkbox_b2" (change)="cambioEstadoB2($event)">
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verPlandeTrabajo === 'TieneVisualizacion' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                        <th>Buscar el Área Minera: </th>
                                                                        <td>
                                                                            <table width="100%" cellpadding="8" border="2">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código Único.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" class="numeros" name="codigo_unico" id="codigo_unico" class="form-control" formControlName="codigo_unico" maxlength="8" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código de Padron Nacional.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" name="padron_nacional" id="padron_nacional" class="form-control" formControlName="padron_nacional" maxlength="10" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="buscar_area_minera" class="btn btn-info" value="Verificar Área Minera" (click)="ejecutarAccion('encontrar_area_minera')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verAreaMineraObtenida === 'TieneVisualizacion' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                        <th>Área Minera Obtenida: </th>
                                                                        <td>
                                                                            <table class="table table-condensed">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Denominación del Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="denominacion_area_minera_obtenido" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Tipo Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="tipo_area_minera_obtenido" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Codigo Único: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="codigo_unico_obtenido" [readonly]="true" />
                                                                                                <input type="hidden" class="form-control" formControlName="id_area_minera_obtenido" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Padron Nacional: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="padron_nacional_obtenido" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="volver_buscar_area_minera" class="btn btn-warning" value="Volver a buscar Área Minera" (click)="ejecutarAccion('retornar_busqueda_area_minera')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verAreaMineraSc === 'TieneVisualizacion' && (codigo_servicio === 'SC-01' || codigo_servicio === 'SC-02')">
                                                                        <th>Buscar el Área Minera: </th>
                                                                        <td>
                                                                            <table width="100%" cellpadding="8" border="2">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código Único.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" class="numeros" name="codigo_unico_sc_a" id="codigo_unico_sc_a" class="form-control" formControlName="codigo_unico_sc_a" maxlength="8" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código de Padron Nacional.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" name="padron_nacional_sc_a" id="padron_nacional_sc_a" class="form-control" formControlName="padron_nacional_sc_a" maxlength="10" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="buscar_area_minera_sc_a" class="btn btn-info" value="Verificar Área Minera" (click)="ejecutarAccion('encontrar_area_minera_sc_a')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verAreaMineraObtenida_sc_a === 'TieneVisualizacion' && (codigo_servicio === 'SC-01' || codigo_servicio === 'SC-02')">
                                                                        <th>Área Minera Obtenida: </th>
                                                                        <td>
                                                                            <table class="table table-condensed">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Denominación del Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="denominacion_area_minera_obtenido_sc_a" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Tipo Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="tipo_area_minera_obtenido_sc_a" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Codigo Único: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="codigo_unico_obtenido_sc_a" [readonly]="true" />
                                                                                                <input type="hidden" class="form-control" formControlName="id_area_minera_obtenido_sc_a" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Padron Nacional: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="padron_nacional_obtenido_sc_a" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="volver_buscar_area_minera" class="btn btn-warning" value="Volver a buscar Área Minera" (click)="ejecutarAccion('retornar_busqueda_area_minera_sc_a')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verAreaMineraG === 'TieneVisualizacion' && (codigo_servicio === 'G-01' || codigo_servicio === 'G-02' || codigo_servicio === 'G-03')">
                                                                        <th>Buscar el Área Minera: </th>
                                                                        <td>
                                                                            <table width="100%" cellpadding="8" border="2">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código Único.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" class="numeros" name="codigo_unico_g" id="codigo_unico_g" class="form-control" formControlName="codigo_unico_g" maxlength="8" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td width="">
                                                                                            <span class="Estilo4">Digite el Código de Padron Nacional.</span>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <input type="text" name="padron_nacional_g" id="padron_nacional_g" class="form-control" formControlName="padron_nacional_g" maxlength="10" style="width:100%;" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="buscar_area_minera_g" class="btn btn-info" value="Verificar Área Minera" (click)="ejecutarAccion('encontrar_area_minera_g')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verAreaMineraObtenida_g === 'TieneVisualizacion' && (codigo_servicio === 'G-01' || codigo_servicio === 'G-02' || codigo_servicio === 'G-03')">
                                                                        <th>Área Minera Obtenida: </th>
                                                                        <td>
                                                                            <table class="table table-condensed">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Denominación del Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="denominacion_area_minera_obtenido_g" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Tipo Área Minera: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="tipo_area_minera_obtenido_g" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <label class="control-label"> Codigo Único: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="codigo_unico_obtenido_g" [readonly]="true" />
                                                                                                <input type="hidden" class="form-control" formControlName="id_area_minera_obtenido_g" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label class="control-label"> Padron Nacional: </label>
                                                                                            <div class="controls">
                                                                                                <input type="text" class="form-control" formControlName="padron_nacional_obtenido_g" [readonly]="true" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2">
                                                                                            <input type="button" id="volver_buscar_area_minera_g" class="btn btn-warning" value="Volver a buscar Área Minera" (click)="ejecutarAccion('retornar_busqueda_area_minera_g')">
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verCoordenadaUTM === 'TieneVisualizacion' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                        <td colspan="2">
                                                                            <div id="contenido2">
                                                                                <table id="tab_parm2" class="tabla_servicios">
                                                                                    <tr>
                                                                                        <td colspan="5"></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <div id="contenido1">
                                                                                <table class="tabla_servicios">
                                                                                    <tr>
                                                                                        <th width="46">Zona:</th>
                                                                                        <td>
                                                                                            <select id="zona" name="zona" class="form-control" formControlName="zona">
                                                                                                <option value="">[Elija una opción]</option>
                                                                                                <option value="19">19</option>
                                                                                                <option value="20">20</option>
                                                                                                <option value="21">21</option>
                                                                                            </select>
                                                                                        </td>
                                                                                        <th width="46">Este:</th>
                                                                                        <!--<td><input type="text" (keypress)="validateFormat($event)" class="form-control" name="este" id="este_controlador" formControlName="este" minlength="6" maxlength="6" style="width:80px;" /></td>-->
											<td><input type="text" [ngModel]="value" (ngModelChange)="onChange($event)" class="form-control" name="este" id="este_controlador" formControlName="este" minlength="6" maxlength="6" style="width:210px;" placeholder="Digite solo números" numbersOnly/></td>
                                                                                        <th width="48">Norte:</th>
                                                                                        <!--<td><input type="text" (keypress)="validateFormat($event)" class="form-control" name="norte" id="norte_controlador" formControlName="norte" minlength="7" maxlength="7" style="width:80px;" /></td>-->
											<td><input type="text" [ngModel]="value" (ngModelChange)="onChange($event)" class="form-control" name="norte" id="norte_controlador" formControlName="norte" minlength="7" maxlength="7" style="width:210px;" placeholder="Digite solo números" numbersOnly/></td>
                                                                                        <th width="46" bordercolor="#000000">Escala:</th>
                                                                                        <td>
                                                                                            <select name="escala" class="form-control" formControlName="escala">
                                                                                                <option value="">[Elija una opción]</option>
                                                                                                <option value="1:5.000">1:5.000</option>
                                                                                                <option value="1:10.000">1:10.000</option>
                                                                                                <option value="1:15.000">1:15.000</option>
                                                                                                <option value="1:20.000">1:20.000</option>
                                                                                                <option value="1:25.000">1:25.000</option>
                                                                                                <option value="1:30.000">1:30.000</option>
                                                                                            </select>
                                                                                        </td>
                                                                                        <th width="121">Orientación Hoja: </th>
                                                                                        <td>
                                                                                            <select name="orientacion" class="form-control" formControlName="orientacion">
                                                                                                <option value="">[Elija una opción]</option>
                                                                                                <option value="VERTICAL">VERTICAL</option>
                                                                                                <option value="1HORIZONTAL">HORIZONTAL</option>
                                                                                            </select>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <input type="hidden" #datos_adicionales_controlador id="datos_adicionales_controlador" name="datos_adicionales_controlador" value="S/O" style="width: 882px; height: 51px;"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="verCodigoCuadricula === 'TieneVisualizacion' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12')">
                                                                        <td colspan="2">
                                                                            <div id="contenido4">
                                                                                <table id="tab_parm2" class="tabla_servicios">
                                                                                    <tr>
                                                                                        <td colspan="5"></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <div id="contenido3">
                                                                                <table class="tabla_servicios">
                                                                                    <tr>
                                                                                        <th width="48">Codigo de Cuadricula:</th>
                                                                                        <!--<td><input type="text" (keypress)="validateFormat($event)" class="form-control" name="codigo_cuadricula" id="codigo_cuadricula_controlador" formControlName="codigo_cuadricula" minlength="11" maxlength="11" style="width:350px;" /></td>-->
											<td><input type="text" [ngModel]="value" (ngModelChange)="onChange($event)" class="form-control" name="codigo_cuadricula" id="codigo_cuadricula_controlador" formControlName="codigo_cuadricula" minlength="11" maxlength="11" style="width:350px;" placeholder="Digite solamente números" numbersOnly/></td>
                                                                                        <th width="46" bordercolor="#000000">Escala:</th>
                                                                                        <td>
                                                                                            <select name="escala_codigo_cuadricula" class="form-control" formControlName="escala_codigo_cuadricula">
                                                                                                <option value="">[Elija una opción]</option>
                                                                                                <option value="1:5.000">1:5.000</option>
                                                                                                <option value="1:10.000">1:10.000</option>
                                                                                                <option value="1:15.000">1:15.000</option>
                                                                                                <option value="1:20.000">1:20.000</option>
                                                                                                <option value="1:25.000">1:25.000</option>
                                                                                                <option value="1:30.000">1:30.000</option>
                                                                                            </select>
                                                                                        </td>
                                                                                        <th width="121">Orientación Hoja: </th>
                                                                                        <td>
                                                                                            <select name="orientacion_codigo_cuadricula" class="form-control" formControlName="orientacion_codigo_cuadricula">
                                                                                                <option value="">[Elija una opción]</option>
                                                                                                <option value="VERTICAL">VERTICAL</option>
                                                                                                <option value="HORIZONTAL">HORIZONTAL</option>
                                                                                            </select>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <input type="hidden" #datos_adicionales_controlador id="datos_adicionales_controlador" name="datos_adicionales_controlador" value="S/O" style="width: 882px; height: 51px;"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <table class="tabla_servicios_botones">
                                                                                <tr>
                                                                                    <td width="5"><input type="button" (click)="ejecutarOperacion({operacion: 'cancelar-lista', id: id_servicio})" id="cancelar_lista" class="btn btn-warning" value="Cancelar">
                                                                                    </td>
                                                                                    <td width="8">
                                                                                        <ng-container *ngIf="codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12'; else elseif1">
                                                                                            <input type="button" (click)="agregarListaConAdicionales(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_con_adicionales" class="btn btn-primary" value="Agregar">
                                                                                        </ng-container>
                                                                                        <ng-template #elseif1>
                                                                                            <ng-container *ngIf="codigo_servicio === 'M-01'; else elseif2">
                                                                                                <input type="button" (click)="agregarListaGaceta1(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                        <ng-template #elseif2>
                                                                                            <ng-container *ngIf="codigo_servicio === 'M-02'; else elseif3">
                                                                                                <input type="button" (click)="agregarListaGaceta2(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                        <ng-template #elseif3>
                                                                                            <ng-container *ngIf="codigo_servicio === 'G-01' || codigo_servicio === 'G-02' || codigo_servicio === 'G-03'; else elseif4">
                                                                                                <input type="button" (click)="agregarListaSinAdicionalesC(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                        <ng-template #elseif4>
                                                                                            <ng-container *ngIf="codigo_servicio === 'SC-01' || codigo_servicio === 'SC-02'; else else1">
                                                                                                <input type="button" (click)="agregarListaSinAdicionalesD(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                        <ng-template #else1>
                                                                                                <input type="button" (click)="agregarListaSinAdicionalesB(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                                        </ng-template>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-title">
                                                        <i class="icon-table"></i> CUADRO DE SERVICIOS SOLICITADOS
                                                    </div>
                                                    <div class="box-content">
                                                        <div id="lista_servicios">
                                                            <div class="table-responsive">
                                                                <table class="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class='estilo1'>
                                                                                <font color="#fff">Servicio</font>
                                                                            </th>
                                                                            <th class='estilo1'>
                                                                                <font color="#fff">Precio Unitario</font>
                                                                            </th>
                                                                            <th class='estilo1'>
                                                                                <font color="#fff">Cantidad</font>
                                                                            </th>
                                                                            <th class='estilo1'>
                                                                                <font color="#fff">Sub Total</font>
                                                                            </th>
                                                                            <th class='estilo1'>
                                                                                <font color="#fff">Opciones</font>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="contenido_servicios">
                                                                        <input type="hidden" id="tiene_servicios" name="tiene_servicios" value="{{servicio_selec}}">
                                                                        <tr>
                                                                            <ng-container *ngIf="servicio_selec === false">
                                                                                <td colspan='5'>No se han seleccionado servicios.</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                        <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                                                            <td>
                                                                                <table class="tabla_servicios">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th colspan="2">
                                                                                                {{ dynamicArray[i].Servicio_Grupo.toString().split("- Grupo:")[0] }} 
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr *ngIf="dynamicArray[i].Tiene_AreaMinera === 'V' && dynamicArray[i].Plan_Trabajo === 'No'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><b>Denominación:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Denominacion_AM }}</td>
                                                                                                            <td><b>Tipo Área Minera:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Tipo_Area_Minera_AM }}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Código Único:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Codigo_Unico_AM }}</td>
                                                                                                            <td><b>Padron Nacional:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Padron_Nacional_AM }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr *ngIf="dynamicArray[i].Tiene_AreaMinera === 'V' && dynamicArray[i].Plan_Trabajo === 'Plan de Trabajo'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td colspan="2"><b>Tipo:</b></td>
                                                                                                            <td colspan="2">{{ dynamicArray[i].Plan_Trabajo }}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Denominación:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Denominacion_AM }}</td>
                                                                                                            <td><b>Tipo Área Minera:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Tipo_Area_Minera_AM }}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Código Único:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Codigo_Unico_AM }}</td>
                                                                                                            <td><b>Padron Nacional:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Padron_Nacional_AM }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr  *ngIf="dynamicArray[i].Tiene_Adicional === 'T' && dynamicArray[i].Tipo_Informativo === 'UTM'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td colspan="2"><b>Tipo:</b></td>
                                                                                                            <td colspan="3">{{ dynamicArray[i].Informativo }}</td>
                                                                                                            <td colspan="2"><b>Tipo Informativo:</b></td>
                                                                                                            <td colspan="3">{{ dynamicArray[i].Tipo_Informativo }}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Zona:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Zona }}</td>
                                                                                                            <td><b>Este:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Este }}</td>
                                                                                                            <td><b>Norte:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Norte }}</td>
                                                                                                            <td><b>Escala:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Escala }}</td>
                                                                                                            <td><b>Orientaciòn Hoja:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Orientacion }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr  *ngIf="dynamicArray[i].Tiene_Adicional === 'T' && dynamicArray[i].Tipo_Informativo === 'CODCUAD'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><b>Tipo:</b></td>
                                                                                                            <td colspan="2">{{ dynamicArray[i].Informativo }}</td>
                                                                                                            <td><b>Tipo Informativo:</b></td>
                                                                                                            <td colspan="2">Codigo de Cuadrícula</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Codigo de Cuadrícula:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Codigo_Cuadricula }}</td>
                                                                                                            <td><b>Escala:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Escala_Codigo_Cuadricula }}</td>
                                                                                                            <td><b>Orientación Hoja:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Orientacion_Codigo_Cuadricula }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr *ngIf="dynamicArray[i].Tiene_Adicional !== 'G'">
                                                                                            <td colspan="2">
                                                                                                {{ dynamicArray[i].Requisitos_Sel }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr *ngIf="dynamicArray[i].Tiene_Adicional === 'G' && dynamicArray[i].Tipo_Gaceta === 'M-01'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><b>Fecha Gaceta:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Fecha_Gaceta }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr *ngIf="dynamicArray[i].Tiene_Adicional === 'G' && dynamicArray[i].Tipo_Gaceta === 'M-02'">
                                                                                            <td colspan="2">
                                                                                                <table width="100%" border="1">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><b>Fecha Gaceta:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Fecha_Gaceta }}</td>
                                                                                                            <td><b>Departamento:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Departamento_Gaceta }}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><b>Tomo:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Tomo }}</td>
                                                                                                            <td><b>Denominacion Area Minera:</b></td>
                                                                                                            <td>{{ dynamicArray[i].Denominacion_Am_Gaceta }}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <input type="hidden" name="tiene_am_check[]" value="false">
                                                                                <input type="hidden" name="area_minera_seleccionada[]" value="">
                                                                                <input type="hidden" name="area_minera_descrita[]" value="">
                                                                                <input type="hidden" name="tipo_servicio[]" value="SC-03-Relación Planimétrica, papel indeformable (tamaño carta). ">
                                                                                <input type="hidden" name="requisitos_seleccionados[]" value="Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.">
                                                                                <input type="hidden" name="zona_seleccionada[]" value="20">
                                                                                <input type="hidden" name="este_seleccionada[]" value="1111">
                                                                                <input type="hidden" name="norte_seleccionada[]" value="111">
                                                                                <input type="hidden" name="escala_seleccionada[]" value="1:20:000">
                                                                                <input type="hidden" name="orientacion_seleccionada[]" value="HORIZONTAL">
                                                                                <input type="hidden" name="plan_trabajo_seleccionada[]" value="false">
                                                                                <input type="hidden" name="escala_variable_seleccionada[]" value="false">
                                                                                <input type="hidden" name="orientacion_variable_seleccionada[]" value="false">
                                                                                <input type="hidden" name="datos_adicionales_registrados[]" value="">
                                                                                <input type="hidden" name="grupo_servicio[]" value="GRUPO 1">
                                                                            </td>
                                                                            <td>
                                                                                {{ dynamicArray[i].Costo_Serv }}
                                                                                <input type="hidden" name="cantidad_registrada[]" value="1">
                                                                            </td>
                                                                            <td> 
                                                                                {{ dynamicArray[i].Cantidad }}
                                                                                <input type="hidden" name="costo_unitario_registrado[]" value=" Bs. 120">
                                                                            </td>
                                                                            <td class="costo_calculado">
                                                                                {{ dynamicArray[i].Costo_Serv_Sel }}
                                                                                <input type="hidden" name="costo_calculado_registrado[]" value="Bs. 120">
                                                                            </td>
                                                                            <td>
                                                                                <input type="hidden" name="ides[]" value="1">
                                                                                <button type="button" (click)="deleteRow(i)" class="btn btn-danger quitar_servicio" value="3"><i class="icon-remove-circle"></i> Quitar Servicio </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="table-responsive">
                                                                <table id="tabla_total" class="tabla_servicios_total">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="40px" nowrap class='estilo1'><font color="#fff">Costo total: </font></td>
                                                                            <td width="200px" align="center"><font color="#fff">Bs. {{ dynamicTotalArray[0] }}</font></td>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-title">
                                                        <i class="icon-table"></i> OBSERVACIONES
                                                    </div>
                                                    <div class="box-content">
                                                        <div class="col-md-12 form-group mb-2">
                                                            <label>Observaciones:</label>
                                                            <textarea class="form-control" rows="3" formControlName="observacion" oninput="this.value = this.value.toUpperCase()" ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row-fluid">
                                            <div class="span12">
                                                <div class="box box-black">
                                                    <div class="box-content">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-row justify-content-end">
                                    <div class="form-group">
                                        <button *ngIf="registra_operacion==='SERVICIOS PERSONA NATURAL' || registra_operacion==='SERVICIOS ACTOR PRODUCTIVO MINERO'" (click)="ejecutarAccion('crear')" type="button" class="btn btn-primary mr-1"
                                            [title]="botonOperacion">
                                            Guardar
                                        </button>
                                        <button *ngIf="registra_operacion==='PERSONA NATURAL'" (click)="ejecutarAccion('enviar_formulario_pn')" type="button" class="btn btn-primary mr-1"
                                            [title]="botonOperacion">
                                            Generar Formulario
                                        </button>
                                        <button *ngIf="registra_operacion==='ACTOR PRODUCTIVO MINERO'" (click)="ejecutarAccion('enviar_formulario_apm')" type="button" class="btn btn-primary mr-1"
                                            [title]="botonOperacion">
                                            Generar Formulario
                                        </button>
                                        <button *ngIf="registra_operacion==='PERSONA NATURAL' || registra_operacion==='ACTOR PRODUCTIVO MINERO'" (click)="ejecutarAccion('cancelar_operacion')" type="button" class="btn btn-dark" title="Cancelar">
                                            Ir a Menu
                                        </button>
                                        <button *ngIf="registra_operacion==='SERVICIOS PERSONA NATURAL'" (click)="ejecutarAccion('cancelar_pn')" type="button" class="btn btn-dark" title="Cancelar">
                                            Cancelar
                                        </button>
                                        <button *ngIf="registra_operacion==='SERVICIOS ACTOR PRODUCTIVO MINERO'" (click)="ejecutarAccion('cancelar_apm')" type="button" class="btn btn-dark" title="Cancelar">
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

        </div>
    </div>

    <div *ngIf="registra_operacion==='PERSONA NATURAL' || registra_operacion==='ACTOR PRODUCTIVO MINERO'" style="height:150px;"></div>

</div>

<ng-template #modalRegistro let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Cargando.......</h4>
    </div>
</ng-template>

<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width:800px; height:900px;">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Documento Cargado</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <embed src="assets/documentos/UNIDAD1.pdf" type="application/pdf" width="100%" height="100%" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div> -->