import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados } from '../../modelos';
import { VistaServiciosRegistradosFilter } from '../../modelos/filtros';

export const establecerFiltroVistaServiciosRegistrados = createAction(
  '[CORRESPONDENCIA] Establecer filtro VISTA_SERVICIOS_REGISTRADOS',
  props<{ filtro: VistaServiciosRegistradosFilter }>()
);
export const establecerListaVistaServiciosRegistrados = createAction(
  '[CORRESPONDENCIA] Establecer lista VISTA_SERVICIOS_REGISTRADOS',
  props<{ lista: VistaServiciosRegistrados[]; paginado: Paginado }>()
);
export const establecerVistaServiciosRegistrados = createAction(
  '[CORRESPONDENCIA] Establecer objeto VISTA_SERVICIOS_REGISTRADOS',
  props<{ objeto: VistaServiciosRegistrados }>()
);
