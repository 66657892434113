import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { VistaServiciosRegistradosFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroVistaServiciosRegistrados(
  state: AppState
): VistaServiciosRegistradosFilter {
  return state.correspondencia.listaVistaServiciosRegistrados?.filtro;
}

function seleccionarPaginadoVistaServiciosRegistrados(
  state: AppState
): Paginado {
  return state.correspondencia.listaVistaServiciosRegistrados?.paginado;
}

export const filtroVistaServiciosRegistradosSelector = createSelector(
  obtenerState,
  seleccionarFiltroVistaServiciosRegistrados
);
export const paginadoVistaServiciosRegistradosSelector = createSelector(
  obtenerState,
  seleccionarPaginadoVistaServiciosRegistrados
);
