<style type="text/css">
    /*stylo CCCS 15/06/2016*/
    .tabla_servicios {
        border-collapse: collapse;
        width: 100%;
    }

    .tabla_servicios td, .tabla_servicios th {
        border: 1px solid black;
        padding: 8px;
    }

    .tabla_servicios_botones {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_botones td, .tabla_servicios_botones th {
        border: 0px solid black;
        padding: 8px;
    }
    .tabla_servicios_total {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_total td, .tabla_servicios_total th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .input_boleta{
        margin-left:10px;
        float:left;
        width:150px;
    }
    .input_depositante{
        text-transform:uppercase;
        width:800px;
    }
    .light{
        color: white;
        font-weight: bolder;
    }
    .modal-body {
        height: 287px;
    }
    /*Fin stylo CCCS 15/06/2016*/
    .box.box-black>.box-title
    {
        background: rgb(4,3,23);
        background: linear-gradient(180deg, rgba(4,3,23,1) 0%, rgba(12,88,115,1) 100%, rgba(102,142,149,1) 100%);
        font-family: Times New Roman;
        font-size: 20px;
        letter-spacing: 0px;
        word-spacing: 0px;
        font-weight: bold;
        text-decoration: none;
        font-style: oblique;
        font-variant: normal;
        text-transform: none;
        color:white;
    }
    /*Estilo para div lado a lado*/
    #div1{
            width: 50%;
            float: left;
            position: relative;
    }
    #div2{
            width: 50%;
            float: right;
            position: relative;
    }
    table thead {
        color: #fff;
        background-color: rgb(44, 62, 88);
    }

    #div4{
        width: 40%;
    }
    #div5{
        width: 100%;
        float: left;
        position: relative;     
    }
    #div6{
        width: 40%;
        float: right;
        position: relative;
    }
</style>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>

<div class="row">
    <div class="col-12">
        <form [formGroup]="formVistaServiciosRegistradosFV" autocomplete="off" class="text-default">
            <div class="box">
                <div class="box-content">
                    <div class="div1">
                        <label class="control-label">Fecha y Hora de Solicitud del Servicio:</label>
                    </div>
                    <div class="div2">
                    <input type="input" class="form-control" formControlName="fecha" value="29/07/2023" readonly />
                    <input type="hidden" id="correlativo_orden_servicio" name="correlativo_orden_servicio" formControlName="correlativo_orden_servicio">
                    <input type="hidden" id="id_soorse" name="id_soorse" formControlName="id_soorse">
                    </div>

                    <hr>

                    <div class="div2">
                        <ng-container>
                            <div class="col-md-6 form-group mb-2">
                                <label>Escriba o seleccione el Área Minera:</label>
                                <div class="col-md-20 form-group mb-20" style="width:1000px;">
                                    <!-- <ng-select (change)="ejecutarOperacion({operacion: 'seleccionar-servicios', id: $event})"
                                        [clearable]="true" formControlName="serviciosSeleccionado">
                                        <ng-option value="">[Elija una opción]</ng-option>
                                        <ng-option *ngFor="let item of listaServiciosCodificadorAreaMinera" [value]="item.id">
                                            {{ item.nombre }}</ng-option>
                                    </ng-select> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="bi bi-calendar2-week"></i> DATOS PERSONALES DEL SOLICITANTE
                                </div>
                                <div class="box-content">
                                    <table class="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Nombre Completo: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.nombre_solicitante }}" readonly />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> CI: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.numero_ci }}" readonly />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Expedido: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.expedido }}" readonly />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Telefono/Celular: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.telefono }}" readonly />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Representa a: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.representa }}" readonly />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Tipo Actor Minero: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.tipo_actor_minero }}" readonly />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Correo Electrónico: </label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.correo_electronico }}" readonly />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> DATOS DEL TITULAR DEL DERECHO PARA EFECTOS DE FACTURACIÓN
                                </div>
                                <div class="box-content">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td width="650px">
                                                    <label class="control-label">Nombre/Razon Social:</label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.razon_social }}" readonly />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label">N.I.T.:</label>
                                                    <div class="controls">
                                                        <input id="myText" type="text" class="form-control" value="{{ vistaServiciosRegistrados.nit }}" readonly />
                                                    </div>
                                                </td>
                                                <!-- <td>
                                                    <label class="control-label">N° Factura:</label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="nfactura" />
                                                    </div>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> CUADRO DE SERVICIOS SOLICITADOS
                                </div>
                                <div class="box-content">
                                    <div id="lista_servicios">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width='calc(100% - 1.6*1em - 1.6*1px)' class='estilo1'>
                                                        <font color="#fff">Servicio</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Precio Unitario</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Cantidad</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Sub Total</font>
                                                    </th>
                                                    <!-- <th width='200' class='estilo1'>
                                                        <font color="#fff">Validar</font>
                                                    </th> -->
                                                </tr>
                                            </thead>
                                            <tbody id="contenido_servicios">

                                                <tr *ngFor="let item of servicioArray">
                                                    <td>
                                                        <table class="tabla_servicios">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="2">
                                                                        {{ item.split('&&')[1] }}
                                                                    </th>
                                                                </tr>
                                                                <tr *ngFor="let item2 of this.retornarArray(item.split('&&')[5])">
                                                                    <td>
                                                                        {{ item2 }}
                                                                    </td>
                                                                    <!-- <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td> -->
                                                                </tr>
                                                                <!-- <tr>
                                                                    <td>
                                                                        Requisitos Unicos
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr> -->
                                                                <!-- <tr>
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Edicion:</td>
                                                                                    <td>50</td>
                                                                                    <td>Tomo:</td>
                                                                                    <td>1</td>
                                                                                    <td>Fecha:</td>
                                                                                    <td>29/07/2023</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Zona:</td>
                                                                                    <td>19</td>
                                                                                    <td>Este:</td>
                                                                                    <td>1122212</td>
                                                                                    <td>Norte:</td>
                                                                                    <td>12221122}</td>
                                                                                    <td>Escala:</td>
                                                                                    <td>1:1020020</td>
                                                                                    <td>Orientaciòn Hoja:</td>
                                                                                    <td>Vertical</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Plan de Trabajo:</td>
                                                                                    <td>SI</td>
                                                                                    <td>Escala Variable:</td>
                                                                                    <td>SI</td>
                                                                                    <td>Orientacion Variable:</td>
                                                                                    <td>SI</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        Datos Adicionales: Detalle Datos Adicionales
                                                                    </td>
                                                                </tr> -->
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        {{ this.convertirNumero(item.split('&&')[2]) }} 
                                                    </td>
                                                    <td> 
                                                        {{ this.convertirNumero(item.split('&&')[3]) }} 
                                                    </td>
                                                    <td class="costo_calculado">
                                                        {{ this.convertirNumero(item.split('&&')[4]) }}
                                                    </td>
                                                    <!-- <td>
                                                        <fieldset>
                                                            <label>
                                                                <input type="radio" name="validacion" value="SI"> SI
                                                            </label>
                                                            <label>
                                                                <input type="radio" name="validacion" value="NO"> NO
                                                            </label>
                                                        </fieldset>
                                                    </td> -->
                                                </tr>
                                                <!-- <tr>
                                                    <ng-container *ngIf="servicio_selec === false">
                                                        <td colspan='5'>No se han seleccionado servicios.</td>
                                                    </ng-container>
                                                </tr> -->
                                                <!-- <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                                    <td>
                                                        <table class="tabla_servicios">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="2">
                                                                        {{ dynamicArray[i].Servicio_Grupo }} 
                                                                    </th>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Tiene_Adicional !== 'G'">
                                                                    <td colspan="2">
                                                                        {{ dynamicArray[i].Requisitos_Sel }}
                                                                    </td>
                                                                </tr>

                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso1' && dynamicArray[i].Archivog11a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[0] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso1' && dynamicArray[i].Archivog12a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[1] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                               
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso3' && dynamicArray[i].Archivog11b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[0] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso3' && dynamicArray[i].Archivog12b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[1] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                
                                                                
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso4' && dynamicArray[i].Archivog21 !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[0] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso4' && dynamicArray[i].Archivog22 !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[1] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso4' && dynamicArray[i].Archivog23 !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[2] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso4' && dynamicArray[i].Archivog24 !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[3] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>


                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso5' && dynamicArray[i].Archivog41a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[0] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso5' && dynamicArray[i].Archivog42a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[1] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso5' && dynamicArray[i].Archivog43a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[2] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso5' && dynamicArray[i].Archivog44a !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[3] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>



                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso6' && dynamicArray[i].Archivog41b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[0] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso6' && dynamicArray[i].Archivog42b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[1] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso6' && dynamicArray[i].Archivog43b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[2] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Caso === 'caso6' && dynamicArray[i].Archivog44b !== 'El archivo no existe'">
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel.toString().split(",")[3] }}
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <button type="button" data-toggle="modal" data-target="#exampleModal">
                                                                            <img width="50" height="50" src="assets/images/pdf_icon.jpg" />
                                                                        </button>
                                                                    </td>
                                                                </tr>

                                                                <tr *ngIf="dynamicArray[i].Tiene_Adicional === 'G'">
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Edicion:</td>
                                                                                    <td>{{ dynamicArray[i].Edicion }}</td>
                                                                                    <td>Tomo:</td>
                                                                                    <td>{{ dynamicArray[i].Tomo }}</td>
                                                                                    <td>Fecha:</td>
                                                                                    <td>{{ dynamicArray[i].Fecha_Gaceta }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr  *ngIf="dynamicArray[i].Tiene_Adicional === 'T'">
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Zona:</td>
                                                                                    <td>{{ dynamicArray[i].Zona }}</td>
                                                                                    <td>Este:</td>
                                                                                    <td>{{ dynamicArray[i].Este }}</td>
                                                                                    <td>Norte:</td>
                                                                                    <td>{{ dynamicArray[i].Norte }}</td>
                                                                                    <td>Escala:</td>
                                                                                    <td>{{ dynamicArray[i].Escala }}</td>
                                                                                    <td>Orientaciòn Hoja:</td>
                                                                                    <td>{{ dynamicArray[i].Orientacion }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr  *ngIf="dynamicArray[i].Tiene_Adicional === 'T'">
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Plan de Trabajo:</td>
                                                                                    <td>{{ dynamicArray[i].plan_trabajo }}</td>
                                                                                    <td>Escala Variable:</td>
                                                                                    <td>{{ dynamicArray[i].escala_variable }}</td>
                                                                                    <td>Orientacion Variable:</td>
                                                                                    <td>{{ dynamicArray[i].orientacion_variable }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        Datos Adicionales: {{ dynamicArray[i].Datos_Adicionales }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        {{ dynamicArray[i].Cantidad }}
                                                        <input type="hidden" name="cantidad_registrada[]" value="1">
                                                    </td>
                                                    <td> 
                                                        {{ dynamicArray[i].Costo_Serv }}
                                                        <input type="hidden" name="costo_unitario_registrado[]" value=" Bs. 120">
                                                    </td>
                                                    <td class="costo_calculado">
                                                        {{ dynamicArray[i].Costo_Serv_Sel }}
                                                        <input type="hidden" name="costo_calculado_registrado[]" value="Bs. 120">
                                                    </td>
                                                    <td>
                                                        <input type="hidden" name="ides[]" value="1">
                                                        <button type="button" (click)="deleteRow(i)" class="btn btn-danger quitar_servicio" value="3"><i class="icon-remove-circle"></i> Quitar Servicio </button>
                                                    </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                        <table id="tabla_total" class="tabla_servicios_total">
                                        <thead>
                                            <tr>
                                                <td width="40px" nowrap class='estilo1'><font color="#fff">Costo total: {{ this.convertirNumero(vistaServiciosRegistrados.monto_total) }} Bs.</font></td>
                                                <!-- <td width="200px" align="center"><font color="#fff">Bs. {{ dynamicTotalArray[0] }}</font></td> -->
                                            </tr>
                                        </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> OBSERVACIONES
                                </div>
                                <div class="box-content">
                                    <div class="col-md-12 form-group mb-2">
                                        <label>Observaciones:</label>
                                        <textarea class="form-control" rows="3" formControlName="observacion" readonly>{{ vistaServiciosRegistrados.observacion }}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-content">
                                
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button (click)="ejecutarAccion('crear')" type="button" class="btn btn-primary mr-1" [title]="botonOperacion">
                        Validar Formulario 
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="button" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>