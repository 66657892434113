<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)" [esConfigurable]="true">
        <hr>
        <div class="row">
            <div class="col-12" [ngClass]="configuracion | pie">
                <span class="font-italic">
                    {{ configuracion.mensaje }}
                </span>
            </div>
            <div class="col-12 text-right small">
                <span class="d-block"><strong>FIRMADO DIGITALMENTE</strong></span>
                <span class="d-block text-muted"><strong>Verificar en</strong> https://validar.firmadigital.bo</span>
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <hr>
    <div class="row">
        <div class="col-12" [ngClass]="configuracion | pie">
            <span class="font-italic">
                {{ configuracion.mensaje }}
            </span>
        </div>
        <div class="col-12 text-right small">
            <span class="d-block"><strong>FIRMADO DIGITALMENTE</strong></span>
            <span class="d-block text-muted"><strong>Verificar en</strong> https://validar.firmadigital.bo</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <hr>
    <div class="row">
        <div class="col-12" [ngClass]="configuracion | pie">
            <span class="font-italic">
                {{ configuracion.mensaje }}
            </span>
        </div>
        <div class="col-12 text-right small">
            <span class="d-block"><strong>FIRMADO DIGITALMENTE</strong></span>
            <span class="d-block text-muted"><strong>Verificar en</strong> https://validar.firmadigital.bo</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <hr>
    <div class="row">
        <div class="col-12" [ngClass]="configuracion | pie">
            <span class="font-italic">
                {{ configuracion.mensaje }}
            </span>
        </div>
        <div class="col-12 text-right small">
            <span class="d-block"><strong>FIRMADO DIGITALMENTE</strong></span>
            <span class="d-block text-muted"><strong>Verificar en</strong> https://validar.firmadigital.bo</span>
        </div>
    </div>
</ng-container>

<ng-template #modalPie let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Pie - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="formConfiguracion" autocomplete="off" class="text-default">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label><span class="required-field-indicator"></span> Mensaje:</label>
                            <textarea class="form-control" rows="2" formControlName="mensaje" ></textarea>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionIzquierda" name="alineacion" value="IZQUIERDA" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionIzquierda">Texto alineado a la izquierda</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionCentro" name="alineacion" value="CENTRO" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionCentro">Texto centrado</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionDerecha" name="alineacion" value="DERECHA" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionDerecha">Texto alineado a la derecha</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit" class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button" class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>