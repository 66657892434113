export class RegistraReactivacion {
    id?: number;
    fk_regsitro_diario: number;
    numero_formulario: string;
    nro_comprobante_bancario: number;
    fk_asignacion_cargo: number;
    operacion: string;
    observacion: string;
    fecha_hora: Date;

    sePuedeModificar?: boolean;
    sePuedeEliminar?: boolean;
  
  }
  