<style type="text/css">
  /*stylo CCCS 15/06/2016*/
  .tabla_servicios {
      border-collapse: collapse;
      width: 100%;
  }

  .tabla_servicios td, .tabla_servicios th {
      border: 1px solid black;
      padding: 8px;
  }

  .tabla_servicios_botones {
      margin: 0px auto;
      border-collapse: collapse;
  }

  .tabla_servicios_botones td, .tabla_servicios_botones th {
      border: 0px solid black;
      padding: 8px;
  }
  .tabla_servicios_total {
      margin: 0px auto;
      border-collapse: collapse;
  }

  .tabla_servicios_total td, .tabla_servicios_total th {
      border: 1px solid #ddd;
      padding: 8px;
  }
  .input_boleta{
      margin-left:10px;
      float:left;
      width:150px;
  }
  .input_depositante{
      text-transform:uppercase;
      width:800px;
  }
  .light{
      color: white;
      font-weight: bolder;
  }
  /* .modal-body {
      height: 287px;
  } */
  /*Fin stylo CCCS 15/06/2016*/
  .box.box-black>.box-title
  {
      background: rgb(4,3,23);
      background: linear-gradient(180deg, rgba(4,3,23,1) 0%, rgba(12,88,115,1) 100%, rgba(102,142,149,1) 100%);
      font-family: Times New Roman;
      font-size: 20px;
      letter-spacing: 0px;
      word-spacing: 0px;
      font-weight: bold;
      text-decoration: none;
      font-style: oblique;
      font-variant: normal;
      text-transform: none;
      color:white;
  }
  /*Estilo para div lado a lado*/
  #div1{
          width: 50%;
          float: left;
          position: relative;
  }
  #div2{
          width: 50%;
          float: right;
          position: relative;
  }
  table thead {
      color: #fff;
      background-color: rgb(44, 62, 88);
  }

  #div4{
      width: 40%;
  }
  #div5{
      width: 100%;
      float: left;
      position: relative;
  }
  #div6{
      width: 40%;
      float: right;
      position: relative;
  }
</style>
<link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
<!-- <link rel="stylesheet" href="/assets/css/font-awesome.min.css" /> -->
<link rel="stylesheet" href="/assets/css/style2.css">

<script src="assets/js/jquery.min.js"></script>
<script src="assets/js/bootstrap.min.js"></script>
<!-- <script src="assets/js/bootstrap-progressbar.min.js"></script>
<script src="assets/js/jquery.countTo.min.js"></script>
<script src="assets/js/jquery.easing.min.js"></script>
<script src="assets/js/jquery.shuffle.min.js"></script>
<script src="assets/js/slick.min.js"></script>
<script src="assets/js/touchswipe.min.js"></script> -->
<!-- <script src="assets/js/script.js"></script> -->

<div class="page-service" style="background-color: #212529;">
  <div class=" container card card-outline">
      <div class="card-header text-center">
          <img class="img-fluid" src="assets/images/logo_AJAM.png" alt="logo">
      </div>
      <div class="card-header p-4 text-center">
          <div class="box box-black">
              <div class="box-title" style="height:150px; align-items: center; display: flex; justify-content: center;">
                  <h2 *ngIf="registra_operacion === 'GUIA'"><b>Guia de Solicitud</b></h2>
                  <h2 *ngIf="registra_operacion === 'VIDEO'"><b>Tutoriales</b></h2>
                  <h2 *ngIf="registra_operacion === 'CONTACTO'"><b>Contactos</b></h2>
                  <h2 *ngIf="registra_operacion === 'FORMULARIO'"><b>Formulario</b></h2>
              </div>
          </div>
      </div>
      <div class="card-body">
        <p class="login-box-msg"></p>
        <div *ngIf="registra_operacion === 'GUIA'">

        <!-- History section -->
        <section class="section-history" id="history">
          <div class="">
              <div class="text-center section-diff-title">
                  <h2>Pasos para elaborar una Solicitud de Orden de Servicio</h2>
              </div>
              <!-- Timeline -->
              <ul class="timeline">
                  <!-- Timeline badge -->
                  <li class="timeline-start">
                      <div class="rectangle"><span style="font-size: 6.1px;">Paso 1</span></div>
                  </li><!-- /.timeline-start -->

                  <!-- Timeline job & description  -->
                  <li>
                      <div class="rectangle timeline-rectangle"></div>
                      <div class="timeline-panel">
                          <div class="timeline-heading">
                              <div class="timeline-date">
                                  <p>Paso 1</p>
                              </div><!-- /.timeline-date -->
                              <div class="timeline-position">
                                  <p>Ingreso desde la página web de la AJAM</p>
                              </div><!-- /.timeline-position -->
                          </div><!-- /.timeline-heading -->
                          <div>
                            <div style="width: 80%; height: 80%; position: absolute; top: 0px; left: 105%;">
                              <img class="img-fluid d-none d-md-block" src="assets/img/imgpaso1.png" alt="">
                            </div><!-- /.timeline-body-thumb -->
                              <div style="text-align: justify;">
                              <p>
                                SI-ADI es un sistema diseñado para facilitar y simplificar a los Actores Productivos Mineros
                                y ciudadanía en general los trámites y plazos en el seguimiento de procesos ante la Autoridad Minera AJAM,
                                habilitando diferentes formas de pago para reducir tiempos y mejorar la eficiencia de la gestión administrativa.
                              </p>
                              <p>
                                Para comenzar, ingrese a la página web de la AJAM, www.autoridadminera.gob.bo.
                              </p>
                              <p>
                                Y diríjase al panel de Servicios en línea, seguidamente seleccione el botón de solicitud de servicios.
                              </p>
                              </div>
                          </div><!-- /.timeline-body -->
                      </div><!-- /.timeline-panel -->
                  </li>

                  <!-- Timeline badge -->
                  <li class="timeline-start">
                      <div class="rectangle"><span style="font-size: 6.1px;">Paso 2</span></div>
                  </li><!-- /.timeline-start -->

                  <!-- Timeline job & description, inverted  -->
                  <li class="timeline-inverted">
                      <div class="rectangle timeline-rectangle"></div>
                      <div class="timeline-panel">
                          <div class="timeline-heading">
                             <div class="timeline-position">
                                  <p>Selección del formulario de Solicitud</p>
                              </div><!-- /.timeline-position -->
                              <div class="timeline-date">
                                  <p>Paso 2</p>
                              </div><!-- /.timeline-date -->
                          </div><!-- /.timeline-heading -->
                          <div>
                              <div style="width: 80%; height: 80%; position: absolute; top: 0px; right: 110%;">
                                <img src="assets/img/imgpaso2.png" alt="" class="img-fluid d-none d-md-block">
                              </div>
                              <div style="text-align: justify;">
                                <p>
                                  <img src="assets/img/pngegg2.png" style="height:22px;width:22px;" />
                                  Si es una Persona Natural, llene el formulario con la dirección departamental o regional donde se entregará el servicio, nombre completo del solicitante y número de identificación. Luego, haga click en Generar Formulario.
                                </p>
                                <p>
                                  <img src="assets/img/pngegg.png" style="height:22px;width:22px;" />
                                  Si es un Actor Productivo Minero, seguirá un proceso similar. Llene el formulario con la dirección departamental o regional donde se entregará el servicio, denominacion del titular, nombre completo del solicitante y número de identificación. Luego, haga click en Generar Formulario.
                                </p>
                              </div>
                          </div><!-- /.timeline-body -->
                      </div><!-- /.timeline-panel -->
                  </li><!-- /.timeline-inverted -->

                  <!-- Timeline Badge  -->
                  <li class="timeline-end">
                      <div class="rectangle"><span style="font-size: 6.1px;">Paso 3</span></div>
                  </li><!-- /.timeline-end -->


                  <!-- Timeline job & description  -->
                  <li>
                    <div class="rectangle timeline-rectangle"></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <div class="timeline-date">
                                <p>Paso 3</p>
                            </div><!-- /.timeline-date -->
                            <div class="timeline-position">
                                <p>Registro de Datos del Solicitante y Facturación</p>
                            </div><!-- /.timeline-position -->
                        </div><!-- /.timeline-heading -->
                        <div>
                            <div style="width: 80%; height: 80%; position: absolute; top: 0px; left: 105%;">
                              <img src="assets/img/imgpaso3.png" alt="" class="img-fluid d-none d-md-block">
                            </div>
                            <div style="text-align: justify;">
                              <p>Dentro del formulario se despliegan los siguientes campos a ser registrados:</p>

                              <p>• <b>Dirección Departamental o Regional donde se entregará el servicio:</b> Permite seleccionar la Dirección Departamental o Regional en la cual hará la entrega del/de los servicio(s) solicitado(s).</p>

                              <p>• <b>Nombre completo:</b> Registre su nombre completo como solicitante<p>

                              <p>• <b>CI:</b> Registre su número de cédula de identidad.</p>

                              <p>• <b>Expedido:</b> Registre el lugar de expedición de su cédula de identidad.</p>

                              <p>• <b>Telefono/Celular:</b> Registre su número de Teléfono o Celular</p>

                              <p>• <b>Representa a:</b> En caso de ser Persona Natural el nombre se copiara automaticamente del campo Nombre Completo en caso de ser APM debe registrar el nombre del Titular</p>

                              <p>• <b>Tipo de Actor Minero:</b> Si es Persona Natural Automaticamente se seleccionará el tipo Persona Natural. En caso de ser APM se despelgarán tres opciones para selección: COOPERATIVA, EMPRESA Y ESTATAL</p>

                              <p>• <b>Correo Electrónico:</b> Debe registrar su cuenta de correo electrónico activa (Nota: Tenga en cuenta que al momento de registrar esta cuenta de correo, se hará el envio a la misma de las notificiacones para pago y facturación)</p>

                              <p>• <b>Dirección:</b> Debe registrar la dirección completa de su domicilio o caso contrario del titular al que representa.</p>

                              <p>• <b>Nombre/Razon Social:</b> Debe registrar el nombre o razon social, para el cual se emitirá la factura.</p>

                              <p>• <b>NIT:</b> Debe registrar el numero de NIT para el cual se emitirá la factura.</p>
                            </div>
                        </div><!-- /.timeline-body -->
                    </div><!-- /.timeline-panel -->
                </li>

                <li class="timeline-end">
                    <div class="rectangle"><span style="font-size: 6.1px;">Paso 4</span></div>
                </li>

                <li class="timeline-inverted">
                  <div class="rectangle timeline-rectangle"></div>
                  <div class="timeline-panel">
                      <div class="timeline-heading">
                         <div class="timeline-position">
                              <p>Selección del o los Servicio(s)</p>
                          </div><!-- /.timeline-position -->
                          <div class="timeline-date">
                              <p>Paso 4</p>
                          </div><!-- /.timeline-date -->
                      </div><!-- /.timeline-heading -->
                      <div>
                          <div style="width: 80%; height: 80%; position: absolute; top: 0px; right: 110%;">
                            <img src="assets/img/imgpaso5.png" alt="" class="img-fluid d-none d-md-block">
                          </div>
                          <div style="text-align: justify;">
                          <p>En la opción seleccione el/los servicio(s) requerido(s), busque el servicio que requiera solicitar, bajo la siguiente consideración:</p>
                          <p><img src="assets/img/pngegg2.png" style="height:22px;width:22px;" /> Si es Persona Natural, se habilitaran los servicios de:</p>
                          <ul>
                              <li>Gaceta Nacional Minera.</li>
                              <li>Relaciones Planimétricas.</li>
                          </ul>
                          <br>
                          <p><img src="assets/img/pngegg.png" style="height:22px;width:22px;" /> Si es Actor Productivo Minero, se habilitaran los servicios de:</p>
                          <ul>
                            <li>Planos Definitivos.</li>
                            <li>Geodésia</li>
                            <li>Gaceta Nacional Minera.</li>
                            <li>Certificaciones.</li>
                            <li>Relaciones Planimétricas.</li>
                          </ul>
                          <br>
                          <p><b>Procedimiento de Registro y Selección (Todos los casos):</b></p>
                          <p>  Se desplegará la descripción del servicio, el costo asociado, la cantidad requerida (debiendo registrar la cantidad que requiere del mismo), el monto total calculado (costo unitario x cantidad requerida).
                          <p>  Tenga en cuenta que los requisitos señalados para este servicio, deberá presentarlos al momento de la entrega del mismo.</p>
                          <p>************************************************************************************************</p>
                          <p>- <b>Servicios para Plano Definitivo (APM):</b> En este caso se encuentran los servicios:</p>
                          <ul>
                            <li>SC-01 Plano definitivo Original, papel indeformable (tamaño 60 cm. x 45 cm.).</li>
                            <li>SC-02 Plano definitivo adicional (Copia), papel bond (tamaño 60 cm. x 45 cm.).</li>
                          </ul>
                          <br>
                          <p>  Para esta opción: Debe registrar el Código Único y Padrón Nacional del Área Minera, correspondiente a los Tipos:  Contratos Mineros, ATE's por Cuadricula, Contrato Administrativo Minero, Licencia de Prospección y Exploración-Otorgada, Contrato Administrativo Minero por Adecuación, Área Revertida y Area Minera Nacionalizada, si él área minera corresponde a uno de los tipos señalados, procederá a agregar el servicio requerido.</p>
                          <p>************************************************************************************************</p>
                          <p>- <b>Servicios para Relaciones Planimétricas (Persona Natural y APM):</b> En este caso se encuentran los servicios: </p>
                          <br>
                          <ul>
                            <li>SC-03 Relación Planimétrica, papel indeformable (tamaño carta).</li>
                            <li>SC-04 Relación Planimétrica, papel bond (tamaño carta).</li>
                            <li>SC-05 Relación Planimétrica para Plan de Trabajo, papel bond (tamaño carta).</li>
                            <li>SC-06 Relación Planimétrica, papel indeformable (tamaño doble carta).</li>
                            <li>SC-07 Relación Planimétrica, papel bond (tamaño doble carta).</li>
                            <li>SC-08 Relación Planimétrica, papel indeformable (tamaño 60 cm. x 45 cm.).</li>
                            <li>SC-09 Relación Planimétrica, papel bond (tamaño 60 cm. x 45 cm.).</li>
                            <li>SC-10 Relación Planimétrica con imagen de satélite, papel indeformable (tamaño doble carta).</li>
                            <li>SC-11 Relación Planimétrica con imagen de satélite, papel couche (tamaño doble carta).</li>
                            <li>SC-12 Relación Planimétrica con imagen de satélite, papel bond (tamaño 60 cm. x 45 cm.).</li>
                          </ul>
                          <br>
                          <p>  Si selecciona la opción informativo: Deberá seleccionar una de las opciones (Coordenadas UTM o Código de Cuadricula). En caso de Coordenadas UTM registrará los campos Zona, Este, Norte, Escala y Orientación de Hoja. En caso de Código de Cuadrícula registrará los campos código de cuadrícula, escala y orientación de hoja.</p>
                          <p>  Si selecciona la opción Plan de Trabajo: Debe registrar el Código Único y Padrón Nacional del Área Minera (vigente), seguidamente procederá a agregar el servicio requerido.</p>
                          <p>************************************************************************************************</p>
                          <p>- <b>Servicios de Geodesia (APM):</b> En este caso se encuentran los servicios:</p>
                          <br>
                          <ul>
                            <li>G-01 Plano Catastral Original, papel indeformable.</li>
                            <li>G-02 Plano Catastral Adicional (Copia), papel bond.</li>
                            <li>G-03 Información de Puntos de Control Suplementario de la Red Geodésica minera nacional.</li>
                          </ul>
                          <br>
                          <p>  Para esta opción: Debe registrar el Código Único y Padrón Nacional del Área Minera, correspondiente a los tipos ATE's por Pertenencia y Área Revertida, si él área minera corresponde a uno de los tipos señalados, procederá a agregar el servicio requerido.</p>
                          <p>************************************************************************************************</p>
                          <p>- <b>Gaceta Naciona Minera (Persona Natural y APM):</b> En este caso se encuentran los servicios:</p>
                          <br>
                          <ul>
                            <li>M-01 Gaceta Nacional Minera (Especial).</li>
                            <li>M-02 Gaceta Nacional Minera (Mensual)</li>
                          </ul>
                          <br>
                          <p>  Para esta opción: Debe registrar los siguientes campos:  Si es Gaceta Nacional Minera (Especial), solo debe registrar la fecha de la gaceta. Si corresponde a la Gaceta Nacional Minera (Mensual) deberá registrar los campos: fecha de gaceta, departamento, tomo y Denominación del Área Minera.</p>
                          <p>************************************************************************************************</p>
                          <p>- <b>Certificaciones (APM):</b> En este caso se encuentran los servicios:</p>
                          <ul>
                            <li>C-01 Duplicado del Certificado de Inscripción en Registro Minero (Conforme a Ley N° 535).</li>
                            <li>C-02 Certificado de Antecedentes en el Registro Minero.</li>
                            <li>C-05 Certificado de Constancia de Pago de Patente Minera.</li>
                          </ul>
                        </div>
                        </div><!-- /.timeline-body -->
                  </div><!-- /.timeline-panel -->
                </li>

                <li class="timeline-end">
                  <div class="rectangle"><span style="font-size: 6.1px;">Paso 5</span></div>
                </li>

                <li>
                  <div class="rectangle timeline-rectangle"></div>
                  <div class="timeline-panel">
                      <div class="timeline-heading">
                          <div class="timeline-date">
                              <p>Paso 5</p>
                          </div><!-- /.timeline-date -->
                          <div class="timeline-position">
                              <p>Registro de Observación y Aceptación</p>
                          </div><!-- /.timeline-position -->
                      </div><!-- /.timeline-heading -->
                      <div>
                          <div style="width: 80%; height: 80%; position: absolute; top: 0px; left: 105%;">
                            <img src="assets/img/imgpaso6.png" alt="" class="img-fluid d-none d-md-block">
                          </div>
                          <div style="text-align: justify;">
                          <p>Para este último registro, en caso de ser necesario, registrará alguna observación o información adicional que complemente al o los servicios seleccionados.</p>
                          <p>Si todos los datos fueron llenados de manera correcta procederá a realizar el envio de la solicitud. (Tenga en cuenta que "Los datos declarados no podrán ser modificados.")</p>
                          <p>Seguidamente será redirigido a la Pasarela de Pagos del Estado y se generará la Proforma de Solicitud de Orden de Servicio.</p>
                          </div>
                        </div><!-- /.timeline-body -->
                  </div><!-- /.timeline-panel -->
                </li>

                <li class="timeline-end">
                  <div class="rectangle"><span style="font-size: 6.1px;">Paso 6</span></div>
                </li>

                <li class="timeline-inverted">
                  <div class="rectangle timeline-rectangle"></div>
                  <div class="timeline-panel">
                      <div class="timeline-heading">
                         <div class="timeline-position">
                              <p>Generacion de la Proforma de Pago</p>
                          </div><!-- /.timeline-position -->
                          <div class="timeline-date">
                              <p>Paso 6</p>
                          </div><!-- /.timeline-date -->
                      </div><!-- /.timeline-heading -->
                      <div>
                          <div style="width: 80%; height: 80%; position: absolute; top: 0px; right: 110%;">
                            <img src="assets/img/imgpaso7.png" alt="" class="img-fluid d-none d-md-block">
                          </div><!-- /.timeline-body-thumb -->
                          <div style="text-align: justify;">
                            <p>Al generarse la proforma de solicitud de Orden de Servicio:</p>
                            <p>Fecha y hora de la solicitud</p>
                            <p>Departamental o Regional donde se entregará el servicio solicitado</p>
                            <p>Nombre Completo del Solicitante</p>
                            <p>C.I. del Solicitante</p>
                            <p>Expedición del C.I. registrado</p>
                            <p>Telefono o Celular del Solicitante o Titular</p>
                            <p>Correo electrónico del Solicitante o Titular</p>
                            <p>Dirección del Solicitiante o Titular</p>
                            <p>Nombre completo del Representante o Titular</p>
                            <p>Tipo de Actor: Persona Natural, Cooperativa, Empresa o Estatal.</p>
                            <p>Descripcion del o los servicios Solicitados (Detalle completo del Servicio, Precio Unitario, Cantidad y Sub Total.)</p>
                            <p>Nombre o Razon Social y NIT para la emision de la Factura</p>
                            <p>Observaciones, información adiciional que aporta al registro de la solicitud.</p>
                          </div>
                        </div><!-- /.timeline-body -->
                  </div><!-- /.timeline-panel -->
              </li><!-- /.timeline-inverted -->

              <li class="timeline-end">
                <div class="rectangle"><span style="font-size: 7.7px;">Paso 7</span></div>
              </li>

              <li>
                <div class="rectangle timeline-rectangle"></div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <div class="timeline-date">
                            <p>Paso 7</p>
                        </div><!-- /.timeline-date -->
                        <div class="timeline-position">
                            <p>Ingreso a la Pasarela de Pagos del Estado</p>
                        </div><!-- /.timeline-position -->
                    </div><!-- /.timeline-heading -->
                    <div>
                        <div style="width: 80%; height: 80%; position: absolute; top: 0px; left: 105%;">
                          <img src="assets/img/imgpaso8.png" alt="" class="img-fluid d-none d-md-block">
                        </div><!-- /.timeline-body-thumb -->
                        <p>Al ingresar a la Pasarela de Pagos del Estado Usted Encontratá los métodos de pago habilitados para este proceso.</p>
                        <p>Seleccion del Método de pago por QR, al hacer click en esta opción, se desplegará un formulario de confirmacion de los datos para efectos de facturación, como ser: Nombre o Razon Social, CI, NIT, Telefono, y cuenta de correo electrónico al cual se enviarán las notificaciones de este proceso. Una vez aceptado este registro se procederá a generar el codigo QR para pago, siendo así que el mismo, podrá ser pagado a través de la plataformas del Banco Union UNINEt o UNIMOVIL.</p>
                        <p>Selección del Método de pago por CPT, al hacer click en esta opci+on, se desplegará un formulario de confirmacion de los datos para efectos de facturación, como ser: Nombre o Razon Social, CI, NIT, Telefono, y cuenta de correo electrónico al cual se enviarán las notificaciones de este proceso. Una vez aceptado este registro se procederá a generar el codigo CPT para pago, siendo así que el mismo, podrá ser pagado a través de la plataformas del Banco Union UNINEt o UNIMOVIL.</p>
                    </div><!-- /.timeline-body -->
                </div><!-- /.timeline-panel -->
              </li>

            <li class="timeline-end">
              <div class="rectangle"><span style="font-size: 6.1px;">Paso 8</span></div>
            </li>

            <li class="timeline-inverted">
              <div class="rectangle timeline-rectangle"></div>
              <div class="timeline-panel">
                  <div class="timeline-heading">
                     <div class="timeline-position">
                          <p>Generacion de la Factura Electrónica</p>
                      </div><!-- /.timeline-position -->
                      <div class="timeline-date">
                          <p>Paso 8</p>
                      </div><!-- /.timeline-date -->
                  </div><!-- /.timeline-heading -->
                  <div>
                      <div style="width: 65%; height: 60%; position: absolute; top: 0px; right: 110%;">
                        <img src="assets/img/imgpaso12.png" alt="" class="img-fluid d-none d-md-block">
                      </div>
                      <p>Una vez se haya realizado el pago a travez de alguna de las plataformas del Banco Union: UNINET o UNIMOVIL, se enviará a su cuenta de correo electrónico activo el respado digital del QR o CPT y la factura electrónica generada, por el pago del servicio a realizar.</p>
                      <p>La Factura contendrá todo el detalle del nombre y NIT del solicitante para efectos de Facturación.</p>
                      <p>También se detalla el o los servicios solicitados; así como el costo sociado, la cantidad requerida y el cosoto total.</p>
                      <p>Y un codigo QR de seguridad.</p>
                  </div><!-- /.timeline-body -->
              </div><!-- /.timeline-panel -->
          </li><!-- /.timeline-inverted -->


              </ul><!-- /.timeline -->
          </div>
        </section>

          <button (click)="ejecutarAccion('cancelar_operacion')" type="button" class="btn" title="Cancelar" style="color: #fff; background-color: #23272b; border-color: #1d2124;">
            Ir a Menu
          </button>
        </div>

        <div *ngIf="registra_operacion === 'FORMULARIO'">
          <form [formGroup]="formRegistraGuia" autocomplete="off" class="form-horizontal" (submit)="buscar()">
            <div class="from-group row">
              <div class="col-sm-6 col-form-label">
                <label for="numero_ci"><span class="required-field-indicator"></span>
                  C.I.
                </label>
                <input type="text" class="form-control" id="numero_ci" formControlName="numero_ci">
              </div>
              <div class="col-sm-6 col-form-label">
                <button type="submit" class="btn btn-dark" style="margin-top: 32px;">Buscar</button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped table-hover border mt-4 ">
                  <thead class="thead-dark">
                    <tr>
                      <th width="15%">Correlativo Servicio</th>
                      <th>Nombre Solicitante</th>
                      <th>Representa a</th>
                      <th>Tipo Actor Minero</th>
                      <th>Cantidad Servicios</th>
                      <th>Servicios Seleccionados</th>
                      <th width="10%">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lista; let indice=index;">
                      <td class="align-middle" width="15%">{{ item.correlativo_servicio }}</td>
                      <td class="align-middle">{{ item.nombre_solicitante }}</td>
                      <td class="align-middle">{{ item.representa }}</td>
                      <td class="align-middle">{{ item.tipo_actor_minero }}</td>
                      <td class="align-middle">{{ item.cantidad_servicios }}</td>
                      <td class="align-middle">{{ item.servicios_seleccionados }}</td>
                      <td class="align-middle" width="10%">
                        <!--<button (click)="reimprimir_proforma(item.id, item.sucursal, item.nombre_solicitante, item.numero_ci, item.expedido, item.telefono, item.correo_electronico, item.direccion, item.representa, item.tipo_actor_minero, item.razon_social, item.nit, item.observacion)" style="color: #fff; background-color: #1677d8; border-color: #0d1d29;">Imprmimir Proforma</button>-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button (click)="ejecutarAccion('cancelar_operacion')" type="button" class="btn" title="Cancelar" style="color: #fff; background-color: #23272b; border-color: #1d2124;">
            Ir a Menu
          </button>
        </div>

        <!-- Panel para videos -->
        <div *ngIf="registra_operacion === 'VIDEO'">
          <!-- Blog Start -->
            <div class="">
              <!-- <div class="d-flex flex-column text-center mb-5">
                  <h4 class="text-primary font-weight-bold">Our Blog</h4>
                  <h4 class="display-4 font-weight-bold">Latest Article From Blog</h4>
              </div> -->
              <div class="row">
                  <div class="col-md-6 mb-5">
                    <div class="embed-responsive embed-responsive-4by3">
                      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/R9NLGACQ2kg?si=kwzkYz8AgCYdDfOD" title="YouTube video player" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                      <div class="d-flex align-items-center mb-4">
                          <div class="d-flex flex-column align-items-center justify-content-center rounded-circle bg-primary text-white" style="width: 80px; height: 80px;">
                              <small>video 01</small>
                          </div>
                          <div>
                              <h3 class="font-weight-bold">Guía de Registro visual para <br/> Persona Natural</h3>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-5">
                    <div class="embed-responsive embed-responsive-4by3">
                      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/9LaMSkQnxME?si=PE8JEaaRNskNgRRv" title="YouTube video player" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div class="d-flex align-items-center mb-4">
                      <div class="d-flex flex-column align-items-center justify-content-center rounded-circle bg-primary text-white" style="width: 80px; height: 80px;">
                          <small>video 02</small>
                      </div>
                      <div>
                          <h3 class="font-weight-bold">Guía de Registro visual para <br/> Actor Productivo Minero</h3>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          <!-- Blog End -->

          <!-- <div class="form-group row">
            <div class="col-6">
              <iframe width="590" height="315" src="https://www.youtube.com/embed/x4CchF7ncQE" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-6">
              <iframe width="590" height="315" src="https://www.youtube.com/embed/1YZo68F3DjU" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
              <iframe width="590" height="315" src="https://www.youtube.com/embed/heTrg2-_ekU" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-6">
              <iframe width="590" height="315" src="https://www.youtube.com/embed/0_NQBJIlwtQ" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div> -->
          <button (click)="ejecutarAccion('cancelar_operacion')" type="button" class="btn" title="Cancelar" style="color: #fff; background-color: #23272b; border-color: #1d2124;">
            Ir a Menu
          </button>
        </div>

      </div>
  </div>
</div>

<!-- <ng-template #modalVistaServiciosRegistrados let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4 class="modal-title">{{ modalTitulo }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div [ngSwitch]="tipoOperacion">
          <app-correspondencia-vista-servicios-registrados-cpt *ngSwitchCase="'cpt'" [tipoOperacion]="tipoOperacion"
              (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-cpt>
      </div>
  </div>
  <div class="modal-footer">
      AJAM
  </div>
</ng-template>
 -->

 <div id="portfolioItem1" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso2.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem2" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso3.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem3" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso4.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem4" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso5.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem5" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso6.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem6" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso7.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem7" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso8.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem8" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso9.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem9" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso10.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem10" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso11.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>

<div id="portfolioItem11" class="modal">
  <div class="modal-dialog">
      <div class="modal-content" style="width:700px; right: 100px;">
          <div class="modal-header">
              <a class="rectangle" data-dismiss="modal"><i class="fa fa-times"></i></a>
              <img class="img-res" src="assets/img/imgpaso12.png" alt="">
          </div>
          <div class="modal-body">
              <h4 class="modal-title">Project title</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo finibus tristique. Maecenas dignissim condimentum sem eu tincidunt. Curabitur in dui quis magna vestibulum pulvinar a ut urna. Nam pellentesque mattis urna. Aenean eget lectus sit amet turpis facilisis consectetur quis vel ante. Integer in massa ut nibh ultricies sagittis imperdiet in ante. Nam sed turpis vel ante placerat feugiat ac tempus magna. Nam aliquet ullamcorper dolor non hendrerit.</p>
          </div>
      </div>
  </div>
</div>
