import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { ActualizacionPatente } from '../../modelos';
import { ActualizacionPatenteFilter } from '../../modelos/filtros';

export const establecerFiltroActualizacionPatente = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer filtro ACTUALIZACION PATENTE',
  props<{ filtro: ActualizacionPatenteFilter }>()
);
export const establecerListaActualizacionPatente = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer lista ACTUALIZACION PATENTE',
  props<{ lista: ActualizacionPatente[]; paginado: Paginado }>()
);
export const establecerActualizacionPatente = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer objeto ACTUALIZACION PATENTE',
  props<{ objeto: ActualizacionPatente }>()
);
