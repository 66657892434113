import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { VistaServiciosRegistrados, SolicitanteOrdenServicio } from 'src/app/modulos/correspondencia/modelos';
import { VistaServiciosRegistradosFilter } from 'src/app/modulos/correspondencia/modelos/filtros';
import { PaginadorComponent } from 'src/app/comun/componentes';

import { SeguridadFacade } from 'src/app/seguridad/fachadas';

@Component({
  selector: 'app-admin-lte3-registro-fin',
  templateUrl: './registro-fin.component.html',
  styles: []
})
export class RegistroFinComponent
  implements OnInit, OnDestroy
{
  @ViewChild('modalVistaServiciosRegistrados') modalVistaServiciosRegistrados: NgbModal;
  @ViewChild(PaginadorComponent) paginador: PaginadorComponent;

  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  formRegistroFin: FormGroup;
  registra_operacion: string;

  suscripcion = new Subscription();
 
  modal: NgbModalRef;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public seguridadFacade: SeguridadFacade,
  ) {
  }

  ngOnInit(): void {

  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  cerrarModal(): void {
    this.modal.close();
  }

}
