import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { Servicios } from '../../modelos';
import { ServiciosFilter } from '../../modelos/filtros';

export const establecerFiltroServicios = createAction(
  '[CORRESPONDENCIA] Establecer filtro SERVICIOS',
  props<{ filtro: ServiciosFilter }>()
);
export const establecerListaServicios = createAction(
  '[CORRESPONDENCIA] Establecer lista SERVICIOS',
  props<{ lista: Servicios[]; paginado: Paginado }>()
);
export const establecerServicios = createAction(
  '[CORRESPONDENCIA] Establecer objeto SERVICIOS',
  props<{ objeto: Servicios }>()
);
