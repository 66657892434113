import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { BoletaEscaneadaFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroBoletaEscaneada(state: AppState): BoletaEscaneadaFilter {
  return state.correspondencia.listaBoletaEscaneada?.filtro;
}

function seleccionarPaginadoBoletaEscaneada(state: AppState): Paginado {
  return state.correspondencia.listaBoletaEscaneada?.paginado;
}

export const filtroBoletaEscaneadaSelector = createSelector(
  obtenerState,
  seleccionarFiltroBoletaEscaneada
);
export const paginadoBoletaEscaneadaSelector = createSelector(
  obtenerState,
  seleccionarPaginadoBoletaEscaneada
);
