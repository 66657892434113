<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-contacto-buscador [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-contacto-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th>NOMBRE</th>
                                <th>PUESTO</th>
                                <th>UNIDAD ORGANIZACIONAL</th>
                                <th>ENTIDAD</th>
                                <th class="td-auto">OPERACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="td-auto align-middle">{{ item.nombre }}</td>
                                <td class="align-middle">{{ item.puesto }}</td>
                                <td class="align-middle">{{ item.uniOrganizacional }}</td>
                                <td class="align-middle">{{ item.entidad }}</td>
                                <td class="align-middle">
                                    <i (click)="ejecutarOperacion({operacion: 'detalle', id: item.id})"
                                        class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Detalle"></i>
                                    <i *ngIf="item.sePuedeModificar" (click)="ejecutarOperacion({operacion: 'modificar', id: item.id})"
                                        class="far fa-edit fa-lg text-info mr-1 cursor-pointer" title="Modificar"></i>
                                    <i *ngIf="item.sePuedeEliminar" (click)="ejecutarOperacion({operacion: 'eliminar', id: item.id})"
                                        class="far fa-trash-alt fa-lg text-danger mr-1 cursor-pointer" title="Eliminar"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-right mt-3">
                    <input type="button" value="Nuevo registro" class="btn btn-primary"
                        (click)="ejecutarOperacion({operacion: 'crear'})">
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalContacto let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-contacto-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-contacto-formulario>
            <app-correspondencia-contacto-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-contacto-detalle>
            <app-correspondencia-contacto-formulario *ngSwitchCase="'modificar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-contacto-formulario>
            <app-correspondencia-contacto-detalle *ngSwitchCase="'eliminar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-contacto-detalle>
        </div>
    </div>
    <div class="modal-footer">
        AJAM
    </div>
</ng-template>
