import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { CorrelativoOs } from '../../modelos';
import { CorrelativoOsFilter } from '../../modelos/filtros';

export const establecerFiltroCorrelativoOs = createAction(
  '[CORRESPONDENCIA] Establecer filtro CORRELATIVO_OS',
  props<{ filtro: CorrelativoOsFilter }>()
);
export const establecerListaCorrelativoOs = createAction(
  '[CORRESPONDENCIA] Establecer lista CORRELATIVO_OS',
  props<{ lista: CorrelativoOs[]; paginado: Paginado }>()
);
export const establecerCorrelativoOs = createAction(
  '[CORRESPONDENCIA] Establecer objeto CORRELATIVO_OS',
  props<{ objeto: CorrelativoOs }>()
);
