import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FuncionesHelper } from '../../../../../comun/auxiliares';

import { VistaAreasMinerasFilter } from '../../../modelos/filtros';

@Component({
  selector: 'app-correspondencia-vista-areas-mineras-digitalizacion-buscador',
  templateUrl: './vista-areas-mineras-digitalizacion-buscador.component.html',
  styles: []
})
export class VistaAreasMinerasDigitalizacionBuscadorComponent implements OnInit {
  @Output() accion = new EventEmitter<any>();
  @Input() objeto: VistaAreasMinerasFilter;

  formBuscador: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formBuscador = this.fb.group({
      denominacion: [''],
      titular: [''],
      codigo_unico: [''],
    });
  }

  ngOnInit(): void {
    this.formBuscador.setValue({
      denominacion: this.objeto?.denominacion || '',
      titular: this.objeto?.titular || '',
      codigo_unico: this.objeto?.codigo_unico || ''
    });
  }

  ejecutarAccion(): void {
    const objeto = { ...this.formBuscador.value } as VistaAreasMinerasFilter;
    this.accion.emit({
      accion: 'buscar',
      vistaAreasMineras: FuncionesHelper.quitarNulos(objeto)
    });
  }

  limpiar(): void {
    this.formBuscador.reset({
      denominacion: '',
      titular: '',
      codigo_unico: ''
    });
  }
}
