<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <div class="text-right mt-3" style="width: 200px; height: 50px; margin: 0 auto;">
                    <input type="button" value="Registrar Servicios de Geodesia y Topografia de Campo" class="btn btn-info"
                        (click)="ejecutarOperacion({operacion: 'crear'})">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-vista-servicios-registrados-buscador [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-vista-servicios-registrados-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th width="15%">Correlativo Servicio</th>
                                <th>Nombre Solicitante</th>
                                <th>Representa a</th>
                                <th>Tipo Actor Minero</th>
                                <th>Cantidad Servicios</th>
                                <th>Servicios Seleccionados</th>
                                <th width="10%">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="align-middle" width="15%">{{ item.correlativo_servicio }}</td>
                                <td class="align-middle">{{ item.nombre_solicitante }}</td>
                                <td class="align-middle">{{ item.representa }}</td>
                                <td class="align-middle">{{ item.tipo_actor_minero }}</td>
                                <td class="align-middle">{{ item.cantidad_servicios }}</td>
                                <td class="align-middle">{{ item.servicios_seleccionados }}</td>
                                <td class="align-middle" width="10%">
                                        <!-- <button *ngIf="item.estado_registro === 'INICIADO'" (click)="ejecutarOperacion({operacion: 'formulario-validacion', id: item.id})" class="btn btn-primary">Validar Formulario</button>
                                        <button *ngIf="item.estado_registro === 'VALIDADO'" (click)="ejecutarOperacion({operacion: 'cpt', id: item.id})" class="btn btn-warning">Generar CPT</button> -->
                                        <!-- <a [routerLink]="['/correspondencia/vistas-servicios-registrados', item.id, 'cpt']"><button class="btn btn-warning">Generar CPT</button></a> -->
                                        <!-- <button *ngIf="item.estado_registro === 'GENERADO'" (click)="ejecutarOperacion({operacion: 'documento-orden-servicio', id: item.id})" class="btn btn-info">Imprimir OS</button> -->
                                        <!-- <button class="btn btn-info" (click)="ejecutarOperacion({operacion: 'imprimir', id: item.id})">Imprimir OS</button> -->
                                        <!-- <button (click)="ejecutarOperacion({operacion: 'hoja-ruta', id: item.id})" class="btn btn-success">Hoja de Ruta Externa</button> -->
                                        <!-- <a *ngIf="item.estado_registro === 'GENERADO'" [routerLink]="['/correspondencia/vistas-servicios-registrados', item.id, 'hoja-ruta']"><button class="btn btn-success">Hoja de Ruta Externa</button></a> -->
                                        <!-- <i [routerLink]="['/correspondencia/vistas-patentes-mineras', item.id, 'patentes']"
                                        class="fas fa-file-alt fa-lg text-warning mr-1 cursor-pointer" title="Patente Minera Ley 535"></i> -->
                                        <button (click)="ejecutarOperacion({operacion: 'formulario-validacion', id: item.id})" class="btn btn-primary">Validar Formulario</button>
                                        <button *ngIf="item.estado_registro === 'PARA ENTREGA'" (click)="ejecutarOperacion({operacion: 'formulario-validacion', id: item.id})" class="btn btn-primary">Validar Formulario</button>
                                        <a href="{{ item.link_factura }}"><button class="btn btn-success">imprimir Factura</button></a>
                                        <!-- <button *ngIf="item.estado_registro === 'PROCESADO'" (click)="ejecutarOperacion({operacion: 'cpt', id: item.id})" class="btn btn-warning">Generar CPT</button> -->
                                        <!-- <button *ngIf="item.estado_registro === 'INICIADO'" (click)="ejecutarOperacion({operacion: 'documento-orden-servicio', id: item.id})" class="btn btn-info">Imprimir OS</button> -->
                                        <!-- <a [routerLink]="['/correspondencia/vistas-servicios-registrados', item.id, 'hoja-ruta']"><button class="btn btn-success">Hoja de Ruta Externa</button></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
    </div>
</div>

<ng-template #modalVistaServiciosRegistrados let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-vista-servicios-registrados-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-formulario>
            <app-correspondencia-vista-servicios-registrados-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-detalle>
            <app-correspondencia-vista-servicios-registrados-formulario-validacion *ngSwitchCase="'formulario-validacion'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-formulario-validacion>
            <app-correspondencia-vista-servicios-registrados-cpt *ngSwitchCase="'cpt'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-cpt>
            <app-correspondencia-vista-servicios-registrados-documento-orden-servicio *ngSwitchCase="'documento-orden-servicio'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-documento-orden-servicio>
            <app-correspondencia-vista-servicios-registrados-hoja-ruta *ngSwitchCase="'hoja-ruta'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-servicios-registrados-hoja-ruta>
        </div>
    </div>
    <div class="modal-footer">
        AJAM
    </div>
</ng-template>
