import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { PaginadorComponent } from 'src/app/comun/componentes';
import { Paginado } from 'src/app/comun/modelos';

import { VistaPatentesMineras } from '../../../modelos';
import { VistaPatentesMinerasFilter } from '../../../modelos/filtros';
import { VistaPatentesMinerasFacade, VistaAreasMinerasFacade } from '../../../fachadas';

@Component({
  selector: 'app-correspondencia-vista-patentes-mineras-lista',
  templateUrl: './vista-patentes-mineras-lista.component.html',
  styles: []
})
export class VistaPatentesMinerasListaComponent
  implements OnInit, OnDestroy
{
  // @ViewChild('modalVistaPatentesMineras') modalBuzon: NgbModal;
  // @ViewChild(PaginadorComponent) paginador: PaginadorComponent;

  suscripcion = new Subscription();

  filtro: VistaPatentesMinerasFilter = new VistaPatentesMinerasFilter();

  // tipoOperacion: string;
  // tipoMovilidad: string;

  IdAreaMinera: number;
  vistaPatentesMineras: VistaPatentesMineras = new VistaPatentesMineras();
  lista: VistaPatentesMineras[];

  denominacionAreaMinera: string;
  titular: string;
  numeroInscripcion: number;
  fechaOtorgacion: string;
  padronNacional: string;
  clasificacionAreaMinera: string;
  cantidadAsignada: string;
  vigencia: boolean;

  modalTitulo: string;
  modal: NgbModalRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private vistaPatentesMinerasFacade: VistaPatentesMinerasFacade,
    private vistaAreasMinerasFacade: VistaAreasMinerasFacade,
    private modalService: NgbModal
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.has('IdAreaMinera')) {
        this.IdAreaMinera = parseInt(params.get('IdAreaMinera'), 10);
        //this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(this.IdAreaMinera);
      }
    });
  }

  ngOnInit(): void {
    
    const vistaPatentesMinerasFilter = new VistaPatentesMinerasFilter()
    vistaPatentesMinerasFilter.id_area_minera = this.IdAreaMinera;
    this.vistaPatentesMinerasFacade.buscar(vistaPatentesMinerasFilter, 1, 10).then((respuesta) => {
      this.lista = respuesta.lista;
    });

    const areaMinera = this.vistaAreasMinerasFacade.obtenerPorId(this.IdAreaMinera).then((respuesta) => {
      this.denominacionAreaMinera = respuesta.objeto.denominacion;
      this.titular = respuesta.objeto.titular; 
      this.numeroInscripcion = respuesta.objeto.codigo_unico;
      this.fechaOtorgacion = respuesta.objeto.fecha_otorgacion;
      this.padronNacional = respuesta.objeto.padron_nacional;
      this.clasificacionAreaMinera = respuesta.objeto.tipo_area;
      this.cantidadAsignada = respuesta.objeto.cantidad_asignada;
      this.vigencia = respuesta.objeto.vigente;
    });

    /*this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(100001).then((respuesta) => {
      this.lista = respuesta.lista;
    });*/

    // this.suscripcion.add(
    //   this.vistaPatentesMinerasFacade.CorrespondenciaState$.subscribe(
    //     ({ listaVistaPatentesMineras, vistaPatentesMineras }) => {
    //       if (listaVistaPatentesMineras.lista) {
    //         if (listaVistaPatentesMineras.lista.length >= 0) {
    //           this.lista = listaVistaPatentesMineras.lista;
    //           if (listaVistaPatentesMineras.paginado && this.paginador) {
    //             this.paginador.mostrarPaginador = true;
    //             this.paginador.valores = new Paginado(
    //               listaVistaPatentesMineras.paginado.totalRegistros,
    //               listaVistaPatentesMineras.paginado.registrosPorPagina,
    //               listaVistaPatentesMineras.paginado.totalPaginas,
    //               listaVistaPatentesMineras.paginado.pagina
    //             );
    //           }
    //         } 
    //       }
    //       if (vistaPatentesMineras) {
    //         this.vistaPatentesMineras = vistaPatentesMineras;
    //       }
    //     }
    //   )
    // );
    // this.suscripcion.add(
    //   this.vistaPatentesMinerasFacade.Filtro$.subscribe((filtro) => {
    //     if (filtro && this.paginador) {
    //       this.filtro = filtro;
    //       this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(100001);
    //     }
    //   })
    // );

    // await this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(this.IdAreaMinera);
  }

  /*ngAfterViewInit(): void {
    this.paginar();
  }*/

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  /*ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'crear': {
        this.tipoOperacion = 'crear';
        this.buzonFacade.obtenerPorId(this.buzonId);
        this.modalTitulo = 'Asignar usuario al buzón';
        this.mostrarModal();
        break;
      }
      case 'detalle': {
        this.tipoOperacion = 'detalle';
        this.buzonFacade.obtenerPorId(this.buzonId);
        this.buzonFacade.obtenerBuzonUsuarioPorId(this.buzonId, evento.id);
        this.modalTitulo = 'Ver detalles de asignación de buzón';
        this.mostrarModal();
        break;
      }
      case 'modificar': {
        this.tipoOperacion = 'modificar';
        this.buzonFacade.obtenerPorId(this.buzonId);
        this.buzonFacade.obtenerBuzonUsuarioPorId(this.buzonId, evento.id);
        this.modalTitulo = 'Modificar asignación de buzón';
        this.mostrarModal();
        break;
      }
      case 'eliminar': {
        this.tipoOperacion = 'eliminar';
        this.buzonFacade.obtenerPorId(this.buzonId);
        this.buzonFacade.obtenerBuzonUsuarioPorId(this.buzonId, evento.id);
        this.modalTitulo = 'Eliminar asignación de buzón';
        this.mostrarModal();
        break;
      }
    }
  }*/

  /*ejecutarAccion(evento: any): void {
    switch (evento.accion) {
      case 'guardar': {
        this.buzonFacade
          .guardarBuzonUsuario(evento.buzonId, evento.buzonUsuario)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {
              this.cerrarModal();
            }
          });
        break;
      }
      case 'modificar': {
        this.buzonFacade
          .modificarBuzonUsuario(
            evento.buzonId,
            evento.buzonUsuarioId,
            evento.buzonUsuario
          )
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {
              this.buzonFacade.obtenerPorId(this.buzonId);
              this.cerrarModal();
            }
          });
        break;
      }
      case 'eliminar': {
        this.buzonFacade
          .eliminarBuzonUsuario(evento.buzonId, evento.buzonUsuarioId)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {
              this.cerrarModal();
            }
          });
        break;
      }
      case 'cancelar': {
        this.cerrarModal();
        break;
      }
    }
  }*/

  /*paginar(): void {
    if (this.paginador) {
      this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(100001);
    }
  }*/

  /*mostrarModal(opciones?: any): void {
    this.modal = this.modalService.open(this.modalBuzon, {
      backdrop: 'static',
      size: 'lg',
      ...opciones
    });
  }

  cerrarModal(): void {
    this.modal.close();
  }*/

  generaAntiguedad(gestionEmision:any, fechaOtorgacion:any) {
    let anio = "";
    if(((Number(gestionEmision) + 1) - Number(fechaOtorgacion.substring(10,6))) > 0)
    {
      anio = String((Number(gestionEmision) + 1) - Number(fechaOtorgacion.substring(10,6))) + '° Año';
      return anio;
    }
    else
    {
      anio = 'N/C';
      return anio;
    }
  }

  montoEstablecido(cantidadCuadriculas:any, montoHasta30Cuadriculas:any, monto3140Cuadriculas:any, monto41MasCuadriculas:any) {
    let cantCuad = Number(cantidadCuadriculas);
    let mont30Cuad = montoHasta30Cuadriculas;
    let monto3140Cuad = monto3140Cuadriculas;
    let monto41Cuad = monto41MasCuadriculas;
    let montoEstablecido = "";

    if(cantCuad < 31)
    {
      montoEstablecido = mont30Cuad;
    }
    else if(cantCuad >= 31 && cantCuad < 41)
    {
      montoEstablecido = monto3140Cuad;
    }
    else
    {
      montoEstablecido = monto41Cuad;
    }
    return montoEstablecido;
  }

  montoCalculado(cantidadCuadriculas:any, montoHasta30Cuadriculas:any, monto3140Cuadriculas:any, monto41MasCuadriculas:any, progresivo:any, multiplicadorAntiguedad:any, idUfv:any){
    let cantCuad = Number(cantidadCuadriculas);
    let mont30Cuad = Number(montoHasta30Cuadriculas);
    let monto3140Cuad = Number(monto3140Cuadriculas);
    let monto41Cuad = Number(monto41MasCuadriculas);
    let prog = Boolean(progresivo);
    let multAnt = 0;
    let ufv = Number(idUfv);
    let montoCalculado = 0;

    if(cantCuad >= 41)
    {
      montoCalculado = monto41Cuad;
    }
    else if(cantCuad >= 31)
    {
      montoCalculado = monto3140Cuad;
    }
    else
    {
      montoCalculado = mont30Cuad;
    }
    multAnt = 1;
    if(prog === true)
    {
      multAnt = Number(multiplicadorAntiguedad);
    }
    if(ufv >= 1)
    {
      montoCalculado = Number(Math.round(cantCuad * montoCalculado * multAnt).toFixed(0));
    }
    else
    {
      montoCalculado = Number(Math.round(cantCuad * montoCalculado * multAnt).toFixed(2));
    }

    return montoCalculado;
  }

  fechaEmision(fechaEmision:any)
  {
    let fecEmision = '';
    if(fechaEmision === '' || fechaEmision === null)
    {
      fecEmision = ''
    }
    else
    {
      fecEmision = fechaEmision.substring(0, 10);
    }
     return fecEmision;
  }

  fechaPago(fechaPago:any)
  {
    let fecPago = '';
    if(fechaPago === '' || fechaPago === null)
    {
      fecPago = ''
    }
    else
    {
      fecPago = fechaPago.substring(0, 10);
    }
     return fecPago;
  }
}
