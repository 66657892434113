import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { ActualizacionPatenteFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroActualizacionPatente(state: AppState): ActualizacionPatenteFilter {
  return state.correspondencia.listaActualizacionPatente?.filtro;
}

function seleccionarPaginadoActualizacionPatente(state: AppState): Paginado {
  return state.correspondencia.listaActualizacionPatente?.paginado;
}

export const filtroActualizacionPatenteSelector = createSelector(
  obtenerState,
  seleccionarFiltroActualizacionPatente
);
export const paginadoActualizacionPatenteSelector = createSelector(
  obtenerState,
  seleccionarPaginadoActualizacionPatente
);
