export class FormularioRecepcionFilter {
    correlativo?: string;
    tipo_persona?: string;
    boleta_bancaria?: string;
    path_boleta?: string;
    archivo_boleta?: string;
    fotocopia1?: boolean;
    fotocopia2?: boolean;
    fotocopia3?: boolean;
    fotocopia4?: boolean;
    estado?: string;
    activo?: boolean;
    documentos_generados?: string;
    observacion?: string;
    fk_asca_creador?: number;
    fecha_creacion?: Date;
    fk_asca_modificador?: number;
    fecha_modificacion?: Date;
    tipo_operacion?: string;
    fecha_verificacion_documental?: Date;
    tipo_operacion_fvd?: string;
    fotocopia5?: boolean;
}
  