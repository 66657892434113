import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PaginadorComponent } from 'src/app/comun/componentes';
import { Paginado } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, OrdenServicio, BoletaPagoServicio, SolicitanteOrdenServicio } from '../../../modelos';
import { VistaServiciosRegistradosFilter, OrdenServicioFilter, BoletaPagoServicioFilter, SolicitanteOrdenServicioFilter } from '../../../modelos/filtros';
import { VistaServiciosRegistradosFacade, OrdenServicioFacade, BoletaPagoServicioFacade, SolicitanteOrdenServicioFacade } from '../../../fachadas';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-vista-servicios-registrados-lista',
  templateUrl: './vista-servicios-registrados-lista.component.html',
  styleUrls: []
})
export class VistaServiciosRegistradosListaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('modalVistaServiciosRegistrados') modalVistaServiciosRegistrados: NgbModal;
  @ViewChild(PaginadorComponent) paginador: PaginadorComponent;

  suscripcion = new Subscription();
  filtro: VistaServiciosRegistradosFilter = new VistaServiciosRegistradosFilter();
  tipoOperacion: string;

  vistaServiciosRegistrados: VistaServiciosRegistrados = new VistaServiciosRegistrados();
  lista: VistaServiciosRegistrados[];
  listaOS: OrdenServicio[];
  listaBP: BoletaPagoServicio[];
  listaSOS: SolicitanteOrdenServicio[];

  modalTitulo: string;
  modal: NgbModalRef;

  constructor(
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private ordenServicioFacade: OrdenServicioFacade,
    private boletaPagoServicioFacade: BoletaPagoServicioFacade,
    private solicitanteServicioFacade: SolicitanteOrdenServicioFacade,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ listaVistaServiciosRegistrados, vistaServiciosRegistrados }) => {
          if (listaVistaServiciosRegistrados.lista) {
            if (listaVistaServiciosRegistrados.lista.length >= 0) {
              this.lista = listaVistaServiciosRegistrados.lista;
              if (listaVistaServiciosRegistrados.paginado && this.paginador) {
                this.paginador.mostrarPaginador = true;
                this.paginador.valores = new Paginado(
                  listaVistaServiciosRegistrados.paginado.totalRegistros,
                  listaVistaServiciosRegistrados.paginado.registrosPorPagina,
                  listaVistaServiciosRegistrados.paginado.totalPaginas,
                  listaVistaServiciosRegistrados.paginado.pagina
                );
              }
            }
          }
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
          }
        }
      )
    );
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.Filtro$.subscribe((filtro) => {
        if (filtro && this.paginador) {
          this.filtro = filtro;
          this.vistaServiciosRegistradosFacade.buscar(
            this.filtro,
            1,
            this.paginador.registrosPorPagina
          );
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.paginar();
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    const vistaServiciosRegistradosTitulo = 'Servicios';
    switch (evento.operacion) {
      case 'crear': {
        this.tipoOperacion = 'crear';
        this.modalTitulo = vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'hoja-ruta': {
        this.tipoOperacion = 'hoja-ruta';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver Hoja de Ruta de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'detalle': {
        this.tipoOperacion = 'detalle';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'formulario-validacion': {
        this.tipoOperacion = 'formulario-validacion';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'cpt': {
        this.tipoOperacion = 'cpt';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'documento-orden-servicio': {
        this.tipoOperacion = 'documento-orden-servicio';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'documento-orden-servicio': {
        this.tipoOperacion = 'documento-orden-servicio';
        this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaServiciosRegistradosTitulo;
        this.mostrarModal();
        break;
      }
      case 'imprimir': {

        const doc = new jsPDF();

        /*this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id).then((respuesta) => {
          this.v_correlativo_servicio = respuesta.objeto.correlativo_servicio;
        });

        const c_correlativo_servicio = this.v_correlativo_servicio.toString();*/
        this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
          ({ listaVistaServiciosRegistrados }) => {
            if (listaVistaServiciosRegistrados.lista) {
              if (listaVistaServiciosRegistrados.lista.length >= 0) {
                this.lista = listaVistaServiciosRegistrados.lista;
              }
            }
          }
        );

        const c_correlativo_servicio = this.lista.find(x => x.id == evento.id).correlativo_servicio;

        const c_remitente = this.lista.find(x => x.id == evento.id).nombre_solicitante;
        const c_telefono = '77754212';
        const c_representante = this.lista.find(x => x.id == evento.id).representa;

        const solicitante_servicio_filter = new SolicitanteOrdenServicioFilter();
        solicitante_servicio_filter.correlativo_servicio = c_correlativo_servicio;
        this.solicitanteServicioFacade.buscar(solicitante_servicio_filter, 1, 1).then((respuesta) => {
          this.listaSOS = respuesta.lista;
        });

        const c_razon_social = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).razon_social;
        const c_nit = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).nit;
        const c_n_factura = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).n_factura;



        var fondo = new Image();
        fondo.src = 'assets/images/fondo-os-cartaf.png';
        doc.addImage(fondo, 'PNG', -15, 30, 250, 250);

        autoTable(doc, {
          body: [
            [
              {
                content: 'AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA \nDIRECCIÓN DE CATASTRO Y CUADRICULADO MINERO \nPLATAFORMA',
                styles: {
                  halign: 'center',
                  fontSize: 12,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
          ],
          theme: 'plain',
          styles: {
            fillColor: '#ffffff'
          }
        });


        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          body: [
            [
              {
                content: 'ORDEN DE SERVICIO \n' + c_correlativo_servicio,
                styles: {
                  halign: 'center',
                  fontSize: 13,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times'
                }
              }
            ],
          ],
          theme: 'plain',
          styles: {
            fillColor: '#ddb340'
          },
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          body: [
            [
              {
                content: 'TÉCNICO PLATAFORMA:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: 'CRISTIAN GABRIEL RIOS ALBORTA',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: 'FECHA Y HORA: ',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: '28/07/2022 09:47:53',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          body: [
            [
              {
                content: 'DATOS PERSONALES DEL SOLICITANTE',
                styles: {
                  halign: 'center',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#ddb340'
                }
              }
            ],
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 49},
            1: {cellWidth: 63},
            2: {cellWidth: 35},
            3: {cellWidth: 30},
          },
          body: [
            [
              {
                content: 'REMITENTE: ',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: c_remitente,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: 'TELF./CEL.:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: c_telefono,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 49},
            1: {cellWidth: 63},
            2: {cellWidth: 35},
            3: {cellWidth: 30},
          },
          body: [
            [
              {
                content: 'REPRESENTANTE: ',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: c_representante,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: 'TIPO DE ACTOR:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#f2c179'
                }
              },
              {
                content: 'PERSONA NATURAL',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          body: [
            [
              {
                content: 'CUADRO DE SERVICIOS SOLICITADOS',
                styles: {
                  halign: 'center',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times',
                  fillColor: '#ddb340'
                }
              }
            ],
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 1
        });

        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
            head: [
              [
                {
                  content: 'Servicio',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Precio Unitario \n(Bs.)',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Cantidad',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Sub Total \n(Bs.)',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                }
              ],
            ],
            body: [
             [
              {
                content: 'hola',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '150',
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '1',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '150',
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ],
             [
              {
                content: 'SC-04-Relación Planimétrica, papel bond (tamaño carta)',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '110',
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '1',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '110',
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'COSTO TOTAL (Bs.):',
                  styles: {
                    halign: 'right',
                    fontSize: 8,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: '260',
                  styles: {
                    halign: 'right',
                    fontSize: 8,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'INFORMACIÓN DE BOLETA(S) DE DEPOSITO',
                  styles: {
                    halign: 'center',
                    fontSize: 8,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#ddb340'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            head: [
              [
                {
                  content: 'N° de Boleta',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Fecha de Boleta',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Depositante',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'Monto de \nBoleta (Bs.)',
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                }
              ],
            ],
            body: [
             [
              {
                content: '16465918',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '29/07/2022 14:02',
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: 'ROMAN MITA TICONA ',
                styles: {
                  halign: 'left',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: '110',
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'grid',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'DEPOSITO TOTAL (Bs.):',
                  styles: {
                    halign: 'right',
                    fontSize: 8,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: '100',
                  styles: {
                    halign: 'right',
                    fontSize: 8,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'DATOS DE LA FACTURA',
                  styles: {
                    halign: 'center',
                    fontSize: 8,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#ddb340'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            columnStyles: {
              0: {cellWidth: 50},
              1: {cellWidth: 70},
              2: {cellWidth: 62},
            },
            head: [
              [
                {
                  content: 'NOMBRE/RAZON SOCIAL:',
                  styles: {
                    halign: 'left',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'N.I.T.:',
                  styles: {
                    halign: 'left',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                },
                {
                  content: 'N FACTURA:',
                  styles: {
                    halign: 'left',
                    fontSize: 7,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#f2c179'
                  }
                }
              ],
            ],
            body: [
             [
              {
                content: c_razon_social,
                styles: {
                  halign: 'left',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: c_nit,
                styles: {
                  halign: 'left',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: c_n_factura,
                styles: {
                  halign: 'left',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'OBSERVACIONES',
                  styles: {
                    halign: 'center',
                    fontSize: 8,
                    textColor: '#000000',
                    fontStyle: 'bold',
                    font: 'times',
                    fillColor: '#ddb340'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'REALIZO EL PAGO DE 260 BS SIENDO LO CORRECTO 150 BS.',
                  styles: {
                    halign: 'left',
                    fontSize: 8,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'ENTREGADO POR:',
                  styles: {
                    halign: 'left',
                    fontSize: 8,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
              [
                {
                  content: 'FIRMA Y SELLO:',
                  styles: {
                    halign: 'center',
                    fontSize: 6,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
              [
                {
                  content: 'FECHA Y HORA: ',
                  styles: {
                    halign: 'left',
                    fontSize: 6,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ]
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            body: [
              [
                {
                  content: 'RECIBIDO POR:',
                  styles: {
                    halign: 'left',
                    fontSize: 8,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
              [
                {
                  content: 'FIRMA',
                  styles: {
                    halign: 'center',
                    fontSize: 6,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
              [
                {
                  content: 'ACLARACIÓN DE FIRMA:',
                  styles: {
                    halign: 'left',
                    fontSize: 6,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ],
              [
                {
                  content: 'FECHA Y HORA',
                  styles: {
                    halign: 'left',
                    fontSize: 6,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ]
            ],
            theme: 'plain',
            startY: (doc as any).lastAutoTable.finalY + 1
          });

        doc.save('angular-demo.pdf')
        break;
      }
    }
  }

  ejecutarAccion(evento: any): void {
    switch (evento.accion) {
      case 'buscar': {
        this.vistaServiciosRegistradosFacade.establecerFiltro(evento.vistaServiciosRegistrados);
        console.log(this.lista);
        break;
      }
      case 'cancelar': {
        this.cerrarModal();
        break;
      }
    }
  }

  paginar(): void {
    if (this.paginador) {
      this.vistaServiciosRegistradosFacade.buscar(
        this.filtro,
        this.paginador.paginaActual,
        this.paginador.registrosPorPagina
      );
    }
  }

  mostrarModal(opciones?: any): void {
    this.modal = this.modalService.open(this.modalVistaServiciosRegistrados, {
      backdrop: 'static',
      size: 'xl',
      ...opciones
    });
  }

  cerrarModal(): void {
    this.modal.close();
  }
}
