import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { ComunModule } from 'src/app/comun';
import { SeguridadModule } from 'src/app/seguridad';
import {
  AutenticacionComponent,
  AutenticacionLdapComponent,
  CambiarContraseniaComponent,
  ContenedorComponent,
  EncabezadoComponent,
  MenuLateralComponent,
  NoEncontradoComponent,
  PerfilComponent,
  PieComponent,
  RecuperarContraseniaComponent,
  RegistrarseComponent,
  TerminosComponent,
  UbicacionComponent,
  RegistroApmComponent,
  RegistroServicioComponent,
  RegistroGuiaComponent,
  RegistroFinComponent,
} from './componentes';

import { NumberDirective } from '../admin-lte3/componentes/registro-servicio/number-only.directive';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    AutenticacionComponent,
    AutenticacionLdapComponent,
    EncabezadoComponent,
    MenuLateralComponent,
    PieComponent,
    UbicacionComponent,
    ContenedorComponent,
    NoEncontradoComponent,
    TerminosComponent,
    PerfilComponent,
    CambiarContraseniaComponent,
    RecuperarContraseniaComponent,
    RegistrarseComponent,
    RegistroApmComponent,
    RegistroServicioComponent,
    RegistroGuiaComponent,
    RegistroFinComponent,
    NumberDirective
  ],
  exports: [
    AutenticacionComponent,
    AutenticacionLdapComponent,
    EncabezadoComponent,
    MenuLateralComponent,
    PieComponent,
    UbicacionComponent,
    ContenedorComponent,
    NoEncontradoComponent,
    RegistroApmComponent,
    RegistroServicioComponent,
    RegistroGuiaComponent,
    RegistroFinComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    ComunModule,
    SeguridadModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    QRCodeModule,
  ]
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA,
  //   NO_ERRORS_SCHEMA
  // ]
})
export class AdminLte3Module {}
