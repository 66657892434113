import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { VistaRegistroDiarioSipago } from '../../modelos';
import { VistaRegistroDiarioSipagoFilter } from '../../modelos/filtros';

export const establecerFiltroVistaRegistroDiarioSipago = createAction(
  '[CORRESPONDENCIA] Establecer filtro VISTA_REGISTRO_DIARIO_SIPAGO',
  props<{ filtro: VistaRegistroDiarioSipagoFilter }>()
);
export const establecerListaVistaRegistroDiarioSipago = createAction(
  '[CORRESPONDENCIA] Establecer lista VISTA_REGISTRO_DIARIO_SIPAGO',
  props<{ lista: VistaRegistroDiarioSipago[]; paginado: Paginado }>()
);
export const establecerVistaRegistroDiarioSipago = createAction(
  '[CORRESPONDENCIA] Establecer objeto VISTA_REGISTRO_DIARIO_SIPAGO',
  props<{ objeto: VistaRegistroDiarioSipago }>()
);
