<style type="text/css">
    /*************************************************/
    /* table {
        border-collapse: collapse;
        width: 1000px;
    }
    
    table,
    th, td {
        border: 1px solid black;
    }
    
    th, td {
        padding: 10px;
    } */
</style>

<style type="text/css">
    table,thead,tbody,tfoot,tr,th,td,p { font-family:"Calibri"; font-size:x-small }
    a.comment-indicator:hover + comment { background:#ffd; position:absolute; display:block; border:1px solid black; padding:0.5em;  } 
    a.comment-indicator { background:red; display:inline-block; border:1px solid black; width:0.5em; height:0.5em;  } 
    comment { display:none;  } 
    .sorttable_sorted,.sorttable_sorted_reverse,table.sortable thead td:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort) { white-space: nowrap; cursor: pointer; }
    table.sortable thead td:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after { content:" \25B4\25BE"; }
</style>

<div class="page-service">
    <div id="capture" class="card card-outline card-primary">
        
        <div class="card-body">

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="163"></colgroup>
                <colgroup width="322"></colgroup>
                <colgroup width="78"></colgroup>
                <colgroup width="176"></colgroup>
                <colgroup width="231"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" rowspan=4 height="50" align="left" valign=middle><font face="Times New Roman" color="#000000"><br><img src="assets/images/logo_AJAM.png" width=124 height=55 hspace=19 vspace=11>
                    </font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000;" height="24" colspan=3 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="right" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA Y HORA DE CREACI&Oacute;N</font></b></td>
                </tr>
                <tr>
                    <td style="border-left: 2px solid #000000; border-right: 2px solid #000000"  height="24" colspan=3 align="center" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" color="#000000">HOJA DE RUTA EXTERNA</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle sdval="45127.7499305556" sdnum="1033;0;M/D/YYYY H:MM"><font face="Times New Roman" color="#000000">7/20/2023 17:59</font></td>
                </tr>
                <tr>
                    <td style="border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=4 color="#000000">AJAMD-PT-EX-65/2023</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">N&deg; FOJAS</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" colspan=3 align="left" valign=top bgcolor="#80D8FF"><font color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" height="24" align="center" valign=middle sdval="4" sdnum="1033;"><font face="Times New Roman" size=1 color="#000000">4</font></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CITE DOCUMENTO:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">Sin CITE</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="right" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA Y HORA DE DOCUMENTO</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">TIPO DOCUMENTO:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">Documento externo</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle sdval="45127" sdnum="1033;0;M/D/YYYY H:MM"><font face="Times New Roman" size=1 color="#000000">7/20/2023 0:00</font></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PROCEDENCIA:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">S/D</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">REMITENTE:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">FREDDY MANCILLA CAZORLA</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CARGO REMITENTE:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">PERSONA NATURAL</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">REFERENCIA:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">SC-07-Relaci&oacute;n Planim&eacute;trica, papel bond (tama&ntilde;o doble carta).</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ADJUNTOS:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">Boleta(s) de Pago(s)</font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" rowspan=2 height="50" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">AREA DESTINO:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">DIRECCI&Oacute;N DE CATASTRO Y CUADRICULADO MINERO</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">NOMBRE</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">JORGE GERARDO LAIME ALTAMIRANO</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CARGO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">DIRECTOR DE CATASTRO Y CUADRICULADO MINERO</font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">USUARIO CREADOR:</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000">Gonzalo Gutierrez Vega (T&Eacute;CNICO PLATAFORMA)</font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>

            <br>

            <table cellspacing="0" border="0" class="sortable">
                <colgroup width="229"></colgroup>
                <colgroup width="20"></colgroup>
                <colgroup width="170"></colgroup>
                <colgroup width="200"></colgroup>
                <colgroup span="4" width="88"></colgroup>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">ACCI&Oacute;N</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">DESTINATARIO</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=5 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="25" align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">URGENTE</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">PLAZO RESPUESTA</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=4 rowspan=11 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="22" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Para su conocimiento y consideracion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=10 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Analizar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="24" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Procesar conforme a normativa</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar respuesta</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Atender lo solicitado</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Revisar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Preparar informe</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar resolucion</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Elaborar contrato</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="23" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proceder conforme a reglamento</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="25" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Proyectar providencia</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 rowspan=2 align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">SELLO Y FIRMA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">FECHA</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">HORA</font></b></td>
                </tr>
                <tr>
                    <td style="border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" height="21" align="left" valign=middle bgcolor="#80D8FF"><font face="Times New Roman" size=1 color="#000000">Archivar</font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 height="21" align="left" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">COORDINAR CON</font></b></td>
                    <td style="border-bottom: 2px solid #000000; border-right: 2px solid #000000" align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=2 align="center" valign=middle bgcolor="#80D8FF"><b><font face="Times New Roman" size=1 color="#000000">CON COPIA A</font></b></td>
                    <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan=3 align="left" valign=middle><font face="Times New Roman" size=1 color="#000000"><br></font></td>
                    </tr>
            </table>
        </div>

        <input type="button" class="btn btn-danger" value="Convert" (click)="convertToPDF()" />
</div>

