<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)">
        <div class="row">
            <div class="col-12">
                <!-- <span class="small d-block">{{ datosPresentacion.copia }}</span> -->
                <span class="small d-block">{{ datosPresentacion.iniciales }}</span>
                <span class="small d-block font-weight-bold">{{ datosPresentacion.hojaRuta }}</span>
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <div class="row">
        <div class="col-12">
            <!-- <span class="small d-block">{{ datosPresentacion.copia }}</span> -->
            <span class="small d-block">{{ datosPresentacion.iniciales }}</span>
            <span class="small d-block font-weight-bold">{{ datosPresentacion.hojaRuta }}</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <div class="row">
        <div class="col-12">
            <!-- <span class="small d-block">{{ datos.copia }}</span> -->
            <span class="small d-block">{{ datos.iniciales }}</span>
            <span class="small d-block font-weight-bold">H.R. {{ datos.hojaRuta }}</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <div class="row">
        <div class="col-12">
            <!-- <span class="small d-block">{{ datos.copia }}</span> -->
            <span class="small d-block">{{ datos.iniciales }}</span>
            <span class="small d-block font-weight-bold">H.R. {{ datos.hojaRuta }}</span>
        </div>
    </div>
</ng-container>

<ng-template #modalReferenciaFinal let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Referencia final - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form autocomplete="off" class="text-default">
                    <h3>Referencia final</h3>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit"
                                class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button"
                                class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados
                                con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>