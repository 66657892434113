export class BoletaPagoServicioFilter {
  correlativo_servicio?: string;
  numero_boleta?: number;
  fecha_pago_boleta?: Date;
  monto_boleta?: number;
  monto_recaudado?: number;
  monto_sin_edintificar?: number;
  depositante?: string;
  activo?: boolean;
  aud_asca_creador?: number;
  aud_fecha_creacion?: Date;
  aud_asca_modificador?: number;
  aud_fecha_modificacion?: Date;
  aud_tipo_operacion?: string;
}
