<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-2">
                <div class="card card-dark card-outline mt-4">
                    <div class="card-body">
                        <!-- <p *ngIf="grupo?.estado === 'ACTIVO'" class="text-center">
                            <strong>{{ grupo?.nombre }}</strong></p> -->
                        <div class="text-center">
                            <i class="fas fa-inbox fa-5x"></i>
                        </div>
                        <h5 class="text-center">{{ grupo?.descripcion }}</h5>
                        <!-- <p class="text-muted text-center">{{ grupo?.estado }}</p> -->
                        <!-- <a *ngIf="grupo?.estado === 'ACTIVO'" (click)="ejecutarOperacion({operacion: 'crear'})"
                            class="btn btn-outline-primary btn-block">Asignar buzón</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th>BUZON</th>
                                <th>FEC. INICIO</th>
                                <th>FEC. CONCLUSIÓN</th>
                                <th>ESTADO</th>
                                <th>OPERACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="align-middle">{{ item.buzonId }}</td>
                                <td class="align-middle">{{ item.fecInicio | date: 'dd/MM/yyyy' : 'UTC' }}</td>
                                <td class="align-middle">{{ item.fecConclusion | date: 'dd/MM/yyyy' : 'UTC' }}</td>
                                <td class="align-middle">{{ item.estado }}</td>
                                <td class="align-middle">
                                    <i (click)="ejecutarOperacion({operacion: 'detalle', id: item.id})"
                                        class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Detalle"></i>
                                    <i *ngIf="item.sePuedeModificar" (click)="ejecutarOperacion({operacion: 'modificar', id: item.id})"
                                        class="far fa-edit fa-lg text-info mr-1 cursor-pointer" title="Modificar"></i>
                                    <i *ngIf="item.sePuedeEliminar" (click)="ejecutarOperacion({operacion: 'eliminar', id: item.id})"
                                        class="far fa-trash-alt fa-lg text-danger mr-1 cursor-pointer"
                                        title="Eliminar"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-left mt-3">
                    <button [routerLink]="['/correspondencia/grupos']" type="button" class="btn btn-dark">
                        <i class="fas fa-arrow-circle-left"></i>
                        Volver
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalGrupo let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-grupo-buzon-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-grupo-buzon-formulario>
            <app-correspondencia-grupo-buzon-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-grupo-buzon-detalle>
            <app-correspondencia-grupo-buzon-formulario *ngSwitchCase="'modificar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-grupo-buzon-formulario>
            <app-correspondencia-grupo-buzon-detalle *ngSwitchCase="'eliminar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-grupo-buzon-detalle>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>