export class RespaldoBoletaEscaneadoFilter {
  tipo_documento?: string;
  nombre_documento?: string;
  path_documento?: string;
  fk_boes?: number;
  fk_asca_creador?: number;
  operacion_creador?: string;
  fecha_creacion?: Date;
  fk_asca_modificador?: number;
  operacion_modificador?: string;
  fecha_modificacion?: Date;
  otro_documento?: string;
}
