<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown user-menu">
            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
              <img [src]="usuarioAutenticado?.fotografia || 'assets/admin-lte3/img/unknown.jpg'" class="user-image img-circle elevation-2" alt="User Image">
              <span class="d-none d-md-inline">{{ usuarioAutenticado?.cuenta }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <!-- User image -->
              <li class="user-header bg-dark">
                <img [src]="usuarioAutenticado?.fotografia || 'assets/admin-lte3/img/unknown.jpg'" class="img-circle elevation-2" alt="User Image">
                <p>
                  {{ usuarioAutenticado?.nombre }}
                  <small>{{ puesto }}</small>
                </p>
              </li>
              <!-- Menu Footer-->
              <li class="user-footer">
                <a [routerLink]="['/perfil']" class="btn btn-default btn-flat">Perfil</a>
                <a (click)="cerrarSesion()" href="#" id="cerrarSesion" class="btn btn-default btn-flat float-right">Cerrar sesión</a>
              </li>
            </ul>
        </li>
    </ul>
</nav>