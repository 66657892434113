export class SolicitanteOrdenServicioFilter {
  correlativo_servicio?: string;
  nombres_completo?: string;
  numero_documento?: number;
  tipo_documento?: string;
  expedicion?: string;
  telefonos?: string;
  celular?: string;
  representa?: string;
  tipo_actor_minero?: string;
  activo?: boolean;
  aud_tipo_operacion?: string;
  razon_social?: string;
  nit?: string;
  observaciones?: string;
  hoja_ruta?: string;
  n_factura?: string;
  atender_dccm?: boolean;
  chk_atendido?: boolean;
  correo_electronico?: string;
}
