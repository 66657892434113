import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
  ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios } from '../../../modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade } from '../../../fachadas';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { SeguridadFacade } from 'src/app/seguridad/fachadas';

@Component({
  selector: 'app-correspondencia-vista-servicios-registrados-documento-orden-servicio',
  templateUrl: './vista-servicios-registrados-documento-orden-servicio.component.html',
  styles: []
})
export class VistaServiciosRegistradosDocumentoOrdenServicioComponent
  implements OnInit, OnDestroy
{
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formCpt: FormGroup;

  vistaServiciosRegistrados: VistaServiciosRegistrados;
  servicioArray: string [];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public seguridadFacade: SeguridadFacade,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private serviciosFacade: ServiciosFacade,
    private toastrService: ToastrService,
    private _location: Location
  ) {
    if (!this.vistaServiciosRegistrados) {
      this.vistaServiciosRegistrados = new VistaServiciosRegistrados();
    }
  }

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ vistaServiciosRegistrados }) => {
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
            this.servicioArray = vistaServiciosRegistrados.servicios_seleccionados_completo.split('|');
          }
        }
      )
    );
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'seleccionar-servicios': {
        
        break;
      }
      case 'cancelar-lista': {
        break;
      }
    }
  }

  ejecutarAccion(accion: string): void {
    //let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {
        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

  goBack(){
    this._location.back();
  }

  convertirNumero(numero: any)
  {
    const numero_a_convertir = numero;
    return Number(numero_a_convertir);
  }

  addCommas(nStr: any) {
    nStr = String(Number(nStr));
    nStr += '';
    var x = nStr.split(',');
    var x1 = x[0];
    var x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  }

  convertirNumerosLetras(numero: any)
  {
    const numero_a_conv = Number(numero);
    return this.numeroALetras(numero_a_conv, {
      plural: "BOLIVIANOS",
      singular: "BOLIVIANO",
      centPlural: "CENTAVOS",
      centSingular: "CENTAVO"
    });
  }

  convertToPDF() {

    html2canvas(document.querySelector("#capture")).then(function(canvas) {
      var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 245;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
  
        var doc = new jsPDF();
        var position = 0;
  
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+10);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+10);
            heightLeft -= pageHeight;
        }
      doc.save("Dashboard.pdf");
      });
    }
  //   const DATA = document.getElementById('capture');
  //   const doc = new jsPDF('p', 'pt', 'a4');
  //   const options = {
  //     background: 'white',
  //     scale: 3
  //   };
  //   html2canvas(DATA, options).then((canvas) => {
  //       const img = canvas.toDataURL('image/PNG');
  
  //       // Add image Canvas to PDF
  //       const bufferX = 15;
  //       const bufferY = 15;
  //       const imgProps = (doc as any).getImageProperties(img);
  //       const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
  //       return doc;
  //     }).then((docResult) => {
  //       docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
  //   });
  // }

  // html2canvas(document.querySelector("#capture")).then(canvas => {
  //   const contentDataURL = canvas.toDataURL('image/png')
  //   let pdf = new jsPDF();
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = canvas.height * width / canvas.width;
  //   pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
  //   pdf.save('output.pdf');
  //   });
  // }
  numeroALetras = (function() {
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        let strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
        let strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    } //Millones()

    return function NumeroALetras(num, currency) {
        currency = currency || {};
        let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || 'PESOS BOLIVIANOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
            letrasMonedaSingular: currency.singular || 'PESO BOLIVIANOS', //'PESO', 'Dólar', 'Bolivar', 'etc'
            letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS BOLIVIANOS',
            letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO BOLIVIANOS'
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'CON ' + (function() {
                if (data.centavos == 1)
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                else
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
            })();
        };

        // if (data.enteros == 0)
        //     return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        // if (data.enteros == 1)
        //     return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
        // else
        //     return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        if (data.enteros == 0)
            return 'CERO ' + 'CON 00/100.- BOLIVIANOS';
        if (data.enteros == 1)
            return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
        else
            return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
    };

})();

}
