export class DerivacionHrOs {
  id?: number;
  instruccion: string;
  estado: string;
  fecha_limite: Date;
  fecha_envio: string;
  fecha_recepcion: Date;
  fecha_conclusion: Date;
  hoja_ruta_servicio: string;
  fk_asignacion_cargo_creador: number;
  fecha_creacion: Date;
  fk_asignacion_cargo_remitente: number;
  fk_asignacion_cargo_destinatario: number;
  motivo_conclusion: string;
  activo: boolean;
}
