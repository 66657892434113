import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import {Router} from '@angular/router';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios } from '../../../modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade, VistaAreasMinerasFacade } from '../../../fachadas';

@Component({
  selector: 'app-correspondencia-vista-servicios-registrados-hoja-ruta',
  templateUrl: './vista-servicios-registrados-hoja-ruta.component.html',
  styles: []
})
export class VistaServiciosRegistradosHojaRutaComponent
  implements OnInit, OnDestroy
{
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formHojaRutaExterna: FormGroup;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private router:Router,
    private serviciosFacade: ServiciosFacade,
    private vistaAreasMinerasFacade: VistaAreasMinerasFacade,
    private toastrService: ToastrService,
    private _location: Location
  ) {

  }

  ngOnInit(): void {
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'seleccionar-servicios': {
        
        break;
      }
      case 'cancelar-lista': {
        break;
      }
    }
  }

  ejecutarAccion(accion: string): void {
    let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {

        this.vistaAreasMinerasFacade.generahojaRuta('Alissa Strekozova Bolkov81', '4818888', 'CI', '77755588', 'Alissa Strekozova Bolkov81', 'PERSONA NATURAL','AJAMR-TP-TJ-OS-888-2023', 'SC-07-Relacion Planimetrica, papel bond (tamaño doble carta)', 'fecha1', 2 , 1103, 7, 'SIN CITE', 'fecha2', 21208, 'texto de prueba', 1765);
        this.router.navigate(['/correspondencia/vistas-servicios-registrados', 1, 'impresion-hoja-ruta']);

        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

  goBack(){
    this._location.back();
  }

}
