import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EnvironmentHelper } from 'src/app/comun/auxiliares';

import { AppState } from 'src/app/app.state';
import { ServicioMetodosBaseService } from 'src/app/comun/servicios';

@Injectable({
  providedIn: 'root'
})
export class VistaServiciosRegistradosService extends ServicioMetodosBaseService {
  private env = EnvironmentHelper.obtenerConfiguracion('orden-servicio');

  constructor(
    protected override http: HttpClient,
    protected override store: Store<AppState>
  ) {
    super(http, store);
    this.urlApiBase = this.env['api']['url'];
    this.controlador = 'vistas-servicios-registrados';
  }

  guardar_os(objeto: any, objeto_os: any[]): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/guardar_os`;
    const body = JSON.stringify({objetoSolicitante: objeto, objetoListadoServicios: objeto_os});
    //console.log(objeto_os);
    return this.http.post(url, body, { headers: this.headers });
  }

  validar(objeto: any): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/validar`;
    const body = JSON.stringify({objetoSolicitante: objeto});
    //console.log(objeto_os);
    return this.http.post(url, body, { headers: this.headers });
  }

  /*generarDeuda(): Observable<any> {
    const url = `https://ppe.demo.agetic.gob.bo/api/transaccion/deuda`;
    var reqHeader = new HttpHeaders({ 
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ODU5NTE5NiIsImV4cCI6MTcyNzc1NTE5OSwiaXNzIjoieEJsTW04bTgya3Z0clVBWjdpUlJKMTlNMVpFMlVzdGMifQ.WQEst-XjFWRzomC1wpbe-7VmlD3DIstSMli0DppHVI8',
      'x-cpt-authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBR0VUSUMiLCJpYXQiOjE2OTI2MjIzNjIsImlkVXN1YXJpb0FwbGljYWNpb24iOjQwLCJpZFRyYW1pdGUiOiIxNTQifQ.2Vbn6lAIJDWZ4r-V0NpOPIT9MEq0UB5c7n1pc7jrSjc',
      'Content-type':'application/json'
    });
    const body = JSON.stringify({
      descripcion: 'Servicios del Catastro (Orden de Servicio)',
      codigoOrden: 'F91280-E75YZ111',
      datosPago: {
        nombresCliente: 'ANA',
        apellidosCliente: 'CABRERA BARRIENTOS',
        tipoDocumentoCliente: 1,
        numeroDocumentoCliente: '4208758',
        fechaNacimientoCliente: '1986-04-05',
        cuentaBancaria: '10000017869617',
        montoTotal: 860,
        moneda: 'BOB',
        tipoCambioMoneda: 1,
        correo: 'cccs-1@hotmail.com'
      },
      productos: [
       {
        actividadEconomica: '841121',
        descripcion: 'PLANO CATASTRAL ORIGINAL PAPEL',
        precioUnitario: 860,
        unidadMedida: 15,
        cantidad: 1
       }
      ]
     });
    return this.http.post(url, body, { headers: reqHeader });
  }*/

  /*generarDeuda2(): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/generar-deuda`;
    const body = JSON.stringify({
      descripcion: 'Servicios del Catastro (Orden de Servicio)',
      codigoOrden: 'F91280-E7996RST111',
      datosPago: {
        nombresCliente: 'ANA',
        apellidosCliente: 'CABRERA BARRIENTOS',
        tipoDocumentoCliente: 1,
        numeroDocumentoCliente: '4208758',
        fechaNacimientoCliente: '1986-04-05',
        cuentaBancaria: '10000017869617',
        montoTotal: 160,
        moneda: 'BOB',
        tipoCambioMoneda: 1,
        correo: 'accabrera@gmail.com'
      },
      productos: [
       {
        actividadEconomica: '841121',
        descripcion: 'PLANO CATASTRAL ORIGINAL PAPEL',
        precioUnitario: 160,
        unidadMedida: 15,
        cantidad: 1
       }
      ]
     });*/
    //console.log(objeto_os);
    // var reqHeader = new HttpHeaders({ 
    //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ODU5NTE5NiIsImV4cCI6MTcyNzc1NTE5OSwiaXNzIjoieEJsTW04bTgya3Z0clVBWjdpUlJKMTlNMVpFMlVzdGMifQ.WQEst-XjFWRzomC1wpbe-7VmlD3DIstSMli0DppHVI8',
    //   'x-cpt-authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBR0VUSUMiLCJpYXQiOjE2OTI2MjIzNjIsImlkVXN1YXJpb0FwbGljYWNpb24iOjQwLCJpZFRyYW1pdGUiOiIxNTQifQ.2Vbn6lAIJDWZ4r-V0NpOPIT9MEq0UB5c7n1pc7jrSjc',
    //   'Content-type':'application/json'
    // });
    /*return this.http.post(url, body);
  }*/

  /*consultaEstado(codigoTransaccion : any): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/consulta-estado/${codigoTransaccion}`;
    return this.http.get(url);
  }*/
  
  // obtenerEstadoCPT(): Observable<any> {
  //   const url = `https://ppe.demo.agetic.gob.bo/consulta/estado/59668588`;
  //   var reqHeader = new HttpHeaders({ 
  //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ODU5NTE5NiIsImV4cCI6MTcyNzc1NTE5OSwiaXNzIjoieEJsTW04bTgya3Z0clVBWjdpUlJKMTlNMVpFMlVzdGMifQ.WQEst-XjFWRzomC1wpbe-7VmlD3DIstSMli0DppHVI8',
  //     'Content-type':'application/json'
  //   });
  //   return this.http.get(url, { headers: reqHeader });
  // }
}
