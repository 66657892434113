import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { RespaldoBoletaEscaneado } from '../../modelos';
import { RespaldoBoletaEscaneadoFilter } from '../../modelos/filtros';

export const establecerFiltroRespaldoBoletaEscaneado = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer filtro RESPALDO BOLETA ESCANEADO',
  props<{ filtro: RespaldoBoletaEscaneadoFilter }>()
);
export const establecerListaRespaldoBoletaEscaneado = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer lista RESPALDO BOLETA ESCANEADO',
  props<{ lista: RespaldoBoletaEscaneado[]; paginado: Paginado }>()
);
export const establecerRespaldoBoletaEscaneado = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer objeto RESPALDO BOLETA ESCANEADO',
  props<{ objeto: RespaldoBoletaEscaneado }>()
);
