import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { FuncionesHelper } from 'src/app/comun/auxiliares';

import { FormularioRecepcion } from '../../../modelos';
import { FormularioRecepcionFacade } from '../../../fachadas';
import { FormularioRecepcionListaComponent } from '../lista/formulario-recepcion-lista.component';
import { FormularioRecepcionFilter } from '../../../modelos/filtros';
import { GeneraCertificadoVigenciaFilter } from '../../../modelos/filtros';

@Component({
  selector: 'app-correspondencia-formulario-recepcion-formulario',
  templateUrl: './formulario-recepcion-formulario.component.html',
  styles: []
})
export class FormularioRecepcionFormularioComponent implements OnInit, OnDestroy {
  @Input() public tipoOperacion: string;
  @Input() public validacion: string;
  @Output() accion = new EventEmitter<any>();
  @Input() objeto: GeneraCertificadoVigenciaFilter;
  formBuscador: FormGroup;

  suscripcion = new Subscription();

  formFormularioRecepcion: FormGroup;
  formAreaMinera: FormGroup;
  botonOperacion: string;
  ver: string;

  formularioRecepcion: FormularioRecepcion;
 

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private formularioRecepcionFacade: FormularioRecepcionFacade,
    private toastrService: ToastrService
  ) {
    if (!this.formularioRecepcion) {
      this.formularioRecepcion = new FormularioRecepcion();
    }

    this.formFormularioRecepcion = this.fb.group({
      
      notaSolicitud: ['', Validators.required],
      notaSolicitudNo: ['', Validators.required],
      fotocopiaTestimonio: ['', Validators.required],
      fotocopiaTestimonioNo: ['', Validators.required],
      carnet: ['', Validators.required],
      carnetNo: ['', Validators.required],
      formulario: ['', Validators.required],
      formularioNo: ['', Validators.required],
      comprobante: ['', Validators.required],
      comprobanteNo: ['', Validators.required],
      busquedaAreaMinera: ['', Validators.required],
      nroBoleta: ['', Validators.compose([Validators.required,Validators.min(1)])],
      boletaEscaneada: ['', Validators.required],
      fechaVerificacion: ['', Validators.required],

    });

    this.formAreaMinera = this.fb.group({
      fk_area_minera:['',Validators.required],
      estado: ['', Validators.required],
      codigoUnico: ['', Validators.required],
      tipoArea: ['', Validators.required],
      nombre: ['', Validators.required],
      actorProductivoMinero: ['', Validators.required],
      tipoActorMinero: ['', Validators.required],
      nro: ['', Validators.required],
      areaMineraSeleccionada: ['', Validators.required],
    });

  }

  ngOnInit(): void {
    console.log("yery");
    this.suscripcion.add(
      this.formularioRecepcionFacade.CorrespondenciaState$.subscribe(({ formularioRecepcion }) => {
        console.log("yery2");
        if (formularioRecepcion) {
          this.formularioRecepcion = formularioRecepcion;
          console.log("yery3");
            if(this.formularioRecepcion.activo){
              this.formAreaMinera.setValue({
                fk_area_minera: "nro",
                estado: this.formularioRecepcion.correlativo,
                codigoUnico: this.formularioRecepcion.tipo_persona,
                tipoArea: this.formularioRecepcion.fecha_creacion,
                nombre: this.formularioRecepcion.documentos_generados,
                actorProductivoMinero: this.formularioRecepcion.tipo_operacion_fvd,
                tipoActorMinero: this.formularioRecepcion.fecha_verificacion_documental,
                nro: this.formularioRecepcion.fecha_verificacion_documental,
                areaMineraSeleccionada: "correcto"
              });
            }
            else{
              this.formAreaMinera.setValue({
                fk_area_minera: "nro2",
                estado: this.formularioRecepcion.correlativo,
                codigoUnico: this.formularioRecepcion.tipo_persona,
                tipoArea: this.formularioRecepcion.fecha_creacion,
                nombre: this.formularioRecepcion.documentos_generados,
                actorProductivoMinero: this.formularioRecepcion.tipo_operacion_fvd,
                tipoActorMinero: this.formularioRecepcion.fecha_verificacion_documental,
                nro: this.formularioRecepcion.fecha_verificacion_documental,
                areaMineraSeleccionada: "incorrecto"
              });
            }
             

        }
      })
    );
    
    switch (this.tipoOperacion) {
      case 'crear':
        this.botonOperacion = 'Guardar';
        break;
      case 'modificar':
        this.botonOperacion = 'Modificar';
        break;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarAccion(accion: string): void {
    let formularioRecepcion = new FormularioRecepcion();
    switch (accion) {
      case 'crear': {
        FuncionesHelper.limpiarEspacios(this.formFormularioRecepcion);
        if (!this.formFormularioRecepcion.valid) {
          this.formFormularioRecepcion.markAllAsTouched();
          return;
        }
        formularioRecepcion = { ...this.formFormularioRecepcion.value };
        this.accion.emit({
          accion: 'guardar',
          formularioRecepcion
        });
        break;
      }
      case 'modificar': {
        FuncionesHelper.limpiarEspacios(this.formFormularioRecepcion);
        if (!this.formFormularioRecepcion.valid) {
          this.formFormularioRecepcion.markAllAsTouched();
          return;
        }
        formularioRecepcion = { ...this.formFormularioRecepcion.value };
        this.accion.emit({
          accion,
          formularioRecepcionId: this.formularioRecepcion.id,
          formularioRecepcion
        });
        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
    const objeto = { ...this.formBuscador.value } as FormularioRecepcionFilter;
    this.accion.emit({
      accion: 'buscar',
      formularioRecepcion: FuncionesHelper.quitarNulos(objeto)
    });
  }
  valor():boolean{
    if(this.validacion=='true'){
      return true;
    }
    return false;
  }

  limpiar(): void {
    this.formBuscador.reset({
      correlativo: '',
      fecha_creacion: ''
    });
  }

 




}
