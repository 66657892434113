<div class="container-fluid">
    <div class="row">
        <div class="col-md-8" style="height: 100%; overflow-y: scroll;">
            <!-- <app-correspondencia-contenido-formulario [modoVisualizacion]="modoVisualizacion"
                estado="PARA APROBACIÓN"></app-correspondencia-contenido-formulario> -->
            <app-correspondencia-documento-vista-previa estado="PARA APROBACIÓN"></app-correspondencia-documento-vista-previa>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <div class="float-left">
                        <i class="fas fa-bars"></i>
                    </div>
                    <div class="text-right">
                        <button [routerLink]="['/', 'correspondencia', 'bandeja-entrada']" type="button"
                            class="btn btn-dark" title="Volver">
                            Volver
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <app-correspondencia-documento-aprobar [redireccion]="['/', 'correspondencia', 'bandeja-entrada']"></app-correspondencia-documento-aprobar>
                    <hr>
                    <h3>Anexos</h3>
                    <app-correspondencia-documento-anexo></app-correspondencia-documento-anexo>
                    <hr>
                    <h3>Documentos en esta hoja de ruta</h3>
                    <app-correspondencia-hoja-ruta-detalle></app-correspondencia-hoja-ruta-detalle>
                    <hr>
                    <h3>Seguimiento del documento</h3>
                    <app-correspondencia-documento-seguimiento></app-correspondencia-documento-seguimiento>
                    <hr>
                    <h3>Reasignación de hoja de ruta</h3>
                    <app-correspondencia-hoja-ruta-vincular></app-correspondencia-hoja-ruta-vincular>
                </div>
            </div>
        </div>
    </div>
</div>