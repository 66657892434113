import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { RegistraReactivacion } from '../../modelos';
import { RegistraReactivacionFilter } from '../../modelos/filtros';

export const establecerFiltroRegistraReactivacion = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer filtro REGISTRA REACTIVACION',
  props<{ filtro: RegistraReactivacionFilter }>()
);
export const establecerListaRegistraReactivacion = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer lista REGISTRA REACTIVACION',
  props<{ lista: RegistraReactivacion[]; paginado: Paginado }>()
);
export const establecerRegistraReactivacion = createAction(
  '[DIGITALIZACION_DOCUMENTACION_PATENTE] Establecer objeto REGISTRA REACTIVACION',
  props<{ objeto: RegistraReactivacion }>()
);
