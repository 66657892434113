<div class="mx-auto" style="margin: 0; padding: 0; width: 220mm;">
    <div>
        <span class="d-block mb-2">
            <input type="file" accept=".pdf" style="display: none;"
                (change)="ejecutarOperacion({operacion: 'adjunto-seleccionado', input: $event})" #fileUpload>
            <ng-container *ngIf="!tipoDocumento?.conPlantilla && !documentoAdjunto">
                <i (click)="fileUpload.click()" class="fas fa-file-upload fa-2x text-muted ml-2 mt-2 cursor-pointer"
                    title="Cargar documento"></i>
            </ng-container>
            <ng-container *ngIf="tipoDocumento?.conPlantilla && documento?.esBorrador">
                <i (click)="ejecutarAccion({accion: 'guardar'})"
                    class="fas fa-save fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Guardar contenido"></i>
            </ng-container>
            <ng-container *ngIf="documento?.esBorrador">
                <i (click)="ejecutarOperacion({operacion: 'modificar', id: documento?.id})"
                    class="fas fa-pen-square fa-2x text-muted ml-2 mt-2 cursor-pointer"
                    title="Modificar encabezado"></i>
            </ng-container>
            <ng-container>
                <i (click)="ejecutarAccion({accion: 'duplicar', id: documento?.id})"
                    class="fas fa-copy fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Duplicar documento"></i>
            </ng-container>
            <ng-container *ngIf="!documentoAdjuntoBase64">
                <i (click)="ejecutarAccion({accion: 'vista-previa', id: documento?.id})"
                    class="fas fa-file-pdf fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Vista previa del documento"></i>
            </ng-container>
            <ng-container *ngIf="documentoAdjuntoBase64">
                <i (click)="ejecutarAccion({accion: 'modo-edicion', id: documento?.id})"
                    class="fas fa-file-alt fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Modo edición"></i>
            </ng-container>
            <ng-container *ngIf="documentoAdjunto">
                <i (click)="ejecutarAccion({accion: 'descargar', id: documento?.id, adjuntoId: documentoAdjunto?.id})"
                    class="fas fa-file-pdf fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Descargar documento"></i>
            </ng-container>
            <ng-container *ngIf="estado">
                <span class="float-right text-right">
                    <h3 *ngIf="contenidoSinGuardar"><span class="badge badge-danger">{{ estado }} SIN GUARDAR</span></h3>
                    <h3 *ngIf="!contenidoSinGuardar"><span class="badge badge-success">{{ estado }}</span></h3>
                </span>
            </ng-container>
        </span>
    </div>
</div>

<app-plantilla-documento [ngClass]="{'d-none': documentoAdjuntoBase64}"  [modoVisualizacion]="modoVisualizacion"></app-plantilla-documento>

<div *ngIf="documentoAdjuntoBase64" style="margin: 0; padding: 0; width: 220mm; min-height: 280mm;" class="mb-5 mx-auto">
    <ngx-extended-pdf-viewer [base64Src]="documentoAdjuntoBase64" [useBrowserLocale]="true" [height]="'300mm'"
        [filenameForDownload]="documentoNombre" [showBookmarkButton]="false" [showBorders]="false" [showEditor]="false"
        [showOpenFileButton]="false" [showPrintButton]="false" [showSidebarButton]="false" [textLayer]="true"
        [showHandToolButton]="true" [handTool]="false">
    </ngx-extended-pdf-viewer>
</div>

<ng-template #modalDocumento let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div ngSwitch="modificar">
            <app-correspondencia-documento-formulario *ngSwitchCase="'modificar'" tipoOperacion="modificar"
                (accion)="ejecutarAccion($event)"></app-correspondencia-documento-formulario>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>