export class VistaServiciosRegistrados {
  id?: number;
  correlativo_servicio?: string;
  atender_dccm?: boolean;
  nombre_solicitante?: string;
  idhr?: number;
  cantidad_servicios?: number;
  servicios_seleccionados?: string;
  impreso?: string;
  correo_electronico?: string;
  direccion: string;
  codigo_seguridad: string;
  estado_registro: string;
  numero_ci?: string;
  expedido?: string;
  telefono?: string;
  representa?: string;
  tipo_actor_minero?: string;
  razon_social?: string;
  nit?: string;
  observacion?: string;
  fecha?: Date;
  denominacion: string;

  codigo_transaccion?: string;
  url_redireccion?: string;
  sucursal?: string;
  link_factura?: string;
  modo_registro?: string;

  nombre_completo?: string;
  num_boleta1?: number;
  fecha_depos1?: Date;
  monto_depos1?: number;
  depos1?: string;
  nfactura?: string;
  servicios_seleccionados_completo?: string;
  monto_total?: number;

  correlativo_informe: string;
  correlativo_hoja_ruta: string;
}
