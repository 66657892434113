<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Uni. organizacional:</label>
                    <span>{{ buzon.uniOrganizacional }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Puesto:</label>
                    <span>{{ buzon.puesto }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="esReceptorCorrespondenciaExterna" [checked]="buzon.esReceptorCorrespondenciaExterna" disabled>
                        <label class="custom-control-label" for="esReceptorCorrespondenciaExterna">es receptor de correspondencia externa</label>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 form-group mb-2">
                    <label class="d-block">Estado:</label>
                    <span>{{ buzon.estado }}</span>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
