import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { VistaPatentesMinerasInactivos } from '../../modelos';
import { VistaPatentesMinerasInactivosFilter } from '../../modelos/filtros';

export const establecerFiltroVistaPatentesMinerasInactivos = createAction(
  '[CORRESPONDENCIA] Establecer filtro VISTA_PATENTES_MINERAS_INACTIVOS',
  props<{ filtro: VistaPatentesMinerasInactivosFilter }>()
);
export const establecerListaVistaPatentesMinerasInactivos = createAction(
  '[CORRESPONDENCIA] Establecer lista  VISTA_PATENTES_MINERAS_INACTIVO',
  props<{ lista: VistaPatentesMinerasInactivos[]; paginado: Paginado }>()
);
export const establecerVistaPatentesMinerasInactivos = createAction(
  '[CORRESPONDENCIA] Establecer objeto  VISTA_PATENTES_MINERAS_INACTIVO',
  props<{ objeto: VistaPatentesMinerasInactivos }>()
);
