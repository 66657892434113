import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { VistaPatentesMineras } from '../../modelos';
import { VistaPatentesMinerasFilter } from '../../modelos/filtros';

export const establecerFiltroVistaPatentesMineras = createAction(
  '[CORRESPONDENCIA] Establecer filtro VISTA_PATENTES_MINERAS',
  props<{ filtro: VistaPatentesMinerasFilter }>()
);
export const establecerListaVistaPatentesMineras = createAction(
  '[CORRESPONDENCIA] Establecer lista  VISTA_PATENTES_MINERAS',
  props<{ lista: VistaPatentesMineras[]; paginado: Paginado }>()
);
export const establecerVistaPatentesMineras = createAction(
  '[CORRESPONDENCIA] Establecer objeto  VISTA_PATENTES_MINERAS',
  props<{ objeto: VistaPatentesMineras }>()
);
