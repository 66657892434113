import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { FormularioRecepcionFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroFormularioRecepcion(state: AppState): FormularioRecepcionFilter {
  return state.correspondencia.listaFormularioRecepcion?.filtro;
}

function seleccionarPaginadoFormularioRecepcion(state: AppState): Paginado {
  return state.correspondencia.listaFormularioRecepcion?.paginado;
}

export const filtroFormularioRecepcionSelector = createSelector(
  obtenerState,
  seleccionarFiltroFormularioRecepcion
);
export const paginadoFormularioRecepcionSelector = createSelector(
  obtenerState,
  seleccionarPaginadoFormularioRecepcion
);
