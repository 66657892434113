import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// import Swal from 'sweetalert2';

// import { FuncionesHelper } from 'src/app/comun/auxiliares';
// import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados } from 'src/app/modulos/correspondencia/modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade } from 'src/app/modulos/correspondencia/fachadas';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { SeguridadFacade } from 'src/app/seguridad/fachadas';

import { ReporteService } from 'src/app/modulos/correspondencia/servicios';

@Component({
  selector: 'app-admin-lte3-generacion-cpt',
  templateUrl: './generacion-cpt.component.html',
  styles: []
})
export class GeneracionCptComponent
  implements OnInit, OnDestroy
{
  @Input() tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  DatosQR: string;
  vistaServiciosRegistrados: VistaServiciosRegistrados;
  servicioArray: string [];

  botonOperacion: string;

  tramiteId: number;

  constructor(
    // @Inject(LOCALE_ID) private locale: string,
    private activatedRoute: ActivatedRoute,
    public seguridadFacade: SeguridadFacade,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private reporteService: ReporteService
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.has('tramite')) {
        this.tramiteId = parseInt(params.get('tramite'), 10);
      }
    });
  }

  ngOnInit(): void {

    this.seguridadFacade.iniciarSesionExterna(
      'ccampuzano',
      '123456789'
    );

   this.vistaServiciosRegistradosFacade.obtenerPorId(this.tramiteId).then((resultado) => {
      this.vistaServiciosRegistrados = resultado.objeto;
      this.servicioArray = resultado.objeto.servicios_seleccionados_completo.split('|');
      console.log(this.servicioArray);
   });
    // this.suscripcion.add(
    //   this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
    //     ({ vistaServiciosRegistrados }) => {
    //       if (vistaServiciosRegistrados) {
    //         this.vistaServiciosRegistrados = vistaServiciosRegistrados;
    //         this.servicioArray = vistaServiciosRegistrados.servicios_seleccionados_completo.split('|');

    //         this.DatosQR = vistaServiciosRegistrados.nombre_solicitante + "|" + vistaServiciosRegistrados.telefono + "|" + vistaServiciosRegistrados.representa + "|" + vistaServiciosRegistrados.tipo_actor_minero + "|SERVICIOS 2023|" + "Bs. " + this.addCommas(vistaServiciosRegistrados.monto_total) + " .- " + this.convertirNumerosLetras(vistaServiciosRegistrados.monto_total) + "|" + vistaServiciosRegistrados.razon_social + "|" + vistaServiciosRegistrados.nit;

    //       }
    //     }
    //   )
    // );

  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  imprimirPdfTramite(){

    this.reporteService.generatePdf(this.vistaServiciosRegistrados, this.servicioArray);

  }

  convertirNumero(numero: any)
  {
    const numero_a_convertir = numero;
    return Number(numero_a_convertir);
  }

  addCommas(nStr: any) {
    nStr = String(Number(nStr));
    nStr += '';
    var x = nStr.split(',');
    var x1 = x[0];
    var x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  }

  convertirNumerosLetras(numero: any)
  {
    const numero_a_conv = Number(numero);
    return this.numeroALetras(numero_a_conv, {
      plural: "BOLIVIANOS",
      singular: "BOLIVIANO",
      centPlural: "CENTAVOS",
      centSingular: "CENTAVO"
    });
  }

  numeroALetras = (function() {
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        let strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
        let strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    } //Millones()

    return function NumeroALetras(num, currency) {
        currency = currency || {};
        let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || 'PESOS BOLIVIANOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
            letrasMonedaSingular: currency.singular || 'PESO BOLIVIANOS', //'PESO', 'Dólar', 'Bolivar', 'etc'
            letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS BOLIVIANOS',
            letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO BOLIVIANOS'
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'CON ' + (function() {
                if (data.centavos == 1)
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                else
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
            })();
        };

        // if (data.enteros == 0)
        //     return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        // if (data.enteros == 1)
        //     return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
        // else
        //     return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        if (data.enteros == 0)
            return 'CERO ' + 'CON 00/100.- BOLIVIANOS';
        if (data.enteros == 1)
            return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
        else
            return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
    };

})();

}
