import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { VistaPatentesMinerasInactivosFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroVistaPatentesMinerasInactivos(
  state: AppState
): VistaPatentesMinerasInactivosFilter {
  return state.correspondencia.listaVistaPatentesMinerasInactivos?.filtro;
}

function seleccionarPaginadoVistaPatentesMinerasInactivos(
  state: AppState
): Paginado {
  return state.correspondencia.listaVistaPatentesMinerasInactivos?.paginado;
}

export const filtroVistaPatentesMinerasInactivosSelector = createSelector(
  obtenerState,
  seleccionarFiltroVistaPatentesMinerasInactivos
);
export const paginadoVistaPatentesMinerasInactivosSelector = createSelector(
  obtenerState,
  seleccionarPaginadoVistaPatentesMinerasInactivos
);
