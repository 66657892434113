import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PaginadorComponent } from 'src/app/comun/componentes';
import { Paginado } from 'src/app/comun/modelos';

import { GeneraCertificadoVigencia } from '../../../modelos';
import { GeneraCertificadoVigenciaFilter } from '../../../modelos/filtros';
import { GeneraCertificadoVigenciaFacade } from '../../../fachadas';
import { GeneraCertificadoVigenciaFormularioComponent } from '../../genera-certificado-vigencia';

@Component({
  selector: 'app-genera-certificado-vigencia-lista',
  templateUrl: './genera-certificado-vigencia-lista.component.html',
  styleUrls: []
})
export class GeneraCertificadoVigenciaListaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('modalGeneraCertificadoVigencia') modalGeneraCertificadoVigencia: NgbModal;
  @ViewChild(PaginadorComponent) paginador: PaginadorComponent;

  suscripcion = new Subscription();
  filtro: GeneraCertificadoVigenciaFilter = new GeneraCertificadoVigenciaFilter();
  tipoOperacion: string;
  validacion: string;
  carnet: string;

  generaCertificadoVigencia: GeneraCertificadoVigencia = new GeneraCertificadoVigencia();
  lista: GeneraCertificadoVigencia[];

  modalTitulo: string;
  modal: NgbModalRef;
  constructor(
    private generaCertificadoVigenciaFacade: GeneraCertificadoVigenciaFacade,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.suscripcion.add(
      this.generaCertificadoVigenciaFacade.CorrespondenciaState$.subscribe(
        ({ listaGeneraCertificadoVigencia, generaCertificadoVigencia }) => {
          if (listaGeneraCertificadoVigencia.lista) {
            if (listaGeneraCertificadoVigencia.lista.length >= 0) {
              this.lista = listaGeneraCertificadoVigencia.lista;
              if (listaGeneraCertificadoVigencia.paginado && this.paginador) {
                this.paginador.mostrarPaginador = true;
                this.paginador.valores = new Paginado(
                  listaGeneraCertificadoVigencia.paginado.totalRegistros,
                  listaGeneraCertificadoVigencia.paginado.registrosPorPagina,
                  listaGeneraCertificadoVigencia.paginado.totalPaginas,
                  listaGeneraCertificadoVigencia.paginado.pagina
                );
              }
            }
          }
          if (generaCertificadoVigencia) {
            this.generaCertificadoVigencia = generaCertificadoVigencia;
          }
        }
      )
    );
    this.suscripcion.add(
      this.generaCertificadoVigenciaFacade.Filtro$.subscribe((filtro) => {
        if (filtro && this.paginador) {
          this.filtro = filtro;
          this.generaCertificadoVigenciaFacade.buscar(
            this.filtro,
            1,
            this.paginador.registrosPorPagina
          );
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.paginar();
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    const generaCertificadoVigenciaTitulo = 'Certificado de Pago de Patente Minera';
    switch (evento.operacion) {
      case 'crear': {
        this.tipoOperacion = 'crear';
        this.modalTitulo = 'Crear ' + generaCertificadoVigenciaTitulo;
        this.validacion = 'false';;
        console.log(this.validacion);
        console.log(this.tipoOperacion)
        this.mostrarModal();
        break;
      }
      case 'crear2': {
        this.tipoOperacion = 'crear2';
        this.modalTitulo = 'Crear ' + generaCertificadoVigenciaTitulo;
        console.log(this.validacion);
        this.mostrarModal();
        break;
      }
      case 'detalle': {
        this.tipoOperacion = 'detalle';
        this.generaCertificadoVigenciaFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + generaCertificadoVigenciaTitulo;
        this.mostrarModal();
        break;
      }
      case 'modificar': {
        this.tipoOperacion = 'modificar';
        this.generaCertificadoVigenciaFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Modificar ' + generaCertificadoVigenciaTitulo;
        this.mostrarModal();
        break;
      }
      case 'eliminar': {
        this.tipoOperacion = 'eliminar';
        this.generaCertificadoVigenciaFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Eliminar ' + generaCertificadoVigenciaTitulo;
        this.mostrarModal();
        break;
      }
    }
  }

  ejecutarAccion(evento: any): void {
    switch (evento.accion) {
      case 'guardar': {
        this.generaCertificadoVigenciaFacade.guardar(evento.generaCertificadoVigencia).then((respuesta) => {
          if (respuesta.tipoRespuesta === 'Exito') {
            this.cerrarModal();
          }
        });
        break;
      }
      case 'modificar': {
        this.generaCertificadoVigenciaFacade
          .modificar(evento.generaCertificadoVigenciaId, evento.generaCertificadoVigencia)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {
              this.cerrarModal();
            }
          });
        break;
      }
      case 'eliminar': {
        this.generaCertificadoVigenciaFacade.eliminar(evento.generaCertificadoVigenciaId).then((respuesta) => {
          if (respuesta.tipoRespuesta === 'Exito') {
            this.cerrarModal();
          }
        });
        break;
      }
      case 'buscar': {
        this.generaCertificadoVigenciaFacade.establecerFiltro(evento.generaCertificadoVigencia);
        break;
      }
      case 'cancelar': {
        this.cerrarModal();
        break;
      }
    }
  }

  paginar(): void {
    if (this.paginador) {
      this.generaCertificadoVigenciaFacade.buscar(
        this.filtro,
        this.paginador.paginaActual,
        this.paginador.registrosPorPagina
      );
    }
  }

  mostrarModal(opciones?: any): void {
    this.modal = this.modalService.open(this.modalGeneraCertificadoVigencia, {
      backdrop: 'static',
      size: 'xl',
      ...opciones
    });
  }
  cerrarModal(): void {
    this.modal.close();
  }
  
}
