import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FuncionesHelper } from '../../../../../comun/auxiliares';

import { VistaServiciosRegistradosFilter } from '../../../modelos/filtros';

@Component({
  selector: 'app-correspondencia-vista-servicios-registrados-buscador',
  templateUrl: './vista-servicios-registrados-buscador.component.html',
  styles: []
})
export class VistaServiciosRegistradosBuscadorComponent implements OnInit {
  @Output() accion = new EventEmitter<any>();
  @Input() objeto: VistaServiciosRegistradosFilter;

  formBuscador: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formBuscador = this.fb.group({
      correlativo_servicio: [''],
      nombre_solicitante: [''],
      representa: [''],
      tipo_actor_minero: [''],
      servicios_seleccionados: [''],
    });
  }

  ngOnInit(): void {
    this.formBuscador.setValue({
      correlativo_servicio: this.objeto?.correlativo_servicio || '',
      nombre_solicitante: this.objeto?.nombre_solicitante || '',
      representa: this.objeto?.representa || '',
      tipo_actor_minero: this.objeto?.tipo_actor_minero || '',
      servicios_seleccionados: this.objeto?.servicios_seleccionados || ''
    });
  }

  ejecutarAccion(): void {
    const objeto = { ...this.formBuscador.value } as VistaServiciosRegistradosFilter;
    this.accion.emit({
      accion: 'buscar',
      vistaServiciosRegistrados: FuncionesHelper.quitarNulos(objeto)
    });
  }

  limpiar(): void {
    this.formBuscador.reset({
      correlativo_servicio: '',
      nombre_solicitante: '',
      representa: '',
      tipo_actor_minero: '',
      servicios_seleccionados: ''
    });
  }
}
