<div class="row">
    <div class="col-md-12">
        <h3>Bloques básicos</h3>
        <hr>
        <span class="small text-muted">ESTRUCTURA</span>
        <span class="d-block h5">
            <i (click)="cargarBloque('MembreteComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar membrete"></i>
            Membrete
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('EncabezadoComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar encabezado"></i>
            Encabezado
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('CiteExternoComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar CITE externo"></i>
            CITE externo
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('ParticipantesComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar participantes"></i>
            Participantes
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('RemitenteExternoComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar remitente externo"></i>
            Remitente externo
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('ReferenciaComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar referencia"></i>
            Referencia
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('FechaComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar fecha"></i>
            Fecha
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('LineaComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar línea divisoria"></i>
            Línea divisoria
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('ContenidoComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar contenido"></i>
            Contenido
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('FirmaDigitalComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar firma digital"></i>
            Firma digital
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('AdjuntosComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar adjuntos"></i>
            Adjuntos
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('ReferenciaFinalComponent')"
                class="fas fa-arrow-circle-left text-success cursor-pointer" title="Agregar referencias finales"></i>
            Referencia final
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('PieComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar pie de página"></i>
            Pie de página
        </span>
        <hr>
        <span class="small text-muted">FORMATO</span>
        <span class="d-block h5">
            <i (click)="cargarBloque('TituloComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar título"></i>
            Título
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('TextoComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar texto"></i>
            Texto
        </span>
        <span class="d-block h5">
            <i (click)="cargarBloque('EspacioComponent')" class="fas fa-arrow-circle-left text-success cursor-pointer"
                title="Agregar espacio"></i>
            Espacio
        </span>
    </div>
</div>