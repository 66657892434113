<div class="hold-transition login-page">
    <div class="login-box">
        <!-- /.login-logo -->
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <img class="img-fluid" src="assets/images/logo-autenticacion.png" alt="logo">
            </div>
            <div class="card-body">
                <p class="login-box-msg">{{ denominacion }}</p>
                <form (ngSubmit)="iniciarSesion()" [formGroup]="formAutenticacion" autocomplete="off">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Correo electrónico o cuenta"
                            formControlName='usuario'>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Contraseña"
                            formControlName='contrasenia'>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-dark btn-block" [disabled]="procesando">
                                Acceder <i *ngIf="procesando" class="fa fa-spinner fa-spin"></i></button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <div class="mt-3">
                    <p class="mb-0">
                        <a *ngIf="permiteRegistrarse" [routerLink]="['/registrarse']" class="text-center">Crear
                            cuenta</a>
                    </p>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.login-box -->
</div>