import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EnvironmentHelper } from 'src/app/comun/auxiliares';

import { AppState } from 'src/app/app.state';
import { ServicioMetodosBaseService } from 'src/app/comun/servicios';
import { VistaServiciosRegistrados } from 'src/app/modulos/correspondencia/modelos';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { fontStyle } from 'html2canvas/dist/types/css/property-descriptors/font-style';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor() { }

  /*imprimirPdf(encabezado: string[], cuerpo: Array<any>,titulo: string, guardar?: boolean) {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "letter"
    });
    doc.text(titulo, doc.internal.pageSize.width / 2, 25, { align: 'center' });
    autoTable(doc, {
      head: [encabezado],
      body: cuerpo,
      startY: 30 // Posición inicial para la tabla
    });
  
    if (guardar) {
      const hoy = new Date();
      doc.save(`${titulo}_${hoy.getDate()}_${hoy.getTime()}.pdf`);
    } else {
      // Puedes utilizar doc.output('dataurlnewwindow') para abrir el PDF en una nueva ventana del navegador
    }
  }*/
 /* generatePdf(data: any[], title: string): void {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'letter'
      });

      doc.text(title, doc.internal.pageSize.width / 2, 25, { align: 'center' });

      const header = Object.keys(data[0]);
      const body: any[] = [];

      data.forEach(item => {
        const rowData = [];
        Object.values(item).forEach(value => {
          if (Array.isArray(value)) {
            value.forEach(nestedItem => {
              rowData.push(Object.values(nestedItem));
            });
          } else {
            rowData.push(value);
          }
        });
        body.push(rowData.flat());
      });

      autoTable(doc,{
        head: [header],
        body: body,
        startY: 40
      });

      doc.save(`${title}.pdf`);
    }*/
    generatePdf(vistaServiciosRegistrados: VistaServiciosRegistrados, serviciosArray: string[]): void {
      const doc = new jsPDF({      
         orientation: 'portrait',
         unit: 'px',
         format: 'letter'
      });
    
      doc.setFontSize(17);
      doc.text("PROFORMA DE PAGO", doc.internal.pageSize.width / 2, 60, { align: 'center' });
      doc.setFontSize(8);
      doc.text("Fecha y hora de generación: Jueves, 20 Julio 2023 12:27", doc.internal.pageSize.width / 2, 70, { align: 'center' });
      
      const imgData = 'assets/images/logo_AJAM.png'; // Reemplaza 'ruta_de_la_imagen.jpg' con la ruta de tu imagen
      const imageWidth = 80; // Ancho de la imagen en el PDF
      const imageHeight = 40; // Alto de la imagen en el PDF
      const xPosition = 35; // Posición X de la imagen centrada
      const yPosition = 25; // Posición Y de la imagen
      doc.addImage(imgData, 'PNG', xPosition, yPosition, imageWidth, imageHeight);

      const imgDataQr = 'assets/images/qr.jpg'; // Reemplaza 'ruta_de_la_imagen.jpg' con la ruta de tu imagen
      const imageWidthQr = 70; // Ancho de la imagen en el PDF
      const imageHeightQr = 70; // Alto de la imagen en el PDF
      const xPositionQr = 365 // Posición X de la imagen centrada
      const yPositionQr = 25; // Posición Y de la imagen
      doc.addImage(imgDataQr, 'JPG', xPositionQr, yPositionQr, imageWidthQr, imageHeightQr);

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        body: [
          [
            {
              content: 'CPT: 7056-6419-1632',
              styles: {
                halign: 'center',
                fontSize: 27,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times'
              }
            }
          ],
        ],
        theme: 'grid',
        styles: {
          fillColor: '#037018'
        },
        startY: 112
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 110},
          1: {cellWidth: 289},
        },
        body: [
          [
            {
              content: 'DATOS PERSONALES DEL SOLICITANTE',
              colSpan: 2,
              styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#037018',
                lineColor: '#fffffb'
              }
            }
          ],
          [
            {
              content: 'REMITENTE:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb',
                lineColor: '#fffffb'
              }
            },
            {
              content: vistaServiciosRegistrados.nombre_solicitante,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb',
                lineColor: '#fffffb'
              }
            }
          ],
          [
            {
              content: 'TELF/CEL:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: vistaServiciosRegistrados.telefono,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: 'REPRESENTANTE:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: vistaServiciosRegistrados.representa,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: 'TIPO DE ACTOR:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: vistaServiciosRegistrados.tipo_actor_minero,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
        ],
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY + 10
      });


      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 110},
          1: {cellWidth: 289},
        },
        body: [
          [
            {
              content: 'CÁLCULO DE LA DEUDA',
              colSpan: 2,
              styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#037018'
              }
            }
          ],
          [
            {
              content: 'CONCEPTO:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: 'SERVICIOS 2023',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: 'MONTO A PAGAR:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: 'Bs.' + this.addCommas(vistaServiciosRegistrados.monto_total) + ' .- ' + this.convertirNumerosLetras(vistaServiciosRegistrados.monto_total),
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ]
        ],
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY + 10
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 399},
        },
        body: [
          [
            {
              content: 'DETALLE DE CÁLCULO DE LA DEUDA',
              styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#037018'
              }
            }
          ]
        ],
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY + 10
      });


      const mainHeaders = ["Servicios:", "Precio Unitario (Bs.)","Cantidad", "Sub Total"];
   
      const mainData = serviciosArray.map(item => [item.split('&&')[1],this.convertirNumero(item.split('&&')[3]), this.convertirNumero(item.split('&&')[2]), this.convertirNumero(item.split('&&')[4]) ]);
   
      autoTable(doc,{
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 249},
          1: {cellWidth: 50},
          2: {cellWidth: 50},
          3: {cellWidth: 50},
        },
        head: [mainHeaders],
        body: mainData,
        headStyles: {
          lineColor: [0, 0, 0],
          halign: 'center',
          fontSize: 7,
          textColor: '#000000',
          fontStyle: 'bold',
          font: 'times',
          fillColor: '#b2e4bb',
        },
        bodyStyles: {
          lineColor: [0, 0, 0],
          halign: 'center',
          fontSize: 7,
          textColor: '#000000',
          font: 'times',
          fillColor: '#eef3eb'
        },
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY
      });

      // autoTable(doc, {
      //   tableLineColor: [0, 0, 0],
      //   tableLineWidth: 0.30,
      //   columnStyles: {
      //     0: {cellWidth: 249},
      //     1: {cellWidth: 50},
      //     2: {cellWidth: 50},
      //     3: {cellWidth: 50},
      //   },
      //   body: [
      //     [
      //       {
      //         content: 'DETALLE DE CÁLCULO DE LA DEUDA',
      //         colSpan: 4,
      //         styles: {
      //           halign: 'left',
      //           fontSize: 12,
      //           textColor: '#fffffb',
      //           fontStyle: 'bold',
      //           font: 'times',
      //           fillColor: '#037018'
      //         }
      //       }
      //     ],
      //     [
      //       {
      //         content: 'Servicios:',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           fontStyle: 'bold',
      //           font: 'times',
      //           fillColor: '#b2e4bb',
      //         }
      //       },
      //       {
      //         content: 'Precio Unitario (Bs.)',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           fontStyle: 'bold',
      //           font: 'times',
      //           fillColor: '#b2e4bb'
      //         }
      //       },
      //       {
      //         content: 'Cantidad',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           fontStyle: 'bold',
      //           font: 'times',
      //           fillColor: '#b2e4bb'
      //         }
      //       },
      //       {
      //         content: 'Sub Total',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           fontStyle: 'bold',
      //           font: 'times',
      //           fillColor: '#b2e4bb'
      //         }
      //       }
      //     ],
      //     [
      //       {
      //         content: 'Servicio: C-01-Certificado de Vigencia de Derecho Minero, papel bond tamaño carta (Hoja membretada). - Grupo: GRUPO 2',
      //         styles: {
      //           halign: 'left',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times',
      //           fillColor: '#eef3eb'
      //         }
      //       },
      //       {
      //         content: '1',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times',
      //           fillColor: '#eef3eb'
      //         }
      //       },
      //       {
      //         content: '90',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times',
      //           fillColor: '#eef3eb'
      //         }
      //       },
      //       {
      //         content: '90',
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times',
      //           fillColor: '#eef3eb'
      //         }
      //       }
      //     ],
      //   ],
      //   theme: 'grid',
      //   startY: (doc as any).lastAutoTable.finalY + 10
      // });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 110},
          1: {cellWidth: 289},
        },
        body: [
          [
            {
              content: 'DATOS PARA LA FACTURA',
              colSpan: 2,
              styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#037018'
              }
            }
          ],
          [
            {
              content: 'NOMBRE/RAZON SOCIAL:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: vistaServiciosRegistrados.razon_social,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: 'NIT:',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#b2e4bb'
              }
            },
            {
              content: vistaServiciosRegistrados.nit,
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ]
        ],
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY + 10
      });


      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 399}
        },
        body: [
          [
            {
              content: 'NOTAS',
              styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#fffffb',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#037018'
              }
            }
          ],
          [
            {
              content: '1. El pago podrá realizarse a través de cualquier SUCURSAL O AGENCIA DEL BNANCO UNION, UNIET Y UNIMOVIL inidicando solamente el CPT que se muestra en la cabecera de la presente proforma',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: '2. La presente Proforma de Pago tiene una validez de 30 dias calendario (Hasta el 30/11/2022) a partir de su fecha de generación. Posterior a esta fecha, se deberá generar una nueva Proforma de Pago.',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: '3. El presente documento no se constituye en un instrumento válido para fines legales. El saldo adeudado está sujeto a actualización, validación y confirmación por parte de la Autoridad Jurisdiccional Administrativa Minera AJAM.',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ],
          [
            {
              content: '4. La impresión de la presente Proforma de Pago es opcional, para el pago ewn ewl Banco solo necesita el CPT que se muestra en la cabecera de la presente proforma.',
              styles: {
                halign: 'left',
                fontSize: 9,
                textColor: '#000000',
                font: 'times',
                fillColor: '#eef3eb'
              }
            }
          ]
        ],
        theme: 'grid',
        startY: (doc as any).lastAutoTable.finalY + 10
      });

  
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 100},
            1: {cellWidth: 299}
          },
          body: [
            [
              {
                content: 'Datos del Solicitante:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times'
                }
              },
              {
                content: 'Alissa Strekozova 2424234',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
            [
              {
                content: 'Nro Documento Identidad:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times'
                }
              },
              {
                content: '55354',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ],
            [
              {
                content: 'Código de Seguridad:',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  fontStyle: 'bold',
                  font: 'times'
                }
              },
              {
                content: '075805ffab567ae81938f6bc445d2ed97e9d76b1 ',
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                  font: 'times'
                }
              }
            ]
          ],
          theme: 'plain',
          startY: (doc as any).lastAutoTable.finalY + 40
        });
  

        
      //doc.save('angular-demo-6.pdf')

    
     //doc.save(`${cadena}.pdf`);
     //doc.save(`assets/docucmentos/${datos}.pdf`);
     doc.output('dataurlnewwindow');
    }
  
    convertirNumero(numero: any)
    {
      const numero_a_convertir = numero;
      return Number(numero_a_convertir);
    }

    addCommas(nStr: any) {
      nStr = String(Number(nStr));
      nStr += '';
      var x = nStr.split(',');
      var x1 = x[0];
      var x2 = x.length > 1 ? ',' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
              x1 = x1.replace(rgx, '$1' + '.' + '$2');
      }
      return x1 + x2;
    }
  
    convertirNumerosLetras(numero: any)
    {
      const numero_a_conv = Number(numero);
      return this.numeroALetras(numero_a_conv, {
        plural: "BOLIVIANOS",
        singular: "BOLIVIANO",
        centPlural: "CENTAVOS",
        centSingular: "CENTAVO"
      });
    }

    numeroALetras = (function() {
      function Unidades(num) {
  
          switch (num) {
              case 1:
                  return 'UN';
              case 2:
                  return 'DOS';
              case 3:
                  return 'TRES';
              case 4:
                  return 'CUATRO';
              case 5:
                  return 'CINCO';
              case 6:
                  return 'SEIS';
              case 7:
                  return 'SIETE';
              case 8:
                  return 'OCHO';
              case 9:
                  return 'NUEVE';
          }
  
          return '';
      } //Unidades()
  
      function Decenas(num) {
  
          let decena = Math.floor(num / 10);
          let unidad = num - (decena * 10);
  
          switch (decena) {
              case 1:
                  switch (unidad) {
                      case 0:
                          return 'DIEZ';
                      case 1:
                          return 'ONCE';
                      case 2:
                          return 'DOCE';
                      case 3:
                          return 'TRECE';
                      case 4:
                          return 'CATORCE';
                      case 5:
                          return 'QUINCE';
                      default:
                          return 'DIECI' + Unidades(unidad);
                  }
              case 2:
                  switch (unidad) {
                      case 0:
                          return 'VEINTE';
                      default:
                          return 'VEINTI' + Unidades(unidad);
                  }
              case 3:
                  return DecenasY('TREINTA', unidad);
              case 4:
                  return DecenasY('CUARENTA', unidad);
              case 5:
                  return DecenasY('CINCUENTA', unidad);
              case 6:
                  return DecenasY('SESENTA', unidad);
              case 7:
                  return DecenasY('SETENTA', unidad);
              case 8:
                  return DecenasY('OCHENTA', unidad);
              case 9:
                  return DecenasY('NOVENTA', unidad);
              case 0:
                  return Unidades(unidad);
          }
      } //Unidades()
  
      function DecenasY(strSin, numUnidades) {
          if (numUnidades > 0)
              return strSin + ' Y ' + Unidades(numUnidades)
  
          return strSin;
      } //DecenasY()
  
      function Centenas(num) {
          let centenas = Math.floor(num / 100);
          let decenas = num - (centenas * 100);
  
          switch (centenas) {
              case 1:
                  if (decenas > 0)
                      return 'CIENTO ' + Decenas(decenas);
                  return 'CIEN';
              case 2:
                  return 'DOSCIENTOS ' + Decenas(decenas);
              case 3:
                  return 'TRESCIENTOS ' + Decenas(decenas);
              case 4:
                  return 'CUATROCIENTOS ' + Decenas(decenas);
              case 5:
                  return 'QUINIENTOS ' + Decenas(decenas);
              case 6:
                  return 'SEISCIENTOS ' + Decenas(decenas);
              case 7:
                  return 'SETECIENTOS ' + Decenas(decenas);
              case 8:
                  return 'OCHOCIENTOS ' + Decenas(decenas);
              case 9:
                  return 'NOVECIENTOS ' + Decenas(decenas);
          }
  
          return Decenas(decenas);
      } //Centenas()
  
      function Seccion(num, divisor, strSingular, strPlural) {
          let cientos = Math.floor(num / divisor)
          let resto = num - (cientos * divisor)
  
          let letras = '';
  
          if (cientos > 0)
              if (cientos > 1)
                  letras = Centenas(cientos) + ' ' + strPlural;
              else
                  letras = strSingular;
  
          if (resto > 0)
              letras += '';
  
          return letras;
      } //Seccion()
  
      function Miles(num) {
          let divisor = 1000;
          let cientos = Math.floor(num / divisor)
          let resto = num - (cientos * divisor)
  
          let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
          let strCentenas = Centenas(resto);
  
          if (strMiles == '')
              return strCentenas;
  
          return strMiles + ' ' + strCentenas;
      } //Miles()
  
      function Millones(num) {
          let divisor = 1000000;
          let cientos = Math.floor(num / divisor)
          let resto = num - (cientos * divisor)
  
          let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
          let strMiles = Miles(resto);
  
          if (strMillones == '')
              return strMiles;
  
          return strMillones + ' ' + strMiles;
      } //Millones()
  
      return function NumeroALetras(num, currency) {
          currency = currency || {};
          let data = {
              numero: num,
              enteros: Math.floor(num),
              centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
              letrasCentavos: '',
              letrasMonedaPlural: currency.plural || 'PESOS BOLIVIANOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
              letrasMonedaSingular: currency.singular || 'PESO BOLIVIANOS', //'PESO', 'Dólar', 'Bolivar', 'etc'
              letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS BOLIVIANOS',
              letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO BOLIVIANOS'
          };
  
          if (data.centavos > 0) {
              data.letrasCentavos = 'CON ' + (function() {
                  if (data.centavos == 1)
                      return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                  else
                      return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
              })();
          };
  
          // if (data.enteros == 0)
          //     return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
          // if (data.enteros == 1)
          //     return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
          // else
          //     return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
          if (data.enteros == 0)
              return 'CERO ' + 'CON 00/100.- BOLIVIANOS';
          if (data.enteros == 1)
              return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
          else
              return Millones(data.enteros) + ' ' + 'CON 00/100.- BOLIVIANOS';
      };
  
  })();

}
