import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';

import { FormularioRecepcion } from '../../../modelos';
import { FormularioRecepcionFacade } from '../../../fachadas';

@Component({
  selector: 'app-correspondencia-formulario-recepcion-detalle',
  templateUrl: './formulario-recepcion-detalle.component.html',
  styles: []
})
export class FormularioRecepcionDetalleComponent implements OnInit, OnDestroy {
  @Input() tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  botonOperacion: string;

  formularioRecepcion: FormularioRecepcion;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private formularioRecepcionFacade: FormularioRecepcionFacade
  ) {
    if (!this.formularioRecepcion) {
      this.formularioRecepcion = new FormularioRecepcion();
    }
  }

  ngOnInit(): void {
    this.suscripcion.add(
      this.formularioRecepcionFacade.CorrespondenciaState$.subscribe(({ formularioRecepcion }) => {
        if (formularioRecepcion) {
          this.formularioRecepcion = formularioRecepcion;
        }
      })
    );
    switch (this.tipoOperacion) {
      case 'detalle':
        this.botonOperacion = null;
        break;
      case 'eliminar':
        this.botonOperacion = 'Eliminar';
        break;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarAccion(accion: string): void {
    switch (accion) {
      case 'eliminar': {
        this.accion.emit({
          accion,
          formularioRecepcionId: this.formularioRecepcion.id
        });
        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }
}
