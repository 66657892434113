import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { VistaPatentesMinerasFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroVistaPatentesMineras(
  state: AppState
): VistaPatentesMinerasFilter {
  return state.correspondencia.listaVistaPatentesMineras?.filtro;
}

function seleccionarPaginadoVistaPatentesMineras(
  state: AppState
): Paginado {
  return state.correspondencia.listaVistaPatentesMineras?.paginado;
}

export const filtroVistaPatentesMinerasSelector = createSelector(
  obtenerState,
  seleccionarFiltroVistaPatentesMineras
);
export const paginadoVistaPatentesMinerasSelector = createSelector(
  obtenerState,
  seleccionarPaginadoVistaPatentesMineras
);
