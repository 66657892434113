<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)" [esConfigurable]="true">
        <div class="row">
            <div class="col-12">
                <hr style="border-top: 2px solid black; height: 2px; max-width: 100%; padding: 0;" [ngStyle]="{'margin': configuracion | linea}">
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <div class="row">
        <div class="col-12">
            <hr style="border-top: 2px solid black; height: 2px; max-width: 100%; padding: 0;" [ngStyle]="{'margin': configuracion | linea}">
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <div class="row">
        <div class="col-12">
            <hr style="border-top: 2px solid black; height: 2px; max-width: 100%; padding: 0;"  [ngStyle]="{'margin': configuracion | linea }">
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <div class="row">
        <div class="col-12">
            <hr style="border-top: 2px solid black; height: 2px; max-width: 100%; padding: 0;" [ngStyle]="{'margin': configuracion | linea}">
        </div>
    </div>
</ng-container>

<ng-template #modalLinea let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Línea divisoria - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="formConfiguracion" autocomplete="off" class="text-default">
                    <div class="form-row mb-3">
                        <div class="col-md-3">
                            <label><span class="required-field-indicator"></span> Margen superior:</label>
                            <input type="number" class="form-control" formControlName="margenSuperior" />
                        </div>
                        <div class="col-md-3">
                            <label><span class="required-field-indicator"></span> Margen inferior:</label>
                            <input type="number" class="form-control" formControlName="margenInferior" />
                        </div>
                    </div>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit" class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button" class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>