<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)"
        [esConfigurable]="true">
        <div class="row">
            <div class="col-12">
                <ng-container *ngIf="configuracion.imprimirImagen">
                    <img class="img-fluid" [ngClass]="configuracion | membrete" style="height: 25mm;"
                        [src]="configuracion.imagen" alt="[MEMBRETE]">
                </ng-container>
                <ng-container *ngIf="!configuracion.imprimirImagen">
                    <span class="d-block" style="height: 10mm;"></span>
                </ng-container>
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="configuracion.imprimirImagen">
                <img class="img-fluid" [ngClass]="configuracion | membrete" style="height: 25mm;"
                    [src]="configuracion.imagen" alt="[MEMBRETE]">
            </ng-container>
            <ng-container *ngIf="!configuracion.imprimirImagen">
                <span class="d-block" style="height: 10mm;"></span>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="configuracion.imprimirImagen">
                <img class="img-fluid" [ngClass]="configuracion | membrete" style="height: 25mm;"
                    [src]="configuracion.imagen" alt="[MEMBRETE]">
            </ng-container>
            <ng-container *ngIf="!configuracion.imprimirImagen">
                <span class="d-block" style="height: 10mm;"></span>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="configuracion.imprimirImagen">
                <img class="img-fluid" [ngClass]="configuracion | membrete" style="height: 25mm;"
                    [src]="configuracion.imagen" alt="[MEMBRETE]">
            </ng-container>
            <ng-container *ngIf="!configuracion.imprimirImagen">
                <span class="d-block" style="height: 10mm;"></span>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #modalMembrete let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Membrete - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="formConfiguracion" autocomplete="off" class="text-default">
                    <div class="form-row mb-2">
                        <div class="col-md-12">
                            <label><span class="required-field-indicator"></span> URL imagen:</label>
                            <input #imagen type="text" class="form-control" formControlName="imagen" />
                        </div>
                        <div class="col-md-12">
                            <div class="form-check text-right">
                                <input class="form-check-input" type="checkbox" id="imprimirImagen"
                                    [checked]="configuracion.imprimirImagen" formControlName="imprimirImagen">
                                <label class="form-check-label" for="imprimirImagen">
                                    imprimir imagen
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-md-6">
                            <img class="img-fluid mx-auto d-block" style="height: 25mm;" [src]="imagen.value"
                                alt="[MEMBRETE]">
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionIzquierda"
                                        name="alineacion" value="IZQUIERDA" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionIzquierda">Imagen alineada a la
                                        izquierda</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionCentro"
                                        name="alineacion" value="CENTRO" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionCentro">Imagen centrada</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group mb-2">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="alineacionDerecha"
                                        name="alineacion" value="DERECHA" formControlName="alineacion">
                                    <label class="custom-control-label" for="alineacionDerecha">Imagen alineada a la
                                        derecha</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit"
                                class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button"
                                class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados
                                con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>