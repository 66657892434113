<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Cuenta:</label>
                    <span>{{ cuenta.codigo }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Modo Autenticacion:</label>
                    <span>{{ cuenta.modoAutenticacion }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Nombre:</label>
                    <span>{{ cuenta.nombre }}</span>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
