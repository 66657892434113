export * from './lista-archivo/documento-archivo.component';
export * from './archivo-detalle/documento-archivo-detalle.component';
export * from './lista-archivo-central/documento-archivo-central.component';
export * from './archivo-central-detalle/documento-archivo-central-detalle.component';
export * from './aprobar/documento-aprobar.component';
export * from './buscador/documento-buscador.component';
export * from './detalle/documento-detalle.component';
export * from './derivar/documento-derivar.component';
export * from './enviar/documento-enviar.component';
export * from './formulario/documento-formulario.component';
export * from './seguimiento/documento-seguimiento.component';
export * from './recibir/documento-recibir.component';
export * from './anexo/documento-anexo.component';
export * from './vista-previa/documento-vista-previa.component';
export * from './respaldo/documento-respaldo.component';
export * from './resumen/documento-resumen.component';
