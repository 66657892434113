<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-documento-buscador [colapsado]="false" [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-documento-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th class="td-auto">FEC. DE REGISTRO</th>
                                <th>DETALLE</th>
                                <th>REMITENTE</th>
                                <th>DESTINATARIO</th>
                                <th class="td-auto">OPERACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="td-auto align-middle">
                                    <span class="d-block"><i class="far fa-calendar-alt"></i> {{ item.instRegistro |
                                        date: 'dd/MM/yyyy' }}</span>
                                    <span class="d-block"><i class="far fa-clock"></i> {{ item.instRegistro | date:
                                        'HH:mm' }}</span>
                                </td>
                                <td class="align-middle">
                                    <span class="d-block"><strong>H.R.:</strong> {{ item.hojaRutaNumero }}</span>
                                    <span class="d-block"><strong>TIPO DE DOCUMENTO:</strong> {{ item.tipoDocumentoNombre }}</span>
                                    <span class="d-block"><strong>CITE: </strong> {{ item.cite }}</span>
                                    <span *ngIf="item.citeExterno" class="d-block"><strong>CITE externo: </strong> {{ item.citeExterno }}</span>
                                    <span class="d-block"><strong>REFERENCIA:</strong> {{ item.referencia }}</span>
                                    <span class="badge badge-dark">{{ item.prioridad }}</span>
                                </td>
                                <td class="td-auto align-middle text-wrap">
                                    <ng-container *ngIf="item.listaParticipante">
                                        <div *ngFor="let participante of item.listaParticipante | participantes: 'REMITENTE'"
                                            class="d-block">
                                            <span class="d-block text-nowrap">{{ participante.nombre }}</span>
                                            <span class="d-block text-muted">{{ participante.puesto }}</span>
                                            <span class="d-block text-muted font-weight-bold">{{
                                                participante.uniOrganizacional }}</span>
                                        </div>
                                    </ng-container>
                                </td>
                                <td class="td-auto align-middle text-wrap">
                                    <div *ngFor="let participante of item.listaParticipante | participantes: 'DESTINATARIO'"
                                        class="d-block">
                                        <span class="d-block text-nowrap">{{ participante.nombre }}</span>
                                        <span class="d-block text-muted">{{ participante.puesto }}</span>
                                        <span class="d-block text-muted font-weight-bold">{{
                                            participante.uniOrganizacional }}</span>
                                    </div>
                                </td>
                                <td class="align-middle">
                                    <i (click)="ejecutarOperacion({operacion: 'seguimiento', id: item.id})"
                                        class="fas fa-stream fa-lg mr-1 cursor-pointer"
                                        title="Ver seguimiento"></i>
                                    <a [routerLink]="['/correspondencia/archivo', item.id, 'detalle']">
                                        <i class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Detalle de documento"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
    </div>
</div>

<ng-template #modalDocumento let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-documento-seguimiento *ngSwitchCase="'seguimiento'" 
                (accion)="ejecutarAccion($event)" [mostrarCabecera]="true"></app-correspondencia-documento-seguimiento>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>