import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { VistaAreasMinerasFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroVistaAreasMineras(state: AppState): VistaAreasMinerasFilter {
  return state.correspondencia.listaVistaAreasMineras?.filtro;
}

function seleccionarPaginadoVistaAreasMineras(state: AppState): Paginado {
  return state.correspondencia.listaVistaAreasMineras?.paginado;
}

export const filtroVistaAreasMinerasSelector = createSelector(
  obtenerState,
  seleccionarFiltroVistaAreasMineras
);
export const paginadoVistaAreasMinerasSelector = createSelector(
  obtenerState,
  seleccionarPaginadoVistaAreasMineras
);
