import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { SolicitanteOrdenServicio } from '../../modelos';
import { SolicitanteOrdenServicioFilter } from '../../modelos/filtros';

export const establecerFiltroSolicitanteOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer filtro SOLICITANTE_ORDEN_SERVICIO',
  props<{ filtro: SolicitanteOrdenServicioFilter }>()
);
export const establecerListaSolicitanteOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer lista SOLICITANTE_ORDEN_SERVICIO',
  props<{ lista: SolicitanteOrdenServicio[]; paginado: Paginado }>()
);
export const establecerSolicitanteOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer objeto SOLICITANTE_ORDEN_SERVICIO',
  props<{ objeto: SolicitanteOrdenServicio }>()
);
