<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">{{pageInfo['title']}}</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <ng-template ngFor let-url [ngForOf]="pageInfo['urls']" let-last="last">
            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
              <a href='javascript:void(0)'>{{url.title}}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
          </ng-template>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>