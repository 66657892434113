import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FuncionesHelper } from '../../../../../comun/auxiliares';

import { GeneraCertificadoVigenciaFilter } from '../../../modelos/filtros';

@Component({
  selector: 'app-correspondencia-genera-certificado-vigencia-buscador',
  templateUrl: './genera-certificado-vigencia-buscador.component.html',
  styles: []
})
export class GeneraCertificadoVigenciaBuscadorComponent implements OnInit {
  @Output() accion = new EventEmitter<any>();
  @Input() objeto: GeneraCertificadoVigenciaFilter;

  formBuscador: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formBuscador = this.fb.group({
      correlativo_fore: [''],
      fecha_genera_certificado: [''],
      correlativo_certificado: ['']
    });
  }

  ngOnInit(): void {
    this.formBuscador.setValue({
      correlativo_fore: this.objeto?.correlativo_fore || '',
      fecha_genera_certificado: this.objeto?.fecha_genera_certificado || '',
      correlativo_certificado:this.objeto?.correlativo_certificado || '' 
    });
  }

  ejecutarAccion(): void {
    const objeto = { ...this.formBuscador.value } as GeneraCertificadoVigenciaFilter;
    this.accion.emit({
      accion: 'buscar',
      generaCertificadoVigencia: FuncionesHelper.quitarNulos(objeto)
    });
  }

  limpiar(): void {
    this.formBuscador.reset({
      correlativo_fore: '',
      fecha_genera_certificado: '',
      correlativo_certificado:''
    });
  }
}
