import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { RequisitosNotasFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroRequisitosNotas(state: AppState):  RequisitosNotasFilter {
  return state.correspondencia.listaRequisitosNotas?.filtro;
}

function seleccionarPaginadoRequisitosNotas(state: AppState): Paginado {
  return state.correspondencia.listaRequisitosNotas?.paginado;
}

export const filtroRequisitosNotasSelector = createSelector(
  obtenerState,
  seleccionarFiltroRequisitosNotas
);
export const paginadoRequisitosNotasSelector = createSelector(
  obtenerState,
  seleccionarPaginadoRequisitosNotas
);
