<style type="text/css">
    /*stylo CCCS 15/06/2016*/
    .tabla_servicios {
        border-collapse: collapse;
        width: 100%;
    }

    .tabla_servicios td, .tabla_servicios th {
        border: 1px solid black;
        padding: 8px;
    }

    .tabla_servicios_botones {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_botones td, .tabla_servicios_botones th {
        border: 0px solid black;
        padding: 8px;
    }
    .tabla_servicios_total {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_total td, .tabla_servicios_total th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .input_boleta{
        margin-left:10px;
        float:left;
        width:150px;
    }
    .input_depositante{
        text-transform:uppercase;
        width:800px;
    }
    .light{
        color: white;
        font-weight: bolder;
    }
    .modal-body {
        height: 287px;
    }
    /*Fin stylo CCCS 15/06/2016*/
    .box.box-black>.box-title
    {
        background: rgb(4,3,23);
        background: linear-gradient(180deg, rgba(4,3,23,1) 0%, rgba(12,88,115,1) 100%, rgba(102,142,149,1) 100%);
        font-family: Times New Roman;
        font-size: 20px;
        letter-spacing: 0px;
        word-spacing: 0px;
        font-weight: bold;
        text-decoration: none;
        font-style: oblique;
        font-variant: normal;
        text-transform: none;
        color:white;
    }
    /*Estilo para div lado a lado*/
    #div1{
            width: 50%;
            float: left;
            position: relative;
    }
    #div2{
            width: 50%;
            float: right;
            position: relative;
    }
    table thead {
        color: #fff;
        background-color: rgb(44, 62, 88);
    }

    #div4{
        width: 40%;
    }
    #div5{
        width: 100%;
        float: left;
        position: relative;     
    }
    #div6{
        width: 40%;
        float: right;
        position: relative;
    }
</style>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>

<div class="row">
    <div class="col-12">
        <form [formGroup]="formVistaServiciosRegistrados" autocomplete="off" class="text-default">
            <!-- <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Correlativo Servicio:</label>
                    <input type="text" class="form-control" formControlName="correlativo_servicio" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Nombre Solicitante:</label>
                    <input type="text" class="form-control" formControlName="nombre_solicitante" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Tipo Actor Minero:</label>
                    <input type="text" class="form-control" formControlName="tipo_actor_minero" />
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1"
                        [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="button" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                        <span class="required-field-indicator"></span></em>
                </div>
            </div> -->
            <div class="box">
                <div class="box-content">
                    <div class="div1">
                        <label class="control-label">Fecha y Hora de Solicitud del Servicio:</label>
                    </div>
                    <div class="div2">
                    <input type="input" class="form-control" formControlName="fecha" [ngModel]='fecha | date:"dd/MM/yyyy HH:mm:ss"' readonly/>
                    </div>

                    <div class="div1">
                        <label class="control-label">Dirección Departamental o Regional donde se entregará el servicio:</label>
                    </div>
                    <div class="div2">
                        <ng-select formControlName="sucursal">
                            <ng-option value="">[Elija una opción]</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA OFICINA NACIONAL</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL COCHABAMBA</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL SANTA CRUZ</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL ORURO</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL POTOSI</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA DEPARTAMENTAL CHUQUISACA</ng-option>
                            <ng-option value="AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA">AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA REGIONAL TUPIZA TARIJA</ng-option>
                        </ng-select>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="bi bi-calendar2-week"></i> DATOS PERSONALES DEL SOLICITANTE
                                </div>
                                <div class="box-content">
                                    <table class="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Nombre Completo: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="nombre_completo" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> CI: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="numero_ci" maxlength="20" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Expedido: </label>
                                                    <div class="controls">
                                                        <ng-select formControlName="expedido" [clearable]="false">
                                                            <ng-option value="">[Elija una opción]</ng-option>
                                                            <ng-option value="LA PAZ">LA PAZ</ng-option>
                                                            <ng-option value="COCHABAMBA">COCHABAMBA</ng-option>
                                                            <ng-option value="SANTA CRUZ">SANTA CRUZ</ng-option>
                                                            <ng-option value="PANDO">PANDO</ng-option>
                                                            <ng-option value="BENI">BENI</ng-option>
                                                            <ng-option value="ORURO">ORURO</ng-option>
                                                            <ng-option value="POTOSI">POTOSI</ng-option>
                                                            <ng-option value="CHUQUISACA">CHUQUISACA</ng-option>
                                                            <ng-option value="TARIJA">TARIJA</ng-option>
                                                            <ng-option value="EXTERNO">EXTERNO</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Telefono/Celular: </label>
                                                    <div class="controls">
                                                        <input type="text" (keypress)="validateFormat($event)" class="form-control" formControlName="telefono" maxlength="8"/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Representa a: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="representa" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label"> Tipo Actor Minero: </label>
                                                    <div class="controls">
                                                        <ng-select formControlName="tipo_actor_minero">
                                                            <ng-option value="">[Elija una opción]</ng-option>
                                                            <ng-option value="PERSONA NATURAL">PERSONA NATURAL</ng-option>
                                                            <ng-option value="COOPERATIVA">COOPERATIVA</ng-option>
                                                            <ng-option value="EMPRESA">EMPRESA</ng-option>
                                                            <ng-option value="ESTATAL">ESTATAL</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Correo Electrónico: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="correo_electronico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"/>
                                                    </div>
                                                </td>
                                                <td colspan="2">
                                                    <label class="control-label"> Direccion: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="direccion" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> DATOS PARA EFECTOS DE FACTURACIÓN
                                </div>
                                <div class="box-content">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td width="650px">
                                                    <label class="control-label">Nombre/Razon Social:</label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="razon_social" style="width: 400px;" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="control-label">N.I.T.:</label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="nit" maxlength="13" oninput="this.value = this.value.toUpperCase()" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> SERVICIOS DEL CATASTRO
                                </div>
                                <div class="box-content">
                                    <ng-container>
                                        <div class="col-md-6 form-group mb-2">
                                            <label>Escriba y selecciones el/los servicio/s requeridos/s:</label>
                                            <div class="col-md-20 form-group mb-20" style="width:1000px;">
                                                <select class="form-control" formControlName="serviciosSeleccionado" id="serviciosSeleccionado" (change)="onChangeSeleccion($event)">
                                                    <option value=""></option>
                                                    <ng-container *ngFor="let item of listaServiciosCodificador">
                                                        <option *ngIf='item.nombre.split("&&")[1] === "INTERNO"' [value]="item.id">{{ item.nombre.split("&&")[0] }}</option>
                                                    </ng-container>
                                                </select>
                                                <!-- <ng-select (change)="ejecutarOperacion({operacion: 'seleccionar-servicios', id: $event})"
                                                    [clearable]="true" formControlName="serviciosSeleccionado">
                                                    <ng-option value="">[Elija una opción]</ng-option>
                                                    <ng-container *ngFor="let item of listaServiciosCodificador">
                                                        <ng-option *ngIf='item.nombre.split("&&")[1] === "INTERNO"' [value]="item.id">
                                                            {{ item.nombre.split("&&")[0] }}
                                                        </ng-option>
                                                    </ng-container>
                                                </ng-select> -->
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div id="parametrosServicio" *ngIf="registra_servicio === true">
                                        <table class="tabla_servicios">
                                            <tr>
                                                <th width="250px">A seleccionado el siguiente servicio: </th>
                                                <td>
                                                    <span id="nombre_servicio" style="color:blue">
                                                        {{ codigo_descripcion }}
                                                        <input #servicio_grupo type="hidden" class="form-control" name="servicioGrupo" style="width:100px;" value="Servicio: {{ codigo_descripcion }} - Grupo: {{ grupo_servicio }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <input #id_servicio_sel type="hidden" class="form-control" name="id_servicio_sel" style="width:100px;" value="{{ id_servicio }}"/>
                                            <input #grupo type="hidden" class="form-control" name="grupo" style="width:100px;" value="{{ grupo_servicio }}"/>
                                            <tr>
                                                <th>
                                                    <ng-container *ngIf="codigo_servicio === 'T-01' || codigo_servicio === 'T-02' || codigo_servicio === 'T-07'; else elseifa1">
                                                        Y tiene el siguiente Costo x día: 
                                                    </ng-container>
                                                    <ng-template #elseifa1>
                                                        <ng-container *ngIf="codigo_servicio === 'T-03' || codigo_servicio === 'T-04' || codigo_servicio === 'T-05'; else elsea1">
                                                            Y tiene el siguiente Costo x punto: 
                                                        </ng-container>
                                                    </ng-template>
                                                    <ng-template #elsea1>
                                                        Y tiene el siguiente Costo: 
                                                    </ng-template>
                                                </th>
                                                <td>
                                                    <span id="costo_unitario" style="color:green">
                                                        {{ costo_servicio }}
                                                        <input #costo_serv type="hidden" class="form-control" name="costo_serv" style="width:100px;" value="{{ costo_servicio }}"/>
                                                        <input #costo_servicio_numero type="hidden" class="form-control" name="costo_servicio_numero" style="width:100px;" value="{{ costo_servicio_numero_n }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <ng-container *ngIf="codigo_servicio === 'T-01' || codigo_servicio === 'T-02' || codigo_servicio === 'T-07'; else elseifb1">
                                                        Escriba la cantidad de días requeridos para el Servicio: 
                                                    </ng-container>
                                                    <ng-template #elseifb1>
                                                        <ng-container *ngIf="codigo_servicio === 'T-03' || codigo_servicio === 'T-04' || codigo_servicio === 'T-05'; else elseb1">
                                                            Escriba la cantidad de puntos requeridos para el Servicio: 
                                                        </ng-container>
                                                    </ng-template>
                                                    <ng-template #elseb1>
                                                        Escriba la cantidad de hojas requeridas para el Servicio:
                                                    </ng-template>
                                                </th>
                                                <td>
                                                    <input #cantidad type="text" id="cantidad" (keyup)="onKeyUp($event)" (keypress)="validateFormat($event)" class="form-control" formControlName="cantidad" name="cantidad" style="width:100px;" value="1" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Calculado:</th>
                                                <td>
                                                    <span id="costo_calculado" style="color:red">
                                                        {{ costo_servicio_selec }}
                                                        <input #costo_serv_sel type="hidden" class="form-control" name="costo_serv_sel" style="width:100px;" value="{{ costo_servicio_selec }}"/>
                                                        <input #costo_serv_sel_numero type="hidden" class="form-control" name="costo_serv_sel_numero" style="width:100px;" value="{{ costo_servicio_selec_numero }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Regitrar datos del Informe y Hoja de Ruta, generadores del servicio: </th>
                                                <td>
                                                    <table width="100%" cellpadding="8" border="2">
                                                        <tbody>
                                                            <tr>
                                                                <td width="400px">
                                                                    <span class="Estilo4">Digite el Correlativo de Informe.</span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <input type="text" name="correlativo_informe" id="correlativo_informe" class="form-control" formControlName="correlativo_informe" style="width:400px;" oninput="this.value = this.value.toUpperCase()" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="400px">
                                                                    <span class="Estilo4">Digite el Correlativo de la Hoja de Ruta.</span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <input type="text" name="correlativo_hoja_ruta" id="correlativo_hoja_ruta" class="form-control" formControlName="correlativo_hoja_ruta" style="width:400px;" oninput="this.value = this.value.toUpperCase()" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Requisitos: </th>
                                                <td>
                                                    <div *ngIf="codigo_servicio === 'T-07'">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Solicitud de Autoridad Competente.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Solicitud de Autoridad Competente." checked="true" disabled>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="codigo_servicio === 'T-01' || codigo_servicio === 'T-02' || codigo_servicio === 'T-03' || codigo_servicio === 'T-04' || codigo_servicio === 'T-05' || codigo_servicio === 'T-06'">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión).</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]"  value="Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión)." checked="true" disabled>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de C.I. del titular, representante legal o apoderado.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]"  value="Fotocopia de C.I. del titular, representante legal o apoderado." checked="true" disabled>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia del testimonio de Poder (si corresponde).</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]"  value="Fotocopia del testimonio de Poder (si corresponde)." checked="true" disabled>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>    
                                            <tr>
                                                <td colspan="2">
                                                    <table class="tabla_servicios_botones">
                                                        <tr>
                                                            <td width="5">
                                                                <input type="button" (click)="ejecutarOperacion({operacion: 'cancelar-lista', id: id_servicio})" id="cancelar_lista" class="btn btn-warning" value="Cancelar">
                                                            </td>
                                                            <td width="8">
                                                                <ng-container *ngIf="codigo_servicio === 'T-01' || codigo_servicio === 'T-02' || codigo_servicio === 'T-03' || codigo_servicio === 'T-04' || codigo_servicio === 'T-05' || codigo_servicio === 'T-06'; else else1">
                                                                    <input type="button" (click)="agregarListaSinAdicionalesA(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_con_adicionales" class="btn btn-primary" value="Agregar">
                                                                </ng-container>
                                                                <ng-template #else1>
                                                                        <input type="button" (click)="agregarListaSinAdicionalesB(servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, costo_serv_sel_numero.value, costo_servicio_numero.value, grupo.value, id_servicio_sel.value)" id="agregar_sin_adicionales" class="btn btn-primary" value="Agregar">
                                                                </ng-template>                                                            
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> CUADRO DE SERVICIOS SOLICITADOS
                                </div>
                                <div class="box-content">
                                    <div id="lista_servicios">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width='calc(100% - 1.6*1em - 1.6*1px)' class='estilo1'>
                                                        <font color="#fff">Servicio</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Precio Unitario</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Cantidad</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Sub Total</font>
                                                    </th>
                                                    <th width='200' class='estilo1'>
                                                        <font color="#fff">Opciones</font>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="contenido_servicios">
                                                <input type="hidden" id="tiene_servicios" name="tiene_servicios" value="{{servicio_selec}}">
                                                <tr>
                                                    <ng-container *ngIf="servicio_selec === false">
                                                        <td colspan='5'>No se han seleccionado servicios.</td>
                                                    </ng-container>
                                                </tr>
                                                <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                                    <td>
                                                        <table class="tabla_servicios">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="2">
                                                                        {{ dynamicArray[i].Servicio_Grupo.toString().split("- Grupo:")[0] }} 
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><b>Correlativo de Informe:</b></td>
                                                                                    <td>{{ dynamicArray[i].Correlativo_Informe }}</td>
                                                                                    <td><b>Correlativo de Hoja de Ruta:</b></td>
                                                                                    <td>{{ dynamicArray[i].Correlativo_Hoja_Ruta }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="dynamicArray[i].Tiene_Adicional !== 'G'">
                                                                    <td colspan="2">
                                                                        {{ dynamicArray[i].Requisitos_Sel }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        {{ dynamicArray[i].Costo_Serv }}
                                                        <input type="hidden" name="cantidad_registrada[]" value="1">
                                                    </td>
                                                    <td> 
                                                        {{ dynamicArray[i].Cantidad }}
                                                        <input type="hidden" name="costo_unitario_registrado[]" value=" Bs. 120">
                                                    </td>
                                                    <td class="costo_calculado">
                                                        {{ dynamicArray[i].Costo_Serv_Sel }}
                                                        <input type="hidden" name="costo_calculado_registrado[]" value="Bs. 120">
                                                    </td>
                                                    <td>
                                                        <input type="hidden" name="ides[]" value="1">
                                                        <button type="button" (click)="deleteRow(i)" class="btn btn-danger quitar_servicio" value="3"><i class="icon-remove-circle"></i> Quitar Servicio </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tabla_total" class="tabla_servicios_total">
                                        <thead>
                                            <tr>
                                                <td width="40px" nowrap class='estilo1'><font color="#fff">Costo total: </font></td>
                                                <td width="200px" align="center"><font color="#fff">Bs. {{ dynamicTotalArray[0] }}</font></td>
                                            </tr>
                                        </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> OBSERVACIONES
                                </div>
                                <div class="box-content">
                                    <div class="col-md-12 form-group mb-2">
                                        <label>Observaciones:</label>
                                        <textarea class="form-control" rows="3" formControlName="observacion"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-content">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button (click)="ejecutarAccion('crear')" type="button" class="btn btn-primary mr-1"
                        [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="button" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width:800px; height:900px;">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Documento Cargado</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <embed src="assets/documentos/UNIDAD1.pdf" type="application/pdf" width="100%" height="100%" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>