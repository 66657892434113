import { MenuItem } from 'src/app/comun/modelos';

export const CorrespondenciaMenu: MenuItem[] = [
  {
    ruta: '',
    titulo: 'SISTEMA DE ADMINISTRACION DE INGRESOS',
    icono: '',
    class: 'nav-header',
    esEnlace: false,
    submenu: [],
    roles: []
  },
  /*{*/
    ///RECUPERAR
    /*ruta: '#',
    titulo: 'Administración',
    icono: 'fas fa-user-lock',
    class: '',
    esEnlace: true,
    submenu: [*/


      /*{
        ruta: '/correspondencia/parametros',
        titulo: 'Parámetros',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },*/
      
      ///RECUPERAR
      /*{
        ruta: '/correspondencia/tipos-documento',
        titulo: 'Tipos de documento',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },*/


      /*{
        ruta: '/correspondencia/plantillas',
        titulo: 'Plantillas',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/clasificaciones',
        titulo: 'Clasificaciones',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/buzones',
        titulo: 'Buzones',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },*/
      
       ///RECUPERAR
      /*{
        ruta: '/correspondencia/contactos',
        titulo: 'Contactos',
        icono: 'fas fa-address-book',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/cuentas',
        titulo: 'Cuentas',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/grupos',
        titulo: 'Grupos',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/roles',
        titulo: 'Roles',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      },
      {
        ruta: '/correspondencia/usuarios',
        titulo: 'Usuarios',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      }*/

      /*{
        ruta: '/correspondencia/vistas-areas-mineras',
        titulo: 'Areas Mineras',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      }*/
    
    ///RECUPERAR
    /*],
    roles: []
  },*/
  // {
  //   ruta: '#',
  //   titulo: 'Patente Minera',
  //   icono: 'fas fa-inbox',
  //   class: '',
  //   esEnlace: true,
  //   submenu: [
  //     {
  //       ruta: '/correspondencia/vistas-areas-mineras',
  //       titulo: 'Búsqueda de Areas Mineras',
  //       icono: 'fas fa-cog',
  //       class: '',
  //       esEnlace: true,
  //       submenu: [],
  //       roles: []
  //     }
  //   ],
  //   roles: []
  // },
    {
    ruta: '#',
    titulo: 'Orden de Servicio',
    icono: 'fas fa-folder-open',
    class: '',
    esEnlace: true,
    submenu: [
      {
        ruta: '/correspondencia/vistas-servicios-registrados',
        titulo: 'Búsqueda de Servicios Registrados',
        icono: 'fas fa-cog',
        class: '',
        esEnlace: true,
        submenu: [],
        roles: []
      }
    ],
    roles: []
  },
  // {
  //   ruta: '#',
  //   titulo: 'Certificado Vigencia',
  //   icono: 'fas fa-user-lock',
  //   class: '',
  //   esEnlace: true,
  //   submenu: [
      
  //     {
  //       ruta: '/correspondencia/formularios-recepciones',
  //       titulo: 'Listado de Formulario de Verificación Documental',
  //       icono: 'fas fa-cog',
  //       class: '',
  //       esEnlace: true,
  //       submenu: [],
  //       roles: []
  //     },

  //     {
  //       ruta: '/correspondencia/genera-certificados-vigentes',
  //       titulo: 'Emisión de Certificado de Pago de Patente Minera',
  //       icono: 'fas fa-cog',
  //       class: '',
  //       esEnlace: true,
  //       submenu: [],
  //       roles: []
  //     }
  //   ],
  //   roles: []
  // },
  // {
  //   ruta: '#',
  //   titulo: 'Digitalizacion Documentacion Patente Minera',
  //   icono: 'fas fa-user-lock',
  //   class: '',
  //   esEnlace: true,
  //   submenu: [
  //     {
  //       ruta: '/correspondencia/vistas-areas-mineras-digitalizacion',
  //       titulo: 'Buscar por Areas Mineras',
  //       icono: 'fas fa-cog',
  //       class: '',
  //       esEnlace: true,
  //       submenu: [],
  //       roles: []
  //     }
  //   ],
  //   roles: []
  // }
  /*/{
    ruta: '/correspondencia/bandeja-borradores',
    titulo: 'Borradores',
    icono: 'fas fa-inbox',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/bandeja-entrada',
    titulo: 'Bandeja de Entrada',
    icono: 'fas fa-inbox',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/bandeja-salida',
    titulo: 'Bandeja de Salida',
    icono: 'fas fa-inbox',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/bandeja-enviados',
    titulo: 'Bandeja de Enviados',
    icono: 'fas fa-inbox',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/bandeja-archivados',
    titulo: 'Bandeja de Archivados',
    icono: 'fas fa-inbox',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/archivo',
    titulo: 'Archivo',
    icono: 'fas fa-folder-open',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  },
  {
    ruta: '/correspondencia/archivo-central',
    titulo: 'Archivo central',
    icono: 'fas fa-folder-open',
    class: '',
    esEnlace: true,
    submenu: [],
    roles: []
  }*/
];
