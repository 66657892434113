<div class="row">
    <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
            <div *ngFor="let data of Data; let i=index">
              <label>
                <input type="checkbox" [value]="data.value" (change)="onCheckboxChange($event)" />
                {{data.name}}
              </label>
            </div>
            <p class="error" *ngIf="this.form.controls['checkArray'].errors?.['required']">
              Checkbox is required, select atleast one value.
            </p>
            <input type="submit" value="Submit">
        </form>
    </div>
</div>
