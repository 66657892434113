import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { GeneraCertificadoVigencia } from '../../modelos';
import { GeneraCertificadoVigenciaFilter } from '../../modelos/filtros';

export const establecerFiltroGeneraCertificadoVigencia = createAction(
  '[CORRESPONDENCIA] Establecer filtro GENERA CERTIFICADO VIGENCIA',
  props<{ filtro: GeneraCertificadoVigenciaFilter }>()
);
export const establecerListaGeneraCertificadoVigencia = createAction(
  '[CORRESPONDENCIA] Establecer lista GENERA CERTIFICADO VIGENCIA',
  props<{ lista: GeneraCertificadoVigencia[]; paginado: Paginado }>()
);
export const establecerGeneraCertificadoVigencia = createAction(
  '[CORRESPONDENCIA] Establecer objeto GENERA CERTIFICADO VIGENCIA',
  props<{ objeto: GeneraCertificadoVigencia }>()
);
