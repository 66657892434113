import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { BrowserModule } from '@angular/platform-browser'

import { SeguridadFacade } from 'src/app/seguridad/fachadas';

import { FuncionesHelper } from 'src/app/comun/auxiliares';

@Component({
  selector: 'app-admin-lte3-registro-menu',
  templateUrl: './registro-menu.component.html',
  styles: [],
})

export class RegistroMenuComponent implements OnInit, OnDestroy {
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formVistaServiciosRegistrados: FormGroup;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'seleccionar-servicios': {

        break;
      }
      case 'cancelar-lista': {

        break;
      }
    }
  }

  ejecutarAccion(accion: string): void {
    //let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'acceder-formulario_pn': {
        this.router.navigate(['registro-servicio'], { queryParams: { id: 'PERSONA NATURAL' }});
        break;
      }
      case 'acceder-formulario_apm': {
        this.router.navigate(['registro-servicio'], { queryParams: { id: 'ACTOR PRODUCTIVO MINERO' }});
        break;
      }
      case 'acceder-guia': {
        this.router.navigate(['registro-guia'], { queryParams: { id: 'GUIA' } });
        break;
      }
      case 'acceder-video': {
        this.router.navigate(['registro-guia'], { queryParams: { id: 'VIDEO' } });
        break;
      }
      case 'acceder-contacto': {
        this.router.navigate(['registro-guia'], { queryParams: { id: 'CONTACTO' } });
        break;
      }
      case 'acceder-formulario_cpt': {
        this.router.navigate(['registro-guia'], { queryParams: { id: 'FORMULARIO' } });
        break;
      }
      case 'cancelar': {

        break;
      }
    }
  }
}
