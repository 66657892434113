<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Nombre:</label>
                    <span>{{ clasificacion.nombre }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Descripción:</label>
                    <span>{{ clasificacion.descripcion }}</span>
                </div>
            </div>
            <!-- <div class="form-row">
                <div class="col-md-4 form-group mb-2">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="estaActivo" [checked]="clasificacion.estaActivo" disabled>
                        <label class="custom-control-label" for="estaActivo">esta activo</label>
                    </div>
                </div>
            </div> -->
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
