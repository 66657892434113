import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { FuncionesHelper } from 'src/app/comun/auxiliares';

import { GeneraCertificadoVigencia } from '../../../modelos';
import { GeneraCertificadoVigenciaFacade } from '../../../fachadas';
import { GeneraCertificadoVigenciaListaComponent } from '../lista/genera-certificado-vigencia-lista.component';

@Component({
  selector: 'app-correspondencia-genera-certificado-vigencia-formulario',
  templateUrl: './genera-certificado-vigencia-formulario.component.html',
  styles: []
})
export class GeneraCertificadoVigenciaFormularioComponent implements OnInit, OnDestroy {
  @Input() public tipoOperacion: string;
  @Input() public validacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formGeneraCertificadoVigencia: FormGroup;
  botonOperacion: string;
  ver: string;

  generaCertificadoVigencia: GeneraCertificadoVigencia;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private generaCertificadoVigenciaFacade: GeneraCertificadoVigenciaFacade,
    private toastrService: ToastrService
  ) {
    if (!this.generaCertificadoVigencia) {
      this.generaCertificadoVigencia = new GeneraCertificadoVigencia();
    }

    this.formGeneraCertificadoVigencia = this.fb.group({
      correlativo_fore: ['', Validators.required],
      correlativo_certificado: ['', Validators.required],
      fecha_genera_certificado: ['', Validators.required],
      cantidad_generada: ['', Validators.required],
      estado: ['', Validators.required],

      notaSolicitud: ['', Validators.required],
      notaSolicitudNo: ['', Validators.required],
      fotocopiaTestimonio: ['', Validators.required],
      fotocopiaTestimonioNo: ['', Validators.required],
      carnet: ['', Validators.required],
      carnetNo: ['', Validators.required],
      formulario: ['', Validators.required],
      formularioNo: ['', Validators.required],
      comprobante: ['', Validators.required],
      comprobanteNo: ['', Validators.required],
      busquedaAreaMinera: ['', Validators.required],
      codigoUnico: ['', Validators.required],
      tipoArea: ['', Validators.required],
      nombre: ['', Validators.required],
      actorProductivoMinero: ['', Validators.required],
      tipoActorMinero: ['', Validators.required],
      nro: ['', Validators.required],

    });
  }

  ngOnInit(): void {
    console.log("ngoninit 1");
    this.suscripcion.add(
      this.generaCertificadoVigenciaFacade.CorrespondenciaState$.subscribe(({ generaCertificadoVigencia }) => {
        if (generaCertificadoVigencia) {
          this.generaCertificadoVigencia = generaCertificadoVigencia;
          if (this.tipoOperacion === 'modificar' && this.generaCertificadoVigencia.id) {
            this.formGeneraCertificadoVigencia.setValue({
              correlativo_fore: this.generaCertificadoVigencia.correlativo_fore,
              correlativo_certificado: this.generaCertificadoVigencia.correlativo_certificado,
              fecha_genera_certificado: this.generaCertificadoVigencia.fecha_genera_certificado,
              cantidad_generada: this.generaCertificadoVigencia.cantidad_generada,
              estado: this.generaCertificadoVigencia.estado,
            });
          }
        }
      })
    );
    switch (this.tipoOperacion) {
      case 'crear':
        this.botonOperacion = 'Guardar';
        break;
      case 'modificar':
        this.botonOperacion = 'Modificar';
        break;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarAccion(accion: string): void {
    let generaCertificadoVigencia = new GeneraCertificadoVigencia();
    switch (accion) {
      case 'crear': {
        FuncionesHelper.limpiarEspacios(this.formGeneraCertificadoVigencia);
        if (!this.formGeneraCertificadoVigencia.valid) {
          this.formGeneraCertificadoVigencia.markAllAsTouched();
          return;
        }
        generaCertificadoVigencia = { ...this.formGeneraCertificadoVigencia.value };
        this.accion.emit({
          accion: 'guardar',
          generaCertificadoVigencia
        });
        break;
      }
      case 'modificar': {
        FuncionesHelper.limpiarEspacios(this.formGeneraCertificadoVigencia);
        if (!this.formGeneraCertificadoVigencia.valid) {
          this.formGeneraCertificadoVigencia.markAllAsTouched();
          return;
        }
        generaCertificadoVigencia = { ...this.formGeneraCertificadoVigencia.value };
        this.accion.emit({
          accion,
          generaCertificadoVigenciaId: this.generaCertificadoVigencia.id,
          generaCertificadoVigencia
        });
        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

}
