<div class="container">
    <div class="row">
        <span class="display-3">Términos y condiciones de uso</span>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-text">Titulo</h3>
                    <p class="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac mauris et libero tincidunt elementum. Maecenas porta gravida dolor id bibendum. Praesent elit eros, feugiat eget tempus quis, porta ac justo. Morbi sollicitudin porta lacus a porta. Cras leo ipsum, tempus a tellus non, vestibulum porta metus. Morbi accumsan dui ut lacinia suscipit. Vestibulum porttitor aliquam magna non viverra. Aenean interdum ipsum nisl, sed congue mauris tempus consectetur. Donec ornare diam et orci commodo venenatis. Nunc neque purus, dapibus ac tempus nec, accumsan quis leo. Praesent pulvinar, lacus et dapibus facilisis, mi dolor gravida odio, vel ullamcorper sapien orci ac ex. Ut blandit porttitor eros, ac auctor velit luctus vitae. Cras luctus efficitur lacinia. Ut eu nisl euismod, placerat metus ut, euismod sem. Donec viverra placerat nisi, ut laoreet magna. Morbi nisi nisi, fringilla sed porta non, cursus at diam.
                    </p>
                    <p class="card-text">
                        Fusce suscipit convallis efficitur. Curabitur aliquet ullamcorper lacinia. Fusce et ante commodo, tincidunt justo sit amet, blandit lacus. Mauris pellentesque ante dui, a egestas nunc euismod id. Phasellus vehicula porta mattis. Quisque consequat congue mauris eu maximus. Nulla vestibulum rhoncus nulla commodo fringilla. Vivamus a orci eget elit posuere porta. Quisque sit amet urna commodo, ultricies ex at, consequat dui.
                    </p>
                    <h3 class="card-text">Titulo</h3>
                    <p class="card-text">
                        Donec euismod convallis orci. Sed quam sapien, mattis vel sapien vitae, fringilla scelerisque ipsum. Maecenas et volutpat libero. Praesent eget dignissim augue, eu tincidunt ligula. Vivamus fringilla elit eget faucibus dignissim. Praesent vel venenatis purus, et auctor velit. Aliquam purus risus, dapibus non ex a, malesuada ultricies lorem.
                    </p>
                    <p class="card-text">
                        Quisque aliquet iaculis ligula, eget commodo erat imperdiet quis. Ut eleifend congue nisl in eleifend. Pellentesque at eleifend dui. Morbi a purus et purus molestie lacinia. Vivamus mattis velit in urna eleifend gravida. Mauris lobortis, arcu et posuere condimentum, neque neque volutpat justo, ac commodo ex mauris eu sapien. Etiam ipsum lectus, sollicitudin a lorem vitae, luctus varius ipsum. Pellentesque eget bibendum lacus. Morbi fermentum non justo ac hendrerit. Nunc ullamcorper sem et enim fermentum, id elementum velit gravida. Proin felis mi, sodales eget velit id, ultricies ultricies dolor. Suspendisse sollicitudin ipsum quis odio fermentum semper.
                    </p>
                    <p class="card-text">
                        Duis lacinia pretium rhoncus. Nullam porta, diam vel facilisis elementum, arcu purus iaculis metus, eget tincidunt leo odio sit amet nisi. Mauris tincidunt quis dolor et imperdiet. Phasellus convallis congue libero ut hendrerit. Vestibulum congue dolor ut est aliquet faucibus. Quisque vehicula pretium dolor id porta. In tempor ut nisi ac rutrum. Proin nec elementum enim, sed ullamcorper ex.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <a [routerLink]="['/registro']" class="btn btn-dark float-right">
                Volver
            </a>
        </div>
    </div>
</div>
