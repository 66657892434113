import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { SeguimientoFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroSeguimiento(state: AppState): SeguimientoFilter {
  return state.correspondencia.listaSeguimiento?.filtro;
}

function seleccionarPaginadoSeguimiento(state: AppState): Paginado {
  return state.correspondencia.listaSeguimiento?.paginado;
}

export const filtroSeguimientoSelector = createSelector(
  obtenerState,
  seleccionarFiltroSeguimiento
);
export const paginadoSeguimientoSelector = createSelector(
  obtenerState,
  seleccionarPaginadoSeguimiento
);
