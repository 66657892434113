<div *ngIf="mostrarCabecera" class="row">
    <div class="col-md-12">
        <h5 class="d-block text-center text-muted m-0">{{ documento?.tipoDocumentoNombre }}</h5>
        <ng-container *ngIf="listaRemitenteExterno.length === 0">
            <h3 class="d-block text-center m-0">{{ documento?.cite }}</h3>
        </ng-container>
        <ng-container *ngIf="listaRemitenteExterno.length > 0">
            <h3 class="d-block text-center m-0">{{ documento?.citeExterno }}</h3>
            <h4 class="d-block text-center text-muted m-0">{{ documento?.cite }}</h4>
        </ng-container>
        <h5 class="d-block text-center m-0">H.R. {{ documento?.hojaRutaNumero }}</h5>
        <span class="d-block text-center m-0">{{ documento?.referencia }}</span>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <!-- The time line -->
        <div class="timeline">
            <!-- timeline item -->
            <div *ngFor="let item of lista">
                <ng-container *ngIf="item.accion === 'REGISTRAR'">
                    <i class="fas fa-file-alt bg-dark"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'APROBAR'">
                    <i class="fas fa-paper-plane bg-gray"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'DEVOLVER'">
                    <i class="fas fa-exchange-alt bg-warning"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'APROBAR' && item.estado === 'APROBADO'">
                    <i class="fas fa-check bg-success"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'DERIVAR'">
                    <i class="fas fa-paper-plane bg-primary"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'ARCHIVAR'">
                    <i class="fas fa-archive bg-purple"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'ANULAR'">
                    <i class="fas fa-trash bg-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.accion === 'RESPONDER'">
                    <i class="fas fa-file-alt bg-dark"></i>
                </ng-container>
                <ng-container *ngIf="item.estado === ''">
                    <i class="fas fa-user bg-gray"></i>
                </ng-container>
                <div class="timeline-item">
                    <span class="time">
                        <i class="fas fa-calendar-alt"></i> {{ item.instante | date: 'dd/MM/yyy' }}
                        <i class="fas fa-clock ml-2"></i> {{ item.instante | date: 'HH:mm' }}</span>
                    <h3 class="timeline-header"><strong>{{ item.origen }}</strong> {{ traducirAccion(item.accion) }}</h3>
                    <div class="timeline-body">
                        <span *ngIf="item.destino">
                            <span class="d-block">
                                <i class="fas fa-user-circle"></i> {{ item.destino }} {{ item.instActualizacion ? traducirEstado(item.estado, item.observacion) : '' }}</span>
                            <span *ngIf="item.instActualizacion" class="small text-muted">
                                <i class="fas fa-calendar-alt"></i> {{ item.instActualizacion | date: 'dd/MM/yyy' }}
                                <i class="fas fa-clock ml-1"></i> {{ item.instActualizacion | date: 'HH:mm' }}
                            </span>
                        </span>
                    </div>
                    <!-- <div class="timeline-footer">
                        <a href="#" class="btn btn-sm bg-primary">Ver documento</a>
                    </div> -->
                </div>
            </div>
            <!-- END timeline item -->
        </div>
    </div>
</div>