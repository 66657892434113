export class AreaMineraFilter {
  id?: number;
  nombre?: string;
  fk_actor_minero_poseedor?: number;
  cantidad_parcial_superpuesta?: number;
  cantidad_total_superpuesta?: number;
  cantidad_libre?: number;
  fk_asignacion_cargo_creador?: number;
  codigo_unico?: number;
  extension?: number;
  unidad?: string;
  fecha_inscripcion?: Date;
  hoja_cartografica?: string;
  cuadriculas_solicitadas?: number;
  cuadriculas_reservadas?: number;
  observaciones?: string;
  padron_nacional?: string;
  vigente?: boolean;
  fk_tipo_area_minera?: number;
  fk_tipo_actividad_minera?: number;
  departamentos?: string;
  provincias?: string;
  municipios?: string;
  fecha_baja?: Date;
  zona?: string;
  fecha_otorgacion?: Date;
  nombre_hoja_cartografica?: string;
  fecha_gaceta?: Date;
  regional?: string;
  estado_creacion_documento?: string;
  descripcion_area_protegida?: string;
}
