export class GeneraCertificadoVigenciaFilter {
    fk_fore?: number;
    correlativo_fore?: string;
    genera_certificado?: boolean;
    correlativo_certificado?: string;
    fk_area_minera?: string;
    fk_asca_genera_certificado?: number;
    fecha_genera_certificado?: Date;
    cantidad_generada?: number;
    estado?: string;
    activo?: boolean;
    fk_asca_creador?: number;
    fecha_creacion?: Date;
    fk_asca_modificador?: number;
    fecha_modificacion?: Date;
    tipo_operacion?: string;
    tipo_operacion_fvd_adicional?: string;
}
  