<style type="text/css">
    /*stylo CCCS 15/06/2016*/
    .tabla_servicios {
        border-collapse: collapse;
        width: 100%;
    }

    .tabla_servicios td, .tabla_servicios th {
        border: 1px solid black;
        padding: 8px;
    }

    .tabla_servicios_botones {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_botones td, .tabla_servicios_botones th {
        border: 0px solid black;
        padding: 8px;
    }
    .tabla_servicios_total {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_total td, .tabla_servicios_total th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .input_boleta{
        margin-left:10px;
        float:left;
        width:150px;
    }
    .input_depositante{
        text-transform:uppercase;
        width:800px;
    }
    .light{
        color: white;
        font-weight: bolder;
    }
    .modal-body {
        height: 287px;
    }
    /*Fin stylo CCCS 15/06/2016*/
    .box.box-black>.box-title
    {
        background: rgb(4,3,23);
        background: linear-gradient(180deg, rgba(4,3,23,1) 0%, rgba(12,88,115,1) 100%, rgba(102,142,149,1) 100%);
        font-family: Times New Roman;
        font-size: 36px;
        letter-spacing: 0px;
        word-spacing: 0px;
        font-weight: bold;
        text-decoration: none;
        font-style: oblique;
        font-variant: normal;
        text-transform: none;
        color:white;
    }
    /*Estilo para div lado a lado*/
    #div1{
            width: 50%;
            float: left;
            position: relative;
    }
    #div2{
            width: 50%;
            float: right;
            position: relative;
    }
    table thead {
        color: #fff;
        background-color: rgb(44, 62, 88);
    }

</style>
<div class="row">
    <div class="col-12">
        <form [formGroup]="formHojaRutaExterna" autocomplete="off" class="text-default">
                    <hr>
                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="bi bi-calendar2-week"></i> Hoja de Ruta Externa
                                </div>

                            <div class="col-8">

                                

                                <div class="box-content">
                                    <table class="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Fecha: </label>
                                                    <div class="controls">
                                                        <input type="date" class="form-control" formControlName="fecha"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Cite/Documento Externo: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="cite_doc_externo"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Fecha Cite Externo: </label>
                                                    <div class="controls">
                                                        <input type="date" class="form-control" formControlName="fecha_cite_externo"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Remitente: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="remitente"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Referencia: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="referencia"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Destinatario: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="detinatario"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Cantidad de Fojas: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="cantidad_fojas"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="control-label"> Adjuntos: </label>
                                                    <div class="controls">
                                                        <input type="text" class="form-control" formControlName="adjuntos"/>
                                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                                            Launch demo modal
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="form-row justify-content-end">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary mr-1" (click)="ejecutarAccion('crear')">
                                            Generar Hoja de Ruta Externa
                                        </button>
                                        <button class="btn btn-warning mr-1" (click)="goBack()">Cancelar</button>
                                    </div>
                                </div>

                            </div>

                            </div>
                        </div>
                    </div>
        </form>
    </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="modal-content" class="modal-body">
            <div id="Container" style="padding-bottom:56.25%; position:relative; display:block; width: 100%">
                <iframe width="400" height="315" src="https://www.youtube.com/embed/QN7qfwz2biY" 
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; 
                clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
</div>

<!-- <script>
    $(document).ready(function () { 
        $('#modal-content').onload('https://demo-ppte.agetic.gob.bo/cliente');             
});
</script> -->