import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';

//*import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios, VistaRegistroDiarioSipago, OrdenServicio } from '../../../modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade, OrdenServicioFacade, VistaAreasMinerasFacade, AreaMineraFacade } from '../../../fachadas';

import { SeguridadFacade } from 'src/app/seguridad/fachadas';

@Component({
  selector: 'app-correspondencia-vista-servicios-registrados-formulario-validacion',
  templateUrl: './vista-servicios-registrados-formulario-validacion.component.html',
  styles: []
})
export class VistaServiciosRegistradosFormularioValidacionComponent
  implements OnInit, OnDestroy
{
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formVistaServiciosRegistradosFV: FormGroup;
  botonOperacion: string;

  /*listaServiciosSeleccionados: OrdenServicio[] = [];
  results: any[] = [];
  
  registra_servicio: boolean;
  codigo_descripcion: string;
  grupo_servicio: string;
  costo_servicio: string;
  codigo_servicio: string;
  id_servicio: number;

  costo_servicio_numero_n: number;
  costo_servicio_selec_numero: number;
  servicio_selec: boolean;
  codigo_descripcion_selec: string;
  grupo_servicio_selec: string;
  costo_servicio_selec: string;
  nuevo_costo_servicio_select: string;
  codigo_servicio_selec: string;
  id_servicio_selec: number;*/

  vistaServiciosRegistrados: VistaServiciosRegistrados;
  ordenServicio: OrdenServicio;

  listaServiciosCodificadorAreaMinera: Codificador[];
  /*serviciosSeleccionado: Servicios;
  listaServiciosCodificador: Codificador[];

  requisitos: string;
  sumatoriaServiciosTotal: number;

  fecha: Date;*/
  /**/
  //dato1: string;
  //sumaTotal: number;
  /**/

  /*dynamicArray: any [] = [];
  dynamicSumaArray = [];
  dynamicTotalArray = [];
  archivo: string;*/

  servicioArray: string [];
  requisitosArray: string[];
  //splitted: string[];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    public seguridadFacade: SeguridadFacade,
    private fbFV: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private vistaAreasMinerasFacade: VistaAreasMinerasFacade,
    private areaMineraFacade: AreaMineraFacade,
    private ordenServicioFacade: OrdenServicioFacade,
    private serviciosFacade: ServiciosFacade,
    private toastrService: ToastrService
  ) {
    if (!this.vistaServiciosRegistrados) {
      this.vistaServiciosRegistrados = new VistaServiciosRegistrados();
    }

    this.formVistaServiciosRegistradosFV = this.fbFV.group({
      /*correlativo_servicio: ['', Validators.required],
      nombre_solicitante: ['', Validators.required],
      representa: ['', Validators.required]*/
      /*checkArray: this.fbFV.array([], [Validators.required]),
      correlativo_servicio: ['n'],
      atender_dccm: [true],
      nombre_solicitante: ['n'],
      idhr: [1],
      cantidad_servicios: [1],
      servicios_seleccionados: ['0'],
      impreso: ['n'],
      fecha: ['', Validators.required],
      nombre_completo: ['', Validators.required],
      numero_ci: ['', Validators.required],
      expedido: ['', Validators.required],
      telefono: ['', Validators.required],
      representa: ['', Validators.required],
      tipo_actor_minero: ['', Validators.required],*/
      // num_boleta1: ['', Validators.required],
      // fecha_depos1: ['', Validators.required],
      // monto_depos1: ['', Validators.required],
      // depos1: ['', Validators.required],
      /*razon_social: ['', Validators.required],
      nit: ['', Validators.required],*/
      //nfactura: ['', Validators.required],
      /*observacion: ['', Validators.required],
      zona: [null, Validators.required],
      este: [null, Validators.required],
      norte: [null, Validators.required],
      escala: [null, Validators.required],
      orientacion: [null, Validators.required],
      edicion: [null, Validators.required],
      tomo: [null, Validators.required],
      fecha_gaceta: [null, Validators.required],
      plan_trabajo: [null, Validators.required],
      escala_variable: [null, Validators.required],
      orientacion_variable: [null, Validators.required],
      correo_electronico: ['', Validators.required],*/
      correlativo_orden_servicio: [''],
      id_soorse: ['']
    });
  }

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ vistaServiciosRegistrados }) => {
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
            this.servicioArray = vistaServiciosRegistrados.servicios_seleccionados_completo.split('|');

            this.formVistaServiciosRegistradosFV.setValue({
              correlativo_orden_servicio: this.vistaServiciosRegistrados.correlativo_servicio,
              id_soorse: this.vistaServiciosRegistrados.id
            });

          }
        }
      )
    );

    /*this.fecha = new Date();

    this.registra_servicio = false;
    this.servicio_selec = false;
    this.requisitos = '';

    this.tipoOperacion = "crear";*/

    this.areaMineraFacade.obtenerCodificador().then((respuesta) => {
      this.listaServiciosCodificadorAreaMinera = respuesta.lista;
    });

    switch (this.tipoOperacion) {
      case 'crear':
        this.botonOperacion = 'Validar Formulario';
        /*this.serviciosFacade.obtenerCodificador().then((respuesta) => {
          this.listaServiciosCodificador = respuesta.lista;
        });*/
        break;
      /*case 'modificar':
        this.botonOperacion = 'Modificar';
        break;*/
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'seleccionar-servicios': {
        /*if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.costo_servicio_numero_n = Math.trunc(respuesta.objeto.costo);
            this.costo_servicio_selec_numero = Math.trunc(respuesta.objeto.costo);
            this.serviciosSeleccionado = respuesta.objeto;
            this.registra_servicio = true;
            this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio = respuesta.objeto.grupo;
            this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio = respuesta.objeto.codigo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
            this.id_servicio = respuesta.objeto.id;
          });
        } else {
          this.serviciosSeleccionado = null;
        }*/
        break;
      }
      case 'cancelar-lista': {
        /*this.registra_servicio = false;*/
        break;
      }
      // case 'adjunto-seleccionado': {
      //   let elemId: string = (evento.input.target as Element).id;
      //   (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
      //   break;
      // }
      /*case 'agregar-lista': {
        this.registra_servicio = false;
        this.dynamicArray.push({ firstName: 'hola' });
        this.servicio_selec = true;*/
        /*if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.codigo_descripcion_selec = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio_selec = respuesta.objeto.grupo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio_selec = respuesta.objeto.codigo;
            this.id_servicio_selec = respuesta.objeto.id;
            this.servicio_selec = true;
          });
        }*/
        /*break;
      }*/
    }
  }

  ejecutarAccion(accion: string): void {
    let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {
        
        /*this.vistaServiciosRegistradosFacade.generarDeuda().then((respuesta) => {
          console.log(respuesta);
        });*/

        vistaServiciosRegistrados.correlativo_servicio = this.formVistaServiciosRegistradosFV.controls['correlativo_orden_servicio'].value;
        vistaServiciosRegistrados.id = this.formVistaServiciosRegistradosFV.controls['id_soorse'].value;

        this.vistaServiciosRegistradosFacade.validar(vistaServiciosRegistrados).then((respuesta) => {
          //console.log(respuesta);
          if (respuesta.tipoRespuesta === 'Exito') {
            return;
          }
        });
        
        this.accion.emit({
          accion
        });
        //this.formVistaServiciosRegistradosFV.reset();

        /*vistaServiciosRegistrados.correo_electronico = 'cccs-1@hotmail.com';
        this.vistaServiciosRegistradosFacade
          .validar(vistaServiciosRegistrados)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {

            }
          });*/
        /*FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistradosFV);
        if(!this.formVistaServiciosRegistradosFV.controls['nombre_completo'].valid) {
          this.formVistaServiciosRegistradosFV.controls['nombre_completo'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['numero_ci'].valid) {
          this.formVistaServiciosRegistradosFV.controls['numero_ci'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['expedido'].valid) {
          this.formVistaServiciosRegistradosFV.controls['expedido'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['telefono'].valid) {
          this.formVistaServiciosRegistradosFV.controls['telefono'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['representa'].valid) {
          this.formVistaServiciosRegistradosFV.controls['representa'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['tipo_actor_minero'].valid) {
          this.formVistaServiciosRegistradosFV.controls['tipo_actor_minero'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['razon_social'].valid) {
          this.formVistaServiciosRegistradosFV.controls['razon_social'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['nit'].valid) {
          this.formVistaServiciosRegistradosFV.controls['nit'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['observacion'].valid) {
          this.formVistaServiciosRegistradosFV.controls['observacion'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistradosFV.controls['correo_electronico'].valid) {
          this.formVistaServiciosRegistradosFV.controls['correo_electronico'].markAllAsTouched();
          return
        }*/

        /*vistaServiciosRegistrados.fecha = this.formVistaServiciosRegistradosFV.controls['fecha'].value; 
        vistaServiciosRegistrados.nombre_completo = this.formVistaServiciosRegistradosFV.controls['nombre_completo'].value;
        vistaServiciosRegistrados.numero_ci = this.formVistaServiciosRegistradosFV.controls['numero_ci'].value;
        vistaServiciosRegistrados.expedido = this.formVistaServiciosRegistradosFV.controls['expedido'].value;
        vistaServiciosRegistrados.telefono = this.formVistaServiciosRegistradosFV.controls['telefono'].value;
        vistaServiciosRegistrados.nombre_solicitante = this.formVistaServiciosRegistradosFV.controls['nombre_completo'].value;
        vistaServiciosRegistrados.representa = this.formVistaServiciosRegistradosFV.controls['representa'].value;
        vistaServiciosRegistrados.tipo_actor_minero = this.formVistaServiciosRegistradosFV.controls['tipo_actor_minero'].value;
        vistaServiciosRegistrados.razon_social = this.formVistaServiciosRegistradosFV.controls['razon_social'].value;
        vistaServiciosRegistrados.nit = this.formVistaServiciosRegistradosFV.controls['nit'].value;
        vistaServiciosRegistrados.observacion = this.formVistaServiciosRegistradosFV.controls['observacion'].value;
        vistaServiciosRegistrados.correo_electronico = this.formVistaServiciosRegistradosFV.controls['correo_electronico'].value;
        vistaServiciosRegistrados.atender_dccm = true;
        vistaServiciosRegistrados.correlativo_servicio = "n";
        vistaServiciosRegistrados.idhr = 1;
        vistaServiciosRegistrados.cantidad_servicios = 1;
        vistaServiciosRegistrados.servicios_seleccionados = "0";
        vistaServiciosRegistrados.impreso = "n";

        const total_registros = Number(Object.keys(this.dynamicArray).length);

        for(let i = 0 ; i < total_registros ; i++){
          //console.log(this.heroesList[i].name);
          const valor_i = Number(i);
          const servicioSel = new OrdenServicio();
          servicioSel.requisitos = this.dynamicArray[valor_i].Requisitos_Sel;
          servicioSel.descripcion_servicio = this.dynamicArray[valor_i].Servicio_Grupo; 
          servicioSel.fk_area_minera = 2025886;
          servicioSel.descripcion_area_minera = "JUKUMARINI VII 2025886 (ID: 2025886)";
          servicioSel.fk_servicio = this.cambioNumero(this.dynamicArray[valor_i].Id_Servicio_Sel);
          servicioSel.costo_unitario = this.cambioNumero(this.dynamicArray[valor_i].Costo_Servicio_Numero);
          servicioSel.cantidad = this.cambioNumero(this.dynamicArray[valor_i].Cantidad);
          servicioSel.subtotal = this.cambioNumero(this.dynamicArray[valor_i].Costo_Serv_Sel_Numero);
          servicioSel.monto_total = this.cambioNumero(this.dynamicTotalArray[0]);
          servicioSel.grupo = this.dynamicArray[valor_i].Grupo;
          servicioSel.plan_trabajo = true;
          servicioSel.escala_variable = true;
          servicioSel.orientacion_variable = true;
          this.listaServiciosSeleccionados.push(servicioSel);
        }*/


        /*this.vistaServiciosRegistradosFacade
          .guardar(vistaServiciosRegistrados, this.listaServiciosSeleccionados)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {

            }
          });*/
        break;
      }
      /*/case 'modificar': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistradosFV);
        if (!this.formVistaServiciosRegistradosFV.valid) {
          this.formVistaServiciosRegistradosFV.markAllAsTouched();
          return;
        }
        vistaServiciosRegistrados = {
          ...this.formVistaServiciosRegistradosFV.value
        };
        this.accion.emit({
          accion,
          rolId: this.vistaServiciosRegistrados.id,
          vistaServiciosRegistrados
        });
        break;
      }*/
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

  /*selectInput(e: any){
    let elemId: string = (e.target as Element).id;
    (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
    this.onCheckboxChange(e, 'mantiene2');
  }

  onKeyUp(evento: any): void{ 
    this.costo_servicio_selec = 'Bs. ' + String(Number(this.costo_servicio.substring(4)) * Number(evento.target.value));
    this.costo_servicio_selec_numero = Number(this.costo_servicio.substring(4)) * Number(evento.target.value);
  }*/

  /*deleteRow(index) {
    this.dynamicArray.splice(index, 1);
    this.dynamicSumaArray.splice(index, 1);*/
    /*******Suma de los totales de Servicios Seleccionados********/
    /*let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);
  }*/


  convertirNumero(numero: any)
  {
    const numero_a_convertir = numero;
    return Number(numero_a_convertir);
  }

  retornarArray(texto: any)
  {
    const texto_valor = texto;
    this.requisitosArray = texto_valor.split('.,');
    return this.requisitosArray;
  }

  // agregarLista(datosAdicionales: any, zona: any, este: any, norte: any, escala: any, orientacion: any, servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, requisitosSel: any): void{
    /*agregarListaConAdicionales(datosAdicionales: any, servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, requisitosSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    let zona = this.formVistaServiciosRegistradosFV.controls['zona'].value;
    let este = this.formVistaServiciosRegistradosFV.controls['este'].value;
    let norte = this.formVistaServiciosRegistradosFV.controls['norte'].value;
    let escala = this.formVistaServiciosRegistradosFV.controls['escala'].value;
    let orientacion = this.formVistaServiciosRegistradosFV.controls['orientacion'].value;
    let plan_trabajo = this.formVistaServiciosRegistradosFV.controls['plan_trabajo'].value;
    let escala_variable = this.formVistaServiciosRegistradosFV.controls['escala_variable'].value;
    let orientacion_variable = this.formVistaServiciosRegistradosFV.controls['orientacion_variable'].value;

    let fileg11a = "";
    let fileg12a = "";
    if((<HTMLInputElement>document.getElementById('resetg11a-src-file')).files[0])
    {
     fileg11a = (<HTMLInputElement>document.getElementById('resetg11a-src-file')).files[0].name;
    }
    else{
      fileg11a = "El archivo no existe";
    }
    if((<HTMLInputElement>document.getElementById('resetg12a-src-file')).files[0])
    {
     fileg12a = (<HTMLInputElement>document.getElementById('resetg12a-src-file')).files[0].name;
    }
    else{
      fileg12a = "El archivo no existe";
    }

    this.registra_servicio = false;
    let row = {Caso: "caso1", Tiene_Adicional: 'T', Datos_Adicionales: datosAdicionales, Plan_Trabajo: plan_trabajo.checked, Escala_Variable: escala_variable.checked, Oriebntacion_Variable: orientacion_variable.checked, Zona: zona, Este: este, Norte: norte, Escala: escala, Orientacion: orientacion, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Archivog11a: fileg11a, Archivog12a: fileg12a, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel};
    this.dynamicArray.push(row);
    this.servicio_selec = true;
    this.onCheckboxChange('elimina', 'elimina');
    this.dynamicSumaArray.push(Number(costoServSel.substring(4)));*/
    /*******Suma de los totales de Servicios Seleccionados********/
    /*let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);*/
    
    //this.dato1 = dato;
  /*}*/
}