import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { BoletaPagoServicioFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroBoletaPagoServicio(state: AppState): BoletaPagoServicioFilter {
  return state.correspondencia.listaBoletaPagoServicio?.filtro;
}

function seleccionarPaginadoBoletaPagoServicio(state: AppState): Paginado {
  return state.correspondencia.listaBoletaPagoServicio?.paginado;
}

export const filtroBoletaPagoServicioSelector = createSelector(
  obtenerState,
  seleccionarFiltroBoletaPagoServicio
);
export const paginadoBoletaPagoServicioSelector = createSelector(
  obtenerState,
  seleccionarPaginadoBoletaPagoServicio
);
