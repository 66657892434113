export class VistaPatentesMinerasInactivos {
  id?: number;
  cuenta?: string;
  subcuenta?: string;
  gestion_emision?: string;
  id_area_minera?: number;
  numero_formulario?: string;
  fecha_pago?: Date;
  nro_comprobante_bancario?: number;
  entidad_bancaria?: string;
  importe_registro_debe?: number;
  importe_registro_haber?: number;
  observacion?: string;
  cantidad_en_cuadriculas?: number;
  fecha_otorgacion_area?: Date;
  fecha_emision?: Date;
  progresivo?: boolean;
  fecha_validez?: Date;
  persona_solicitante?: string;
  documento_solicitante?: string;
  telefono_solicitante?: string;
  fecha_declaracion_explotacion?: Date;
  simbolo?: string;
  minerales?: string;
  fecha_inicio?: Date;
  fecha_fin?: Date;
  con_multa?: boolean;
  monto_prospeccion?: number;
  monto_prospeccion_aerea?: number;
  monto_hasta_30_cuadriculas?: number;
  monto_31_40_cuadriculas?: number;
  monto_41_mas_cuadriculas?: number;
  multiplicador_antiguedad?: number;
  valor_base?: boolean;
  id_ufv?: number;
  id_subcuenta?: number;
  id_precio_patente?: number;
  activo?: boolean;
  fk_asignacion_cargo?: number;
  tipo_formulario?: string;
  observacion_revision?: string;
  entidad_emision_formulario?: string;
  path_documento?: string;
  estado_actualizacion?: string;
  fk_asca_operador_escaneados?: number;
}
