export class BoletaEscaneadaFilter {
  nombre_documento?: string;
  tipo_documento?: string;
  numero_boleta?: string;
  path_documento?: string;
  fk_registro_diario?: number;
  fk_asca_creador?: number;
  operacion_creador?: string;
  fecha_creacion?: Date;
  fk_asca_modificador?: number;
  operacion_modificacion?: string;
  fecha_modificacion?: Date;
  numero_formulario?: string;
}
