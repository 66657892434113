<style type="text/css">
    /*************************************************/
    table {
        border-collapse: collapse;
    }
    
    table,
    th, td {
        border: 1px solid black;
    }
    
    th, td {
        padding: 10px;
    }

    #div1{
        float: left;
        width: 569px;
    }
    
    #div2{
        float: left;
        position: relative;
        width: 12px;     
    }
    
    #div3{
        float: left;
        position: relative;
        width: 569px;
    }
   
</style>


    <div id="capture">
        <div class="card-header">
            <div style="text-align: center; font-family: 'Times New Roman', Times, serif; align-items: center; justify-content: center;">
                <br>
                <h5><b>AUTORIDAD JURISDICCIONAL ADMINISTRATIVA MINERA</b></h5>
                <h5><b>DIRECCION DE CATASTRO Y CUADRICULADO MINERO</b></h5>
                <h5><b>PLATAFORMA</b></h5>
                <br>
            </div>
        </div>
        <div class="card-body" style="width:1250px;">
                <form autocomplete="off">
                    
                    <table style="width: 1126px;">
                        <tr style="text-align: center;">
                            <td style="background-color: #037018; font-size:20px; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>ORDEN DE SERVICIO</b><br>
                                <b>{{ vistaServiciosRegistrados.correlativo_servicio }}</b>
                            </td>
                        </tr>
                    </table>
                    
                    <div style="height:5px;"></div>
                    
                    <table style="font-family: 'Times New Roman', Times, serif; width: 1126px;">
                        <tr style="font-size:13px;">
                            <td style="width: 200px; background-color: #b2e4bb;"><b>TECNICO PLATAFORMA:</b></td>
                            <td style="width: 600px; background-color: #eef3eb;">VIVIANA QUISPE GUTIERREZ</td>
                            <td style="width: 200px; background-color: #b2e4bb;"><b>FECHA Y HORA:</b></td>
                            <td style="width: 200px; background-color: #eef3eb;">10/06/2023 09:40:56</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="background-color: #037018; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>DATOS PERSONALES DEL SOLICITANTE</b>
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; width: 1126px;">
                        <tr style="font-size:13px;">
                            <td style="width: 200px; background-color: #b2e4bb;"><b>REMITENTE:</b></td>
                            <td style="width: 600px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.nombre_solicitante }}</td>
                            <td style="width: 200px; background-color: #b2e4bb;"><b>TELF/CEL:</b></td>
                            <td style="width: 200PX; background-color: #eef3eb;">{{ vistaServiciosRegistrados.telefono }}</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; width: 1126px;">
                        <tr style="font-size:13px;">
                            <td style="width: 200px; background-color: #b2e4bb;"><b>REPRESENTANTE:</b></td>
                            <td style="width: 600px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.representa }}</td>
                            <td style="width: 200px; background-color: #b2e4bb;"><b>TIPO ACTOR MINERO:</b></td>
                            <td style="width: 200px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.tipo_actor_minero }}</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="background-color: #037018; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>CUADRO DE SERVICIOS SOLICITADOS</b>
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 1126px;">
                        <tr>
                            <td style="width: 400px; background-color: #b2e4bb; text-align: center;"><b>Servicio</b></td>
                            <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Precio Unitario (Bs.)</b></td>
                            <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Cantidad</b></td>
                            <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Sub Total (Bs.)</b></td>
                        </tr>
                        <tr *ngFor="let item of servicioArray">
                            <td style="width: 400px; background-color: #eef3eb;">
                                <table style="font-family: 'Times New Roman', Times, serif">
                                <ng-container *ngIf="item.split('&&')[7] !== 'NO TIENE REGISTROS ADICIONALES'; else SinRegistros">
                                    <tr>
                                        <td  colspan="6" style="width: 300px; background-color: #b2e4bb;">
                                            <b>{{ item.split('&&')[1] }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Zona:</b></td>
                                        <td style="width: 5%; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[0] }}</td>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Este:</b></td>
                                        <td style="width: 50px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[1] }}</td>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Norte:</b></td>
                                        <td style="width: 50px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[2] }}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Escala:</b></td>
                                        <td colspan="2" style="width: 100px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[3] }}</td>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Orientacion de Hoja:</b></td>
                                        <td  colspan="2" style="width: 100px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[4] }}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Plan de Trabajo:</b></td>
                                        <td style="width: 50px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[5] }}</td>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Escala Variable:</b></td>
                                        <td style="width: 50px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[6] }}</td>
                                        <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Orientacion Variable:</b></td>
                                        <td style="width: 50px; background-color: #eef3eb;">{{ (item.split('&&')[7]).split('##')[7] }}</td>
                                    </tr>
                                </ng-container>
                                <ng-template #SinRegistros>
                                    <tr>
                                        <td style="background-color: #b2e4bb;">
                                            <b>{{ item.split('&&')[1] }}</b>
                                        </td>
                                    </tr>
                                </ng-template>
                                </table>
                            </td>
                            <td style="width: 50px; background-color: #eef3eb; text-align: center;">{{ this.convertirNumero(item.split('&&')[3]) }}</td>
                            <td style="width: 50px; background-color: #eef3eb; text-align: center;">{{ this.convertirNumero(item.split('&&')[2]) }}</td>
                            <td style="width: 50px; background-color: #eef3eb; text-align: center;">{{ this.convertirNumero(item.split('&&')[4]) }}</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="width: 1000px; background-color: #b2e4bb;">
                                <b>COSTO TOTAL (Bs.)</b>
                            </td>
                            <td style="width: 100px; background-color: #eef3eb;">
                                {{ this.convertirNumero(vistaServiciosRegistrados.monto_total) }}
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="background-color: #037018; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>INFORMACION DE PROFORMA DE PAGO</b>
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 1126px;">
                        <tr>
                            <td style="width: 100px; background-color: #b2e4bb; text-align: center;"><b>CPT</b></td>
                            <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Fecha de Proforma</b></td>
                            <td style="width: 300px; background-color: #b2e4bb; text-align: center;"><b>Depositante</b></td>
                            <td style="width: 50px; background-color: #b2e4bb; text-align: center;"><b>Monto de Proforma (Bs)</b></td>
                        </tr>
                        <tr>
                            <td style="width: 100px; background-color: #eef3eb;">7056-6419-1632</td>
                            <td style="width: 50px; background-color: #eef3eb;">10/06/2023</td>
                            <td style="width: 30px; background-color: #eef3eb;">CRISPIN NINA LIMACHI</td>
                            <td style="width: 50px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.nit }}</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="background-color: #037018; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>DATOS DE LA FACTURA</b>
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 1126px;">
                        <tr>
                            <td style="width: 700px; background-color: #b2e4bb;"><b>NOMBRE/RAZON SOCIAL</b></td>
                            <td style="width: 200px; background-color: #b2e4bb;"><b>NIT</b></td>
                            <td style="width: 300px; background-color: #b2e4bb;"><b>N° FACTURA</b></td>
                        </tr>
                        <tr>
                            <td style="width: 700px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.razon_social }}</td>
                            <td style="width: 200px; background-color: #eef3eb;">{{ vistaServiciosRegistrados.nit }}</td>
                            <td style="width: 300px; background-color: #eef3eb;">1232312312312</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="width: 1126px;">
                        <tr style="font-size:14px; text-align: center;">
                            <td style="background-color: #037018; font-family: 'Times New Roman', Times, serif; color:#fffffb;">
                                <b>OBSERVACIONES</b>
                            </td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 1126px;">
                        <tr>
                            <td style="background-color: #eef3eb;">S.O.</td>
                        </tr>
                    </table>

                    <div style="height:5px;"></div>

                    <div id="div1">
                        <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 556px;">
                            <tr>
                                <td style="width: 700px; height: 110px; text-align: left; vertical-align: top;"><b>ENTREGADO POR:</b></td>
                            </tr>
                            <tr>
                                <td style="width: 700px; height: 10px; text-align: center;">Firma y Sello</td>
                            </tr>
                            <tr>
                                <td style="width: 700px; height: 10px;">Fecha y Hora:</td>
                            </tr>
                        </table>
                    </div>
                    <div id="div2"></div>
                    <div id="div3">
                        <table style="font-family: 'Times New Roman', Times, serif; font-size:12px; width: 556px;">
                            <tr>
                                <td style="width: 700px; height: 110px; text-align: left; vertical-align: top;;"><b>RECIBIDO POR:</b></td>
                            </tr>
                            <tr>
                                <td style="width: 700px; height: 10px; text-align: center;">Firma</td>
                            </tr>
                            <tr>
                                <td style="width: 700px; height: 10px;">Fecha y Hora:</td>
                            </tr>
                        </table>
                    </div>

                    <br>
                    <br>
                    
                </form>
                <div class="mt-3">
                    <p class="mb-0">
                    </p>
                </div>
            </div>
        </div>

        <input type="button" class="btn btn-danger" value="Convert" (click)="convertToPDF()" />


