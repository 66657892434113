<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Correlativo:</label>
                    <span>{{ formularioRecepcion.correlativo }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Tipo Persona:</label>
                    <span>{{ formularioRecepcion.tipo_persona }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Fecha Emision:</label>
                    <span>{{ formularioRecepcion.fecha_creacion }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Documentos Presentados:</label>
                    <span>{{ formularioRecepcion.documentos_generados }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Emite Nota Externa:</label>
                    <span>{{ formularioRecepcion.tipo_operacion_fvd }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Fecha Verificacion:</label>
                    <span>{{ formularioRecepcion.fecha_verificacion_documental }}</span>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Imprimir
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
