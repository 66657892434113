<style>
table {
    width:100%;
    border-collapse:collapse;
    background:-webkit-linear-gradient(left, #2c3f8f, #2d95cd);
    background:-moz-linear-gradient(left, #2c3f8f, #2d95cd);
    background:linear-gradient(left, #2c3f8f, #2d95cd);
}

table, th, td {
  border: 1px solid black;
}

th, td {
    text-align:left;
    padding:8px;
}

th {
    color:#FFF;
}

td {
    background-color:#EEE;
    width: 100px;
}

tr:nth-child(odd) td {
    background-color:#F9F9F9;
}
</style>
<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <table class="table table-bordered table-hover table-condensed fill-head" width="700">
                    <thead>
                        <tr>
                            <th colspan="4">Datos del Área Minera</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Denominación:</td>
                            <td colspan="3"><span class="">{{ vistaAreasMineras.denominacion }}</span></td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Código Único:</td>
                            <td>{{ vistaAreasMineras.codigo_unico }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Padrón Nacional:</td>
                            <td>{{ vistaAreasMineras.padron_nacional }}</td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Resolución Administrativa de Suscripción de Contrato:</td>
                            <td>{{ vistaAreasMineras.numero_resolucion }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Fecha de Resoluci&oacute;n RA-CAM:</td>
                            <td>{{ vistaAreasMineras.fecha_resolucion }}</td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Fecha de Inscripción al Catastro Minero:</td>
                            <td>{{ vistaAreasMineras.fecha_inscripcion }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Oficina Regional:</td>
                            <td>{{ vistaAreasMineras.lugar_solicitud }}</td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Clasificación:</td>
                            <td>CONTRATO ADMINISTRATIVO MINERO POR ADECUACIÓN</td>
                            <td style="color:#FFF; background-color:#5265a9;">Estado:</td>
                            <td>{{ vistaAreasMineras.vigente }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4">Datos del Titular</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Titular:</td>
                            <td colspan="3">{{ vistaAreasMineras.titular }}</td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Clasificación:</td>
                            <td></td>
                            <td style="color:#FFF; background-color:#5265a9;">Subclasificación:</td>
                            <td></td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4">Datos de Ubicación del Área Minera</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Departamento Referencial:</td>
                            <td>{{ vistaAreasMineras.departamentos }}</td>
                            <td style="color:#FFF; background-color:#5265a9;"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Provincia Referencial:</td>
                            <td>{{ vistaAreasMineras.provincias }}</td>
                            <td style="color:#FFF; background-color:#5265a9;"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Municipio Referencial:</td>
                            <td>{{ vistaAreasMineras.municipios }}</td>
                            <td style="color:#FFF; background-color:#5265a9;"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Código Municipio:</td>
                            <td>{{ vistaAreasMineras.codigos_municipios }}</td>
                            <td style="color:#FFF; background-color:#5265a9;"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Hoja Cartográfica:</td>
                            <td>{{ vistaAreasMineras.hoja_cartografica }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Nombre Hoja Cartográfica:</td>
                            <td>{{ vistaAreasMineras.nombre_hoja_cartografica }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4">Datos de la Solicitud</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Extensión Asignada por Adecuación:</td>
                            <td>{{ vistaAreasMineras.cantidad_asignada }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Cuadriculas parcialmente sobrepuestas:</td>
                            <td>{{ vistaAreasMineras.cantidad_parcial_superpuesta }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4">Datos de su Publicación en Gaceta</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th colspan="4">R.A. de Aprobación de Solicitud de Adecuación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Numero de Gaceta:</td>
                            <td>{{ vistaAreasMineras.numero_gaceta }}</td>
                            <td style="color:#FFF; background-color:#5265a9;">Fecha de la Gaceta:</td>
                            <td>{{ vistaAreasMineras.fecha_gaceta }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4">Inscripción CAM adecuación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="color:#FFF; background-color:#5265a9;">Numero de Gaceta:</td>
                            <td></td>
                            <td style="color:#FFF; background-color:#5265a9;">Fecha de la Gaceta:</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Denominacion:</label>
                    <span>{{ vistaAreasMineras.denominacion }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Titular:</label>
                    <span>{{ vistaAreasMineras.titular }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Codigo Unico:</label>
                    <span>{{ vistaAreasMineras.codigo_unico }}</span>
                </div> -->
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>