import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { VistaRegistroDiarioSipago } from '../../../modelos';
import { VistaRegistroDiarioSipagoFacade } from '../../../fachadas';
import { VistaRegistroDiarioSipagoFilter } from '../../../modelos/filtros';

@Component({
  selector: 'app-correspondencia-vista-areas-mineras-detalle-patente-minera',
  templateUrl: './vista-areas-mineras-detalle-patente-minera.component.html',
  styles: []
})
export class VistaAreasMinerasDetallePatenteMineraComponent implements OnInit, OnDestroy {
  @Input() tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  botonOperacion: string;
  IdAreaMinera: number;

  vistaRegistroDiarioSipago: VistaRegistroDiarioSipago;
  lista: VistaRegistroDiarioSipago[];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private activatedRoute: ActivatedRoute,
    private vistaRegistroDiarioSipagoFacade: VistaRegistroDiarioSipagoFacade
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.has('id_area_minera')) {
        this.IdAreaMinera = parseInt(params.get('id_area_minera'), 10);
        //this.vistaPatentesMinerasFacade.obtenerVistaPatnetesMinerasPorIdAreaMinera(this.IdAreaMinera);
      }
    });
  }

  ngOnInit(): void {

    const vistaRegistroDiarioSipagoFilter = new VistaRegistroDiarioSipagoFilter()
    vistaRegistroDiarioSipagoFilter.id_area_minera = this.IdAreaMinera;
    this.vistaRegistroDiarioSipagoFacade.buscar(vistaRegistroDiarioSipagoFilter, 1, 50).then((respuesta) => {
      this.lista = respuesta.lista;
    });

    switch (this.tipoOperacion) {
      case 'detalle-patente-minera':
        this.botonOperacion = null;
        break;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarAccion(accion: string): void {
    switch (accion) {
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }
}
