export class RequisitosNotas {
  id?: number;
  descripcion_req_not: string;
  tipo: string;
  activo: boolean;
  aud_asca_creador: number;
  aud_fecha_creacion: Date;
  aud_asca_modificador: number;
  aud_fecha_modificacion: Date;
  aud_tipo_operacion: string;
  prioridad: number;
}
