import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { BoletaPagoServicio } from '../../modelos';
import { BoletaPagoServicioFilter } from '../../modelos/filtros';

export const establecerFiltroBoletaPagoServicio = createAction(
  '[CORRESPONDENCIA] Establecer filtro BOLETA_PAGO_SERVICIO',
  props<{ filtro: BoletaPagoServicioFilter }>()
);
export const establecerListaBoletaPagoServicio = createAction(
  '[CORRESPONDENCIA] Establecer lista BOLETA_PAGO_SERVICIO',
  props<{ lista: BoletaPagoServicio[]; paginado: Paginado }>()
);
export const establecerBoletaPagoServicio = createAction(
  '[CORRESPONDENCIA] Establecer objeto BOLETA_PAGO_SERVICIO',
  props<{ objeto: BoletaPagoServicio }>()
);
