export class VistaServiciosRegistradosFilter {
  correlativo_servicio?: string;
  atender_dccm?: boolean;
  nombre_solicitante?: string;
  idhr?: number;
  cantidad_servicios?: number;
  servicios_seleccionados?: string;
  impreso?: string;
  correo_electronico?: string;
  fecha?: Date;
  numero_ci?: string;
  expedido?: string;
  telefono?: string;
  representa?: string;
  tipo_actor_minero?: string;
  razon_social?: string;
  nit?: string;
  observacion?: string;

  nombre_completo?: string;
  num_boleta1?: number;
  fecha_depos1?: Date;
  monto_depos1?: number;
  depos1?: string;
  nfactura?: string;
}
