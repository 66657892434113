<div class="row">
    <div class="col-12">
        <form [formGroup]="formFormularioRecepcion" autocomplete="off" class="text-default">
            <div class="form-row">
                
                <div class="col-md-12 form-group mb-2 bg-dark text-white  ">
                    <div class="row" >
                        <h4 class="p-2"><i class="fa fa-check-square" aria-hidden="true"></i> l. DOCUMENTACIÓN LEGAL</h4>
                    </div>
                 </div>

                <div class="col-md-12 form-group mb-2">
                    <div class="row">
                         <div class="col-10">
                             <label>
                                 
                               </label>
                         </div>
                         <div class="col-1">
                            <label>
                                 SI
                            </label>
                         </div>
                         <div class="col-1">
                            <label>
                                NO
                           </label>
                         </div>
                    </div>
                 </div>

                 <div class="col-md-12 form-group mb-2  bg-info text-white">
                    <div class="row">
                         <div class="col-10">
                         
                                <span class="p-2"> En el Caso de Persona Naturales</span>
                             
                         </div>
                         <div class="col-1">
                            <label>
                                 
                            </label>
                         </div>
                         <div class="col-1">
                            <label>
                                
                           </label>
                         </div>
                    </div>
                 </div>

                <div class="col-md-12 form-group mb-2">
                   <div class="row">
                        <div class="col-10">
                            
                                <span>Nota Solicitud</span>
                            
                        </div>
                        <div class="col-1">
                            <input class="form-control form-control-sm" type="radio" name="notaSolicitud"  >
                        </div>
                        <div class="col-1">
                            <input class="form-control form-control-sm" type="radio" name="notaSolicitud" >
                        </div>
                   </div>
                </div>

                <div class="col-md-12 form-group mb-2" *ngIf="valor()">
                    <div class="row">
                         <div class="col-10">
                             
                                 <span>Fotocopia de Testimonio de Poder del representante legal de la Persona Jurididica(si corresponde)</span>
                             
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio"  name="fotocopiaTestimonio">
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio" name="fotocopiaTestimonio">
                         </div>
                    </div>
                 </div>

                <div class="col-md-12 form-group mb-2">
                    <div class="row">
                         <div class="col-10">
                      
                                 <span>Fotocopia Simple de Cédula de Identidad</span>
                               
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" name="carnet" type="radio" >
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm"  name="carnet" type="radio" >
                         </div>
                    </div>
                 </div>

                 <div class="col-md-12 form-group mb-2">
                    <div class="row">
                         <div class="col-10">
                           
                                 <span>Fotocopia simple del Formulario de Pago de Patente Minera Correspondiente a la ultima gestión(Art. 230)</span>
                               
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio" name="formulario" >
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio" name="formulario" >
                         </div>
                    </div>
                 </div>
                 <div class="col-md-12 form-group mb-2 bg-dark text-white">
                    <div class="row">
                        <h4 class="p-2"><i class="fa fa-check-square" aria-hidden="true"></i> ll. PAGO DEL SERVICIO CONFORME AL CATALAGO DE SERVICIO</h4>
                    </div>
                 </div>

                 <div class="col-md-12 form-group mb-2">
                    <div class="row">
                         <div class="col-10">
                             
                                <span> Comprobante de despósito bancario original del pago del servicio</span>
                               
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio" name="comprobante" >
                         </div>
                         <div class="col-1">
                             <input class="form-control form-control-sm" type="radio" name="comprobante" >
                         </div>
                    </div>
                 </div>

                 <div class="col-md-12 form-group mb-2 bg-dark text-white" >
                    <div class="row">
                        <h5 class="p-2"><i class="fa fa-list-alt" aria-hidden="true"></i> Información del Area Minera</h5>
                    </div>
                 </div>
                </div>
            </form>
                 <form [formGroup]="formAreaMinera" autocomplete="off" class="text-default">

                    <div class="col-md-12 form-group mb-2">
                        <div class="row">
                             <div class="col-4">
                                 <label>
                                     Codigo Área Minera:
                                   </label>
                             </div>
                             <div class="col-8">
                                 <input class="form-control" type="text" formControlName="fk_area_minera" >
                             </div>
                        </div>
                     </div>
               
                    <div class="col-md-12 form-group mb-2">
                        <div class="row">
                             <div class="col-3">
                                 <label>
                                     Estado:
                                   </label>
                                   <input class="form-control" type="text" formControlName="estado" >
                             </div>
                             <div class="col-2">
                                <label>
                                    Codigo Unico:
                                  </label>
                                  <input class="form-control" type="text" formControlName="codigoUnico">
                            </div>
                            <div class="col-5">
                                <label>
                                    Tipo Área:
                                  </label>
                                  <input class="form-control" type="text" formControlName="tipoArea">
                            </div>
                            <div class="col-2">
                                <label>
                                    Nombre:
                                  </label>
                                  <input class="form-control" type="text" formControlName="nombre">
                            </div>
                        </div>
                     </div>
    
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                             <div class="col-5">
                                 <label>
                                     Actor Productivo Minero:
                                   </label>
                                   <input class="form-control" type="text" formControlName="actorProductivoMinero">
                             </div>
                             <div class="col-5">
                                <label>
                                    Tipo Actor Minero:
                                  </label>
                                  <input class="form-control" type="text" formControlName="tipoActorMinero">
                            </div>
                            <div class="col-2">
                                <label>
                                    Nro:
                                  </label>
                                  <input class="form-control" type="text" formControlName="nro">
                            </div>
                            
                        </div>
                     </div>
    
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                                <div class="col-10">
                                    <label></label>
                                </div>
                            
                                <div class="col-2">
                                    <button (click)="ejecutarAccion('modificar')" type="button" class="btn btn-primary" title="Agregar">
                                        Agregar
                                    </button>
                                </div>
                        </div>
                     </div>

                 
                    <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-2">
                                <label><i class="fa fa-check" aria-hidden="true"></i> Area Mineras Seleccionadas:</label>
                                
                        </div>
                            <div class="col-8" *ngIf="true">
                                <input class="form-control" type="text" formControlName="areaMineraSeleccionada">
                            </div>
                        </div>
                    </div>
                </form>
                <hr/>

                 <form [formGroup]="formFormularioRecepcion" autocomplete="off" class="text-default">
                    <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-3">
                                <span>Numero de Boleta:</span>
                                
                            </div>
                             <div class="col-9">
                                   <input class="form-control" type="text" formControlName="nroBoleta">
                                   
                               
                             </div>
                             {{formFormularioRecepcion.get('nroBoleta').errors | json }}
                             <span>Numero de Boleta:</span>
                             
                        </div>
                     </div>
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-3">
                                <span>Boleta Escaneada:</span>
                                
                            </div>
                             <div class="col-9">
                                   <input class="form-control" type="file" formControlName="boletaEscaneada">
                             </div>
                             
                        </div>
                     </div>
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-12">
                                <span>Verificación de existencia de proceso de extinción de derecho u otro que modifique el derecho del titular.</span>  
                             </div>
                             
                        </div>
                     </div>
    
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-3">
                                <span>Fecha Verificacion.</span>  
                            </div>
                            <div class="col-9">
                                <input class="form-control" type="date" formControlName="fechaVerificacion"> 
                            </div>
                        </div>
                     </div>
    
                     
                     <div class="col-md-12 form-group mb-2">
                        <div class="row">
                            <div class="col-3">
                                <span>Observaciones.</span>  
                            </div>
                            <div class="col-9"> 
                                <textarea class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1"
                                [title]="botonOperacion">
                                {{botonOperacion}}
                            </button>
                            <button (click)="ejecutarAccion('cancelar')" type="button" class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                 </form>
                
            </div>

    
</div>
