<div class="row">
    <div class="col-12">
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <div class="card card-default collapsed-card card-no-shadow">
                        <div class="card-header bg-dark ">
                            <h3 class="card-title cursor-pointer " data-card-widget="collapse">
                                <strong class="text-white"><i class="fa fa-list-alt" aria-hidden="true"></i> Datos del Formulario de Verificacion Documental</strong>
                            </h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <table class="table table-striped table-hover border mt-4 ">
                                <tbody>
                                    <tr>
                                        <th>Usuario:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Cargo:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Oficina:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Correlativo Formulario Recepcion:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Tipo Persona:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Boleta Bancaria:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Documento escaneado:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Estado:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Documentos Recepcionado:</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.correlativo_fore }}</td>
                                    </tr>
                                    <tr>
                                        <th>Fecha Verificacion</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.fecha_genera_certificado }}</td>
                                    </tr>
                                    <tr>
                                        <th>Emite Nota Externa</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.fecha_genera_certificado }}</td>
                                    </tr>
                                    <tr>
                                        <th>Observacion</th>
                                        <td class="td-auto align-middle">{{ generaCertificadoVigencia.fecha_genera_certificado }}</td>
                                    </tr>
                                    
                                
                                
                                </tbody>
                            </table>
                              <!-- /  <div class="form-row justify-content-end">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary mr-1" >Buscar</button>
                                
                                    </div>
                                </div>-->
                        </div>
                        <!-- /.card-body -->
                    </div>
                 </div>

                 <div class="col-md-12 form-group mb-2">
                    <div class="card card-default collapsed-card card-no-shadow">
                        <div class="card-header bg-dark ">
                            <h3 class="card-title cursor-pointer " data-card-widget="collapse">
                                <strong class="text-white"><i class="fa fa-book" aria-hidden="true"></i> Areas Mineras Seleccionadas</strong>
                            </h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped table-hover border mt-auto " >
                                    <tbody>
                                        <tr>
                                            <th>Codigo Unico</th>
                                            <th>Denominación Area Minera:</th>
                                            <th>Tipo Area Minera:</th>
                                            <th>Actor Productivo Minera:</th>
                                            <th>Estado:</th>
                                            <th>Genera Certificado:</th>
                                            <th>Emite Nota Externa:</th>
                                            <th>Imprimir Certificado</th>
                                        </tr>
                                        <tr>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i> {{ "yerasfasfasfasfasfasfasfasfasfasfy" }}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i>{{ generaCertificadoVigencia.fecha_genera_certificado |date: 'yyyy-mm-dd'}}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i>{{ generaCertificadoVigencia.fecha_genera_certificado |date: 'yyyy-mm-dd' }}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i>{{ generaCertificadoVigencia.fecha_genera_certificado |date: 'yyyy-mm-dd' }}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i>{{ generaCertificadoVigencia.fecha_genera_certificado |date: 'yyyy-mm-dd' }}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i>{{ generaCertificadoVigencia.fecha_genera_certificado |date: 'yyyy-mm-dd' }}</td>
                                            <td class="td-auto align-middle"><i class="fa fa-check" aria-hidden="true"></i></td>
                                            <td class="td-auto align-middle"> <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                                                Imprimir
                                            </button></td>
                                        </tr>
                                        
                                    
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>

            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
    </div>
</div>
