<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-bandeja-buscador [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-bandeja-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th class="td-auto">ACCION</th>
                                <th class="td-auto">FECHA</th>
                                <th class="text-center">H.R.</th>
                                <th>CITE</th>
                                <th>REFERENCIA</th>
                                <th>REMITENTE</th>
                                <!-- <th>DESTINATARIO</th> -->
                                <th></th>
                                <th class="td-auto">OPERACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="td-auto align-middle">
                                    <span *ngIf="item.accion === 'DERIVAR'" class="badge badge-dark">DERIVADO</span>
                                    <span *ngIf="item.accion === 'APROBAR'" class="badge badge-dark">PARA APROBACIÓN</span>
                                </td>
                                <td class="td-auto align-middle">
                                    <span class="d-block"><i class="far fa-calendar-alt"></i> {{ item.instante |
                                        date: 'dd/MM/yyyy' }}</span>
                                    <span class="d-block"><i class="far fa-clock"></i> {{ item.instante | date:
                                        'HH:mm' }}</span>
                                    </td>
                                <td class="td-auto align-middle">{{ item.documento?.hojaRutaNumero }}</td>
                                <td class="td-auto align-middle">
                                    <span class="d-block">{{ item.documento?.cite }}</span>
                                    <span class="d-block text-muted font-weight-bold">
                                        {{ item.documento?.tipoDocumentoNombre }}</span>
                                    <span class="d-block"><i class="far fa-calendar-alt"></i> {{ item.documento?.fecha |
                                        date: 'dd/MM/yyyy': 'UTC' }}</span>
                                </td>
                                <td class="align-middle">
                                    <span class="d-block">{{ item.documento?.referencia }}</span>
                                    <span class="badge badge-dark">{{ item.documento?.prioridad }}</span>
                                </td>
                                <td class="td-auto align-middle text-wrap">
                                    <ng-container *ngIf="item.documento?.listaParticipante">
                                        <div *ngFor="let participante of item.documento?.listaParticipante | participantes: 'REMITENTE'"
                                            class="d-block">
                                            <span class="d-block text-nowrap">{{ participante.nombre }}</span>
                                            <span class="d-block text-muted">{{ participante.puesto }}</span>
                                            <span class="d-block text-muted font-weight-bold">{{
                                                participante.uniOrganizacional }}</span>
                                        </div>
                                    </ng-container>
                                </td>
                                <!-- <td class="td-auto align-middle">
                                    <div *ngFor="let participante of item.documento?.listaParticipante | participantes: 'DESTINATARIO'"
                                        class="d-block">
                                        <span class="d-block">{{ participante.nombre }}</span>
                                        <span class="d-block text-muted">{{ participante.puesto }}</span>
                                        <span class="d-block text-muted font-weight-bold">{{
                                            participante.uniOrganizacional }}</span>
                                    </div>
                                </td> -->
                                <td class="td-auto align-middle">
                                    <i class="fas fa-paperclip fa-lg mr-1"></i>
                                    <i class="fas fa-print fa-lg mr-1"></i>
                                    <i class="fas fa-file-signature fa-lg mr-1"></i>
                                </td>
                                <td class="align-middle">
                                    <i (click)="ejecutarOperacion({operacion: 'seguimiento', id: item.documento?.id})"
                                        class="fas fa-stream fa-lg mr-1 cursor-pointer"
                                        title="Ver seguimiento"></i>
                                    <ng-container *ngIf="item.estado === 'ENVIADO' && item.accion === 'DERIVAR'">
                                        <a (click)="ejecutarOperacion({operacion: 'recibir', id: item.documento?.id, seguimientoId: item.id})" class="cursor-pointer">
                                            <i class="far fa-check-circle fa-lg text-success mr-1 cursor-pointer" title="Recibir documento"></i></a>
                                    </ng-container>
                                    <ng-container *ngIf="item.estado === 'ENVIADO' && item.accion === 'APROBAR'">
                                        <a [routerLink]="['/correspondencia/documentos', item.id, 'aprobar']" class="cursor-pointer">
                                            <i class="fas fa-thumbs-up fa-lg text-success mr-1 cursor-pointer" title="Aprobar documento"></i></a>
                                    </ng-container>
                                    <ng-container *ngIf="item.estado === 'RECIBIDO'">
                                        <a [routerLink]="['/correspondencia/documentos', item.id, 'derivar']" class="cursor-pointer">
                                            <i class="far fa-paper-plane fa-lg text-info mr-1 cursor-pointer" title="Derivar documento"></i></a>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
    </div>
</div>

<ng-template #modalDocumento let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-documento-seguimiento *ngSwitchCase="'seguimiento'" 
                (accion)="ejecutarAccion($event)" [mostrarCabecera]="true"></app-correspondencia-documento-seguimiento>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>