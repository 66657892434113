<style type="text/css">
    /*************************************************/
    table {
        border-collapse: collapse;
        width: 1126px;
    }
    
    table,
    th, td {
        border: 1px solid black;
    }
    
    th, td {
        padding: 10px;
    }
</style>

    <div id="capture">
        <div class="card-header">
            <div id="div1" style="float:left; width:23.3%;">
                <img class="img-fluid" src="assets/images/logo_AJAM.png" alt="logo" width="200" height="200">
            </div>
            <div id="div2" style="float:left; width:53.3%; text-align: center; font-family: 'Times New Roman', Times, serif; align-items: center; justify-content: center;">
                <br>
                <br>
                <h1><b>PROFORMA DE PAGO</b></h1>
                <h5>Fecha y hora de generación: Jueves, 20 Julio 2023 12:27</h5>
                <br>
            </div>
            <div id="div3" style="float:left; width:23.3%; text-align: right;">
                <qrcode [qrdata]="DatosQR" [width]="180" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
        <div class="card-body" style="width:1250px;">
                <p class="login-box-msg"></p>
                <form autocomplete="off">
                    
                    <table>
                        <tr style="text-align: center;">
                            <td style="background-color: #037018; font-size:50px; font-family: 'Times New Roman', Times, serif; color:#fffffb;"><b>CPT: 7056-6419-1632</b></td>
                        </tr>
                    </table>

                    <br>

                    <table style="font-family: 'Times New Roman', Times, serif">
                        <tr style="font-size:22px;">
                            <td colspan="2" style="background-color: #037018; color:#fffffb;"><b>DATOS PERSONALES DEL SOLICITANTE</b></td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>REMITENTE:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.nombre_solicitante }}</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>TELF/CEL:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.telefono }}1</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>REPRESENTANTE:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.representa }}</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>TIPO DE ACTOR:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.tipo_actor_minero }}</td>
                        </tr>
                    </table>

                    <br>

                    <table style="font-family: 'Times New Roman', Times, serif">
                        <tr style="font-size:22px;">
                            <td colspan="2" style="background-color: #037018; color:#fffffb;"><b>CÁLCULO DE LA DEUDA</b></td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>CONCEPTO:</b></td>
                            <td style="background-color: #eef3eb;">SERVICIOS 2023</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>MONTO A PAGAR:</b></td>
                            <td style="background-color: #eef3eb;">Bs. {{ this.addCommas(vistaServiciosRegistrados.monto_total) }} .- {{ this.convertirNumerosLetras(vistaServiciosRegistrados.monto_total) }} </td>
                        </tr>
                    </table>

                    <br>

                    <table style="font-family: 'Times New Roman', Times, serif">
                        <tr style="font-size:22px;">
                            <td colspan="4" style="background-color: #037018; color:#fffffb;"><b>DETALLE DE CÁLCULO DE LA DEUDA</b></td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 630px; text-align: center; background-color: #b2e4bb;"><b>Servicios:</b></td>
                            <td style="width: 138px; text-align: center; background-color: #b2e4bb;"><b>Precio Unitario (Bs.)</b></td>
                            <td style="width: 138px; text-align: center; background-color: #b2e4bb;"><b>Cantidad</b></td>
                            <td style="width: 138px; text-align: center; background-color: #b2e4bb;"><b>Sub Total</b></td>
                        </tr>
                        <tr style="font-size:18px;" *ngFor="let item of servicioArray">
                            <td style="width: 630px; background-color: #eef3eb;">{{ item.split('&&')[1] }}</td>
                            <td style="width: 138px; text-align: center; background-color: #eef3eb;">{{ this.convertirNumero(item.split('&&')[3]) }}</td>
                            <td style="width: 138px; text-align: center; background-color: #eef3eb;">{{ this.convertirNumero(item.split('&&')[2]) }}</td>
                            <td style="width: 138px; text-align: center; background-color: #eef3eb;">{{ this.convertirNumero(item.split('&&')[4]) }}</td>
                        </tr>
                    </table>

                    <br>

                    <table style="font-family: 'Times New Roman', Times, serif">
                        <tr style="font-size:22px;">
                            <td colspan="2" style="background-color: #037018; color:#fffffb;"><b>DATOS PARA LA FACTURA</b></td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>NOMBRE/RAZON SOCIAL:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.razon_social }}</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="width: 300px; background-color: #b2e4bb;"><b>NIT:</b></td>
                            <td style="background-color: #eef3eb;">{{ vistaServiciosRegistrados.nit }}</td>
                        </tr>
                    </table>

                    <br>

                    <table style="font-family: 'Times New Roman', Times, serif">
                        <tr style="font-size:22px;">
                            <td colspan="2" style="background-color: #037018; color:#fffffb;"><b>NOTAS</b></td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="background-color: #eef3eb;">1. El pago podrá realizarse a través de cualquier <b>SUCURSAL O AGENCIA DEL BNANCO UNION, UNIET Y UNIMOVIL</b> inidicando solamente el CPT que se muestra en la cabecera de la presente proforma</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="background-color: #eef3eb;">2. La presente Proforma de Pago tiene una validez de 30 dias calendario <b>(Hasta el 30/11/2022)</b> a partir de su fecha de generación. Posterior a esta fecha, se deberá generar una nueva Proforma de Pago.</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="background-color: #eef3eb;">3. El presente documento no se constituye en un instrumento válido para fines legales. El saldo adeudado está sujeto a actualización, validación y confirmación por parte de la Autoridad Jurisdiccional Administrativa Minera AJAM.</td>
                        </tr>
                        <tr style="font-size:18px;">
                            <td style="background-color: #eef3eb;">4. La impresión de la presente Proforma de Pago es opcional, para el pago ewn ewl Banco solo necesita el CPT que se muestra en la cabecera de la presente proforma.</td>
                        </tr>
                    </table>

                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    
                    <div style="font-family: 'Times New Roman', Times, serif; font-size:18px;">
                        <b>Datos del Solicitante:</b> {{ vistaServiciosRegistrados.nombre_solicitante }}
                    </div>
                    <div style="font-family: 'Times New Roman', Times, serif; font-size:18px;">
                        <b>Nro Documento Identidad:</b> {{ vistaServiciosRegistrados.numero_ci }}
                    </div>
                    <div style="font-family: 'Times New Roman', Times, serif; font-size:18px;">
                        <b>Código de Seguridad:</b> {{ vistaServiciosRegistrados.codigo_seguridad }}
                    </div>
                </form>
                <div class="mt-3">
                    <p class="mb-0">
                    </p>
                </div>
            </div>
        </div>

        <input type="button" class="btn btn-info" value="imprimir CPT" (click)="imprimirPdfTramite()" />


