import { Action, createReducer, on } from '@ngrx/store';

import { CorrespondenciaState } from '.';
import * as CorrespondenciaAcciones from './acciones';

export const correspondenciaInicial: CorrespondenciaState = {
  listaTipoDocumento: {
    filtro: null,
    paginado: null,
    lista: []
  },
  tipoDocumento: null,

  listaContacto: {
    filtro: null,
    paginado: null,
    lista: []
  },
  contacto: null,

  listaClasificacion: {
    lista: []
  },
  clasificacion: null,

  listaBuzon: {
    filtro: null,
    paginado: null,
    lista: []
  },
  buzon: null,

  listaDocumento: {
    filtro: null,
    paginado: null,
    lista: []
  },
  documento: null,
  contenido: null,

  listaBuzonUsuario: {
    paginado: null,
    lista: []
  },
  buzonUsuario: null,

  listaHojaRuta: {
    filtro: null,
    paginado: null,
    lista: []
  },
  hojaRuta: null,

  listaPlantilla: {
    filtro: null,
    paginado: null,
    lista: []
  },
  plantilla: null,

  listaParametro: {
    filtro: null,
    paginado: null,
    lista: []
  },
  parametro: null,

  listaSeguimiento: {
    filtro: null,
    paginado: null,
    lista: []
  },
  seguimiento: null,

  listaGrupo: {
    filtro: null,
    paginado: null,
    lista: []
  },
  grupo: null,

  listaGrupoBuzon: {
    paginado: null,
    lista: []
  },
  grupoBuzon: null,

  listaDocumentoHojaRuta: {
    filtro: null,
    paginado: null,
    lista: []
  },
  documentoHojaRuta: null,

  listaRol: {
    filtro: null,
    paginado: null,
    lista: []
  },
  rol: null,

  listaUsuario: {
    filtro: null,
    paginado: null,
    lista: []
  },
  usuario: null,

  listaCuenta: {
    filtro: null,
    paginado: null,
    lista: []
  },
  cuenta: null,

  listaVistaAreasMineras: {
    filtro: null,
    paginado: null,
    lista: []
  },
  vistaAreasMineras: null,

  listaVistaServiciosRegistrados: {
    filtro: null,
    paginado: null,
    lista: []
  },
  vistaServiciosRegistrados: null,

  listaOrdenServicio: {
    filtro: null,
    paginado: null,
    lista: []
  },
  ordenServicio: null,

  listaBoletaPagoServicio: {
    filtro: null,
    paginado: null,
    lista: []
  },
  boletaPagoServicio: null,

  listaCorrelativoOs: {
    filtro: null,
    paginado: null,
    lista: []
  },
  correlativoOs: null,

  listaDerivacionHrOs: {
    filtro: null,
    paginado: null,
    lista: []
  },
  derivacionHrOs: null,

  listaServicios: {
    filtro: null,
    paginado: null,
    lista: []
  },
  servicios: null,

  listaSolicitanteOrdenServicio: {
    filtro: null,
    paginado: null,
    lista: []
  },
  solicitanteOrdenServicio: null,

  listaRequisitosNotas: {
    filtro: null,
    paginado: null,
    lista: []
  },
  requisitosNotas: null,

  listaMenu: {
    filtro: null,
    paginado: null,
    lista: []
  },
  menu: null,

  listaVistaPatentesMineras: {
    filtro: null,
    paginado: null,
    lista: []
  },
  vistaPatentesMineras: null,

  listaActualizacionPatente: {
    filtro: null,
    paginado: null,
    lista: []
  },
  actualizacionPatente: null,

  listaBoletaEscaneada: {
    filtro: null,
    paginado: null,
    lista: []
  },
  boletaEscaneada: null,

  listaRegistraReactivacion: {
    filtro: null,
    paginado: null,
    lista: []
  },
  registraReactivacion: null,

  listaRespaldoBoletaEscaneado: {
    filtro: null,
    paginado: null,
    lista: []
  },
  respaldoBoletaEscaneado: null,

  listaFormularioRecepcion: {
    filtro: null,
    paginado: null,
    lista: []
  },
  formularioRecepcion: null,

  listaGeneraCertificadoVigencia: {
    filtro: null,
    paginado: null,
    lista: []
  },
  generaCertificadoVigencia: null,

  listaVistaPatentesMinerasInactivos: {
    filtro: null,
    paginado: null,
    lista: []
  },
  vistaPatentesMinerasInactivos: null,

  listaVistaRegistroDiarioSipago: {
    filtro: null,
    paginado: null,
    lista: []
  },
  vistaRegistroDiarioSipago: null,

  listaAreaMinera: {
    filtro: null,
    paginado: null,
    lista: []
  },
  areaMinera: null,
};

const correspondenciaReducer = createReducer(
  correspondenciaInicial,
  //TipoDocumento
  on(
    CorrespondenciaAcciones.establecerFiltroTipoDocumento,
    (state, { filtro }) => {
      return {
        ...state,
        listaTipoDocumento: {
          ...state.listaTipoDocumento,
          filtro: { ...filtro }
        },
        tipoDocumento: null
      };
    }
  ),
  on(
    CorrespondenciaAcciones.establecerListaTipoDocumento,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaTipoDocumento: {
          ...state.listaTipoDocumento,
          lista: [...lista],
          paginado: { ...paginado }
        },
        tipoDocumento: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerTipoDocumento, (state, { objeto }) => {
    return {
      ...state,
      tipoDocumento: { ...objeto }
    };
  }),
  //Contacto
  on(CorrespondenciaAcciones.establecerFiltroContacto, (state, { filtro }) => {
    return {
      ...state,
      listaContacto: {
        ...state.listaContacto,
        filtro: { ...filtro }
      },
      contacto: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaContacto,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaContacto: {
          ...state.listaContacto,
          lista: [...lista],
          paginado: { ...paginado }
        },
        contacto: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerContacto, (state, { objeto }) => {
    return {
      ...state,
      contacto: { ...objeto }
    };
  }),
  // Clasificacion
  on(
    CorrespondenciaAcciones.establecerListaClasificacion,
    (state, { lista }) => ({
      ...state,
      listaClasificacion: {
        ...state.listaClasificacion,
        lista: [...lista]
      },
      clasificacion: null
    })
  ),
  on(CorrespondenciaAcciones.establecerClasificacion, (state, { objeto }) => {
    return {
      ...state,
      clasificacion: { ...objeto }
    };
  }),
  // Buzon
  on(CorrespondenciaAcciones.establecerFiltroBuzon, (state, { filtro }) => {
    return {
      ...state,
      listaBuzon: {
        ...state.listaBuzon,
        filtro: { ...filtro }
      },
      buzon: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaBuzon,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaBuzon: {
          ...state.listaBuzon,
          lista: [...lista],
          paginado: { ...paginado }
        },
        buzon: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerBuzon, (state, { objeto }) => {
    return {
      ...state,
      buzon: { ...objeto }
    };
  }),
  // BuzonUsuario
  on(
    CorrespondenciaAcciones.establecerListaBuzonUsuario,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaBuzonUsuario: {
          ...state.listaBuzonUsuario,
          lista: [...lista],
          paginado: { ...paginado }
        },
        buzonUsuario: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerBuzonUsuario, (state, { objeto }) => {
    return {
      ...state,
      buzonUsuario: { ...objeto }
    };
  }),
  // Documento
  on(CorrespondenciaAcciones.establecerFiltroDocumento, (state, { filtro }) => {
    return {
      ...state,
      listaDocumento: {
        ...state.listaDocumento,
        filtro: { ...filtro }
      },
      documento: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaDocumento,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaDocumento: {
          ...state.listaDocumento,
          lista: [...lista],
          paginado: { ...paginado }
        },
        documento: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerDocumento, (state, { objeto }) => {
    return {
      ...state,
      documento: { ...objeto }
    };
  }),
  // Plantilla
  on(CorrespondenciaAcciones.establecerFiltroPlantilla, (state, { filtro }) => {
    return {
      ...state,
      listaPlantilla: {
        ...state.listaPlantilla,
        filtro: { ...filtro }
      },
      plantilla: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaPlantilla,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaPlantilla: {
          ...state.listaPlantilla,
          lista: [...lista],
          paginado: { ...paginado }
        },
        plantilla: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerPlantilla, (state, { objeto }) => {
    return {
      ...state,
      plantilla: { ...objeto }
    };
  }),
  // Contenido
  on(CorrespondenciaAcciones.establecerContenido, (state, { objeto }) => {
    return {
      ...state,
      contenido: { ...objeto }
    };
  }),
  // Parametro
  on(CorrespondenciaAcciones.establecerFiltroParametro, (state, { filtro }) => {
    return {
      ...state,
      listaParametro: {
        ...state.listaParametro,
        filtro: { ...filtro }
      },
      parametro: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaParametro,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaParametro: {
          ...state.listaParametro,
          lista: [...lista],
          paginado: { ...paginado }
        },
        parametro: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerParametro, (state, { objeto }) => {
    return {
      ...state,
      parametro: { ...objeto }
    };
  }),
  //HojaRuta
  on(CorrespondenciaAcciones.establecerFiltroHojaRuta, (state, { filtro }) => {
    return {
      ...state,
      listaHojaRuta: {
        ...state.listaHojaRuta,
        filtro: { ...filtro }
      },
      hojaRuta: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaHojaRuta,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaHojaRuta: {
          ...state.listaHojaRuta,
          lista: [...lista],
          paginado: { ...paginado }
        },
        hojaRuta: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerHojaRuta, (state, { objeto }) => {
    return {
      ...state,
      hojaRuta: { ...objeto }
    };
  }),
  //Seguimiento
  on(
    CorrespondenciaAcciones.establecerFiltroSeguimiento,
    (state, { filtro }) => {
      return {
        ...state,
        listaSeguimiento: {
          ...state.listaSeguimiento,
          filtro: { ...filtro }
        },
        seguimiento: null
      };
    }
  ),
  on(
    CorrespondenciaAcciones.establecerListaSeguimiento,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaSeguimiento: {
          ...state.listaSeguimiento,
          lista: [...lista],
          paginado: { ...paginado }
        },
        seguimiento: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerSeguimiento, (state, { objeto }) => {
    return {
      ...state,
      seguimiento: { ...objeto }
    };
  }),
  // Grupo
  on(CorrespondenciaAcciones.establecerFiltroGrupo, (state, { filtro }) => {
    return {
      ...state,
      listaGrupo: {
        ...state.listaGrupo,
        filtro: { ...filtro }
      },
      grupo: null
    };
  }),
  on(
    CorrespondenciaAcciones.establecerListaGrupo,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaGrupo: {
          ...state.listaGrupo,
          lista: [...lista],
          paginado: { ...paginado }
        },
        grupo: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerGrupo, (state, { objeto }) => {
    return {
      ...state,
      grupo: { ...objeto }
    };
  }),
  // GrupoBuzon
  on(
    CorrespondenciaAcciones.establecerListaGrupoBuzon,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaGrupoBuzon: {
          ...state.listaGrupoBuzon,
          lista: [...lista],
          paginado: { ...paginado }
        },
        grupoBuzon: null
      };
    }
  ),
  on(CorrespondenciaAcciones.establecerGrupoBuzon, (state, { objeto }) => {
    return {
      ...state,
      grupoBuzon: { ...objeto }
    };
  }),
  //DocumentoHojaRuta
  on(
    CorrespondenciaAcciones.establecerFiltroDocumentoHojaRuta,
    (state, { filtro }) => {
      return {
        ...state,
        listaDocumentoHojaRuta: {
          ...state.listaDocumentoHojaRuta,
          filtro: { ...filtro }
        },
        documentoHojaRuta: null
      };
    }
  ),
  on(
    CorrespondenciaAcciones.establecerListaDocumentoHojaRuta,
    (state, { lista, paginado }) => {
      return {
        ...state,
        listaDocumentoHojaRuta: {
          ...state.listaDocumentoHojaRuta,
          lista: [...lista],
          paginado: { ...paginado }
        },
        documentoHojaRuta: null
      };
    }
  ),
  on(
    CorrespondenciaAcciones.establecerDocumentoHojaRuta,
    (state, { objeto }) => {
      return {
        ...state,
        documentoHojaRuta: { ...objeto }
      };
    }
  ),
    // Rol
    on(CorrespondenciaAcciones.establecerFiltroRol, (state, { filtro }) => {
      return {
        ...state,
        listaRol: {
          ...state.listaRol,
          filtro: { ...filtro }
        },
        rol: null
      };
    }),
    on(
      CorrespondenciaAcciones.establecerListaRol,
      (state, { lista, paginado }) => {
        return {
          ...state,
          listaRol: {
            ...state.listaRol,
            lista: [...lista],
            paginado: { ...paginado }
          },
          rol: null
        };
      }
    ),
    on(CorrespondenciaAcciones.establecerRol, (state, { objeto }) => {
      return {
        ...state,
        rol: { ...objeto }
      };
    }),
        // Usuario
        on(CorrespondenciaAcciones.establecerFiltroUsuario, (state, { filtro }) => {
          return {
            ...state,
            listaUsuario: {
              ...state.listaUsuario,
              filtro: { ...filtro }
            },
            usuario: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaUsuario,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaUsuario: {
                ...state.listaUsuario,
                lista: [...lista],
                paginado: { ...paginado }
              },
              usuario: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerUsuario, (state, { objeto }) => {
          return {
            ...state,
            usuario: { ...objeto }
          };
        }),
        // Cuenta
        on(CorrespondenciaAcciones.establecerFiltroCuenta, (state, { filtro }) => {
          return {
            ...state,
            listaCuenta: {
              ...state.listaCuenta,
              filtro: { ...filtro }
            },
            cuenta: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaCuenta,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaCuenta: {
                ...state.listaCuenta,
                lista: [...lista],
                paginado: { ...paginado }
              },
              cuenta: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerCuenta, (state, { objeto }) => {
          return {
            ...state,
            cuenta: { ...objeto }
          };
        }),
        // VistaAreasMineras
        on(CorrespondenciaAcciones.establecerFiltroVistaAreasMineras, (state, { filtro }) => {
          return {
            ...state,
            listaVistaAreasMineras: {
              ...state.listaVistaAreasMineras,
              filtro: { ...filtro }
            },
            vistaAreasMineras: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaVistaAreasMineras,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaVistaAreasMineras: {
                ...state.listaVistaAreasMineras,
                lista: [...lista],
                paginado: { ...paginado }
              },
              vistaAreasMineras: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerVistaAreasMineras, (state, { objeto }) => {
          return {
            ...state,
            vistaAreasMineras: { ...objeto }
          };
        }),
        // VistaServiciosRegistrados
        on(CorrespondenciaAcciones.establecerFiltroVistaServiciosRegistrados, (state, { filtro }) => {
          return {
            ...state,
            listaVistaServiciosRegistrados: {
              ...state.listaVistaServiciosRegistrados,
              filtro: { ...filtro }
            },
            vistaServiciosRegistrados: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaVistaServiciosRegistrados,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaVistaServiciosRegistrados: {
                ...state.listaVistaServiciosRegistrados,
                lista: [...lista],
                paginado: { ...paginado }
              },
              vistaServiciosRegistrados: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerVistaServiciosRegistrados, (state, { objeto }) => {
          return {
            ...state,
            vistaServiciosRegistrados: { ...objeto }
          };
        }),
        // OrdenServicio
        on(CorrespondenciaAcciones.establecerFiltroOrdenServicio, (state, { filtro }) => {
          return {
            ...state,
            listaOrdenServicio: {
              ...state.listaOrdenServicio,
              filtro: { ...filtro }
            },
            ordenServicio: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaOrdenServicio,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaOrdenServicio: {
                ...state.listaOrdenServicio,
                lista: [...lista],
                paginado: { ...paginado }
              },
              ordenServicio: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerOrdenServicio, (state, { objeto }) => {
          return {
            ...state,
            ordenServicio: { ...objeto }
          };
        }),
        // BoletaPagoServicio
        on(CorrespondenciaAcciones.establecerFiltroBoletaPagoServicio, (state, { filtro }) => {
          return {
            ...state,
            listaBoletaPagoServicio: {
              ...state.listaBoletaPagoServicio,
              filtro: { ...filtro }
            },
            oboletaPagoServicio: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaBoletaPagoServicio,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaBoletaPagoServicio: {
                ...state.listaBoletaPagoServicio,
                lista: [...lista],
                paginado: { ...paginado }
              },
              boletaPagoServicio: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerBoletaPagoServicio, (state, { objeto }) => {
          return {
            ...state,
            boletaPagoServicio: { ...objeto }
          };
        }),
        // CorrelativoOs
        on(CorrespondenciaAcciones.establecerFiltroCorrelativoOs, (state, { filtro }) => {
          return {
            ...state,
            listaCorrelativoOs: {
              ...state.listaCorrelativoOs,
              filtro: { ...filtro }
            },
            correlativoOs: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaCorrelativoOs,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaCorrelativoOs: {
                ...state.listaCorrelativoOs,
                lista: [...lista],
                paginado: { ...paginado }
              },
              correlativoOs: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerCorrelativoOs, (state, { objeto }) => {
          return {
            ...state,
            correlativoOs: { ...objeto }
          };
        }),
        // DerivacionHrOs
        on(CorrespondenciaAcciones.establecerFiltroDerivacionHrOs, (state, { filtro }) => {
          return {
            ...state,
            listaDerivacionHrOs: {
              ...state.listaDerivacionHrOs,
              filtro: { ...filtro }
            },
            derivacionHrOs: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaDerivacionHrOs,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaDerivacionHrOs: {
                ...state.listaDerivacionHrOs,
                lista: [...lista],
                paginado: { ...paginado }
              },
              derivacionHrOs: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerDerivacionHrOs, (state, { objeto }) => {
          return {
            ...state,
            derivacionHrOs: { ...objeto }
          };
        }),
        //Servicios
        on(CorrespondenciaAcciones.establecerFiltroServicios, (state, { filtro }) => {
          return {
            ...state,
            listaServicios: {
              ...state.listaServicios,
              filtro: { ...filtro }
            },
            servicios: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaServicios,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaServicios: {
                ...state.listaServicios,
                lista: [...lista],
                paginado: { ...paginado }
              },
              servicios: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerServicios, (state, { objeto }) => {
          return {
            ...state,
            servicios: { ...objeto }
          };
        }),
        //SolicitanteOrdenServicio
        on(CorrespondenciaAcciones.establecerFiltroSolicitanteOrdenServicio, (state, { filtro }) => {
          return {
            ...state,
            listaSolicitanteOrdenServicio: {
              ...state.listaSolicitanteOrdenServicio,
              filtro: { ...filtro }
            },
            solicitanteOrdenServicio: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaSolicitanteOrdenServicio,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaSolicitanteOrdenServicio: {
                ...state.listaSolicitanteOrdenServicio,
                lista: [...lista],
                paginado: { ...paginado }
              },
              solicitanteOrdenServicio: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerSolicitanteOrdenServicio, (state, { objeto }) => {
          return {
            ...state,
            solicitanteOrdenServicio: { ...objeto }
          };
        }),
        
        //RequisitosNotas
        on(CorrespondenciaAcciones.establecerFiltroRequisitosNotas, (state, { filtro }) => {
          return {
            ...state,
            listaRequisitosNotas: {
              ...state.listaRequisitosNotas,
              filtro: { ...filtro }
            },
            requisitosNotas: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaRequisitosNotas,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaRequisitosNotas: {
                ...state.listaRequisitosNotas,
                lista: [...lista],
                paginado: { ...paginado }
              },
              requisitosNotas: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerRequisitosNotas, (state, { objeto }) => {
          return {
            ...state,
            requisitosNotas: { ...objeto }
          };
        }),
        
        //Menu
        on(CorrespondenciaAcciones.establecerFiltroMenu, (state, { filtro }) => {
          return {
            ...state,
            listaMenu: {
              ...state.listaMenu,
              filtro: { ...filtro }
            },
            menu: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaMenu,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaMenu: {
                ...state.listaMenu,
                lista: [...lista],
                paginado: { ...paginado }
              },
              menu: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerMenu, (state, { objeto }) => {
          return {
            ...state,
            menu: { ...objeto }
          };
        }),
        //VistaPatentesMineras
        on(CorrespondenciaAcciones.establecerFiltroVistaPatentesMineras, (state, { filtro }) => {
          return {
            ...state,
            listaVistaPatentesMineras: {
              ...state.listaVistaPatentesMineras,
              filtro: { ...filtro }
            },
            vistaPatentesMineras: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaVistaPatentesMineras,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaVistaPatentesMineras: {
                ...state.listaVistaPatentesMineras,
                lista: [...lista],
                paginado: { ...paginado }
              },
              vistaPatentesMineras: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerVistaPatentesMineras, (state, { objeto }) => {
          return {
            ...state,
            vistaPatentesMineras: { ...objeto }
          };
        }),
        //Actualizacion Patente
        on(CorrespondenciaAcciones.establecerFiltroActualizacionPatente, (state, { filtro }) => {
          return {
            ...state,
            listaActualizacionPatente: {
              ...state.listaActualizacionPatente,
              filtro: { ...filtro }
            },
            actualizacionPatente: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaActualizacionPatente,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaActualizacionPatente: {
                ...state.listaActualizacionPatente,
                lista: [...lista],
                paginado: { ...paginado }
              },
              actualizacionPatente: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerActualizacionPatente, (state, { objeto }) => {
          return {
            ...state,
            actualizacionPatente: { ...objeto }
          };
        }),
        //Boleta Escaneada
        on(CorrespondenciaAcciones.establecerFiltroBoletaEscaneada, (state, { filtro }) => {
          return {
            ...state,
            listaBoletaEscaneada: {
              ...state.listaBoletaEscaneada,
              filtro: { ...filtro }
            },
            boletaEscaneada: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaBoletaEscaneada,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaBoletaEscaneada: {
                ...state.listaBoletaEscaneada,
                lista: [...lista],
                paginado: { ...paginado }
              },
              boletaEscaneada: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerBoletaEscaneada, (state, { objeto }) => {
          return {
            ...state,
            boletaEscaneada: { ...objeto }
          };
        }),
        //Registra Reactivacion
        on(CorrespondenciaAcciones.establecerFiltroRegistraReactivacion, (state, { filtro }) => {
          return {
            ...state,
            listaRegistraReactivacion: {
              ...state.listaRegistraReactivacion,
              filtro: { ...filtro }
            },
            registraReactivacion: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaRegistraReactivacion,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaRegistraReactivacion: {
                ...state.listaRegistraReactivacion,
                lista: [...lista],
                paginado: { ...paginado }
              },
              registraReactivacion: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerRegistraReactivacion, (state, { objeto }) => {
          return {
            ...state,
            registraReactivacion: { ...objeto }
          };
        }),
        //Respaldo Boleta Escaneado
        on(CorrespondenciaAcciones.establecerFiltroRespaldoBoletaEscaneado, (state, { filtro }) => {
          return {
            ...state,
            listaRespaldoBoletaEscaneado: {
              ...state.listaRespaldoBoletaEscaneado,
              filtro: { ...filtro }
            },
            respaldoBoletaEscaneado: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaRespaldoBoletaEscaneado,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaRespaldoBoletaEscaneado: {
                ...state.listaRespaldoBoletaEscaneado,
                lista: [...lista],
                paginado: { ...paginado }
              },
              respaldoBoletaEscaneado: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerRespaldoBoletaEscaneado, (state, { objeto }) => {
          return {
            ...state,
            respaldoBoletaEscaneado: { ...objeto }
          };
        }),
        //FORMULARIO RECEPCION
        on(CorrespondenciaAcciones.establecerFiltroFormularioRecepcion, (state, { filtro }) => {
          return {
            ...state,
            listaFormularioRecepcion: {
              ...state.listaFormularioRecepcion,
              filtro: { ...filtro }
            },
            FormularioRecepcion: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaFormularioRecepcion,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaFormularioRecepcion: {
                ...state.listaFormularioRecepcion,
                lista: [...lista],
                paginado: { ...paginado }
              },
              formularioRecepcion: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerFormularioRecepcion, (state, { objeto }) => {
          return {
            ...state,
            formularioRecepcion: { ...objeto }
          };
        }),
        
        //GENERA CERTIFICADO VIGENCIA
        on(CorrespondenciaAcciones.establecerFiltroGeneraCertificadoVigencia, (state, { filtro }) => {
          return {
            ...state,
            listaGeneraCertificadoVigencia: {
              ...state.listaGeneraCertificadoVigencia,
              filtro: { ...filtro }
            },
            generaCertificadoVigencia: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaGeneraCertificadoVigencia,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaGeneraCertificadoVigencia: {
                ...state.listaGeneraCertificadoVigencia,
                lista: [...lista],
                paginado: { ...paginado }
              },
              generaCertificadoVigencia: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerGeneraCertificadoVigencia, (state, { objeto }) => {
          return {
            ...state,
            generaCertificadoVigencia: { ...objeto }
          };
        }),
        //VistaPatentesMinerasInactivos
        on(CorrespondenciaAcciones.establecerFiltroVistaPatentesMinerasInactivos, (state, { filtro }) => {
          return {
            ...state,
            listaVistaPatentesMinerasInactivos: {
              ...state.listaVistaPatentesMinerasInactivos,
              filtro: { ...filtro }
            },
            vistaPatentesMinerasInactivos: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaVistaPatentesMinerasInactivos,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaVistaPatentesMinerasInactivos: {
                ...state.listaVistaPatentesMinerasInactivos,
                lista: [...lista],
                paginado: { ...paginado }
              },
              vistaPatentesMinerasInactivos: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerVistaPatentesMinerasInactivos, (state, { objeto }) => {
          return {
            ...state,
            vistaPatentesMinerasInactivos: { ...objeto }
          };
        }),
        //VistaRegistroDiarioSipago
        on(CorrespondenciaAcciones.establecerFiltroVistaRegistroDiarioSipago, (state, { filtro }) => {
          return {
            ...state,
            listaVistaRegistroDiarioSipago: {
              ...state.listaVistaRegistroDiarioSipago,
              filtro: { ...filtro }
            },
            vistaRegistroDiarioSipago: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaVistaRegistroDiarioSipago,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaVistaRegistroDiarioSipago: {
                ...state.listaVistaRegistroDiarioSipago,
                lista: [...lista],
                paginado: { ...paginado }
              },
              vistaRegistroDiarioSipago: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerVistaRegistroDiarioSipago, (state, { objeto }) => {
          return {
            ...state,
            vistaRegistroDiarioSipago: { ...objeto }
          };
        }),
        
         // AreaMinera
         on(CorrespondenciaAcciones.establecerFiltroAreaMinera, (state, { filtro }) => {
          return {
            ...state,
            listaAreaMinera: {
              ...state.listaAreaMinera,
              filtro: { ...filtro }
            },
            areaMinera: null
          };
        }),
        on(
          CorrespondenciaAcciones.establecerListaAreaMinera,
          (state, { lista, paginado }) => {
            return {
              ...state,
              listaAreaMinera: {
                ...state.listaAreaMinera,
                lista: [...lista],
                paginado: { ...paginado }
              },
              areaMinera: null
            };
          }
        ),
        on(CorrespondenciaAcciones.establecerAreaMinera, (state, { objeto }) => {
          return {
            ...state,
            areaMinera: { ...objeto }
          };
        })
);

export function reducer(
  state: CorrespondenciaState | undefined,
  action: Action
): any {
  return correspondenciaReducer(state, action);
}
