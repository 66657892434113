import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { OrdenServicio } from '../../modelos';
import { OrdenServicioFilter } from '../../modelos/filtros';

export const establecerFiltroOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer filtro ORDEN_SERVICIO',
  props<{ filtro: OrdenServicioFilter }>()
);
export const establecerListaOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer lista ORDEN_SERVICIO',
  props<{ lista: OrdenServicio[]; paginado: Paginado }>()
);
export const establecerOrdenServicio = createAction(
  '[CORRESPONDENCIA] Establecer objeto ORDEN_SERVICIO',
  props<{ objeto: OrdenServicio }>()
);
