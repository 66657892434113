import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { RequisitosNotas } from '../../modelos';
import { RequisitosNotasFilter } from '../../modelos/filtros';

export const establecerFiltroRequisitosNotas = createAction(
  '[CORRESPONDENCIA] Establecer filtro REQUISITOS_NOTAS',
  props<{ filtro: RequisitosNotasFilter }>()
);
export const establecerListaRequisitosNotas = createAction(
  '[CORRESPONDENCIA] Establecer lista REQUISITOS_NOTAS',
  props<{ lista: RequisitosNotas[]; paginado: Paginado }>()
);
export const establecerRequisitosNotas = createAction(
  '[CORRESPONDENCIA] Establecer objeto REQUISITOS_NOTAS',
  props<{ objeto: RequisitosNotas }>()
);
