<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Correlativo Servicio:</label>
                    <span>{{ vistaServiciosRegistrados.correlativo_servicio }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Nombre Solicitante:</label>
                    <span>{{ vistaServiciosRegistrados.nombre_solicitante }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Representa:</label>
                    <span>{{ vistaServiciosRegistrados.representa }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Tipo Actor Minero:</label>
                    <span>{{ vistaServiciosRegistrados.tipo_actor_minero }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Servicios Solicitados:</label>
                    <span>{{ vistaServiciosRegistrados.servicios_seleccionados }}</span>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
