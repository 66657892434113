<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-vista-areas-mineras-digitalizacion-buscador [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-vista-areas-mineras-digitalizacion-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th>Vigente</th>
                                <th>Código Único</th>
                                <th>Denominación del Área</th>
                                <th>Titular del Área</th>
                                <th>Extensión</th>
                                <th>Ubicación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="align-middle text-center">
                                    <i *ngIf="item.vigente" class="fas fa-calendar-check fa-lg text-info"></i>
                                    <i *ngIf="!item.vigente" class="fas fa-calendar-check fa-lg text-danger"></i>
                                </td>
                                <td class="align-middle">{{ item.codigo_unico }}</td>
                                <td class="align-middle">{{ item.denominacion }}</td>
                                <td class="align-middle">{{ item.titular }}</td>
                                <td class="align-middle">{{ item.cantidad_asignada }}</td>
                                <td class="align-middle" style="text-align: center;"><div class="bg-info text-dark"><i class="fas fa-globe-americas text-light"></i>  {{ item.departamentos }}<br>{{ item.provincias }}<br>{{ item.municipios }}</div></td>
                                <td class="align-middle">
                                    <button type="button" class="btn btn-success">Detalle Pago Patente</button>
                                    <!-- <i (click)="ejecutarOperacion({operacion: 'detalle', id: item.id})"
                                        class="fas fa-book fa-lg text-info mr-1 cursor-pointer" title="Información"></i>
                                    <i [routerLink]="['/correspondencia/vistas-patentes-mineras', item.id, 'patentes']"
                                        class="fas fa-file-alt fa-lg text-warning mr-1 cursor-pointer" title="Patente Minera Ley 535"></i>
                                    <i [routerLink]="['/correspondencia/vistas-patentes-mineras-inactivos', item.id, 'patentes']"
                                        class="fas fa-edit fa-lg text-success mr-1 cursor-pointer" title="Informción de Registro Histórico de Pago de Pantete Minera (Base de Datos SERGEOTECMIN-DTMS)"></i> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-right mt-3">
                    <input type="button" value="Nuevo registro" class="btn btn-primary"
                        (click)="ejecutarOperacion({operacion: 'crear'})">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #modalVistaAreasMineras let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-vista-areas-mineras-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-areas-mineras-detalle>
            <app-correspondencia-vista-areas-mineras-detalle-patente-minera *ngSwitchCase="'detalle-patente-minera'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-vista-areas-mineras-detalle-patente-minera>        
        </div>
    </div>
    <div class="modal-footer">
        AJAM
    </div>
</ng-template> -->
