import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { MenuFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroMenu(state: AppState): MenuFilter {
  return state.correspondencia.listaMenu?.filtro;
}

function seleccionarPaginadoMenu(state: AppState): Paginado {
  return state.correspondencia.listaMenu?.paginado;
}

export const filtroMenuSelector = createSelector(
  obtenerState,
  seleccionarFiltroMenu
);
export const paginadoMenuSelector = createSelector(
  obtenerState,
  seleccionarPaginadoMenu
);
