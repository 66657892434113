<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="paginaInicio" class="brand-link">
        <img class="img-fluid" src="assets/images/logo-menu-lateral-inverso.png" alt="LOGO">
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul id="custom-menu-toggle" class="navbar-nav">
                <li class="nav-item">
                    <div class="text-right">
                        <a data-widget="pushmenu" href="#" role="button" class="nav-link">
                            <i class="fas fa-bars mr-2"></i></a>
                    </div>
                </li>
            </ul>
            <ul class="nav nav-pills nav-sidebar flex-column" role="menu">
                <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
                 <!-- <li class="nav-item has-treeview">
                    <span class="nav-header">menu 1</span>
                    <a (click)="toogleMenu($event)" href="#" class="nav-link" style="cursor: pointer;">
                        <i class="nav-icon"></i>
                        <p>
                            menu 1
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"
                            [routerLinkActive]="'active'">
                            <a [routerLink]="['/correspondencia/contactos']" class="nav-link" 
                               [routerLinkActive]="'active'">
                                <i class="far fa-circle nav-icon ml-3"></i>
                                <i class="nav-icon ml-3"></i>
                                <p>submenu 1</p>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li *ngFor="let menuItem of menuPrincipal" class="nav-item has-treeview">
                    <span *ngIf="!menuItem.esEnlace" class="nav-header">{{menuItem.titulo}}</span>
                    <a (click)="toogleMenu($event)" *ngIf="menuItem.class !== 'nav-header'" href="#" class="nav-link"
                        [routerLink]="menuItem.ruta !== '#' ? [menuItem.ruta] : null"
                        [routerLinkActive]="menuItem.submenu?.length !== 0 ? '' : 'active'"
                        [ngClass]="[menuItem.class]" style="cursor: pointer;">
                        <i [ngClass]="[menuItem.icono]" class="nav-icon"></i>
                        <p>
                            {{menuItem.titulo}}
                            <i *ngIf="menuItem.submenu?.length > 0" class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul *ngIf="menuItem.submenu?.length > 0" class="nav nav-treeview">
                        <li *ngFor="let submenuItem of menuItem.submenu" class="nav-item"
                            [routerLinkActive]="submenuItem.submenu?.length > 0 ? '' : 'active'">
                            <a href="#" class="nav-link"
                                [routerLink]="submenuItem.submenu?.length > 0 ? null : [submenuItem.ruta]"
                                [routerLinkActive]="submenuItem.submenu?.length > 0 ? '' : 'active'"
                                [ngClass]="[submenuItem.class]">
                                <i *ngIf="!submenuItem.icono" class="far fa-circle nav-icon ml-3"></i>
                                <i *ngIf="submenuItem.icono" [ngClass]="[submenuItem.icono]" class="nav-icon ml-3"></i>
                                <p>{{submenuItem.titulo}}</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>