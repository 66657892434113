import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { CorrelativoOsFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroCorrelativoOs(state: AppState): CorrelativoOsFilter {
  return state.correspondencia.listaCorrelativoOs?.filtro;
}

function seleccionarPaginadoCorrelativoOs(state: AppState): Paginado {
  return state.correspondencia.listaCorrelativoOs?.paginado;
}

export const filtroCorrelativoOsSelector = createSelector(
  obtenerState,
  seleccionarFiltroCorrelativoOs
);
export const paginadoCorrelativoOsSelector = createSelector(
  obtenerState,
  seleccionarPaginadoCorrelativoOs
);
