import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { Menu } from '../../modelos';
import { MenuFilter } from '../../modelos/filtros';

export const establecerFiltroMenu = createAction(
  '[CORRESPONDENCIA] Establecer filtro MENU',
  props<{ filtro: MenuFilter }>()
);
export const establecerListaMenu = createAction(
  '[CORRESPONDENCIA] Establecer lista MENU',
  props<{ lista: Menu[]; paginado: Paginado }>()
);
export const establecerMenu = createAction(
  '[CORRESPONDENCIA] Establecer objeto MENU',
  props<{ objeto: Menu }>()
);
