export class OrdenServicio {
  id?: number;
  fk_servicio: number;
  correlativo_servicio: string;
  hoja_ruta_servicio: string;
  requisitos: string;
  tiene_area_minera: boolean;
  fk_area_minera: number;
  zona: number;
  este: number;
  norte: number;
  escala: string;
  orientacion_hoja: string;
  datos_adicionales: string;
  costo_unitario: number;
  cantidad: number;
  subtotal: number;
  monto_total: number;
  ativo: boolean;
  aud_asca_creador: number;
  aud_fecha_creacion: Date;
  aud_asca_modificador: number;
  aud_fecha_modificacion: Date;
  aud_tipo_operacion: string;
  descripcion_servicio: string;
  descripcion_area_minera: string;
  grupo: string;
  plan_trabajo: boolean;
  escala_variable: boolean;
  orientacion_variable: boolean;
  informativo: boolean;
  coordenadas_utm: boolean;
  tiene_codigo_cuadricula: boolean;
  codigo_cuadricula: string;
  codigo_unico: number;
  padron_nacional: string;
  denominacion_area_minera: string;
  tipo_area_minera: string;
  fecha_gaceta: Date;
  departamento_gaceta: string;
  tomo_gaceta: number;

  correlativo_informe: string;
  correlativo_hoja_ruta: string;
}
