<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)" [esConfigurable]="true">
        <div class="row">
            <div class="col-2">
                <span class="d-block font-weight-bold">{{ configuracion.etiquetaDestinatario }}</span>
            </div>
            <div class="col-10">
                <div *ngFor="let destinatario of datosPresentacion.destinatarios">
                    <span class="d-block">{{ destinatario.nombre }}</span>
                    <span class="d-block font-weight-bold mb-1">{{ destinatario.puesto }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="configuracion.usarVia" class="row">
            <div class="col-2">
                <span class="d-block font-weight-bold">{{ configuracion.etiquetaVia }}</span>
            </div>
            <div class="col-10">
                <div *ngFor="let via of datosPresentacion.vias">
                    <span class="d-block">{{ via.nombre }}</span>
                    <span class="d-block font-weight-bold mb-1">{{ via.puesto }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <span class="d-block font-weight-bold">{{ configuracion.etiquetaRemitente }}</span>
            </div>
            <div class="col-10">
                <div *ngFor="let remitente of datosPresentacion.remitentes">
                    <span class="d-block">{{ remitente.nombre }}</span>
                    <span class="d-block font-weight-bold mb-1">{{ remitente.puesto }}</span>
                </div>
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaDestinatario }}</span>
        </div>
        <div class="col-10">
            <div *ngFor="let destinatario of datosPresentacion.destinatarios">
                <span class="d-block">{{ destinatario.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ destinatario.puesto }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="configuracion.usarVia" class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaVia }}</span>
        </div>
        <div class="col-10">
            <div *ngFor="let via of datosPresentacion.vias">
                <span class="d-block">{{ via.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ via.puesto }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaRemitente }}</span>
        </div>
        <div class="col-10">
            <div *ngFor="let remitente of datosPresentacion.remitentes">
                <span class="d-block">{{ remitente.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ remitente.puesto }}</span>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaDestinatario }}</span>
        </div>
        <div class="col-10">
            <ng-container *ngFor="let destinatario of datos.destinatarios">
                <span class="d-block">{{ destinatario.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ destinatario.puesto }}</span>
            </ng-container>
        </div>
    </div>
    <div *ngIf="datos.vias && datos.vias.length > 0" class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaVia }}</span>
        </div>
        <div class="col-10">
            <ng-container *ngFor="let via of datos.vias">
                <span class="d-block">{{ via.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ via.puesto }}</span>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaRemitente }}</span>
        </div>
        <div class="col-10">
            <ng-container *ngFor="let remitente of datos.remitentes">
                <span class="d-block">{{ remitente.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ remitente.puesto }}</span>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaDestinatario }}</span>
        </div>
        <div class="col-10">
            <ng-container *ngFor="let destinatario of datos.destinatarios">
                <span class="d-block">{{ destinatario.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ destinatario.puesto }}</span>
            </ng-container>
        </div>
    </div>
    <div *ngIf="datos.vias && datos.vias.length > 0" class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaVia }}</span>
        </div>
        <div class="col-10">
            <div *ngFor="let via of datos.vias">
                <span class="d-block">{{ via.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ via.puesto }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <span class="d-block font-weight-bold">{{ configuracion.etiquetaRemitente }}</span>
        </div>
        <div class="col-10">
            <div *ngFor="let remitente of datos.remitentes">
                <span class="d-block">{{ remitente.nombre }}</span>
                <span class="d-block font-weight-bold mb-1">{{ remitente.puesto }}</span>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modalParticipante let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Participante - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="formConfiguracion" autocomplete="off" class="text-default">
                    <div class="form-row mb-3">
                        <div class="col-md-12 form-group mb-2">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="usarVia" formControlName="usarVia">
                                <label class="custom-control-label" for="usarVia">Utilizar autorización por VÍA</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label><span class="required-field-indicator"></span> Destinatario:</label>
                            <input type="text" class="form-control" formControlName="etiquetaDestinatario" />
                        </div>
                        <div class="col-md-4">
                            <label><span class="required-field-indicator"></span> Vía:</label>
                            <input type="text" class="form-control" formControlName="etiquetaVia" />
                        </div>
                        <div class="col-md-4">
                            <label><span class="required-field-indicator"></span> Remitente:</label>
                            <input type="text" class="form-control" formControlName="etiquetaRemitente" />
                        </div>
                    </div>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit" class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button" class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>