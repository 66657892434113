import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { DerivacionHrOs } from '../../modelos';
import { DerivacionHrOsFilter } from '../../modelos/filtros';

export const establecerFiltroDerivacionHrOs = createAction(
  '[CORRESPONDENCIA] Establecer filtro DERIVACION_HR_OS',
  props<{ filtro: DerivacionHrOsFilter }>()
);
export const establecerListaDerivacionHrOs = createAction(
  '[CORRESPONDENCIA] Establecer lista DERIVACION_HR_OS',
  props<{ lista: DerivacionHrOs[]; paginado: Paginado }>()
);
export const establecerDerivacionHrOs = createAction(
  '[CORRESPONDENCIA] Establecer objeto DERIVACION_HR_OS',
  props<{ objeto: DerivacionHrOs }>()
);
