<div class="row">
    <div class="col-md-12">
        <ul class="list-unstyled">
            <li *ngFor="let item of lista">
                <div style="display: flex; flex-direction: row;" class="mb-2">
                    <i class="fas fa-folder-open fa-lg mt-2 mr-1 text-muted"></i>
                    <div>
                        <span class="d-block">
                            <strong>Hoja de ruta origen:</strong> {{item.hojaRutaOrigen}} 
                        </span>
                        <span class="d-block">
                            <strong>Hoja de ruta destino:</strong> {{item.hojaRutaDestino}} 
                        </span>
                        <span class="d-block">
                            <strong>Observación:</strong> {{item.observacion}} 
                        </span>
                        <span class="d-block">
                            <i class="fas fa-calendar-alt"></i> {{ item.instante | date: 'dd/MM/yyy' }}
                            <i class="fas fa-clock ml-2"></i> {{ item.instante | date: 'HH:mm' }}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>