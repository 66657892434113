<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Nombre:</label>
                    <span>{{ usuario.nombre }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Apellido:</label>
                    <span>{{ usuario.apellido }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Nom Publico:</label>
                    <span>{{ usuario.nomPublico }}</span>
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label class="d-block">Correo Electronico:</label>
                    <span>{{ usuario.correoElectronico }}</span>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
