<div class="card card-default card-no-shadow" [ngClass]="{'collapsed-card': colapsado.valueOf()}">
    <div class="card-header">
        <h3 class="card-title cursor-pointer" data-card-widget="collapse">
            <strong class="text-muted">FILTROS</strong>
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-plus" [ngClass]="{'fa-plus': colapsado.valueOf(), 'fa-minus': !colapsado.valueOf()}"></i>
            </button>
        </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <form (ngSubmit)="ejecutarAccion()" [formGroup]="formBuscador">
            <div class="form-row">
                <div class="col-md-2 form-group">
                    <label class="d-block">Hoja de ruta</label>
                    <input type="text" class="form-control" formControlName="hojaRutaNumero" />
                </div>
                <div class="col-md-2 form-group mb-2">
                    <label>Tipo de documento:</label>
                    <ng-select [clearable]="false" formControlName="tipoDocumentoId">
                        <ng-option value="">[Elija una opción]</ng-option>
                        <ng-option *ngFor="let item of listaTipoDocumentoCodificador" [value]="item.id">
                            {{ item.nombre }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-3 form-group">
                    <label class="d-block">CITE</label>
                    <input type="text" class="form-control" formControlName="cite" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="d-block">CITE externo</label>
                    <input type="text" class="form-control" formControlName="citeExterno" />
                </div>
            </div>
            <div class="form-row">
                <div *ngIf="filtrarPorUniOrganizacional" class="col-md-4 form-group">
                    <label class="d-block">Unidad organizacional</label>
                    <ng-select [clearable]="false" formControlName="uniOrganizacionalId">
                        <ng-option value="">[Elija una opción]</ng-option>
                        <ng-option *ngFor="let item of listaUniOrganizacionalCodificador" [value]="item.id">
                            {{ item.nombre }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-4 form-group">
                    <label class="d-block">Referencia</label>
                    <input type="text" class="form-control" formControlName="referencia" />
                </div>
                <div class="col-md-2 form-group mb-2">
                    <label>Desde:</label>
                    <input type="date" class="form-control" formControlName="fechaDesde" />
                </div>
                <div class="col-md-2 form-group mb-2">
                    <label>Hasta:</label>
                    <input type="date" class="form-control" formControlName="fechaHasta" />
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button type="submit" class="btn btn-primary mr-1" >Buscar</button>
                    <button type="button" (click)="limpiar()" class="btn btn-dark" >Limpiar</button>
                </div>
            </div>
        </form>
    </div>
    <!-- /.card-body -->
</div>
<!-- /.card -->
