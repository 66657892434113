export class VistaAreasMinerasFilter {
  denominacion?: string;
  titular?: string;
  nit?: string;
  nacionalidad?: string;
  cantidad_parcial_superpuesta?: string;
  cantidad_total_superpuesta?: string;
  cantidad_libre?: string;
  persona_creador?: string;
  cargo_creador?: string;
  codigo_unico?: number;
  lugar_solicitud?: string;
  extension?: number;
  unidad?: string;
  cantidad_cuadriculas?: number;
  cantidad_asignada?: string;
  fecha_inscripcion?: string;
  hoja_cartografica?: string;
  nombre_hoja_cartografica?: string;
  cantidad_solicitada?: string;
  cantidad_reservada?: string;
  observaciones?: string;
  padron_nacional?: string;
  vigente?: boolean;
  tipo_area?: string;
  tipo_actividad_minera?: string;
  departamento_declarado?: string;
  provincia_declarada?: string;
  canton_declarado?: string;
  departamentos?: string;
  provincias?: string;
  municipios?: string;
  codigos_municipios?: string;
  fecha_baja?: Date;
  zona?: string;
  lugar_formalizacion_solicitud?: string;
  fecha_otorgacion?: string;
  fecha_adjudicacion?: string;
  fecha_titulacion?: string;
  numero_gaceta?: number;
  fecha_gaceta?: string;
  fecha_plano_definitivo?: Date;
  numero_resolucion?: string;
  fecha_resolucion?: string;
  observaciones_extincion?: string;
  nacionalizado?: boolean;
  estado_creacion_documento?: string;
}
