import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EnvironmentHelper } from 'src/app/comun/auxiliares';

import { AppState } from 'src/app/app.state';
import { ServicioMetodosBaseService } from 'src/app/comun/servicios';

@Injectable({
  providedIn: 'root'
})
export class VistaAreasMinerasService extends ServicioMetodosBaseService {
  private env = EnvironmentHelper.obtenerConfiguracion('sincobol');

  constructor(
    protected override http: HttpClient,
    protected override store: Store<AppState>
  ) {
    super(http, store);
    this.urlApiBase = this.env['api']['url'];
    this.controlador = 'vista-areas-mineras';
  }

  obtenerAreaMinera(
    padron_nacional: string,
    codigo_unico: number
  ): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/area_minera/${padron_nacional}/${codigo_unico}`;
    return this.http.get(url, { headers: this.headers });
  }

  obtenerAreaMineraATE(
    padron_nacional: string,
    codigo_unico: number
  ): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/area_minera_ate/${padron_nacional}/${codigo_unico}`;
    return this.http.get(url, { headers: this.headers });
  }

  obtenerAreaMineraSinATE(
    padron_nacional: string,
    codigo_unico: number
  ): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/area_minera_sin_ate/${padron_nacional}/${codigo_unico}`;
    return this.http.get(url, { headers: this.headers });
  }
  /*obtenerPorDenominacionCodigo(
    padron_nacional: string,
    codigo_unico: number
  ): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/area_minera/${padron_nacional}/${codigo_unico}`;
    return this.http.get(url, { headers: this.headers });
  }*/

  obtenerCodificador(): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/codificador`;
    return this.http.get(url, { headers: this.headers });
  }

  generahojaRuta(
    nombre_completo: string,
    ci: string,
    tipo_doc: string,
    telf: string,
    representa: string,
    tipo_actor_minero: string,
    correlativo: string,
    referencia: string,
    fecha: string,
    fojas: number,
    fk_asignacion_cargo: number,
    fk_tipo_hr: number,
    cite_doc_ext: string,
    fecha_cite_ext: string,
    fk_persona_creador: number,
    adjuntos: string,
    fk_destinatario: number
  ): Observable<any> {
    const url = `${this.urlApiBase}/${this.controlador}/function/${nombre_completo}/${ci}/${tipo_doc}/${telf}/${representa}/${tipo_actor_minero}/${correlativo}/${referencia}/${fecha}/${fojas}/${fk_asignacion_cargo}/${fk_tipo_hr}/${cite_doc_ext}/${fecha_cite_ext}/${fk_persona_creador}/${adjuntos}/${fk_destinatario}`;
    return this.http.get(url, { headers: this.headers });
  }
}
