import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import {
  Codificador,
  Paginado,
  Respuesta,
  RespuestaLista,
  RespuestaObjeto
} from '../../../comun/modelos';
import { ComunState } from '../../../comun/estados';
import * as ComunAcciones from '../../../comun/estados';

import { CorrespondenciaState } from '../estados';
import * as CorrespondenciaAcciones from '../estados/acciones';
import {
  filtroVistaAreasMinerasSelector,
  paginadoVistaAreasMinerasSelector
} from '../estados/selectores';
import { VistaAreasMineras } from '../modelos';
import { VistaAreasMinerasFilter } from '../modelos/filtros';
import { VistaAreasMinerasService } from '../servicios';

import { ClasificacionService } from '../servicios';

@Injectable({
  providedIn: 'root'
})
export class VistaAreasMinerasFacade {
  filtro: VistaAreasMinerasFilter = new VistaAreasMinerasFilter();
  paginado: Paginado;

  get ComunState$(): Observable<ComunState> {
    return this.store.select('comun');
  }

  get CorrespondenciaState$(): Observable<CorrespondenciaState> {
    return this.store.select('correspondencia');
  }

  get Filtro$(): Observable<VistaAreasMinerasFilter> {
    return this.store.select(filtroVistaAreasMinerasSelector);
  }

  constructor(
    private store: Store<AppState>,
    private vistaAreasMinerasService: VistaAreasMinerasService,
    private clasificacionService: ClasificacionService
  ) {
    this.store.select(filtroVistaAreasMinerasSelector).subscribe((filtro) => {
      if (filtro != null) {
        this.filtro = filtro;
      }
    });
    this.store
      .select(paginadoVistaAreasMinerasSelector)
      .subscribe((paginado) => {
        if (paginado != null) {
          this.paginado = paginado;
        }
      });
  }

  establecerFiltro(filtro: VistaAreasMinerasFilter): void {
    this.store.dispatch(
      CorrespondenciaAcciones.establecerFiltroVistaAreasMineras({
        filtro: { ...filtro }
      })
    );
  }

  buscar(
    objeto: VistaAreasMinerasFilter,
    pagina: number,
    cantidad: number
  ): Promise<RespuestaLista<VistaAreasMineras>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .buscar(objeto, pagina, cantidad)
        .subscribe((respuesta: RespuestaLista<VistaAreasMineras>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaVistaAreasMineras({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerPorId(id: number): Promise<RespuestaObjeto<VistaAreasMineras>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .obtenerPorId(id)
        .subscribe((respuesta: RespuestaObjeto<VistaAreasMineras>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaAreasMineras({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  // obtenerPorDenominacionCodigo(
  //   padron_nacional: string,
  //   codigo_unico: number
  //   ): Promise<Respuesta> {
  //   this.store.dispatch(
  //     ComunAcciones.establecerProcesando({ procesando: true })
  //   );
  //   return new Promise(async (resolve) => {
  //     this.vistaAreasMinerasService.obtenerPorDenominacionCodigo(padron_nacional, codigo_unico).subscribe((respuesta: Respuesta) => {
  //       if (respuesta.tipoRespuesta === 'Exito') {
  //         //this.obtenerPorId(id);
  //       }
  //       this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
  //       this.store.dispatch(
  //         ComunAcciones.establecerProcesando({ procesando: false })
  //       );
  //       resolve(respuesta);
  //     });
  //   });
  // }

  obtenerAreaMinera(
    padron_nacional: string, 
    codigo_unico: number
    ): Promise<RespuestaObjeto<VistaAreasMineras>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .obtenerAreaMinera(padron_nacional, codigo_unico)
        .subscribe((respuesta: RespuestaObjeto<VistaAreasMineras>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaAreasMineras({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerAreaMineraATE(
    padron_nacional: string, 
    codigo_unico: number
    ): Promise<RespuestaObjeto<VistaAreasMineras>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .obtenerAreaMineraATE(padron_nacional, codigo_unico)
        .subscribe((respuesta: RespuestaObjeto<VistaAreasMineras>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaAreasMineras({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerAreaMineraSinATE(
    padron_nacional: string, 
    codigo_unico: number
    ): Promise<RespuestaObjeto<VistaAreasMineras>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .obtenerAreaMineraSinATE(padron_nacional, codigo_unico)
        .subscribe((respuesta: RespuestaObjeto<VistaAreasMineras>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaAreasMineras({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerCodificador(): Promise<RespuestaLista<Codificador>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaAreasMinerasService
        .obtenerCodificador()
        .subscribe((respuesta: RespuestaLista<Codificador>) => {
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  generahojaRuta(
    nombre_completo: string,
    ci: string,
    tipo_doc: string,
    telf: string,
    representa: string,
    tipo_actor_minero: string,
    correlativo: string,
    referencia: string,
    fecha: string,
    fojas: number,
    fk_asignacion_cargo: number,
    fk_tipo_hr: number,
    cite_doc_ext: string,
    fecha_cite_ext: string,
    fk_persona_creador: number,
    adjuntos: string,
    fk_destinatario: number
    ): Promise<Respuesta> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise(async (resolve) => {
      this.vistaAreasMinerasService.generahojaRuta(nombre_completo, ci, tipo_doc, telf, representa, tipo_actor_minero, correlativo, referencia, fecha, fojas, fk_asignacion_cargo, fk_tipo_hr, cite_doc_ext, fecha_cite_ext, fk_persona_creador, adjuntos, fk_destinatario).subscribe((respuesta: Respuesta) => {
        if (respuesta.tipoRespuesta === 'Exito') {
          //this.obtenerPorId(id);
        }
        this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
        this.store.dispatch(
          ComunAcciones.establecerProcesando({ procesando: false })
        );
        resolve(respuesta);
      });
    });
  }

}
