import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription, elementAt } from 'rxjs';

import Swal from 'sweetalert2';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios, VistaRegistroDiarioSipago, OrdenServicio, DatosAreaMinera, VistaAreasMineras, SolicitanteOrdenServicio } from 'src/app/modulos/correspondencia/modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade, VistaAreasMinerasFacade, SolicitanteOrdenServicioFacade } from 'src/app/modulos/correspondencia/fachadas';
import { VistaServiciosRegistradosFilter, SolicitanteOrdenServicioFilter } from 'src/app/modulos/correspondencia/modelos/filtros';

import { SeguridadFacade } from 'src/app/seguridad/fachadas';
import { NgLabelTemplateDirective } from '@ng-select/ng-select';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
//import { check } from 'prettier';
//import { SERVFAIL } from 'dns';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

//import { NgxUiLoaderService } from "ngx-ui-loader"; 

@Component({
  selector: 'app-admin-lte3-registro-servicio',
  templateUrl: './registro-servicio.component.html',
  styles: []
})
export class RegistroServicioComponent
  implements OnInit, OnDestroy
{
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  @ViewChild('modalRegistro') modalRegistro: NgbModal;

  suscripcion = new Subscription();

  formVistaServiciosRegistrados: FormGroup;
  botonOperacion: string;

  listaServiciosSeleccionados: OrdenServicio[] = [];
  results: any[] = [];
  
  registra_servicio: boolean;
  codigo_descripcion: string;
  grupo_servicio: string;
  costo_servicio: string;
  codigo_servicio: string;
  id_servicio: number;

  costo_servicio_numero_n: number;
  costo_servicio_selec_numero: number;
  servicio_selec: boolean;
  codigo_descripcion_selec: string;
  grupo_servicio_selec: string;
  costo_servicio_selec: string;
  nuevo_costo_servicio_select: string;
  codigo_servicio_selec: string;
  id_servicio_selec: number;

  vistaServiciosRegistrados: VistaServiciosRegistrados;
  serviciosSeleccionado: Servicios;
  listaServiciosCodificador: Codificador[];
  selectedTargets: [];

  requisitos: string;
  sumatoriaServiciosTotal: number;

  fecha: Date;

  registra_operacion: string;
  tipo_solicitante: string;

  existe_area_minera: string;
  /**/
  //dato1: string;
  //sumaTotal: number;
  /**/
  items_numbers: number [];
  dynamicArray: any [] = [];
  dynamicSumaArray = [];
  dynamicTotalArray = [];
  archivo: string;

  lista: VistaServiciosRegistrados[];
  listaSOS: SolicitanteOrdenServicio[];

  areasMineraObtenida: VistaAreasMineras;
  areasMineraObtenida_sc_a: VistaAreasMineras;
  areasMineraObtenida_g: VistaAreasMineras;
  //denominacion_area_minera: string;

  verPlandeTrabajo: string;
  verAreaMineraObtenida: string;
  verAreaMineraObtenida_sc_a: string;
  verAreaMineraObtenida_g: string;
  verInformativo: string;
  verCoordenadaUTM: string;
  verCodigoCuadricula: string;

  verAreaMineraG: string;
  verAreaMineraSc: string;

  verificaDenominacionAreaMinera: string;

  bloquear: boolean = false;

  modalTitulo: string;
  modal: NgbModalRef;
  powers = ['Really Smart', 'Super Flexible',
  'Super Hot', 'Weather Changer'];
  //code: string;
  value: string = '';
  counter: number = 0;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    public seguridadFacade: SeguridadFacade,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private serviciosFacade: ServiciosFacade,
    private vistaAreasMinerasFacade: VistaAreasMinerasFacade,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private solicitanteServicioFacade: SolicitanteOrdenServicioFacade
  ) {
    if (!this.vistaServiciosRegistrados) {
      this.vistaServiciosRegistrados = new VistaServiciosRegistrados();
    }

    this.formVistaServiciosRegistrados = this.fb.group({
      /*correlativo_servicio: ['', Validators.required],
      nombre_solicitante: ['', Validators.required],
      representa: ['', Validators.required]*/
      checkArray: this.fb.array([], [Validators.required]),
      correlativo_servicio: ['n'],
      atender_dccm: [true],
      nombre_solicitante: ['n'],
      idhr: [1],
      cantidad_servicios: [1],
      servicios_seleccionados: ['0'],
      impreso: ['n'],
      fecha: ['', Validators.required],
      nombre_completo: ['', Validators.required],
      numero_ci: ['', Validators.required],
      expedido: ['', Validators.required],
      telefono: ['', Validators.required],
      representa: ['', Validators.required],
      tipo_actor_minero: ['', Validators.required],
      // num_boleta1: ['', Validators.required],
      // fecha_depos1: ['', Validators.required],
      // monto_depos1: ['', Validators.required],
      // depos1: ['', Validators.required],
      razon_social: ['', Validators.required],
      nit: ['', Validators.required],
      //nfactura: ['', Validators.required],
      observacion: [''],
      zona: [null, Validators.required],
      este: [null, Validators.required],
      norte: [null, Validators.required],
      escala: [null, Validators.required],
      orientacion: [null, Validators.required],
      departamento_gaceta: [null, Validators.required],
      tomo: [null, Validators.required],
      fecha_gaceta: [null, Validators.required],
      denominacion_area_minera_gaceta: [null, Validators.required],
      plan_trabajo: [null, Validators.required],
      escala_variable: [null, Validators.required],
      orientacion_variable: [null, Validators.required],
      correo_electronico: ['', Validators.required],
      direccion: ['', Validators.required],

      codigo_cuadricula: [null, Validators.required],
      escala_codigo_cuadricula: [null, Validators.required],
      orientacion_codigo_cuadricula: [null, Validators.required],

      sucursal_pn: ['', Validators.required],
      nombre_completo_pn: ['', Validators.required],
      numero_ci_pn: ['', Validators.required],
      expedido_pn: ['', Validators.required],

      sucursal_apm: ['', Validators.required],
      denominacion_titular_apm: ['', Validators.required],
      nombre_completo_apm: ['', Validators.required],
      numero_ci_apm: ['', Validators.required],
      expedido_apm: ['', Validators.required],
      // codigo_unico_apm: ['', Validators.required],
      // padron_nacional_apm: ['', Validators.required],

      codigo_unico: ['', Validators.required],
      padron_nacional: ['', Validators.required],
      codigo_unico_g: ['', Validators.required],
      padron_nacional_g: ['', Validators.required],
      codigo_unico_sc_a: ['', Validators.required],
      padron_nacional_sc_a: ['', Validators.required],
      sucursal: ['', Validators.required],

      denominacion_area_minera_obtenido: [''],
      codigo_unico_obtenido: [''],
      padron_nacional_obtenido: [''],
      id_area_minera_obtenido: [''],
      tipo_area_minera_obtenido: [''],

      denominacion_area_minera_obtenido_g: [''],
      codigo_unico_obtenido_g: [''],
      padron_nacional_obtenido_g: [''],
      id_area_minera_obtenido_g: [''],
      tipo_area_minera_obtenido_g: [''],

      denominacion_area_minera_obtenido_sc_a: [''],
      codigo_unico_obtenido_sc_a: [''],
      padron_nacional_obtenido_sc_a: [''],
      id_area_minera_obtenido_sc_a: [''],
      tipo_area_minera_obtenido_sc_a: [''],

      tiene_servicios: [false],
      captcha: [''],
    });
  }

  ngOnInit(): void {

    this.seguridadFacade.iniciarSesionExterna(
      'ccampuzano',
      '123456789'
    );

    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ vistaServiciosRegistrados }) => {
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
            /*if (this.tipoOperacion === 'modificar' && this.vistaServiciosRegistrados.id) {
            this.formVistaServiciosRegistrados.setValue({
              correlativo_servicio: this.vistaServiciosRegistrados.correlativo_servicio,
              nombre_solicitante: this.vistaServiciosRegistrados.nombre_solicitante,
              representa: this.vistaServiciosRegistrados.representa
            });
          }*/
          }
        }
      )
    );

    this.fecha = new Date();

    const tipo:string = this.router.routerState.snapshot.root.queryParams['id'];
    if(tipo === undefined)
    {
      this.registra_operacion = "NINGUNO";
    }
    else
    {
      this.registra_operacion = tipo;
      if(tipo[0] === "SERVICIOS PERSONA NATURAL")
      {
        this.tipo_solicitante = "PN";
      }
      else
      {
        this.tipo_solicitante = "APM";
        this.verAreaMineraG = "TieneVisualizacion";
        this.verAreaMineraSc = "TieneVisualizacion";

      }
    }

    this.items_numbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99];
    this.registra_servicio = false;
    this.servicio_selec = false;
    this.requisitos = '';

    this.tipoOperacion = "crear";

  /************ Codigo para crear Captcha ***********/
  /*let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
  let lengthOtp = 6;
  let captcha = [];
  for (let i = 0; i < lengthOtp; i++) {*/
    //below code will not allow Repetition of Characters
    /*let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  this.code = captcha.join("");*/
  /************ Fin Codigo para crear Captcha ***********/

    switch (this.tipoOperacion) {
      case 'crear':
        this.botonOperacion = 'Guardar';
        this.serviciosFacade.obtenerCodificador().then((respuesta) => {
          this.listaServiciosCodificador = respuesta.lista;
        });
        break;
      /*case 'modificar':
        this.botonOperacion = 'Modificar';
        break;*/
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      /*case 'seleccionar-servicios': {
        if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.costo_servicio_numero_n = Math.trunc(respuesta.objeto.costo);
            this.costo_servicio_selec_numero = Math.trunc(respuesta.objeto.costo);
            this.serviciosSeleccionado = respuesta.objeto;
            this.registra_servicio = true;
            this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio = respuesta.objeto.grupo;
            this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio = respuesta.objeto.codigo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
            this.id_servicio = respuesta.objeto.id;
          });
        } else {
          this.serviciosSeleccionado = null;
        }
        break;
      }*/
      case 'cancelar-lista': {
        this.registra_servicio = false;
        this.verPlandeTrabajo = 'NoTieneVisualizacion';
        this.verInformativo = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida_sc_a = 'NoTieneVisualizacion_sc_a';
        this.verAreaMineraObtenida_g = 'NoTieneVisualizacion_g';
        (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
        break;
      }
      // case 'adjunto-seleccionado': {
      //   let elemId: string = (evento.input.target as Element).id;
      //   (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
      //   break;
      // }
      /*case 'agregar-lista': {
        this.registra_servicio = false;
        this.dynamicArray.push({ firstName: 'hola' });
        this.servicio_selec = true;*/
        /*if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.codigo_descripcion_selec = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio_selec = respuesta.objeto.grupo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio_selec = respuesta.objeto.codigo;
            this.id_servicio_selec = respuesta.objeto.id;
            this.servicio_selec = true;
          });
        }*/
        /*break;
      }*/
    }
  }

  ejecutarAccion(accion: string): void {
    let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {
        
        //let tiene_servicios:boolean = this.formVistaServiciosRegistrados.controls['tiene_servicios'].value;

        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        //if (!this.formVistaServiciosRegistrados.valid) {
        if(!this.formVistaServiciosRegistrados.controls['sucursal'].valid) {
          this.formVistaServiciosRegistrados.controls['sucursal'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['nombre_completo'].valid) {
          this.formVistaServiciosRegistrados.controls['nombre_completo'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['numero_ci'].valid) {
          this.formVistaServiciosRegistrados.controls['numero_ci'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['expedido'].valid) {
          this.formVistaServiciosRegistrados.controls['expedido'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['telefono'].valid) {
          this.formVistaServiciosRegistrados.controls['telefono'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['representa'].valid) {
          this.formVistaServiciosRegistrados.controls['representa'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].valid) {
          this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['razon_social'].valid) {
          this.formVistaServiciosRegistrados.controls['razon_social'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['nit'].valid) {
          this.formVistaServiciosRegistrados.controls['nit'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['observacion'].valid) {
          this.formVistaServiciosRegistrados.controls['observacion'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['correo_electronico'].valid) {
          this.formVistaServiciosRegistrados.controls['correo_electronico'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['direccion'].valid) {
          this.formVistaServiciosRegistrados.controls['direccion'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['sucursal'].valid) {
          this.formVistaServiciosRegistrados.controls['sucursal'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['sucursal'].valid) {
          this.formVistaServiciosRegistrados.controls['sucursal'].markAllAsTouched();
          return
        }
        if(this.servicio_selec === false) {
          return
        }
        
          //return;
        //}
        /*vistaServiciosRegistrados = {
          ...this.formVistaServiciosRegistrados.value
        };*/
        vistaServiciosRegistrados.fecha = this.formVistaServiciosRegistrados.controls['fecha'].value; 
        vistaServiciosRegistrados.sucursal = this.formVistaServiciosRegistrados.controls['sucursal'].value; 
        vistaServiciosRegistrados.nombre_completo = this.formVistaServiciosRegistrados.controls['nombre_completo'].value;
        vistaServiciosRegistrados.numero_ci = this.formVistaServiciosRegistrados.controls['numero_ci'].value;
        vistaServiciosRegistrados.expedido = this.formVistaServiciosRegistrados.controls['expedido'].value;
        vistaServiciosRegistrados.telefono = this.formVistaServiciosRegistrados.controls['telefono'].value;
        vistaServiciosRegistrados.nombre_solicitante = this.formVistaServiciosRegistrados.controls['nombre_completo'].value;
        vistaServiciosRegistrados.representa = this.formVistaServiciosRegistrados.controls['representa'].value;
        vistaServiciosRegistrados.tipo_actor_minero = this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].value;
        vistaServiciosRegistrados.razon_social = this.formVistaServiciosRegistrados.controls['razon_social'].value;
        vistaServiciosRegistrados.nit = this.formVistaServiciosRegistrados.controls['nit'].value;
        vistaServiciosRegistrados.observacion = this.formVistaServiciosRegistrados.controls['observacion'].value;
        vistaServiciosRegistrados.correo_electronico = this.formVistaServiciosRegistrados.controls['correo_electronico'].value;
        vistaServiciosRegistrados.direccion = this.formVistaServiciosRegistrados.controls['direccion'].value;
        vistaServiciosRegistrados.atender_dccm = true;
        vistaServiciosRegistrados.correlativo_servicio = "n";
        vistaServiciosRegistrados.idhr = 1;
        vistaServiciosRegistrados.cantidad_servicios = 1;
        vistaServiciosRegistrados.servicios_seleccionados = "0";
        vistaServiciosRegistrados.impreso = "n";
        vistaServiciosRegistrados.modo_registro = 'EXTERNO';
        const total_registros = Number(Object.keys(this.dynamicArray).length);

        for(let i = 0 ; i < total_registros ; i++){
          //console.log(this.heroesList[i].name);
          const valor_i = Number(i);
          const servicioSel = new OrdenServicio();
          servicioSel.requisitos = this.dynamicArray[valor_i].Requisitos_Sel;
          /*"Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.";*/
          servicioSel.descripcion_servicio = this.dynamicArray[valor_i].Servicio_Grupo; 
          if(this.dynamicArray[valor_i].Tiene_AreaMinera === "V")
          {
            servicioSel.fk_area_minera = this.dynamicArray[valor_i].Id_AM;
            servicioSel.descripcion_area_minera = this.dynamicArray[valor_i].Denominacion_AM;
            servicioSel.codigo_unico = this.dynamicArray[valor_i].Codigo_Unico_AM;
            servicioSel.padron_nacional = this.dynamicArray[valor_i].Padron_Nacional_AM;
            servicioSel.denominacion_area_minera = this.dynamicArray[valor_i].Denominacion_AM;
            servicioSel.tipo_area_minera = this.dynamicArray[valor_i].Tipo_Area_Minera_AM;
          }
          servicioSel.plan_trabajo = this.dynamicArray[valor_i].plan_trabajo;
          servicioSel.fk_servicio = this.cambioNumero(this.dynamicArray[valor_i].Id_Servicio_Sel);
          servicioSel.costo_unitario = this.cambioNumero(this.dynamicArray[valor_i].Costo_Servicio_Numero);
          servicioSel.cantidad = this.cambioNumero(this.dynamicArray[valor_i].Cantidad);
          servicioSel.subtotal = this.cambioNumero(this.dynamicArray[valor_i].Costo_Serv_Sel_Numero);
          servicioSel.monto_total = this.cambioNumero(this.dynamicTotalArray[0]);
          servicioSel.grupo = this.dynamicArray[valor_i].Grupo;
          //servicioSel.plan_trabajo = false;
          //servicioSel.escala_variable = false;
          //servicioSel.orientacion_variable = false;
          servicioSel.informativo = this.dynamicArray[valor_i].Informativo;
          if(this.dynamicArray[valor_i].Tipo_Informativo === 'UTM')
          {
            servicioSel.coordenadas_utm = true;
            servicioSel.tiene_codigo_cuadricula = false;
            servicioSel.zona = this.dynamicArray[valor_i].Zona;
            servicioSel.este = this.dynamicArray[valor_i].Este;
            servicioSel.norte = this.dynamicArray[valor_i].Norte;
            servicioSel.escala = this.dynamicArray[valor_i].Escala;
            servicioSel.orientacion_hoja = this.dynamicArray[valor_i].Orientacion;
          }
          else
          {
            servicioSel.tiene_codigo_cuadricula = true;
            servicioSel.coordenadas_utm = false;
            servicioSel.codigo_cuadricula = this.dynamicArray[valor_i].Codigo_Cuadricula;
            servicioSel.escala = this.dynamicArray[valor_i].Escala_Codigo_Cuadricula;
            servicioSel.orientacion_hoja = this.dynamicArray[valor_i].Orientacion_Codigo_Cuadricula;
          }
          if(this.dynamicArray[valor_i].Tipo_Gaceta === 'M-01')
          {
            servicioSel.fecha_gaceta = this.dynamicArray[valor_i].Fecha_Gaceta;
          }
          else
          {
            servicioSel.fecha_gaceta = this.dynamicArray[valor_i].Fecha_Gaceta;
            servicioSel.departamento_gaceta = this.dynamicArray[valor_i].Departamento_Gaceta;
            servicioSel.tomo_gaceta = this.dynamicArray[valor_i].Tomo;
            servicioSel.denominacion_area_minera = this.dynamicArray[valor_i].Denominacion_Am_Gaceta;
          }


          
          /*if(this.dynamicArray[valor_i].Datos_Adicionales !== "")
          {
            servicioSel.datos_adicionales = this.dynamicArray[valor_i].Datos_Adicionales;
          }*/
          this.listaServiciosSeleccionados.push(servicioSel);
        }

        
        //Implementacion de Validacion 11092023
        Swal.fire({
          title: '¿Seguro que desea realizar la solicitud del servicio o los servicios seleccionados?',
          text: 'Los datos declarados no podrán ser modificados.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, enviar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            //this.bloquear = true;

            //this.modalTitulo = 'Cargando';
            this.mostrarModal();

            this.vistaServiciosRegistradosFacade
            .guardar(vistaServiciosRegistrados, this.listaServiciosSeleccionados)
            .then((respuesta) => {
              if (respuesta.tipoRespuesta === 'Exito') {
                //this.bloquear = false;
                //this.ngxService.stop();

                this.cerrarModal();

                this.seguridadFacade.cerrarSesion();

                //console.log(respuesta.mensaje);

                //window.open('','_blank');
                //window.open("C:/Users/AJAM-Admin/Desktop/Sistema de Adminsitracion de Ingresos AJAM/ingresos-backend/src/orden-servicio/aplicacion/servicios/mails/1c34d72ef0a0799d99243e89e41ef3eddf135130.pdf", "_blank");

                //window.open('https://sefe.demo.agetic.gob.bo/public/facturas_pdf/466E23D207AF61FE825FEE918B8183C41A49DEDAA488614C7D5AEFD74.pdf', '_blank');

                window.open(respuesta.mensaje,'_self');

              
              }
            });
          }
        });
        break;
      }
      case 'enviar_formulario_pn': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        if(!this.formVistaServiciosRegistrados.controls['sucursal_pn'].valid) {
          this.formVistaServiciosRegistrados.controls['sucursal_pn'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['nombre_completo_pn'].valid) {
          this.formVistaServiciosRegistrados.controls['nombre_completo_pn'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['numero_ci_pn'].valid) {
          this.formVistaServiciosRegistrados.controls['numero_ci_pn'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['expedido_pn'].valid) {
          this.formVistaServiciosRegistrados.controls['expedido_pn'].markAllAsTouched();
          return
        }


        this.registra_operacion = "SERVICIOS PERSONA NATURAL";
        this.formVistaServiciosRegistrados.patchValue({
          representa: this.formVistaServiciosRegistrados.controls['nombre_completo_pn'].value.toUpperCase()
        });
        this.formVistaServiciosRegistrados.patchValue({
          nombre_completo: this.formVistaServiciosRegistrados.controls['nombre_completo_pn'].value.toUpperCase()
        });
        this.formVistaServiciosRegistrados.patchValue({
          numero_ci: this.formVistaServiciosRegistrados.controls['numero_ci_pn'].value.toUpperCase()
        });
        this.serviciosFacade
        .obtenerCodificador()
        .then((respuesta) => {
          this.listaServiciosCodificador = respuesta.lista;
          this.formVistaServiciosRegistrados.controls['expedido'].setValue(this.formVistaServiciosRegistrados.controls['expedido_pn'].value);
          this.formVistaServiciosRegistrados.controls['sucursal'].setValue(this.formVistaServiciosRegistrados.controls['sucursal_pn'].value);
          this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].setValue('PERSONA NATURAL');
        });

        //let variable_captcha =  this.formVistaServiciosRegistrados.controls['captcha'].value;
        //if(variable_captcha.trim() !== this.code.trim())
        //{
        //  alert("Codigo Captcha invalido. Inténtelo de nuevo");
        //  this.registra_operacion = 'PERSONA NATURAL';
        //}
        break;
      }
      case 'enviar_formulario_apm': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        if(!this.formVistaServiciosRegistrados.controls['sucursal_apm'].valid) {
          this.formVistaServiciosRegistrados.controls['sucursal_apm'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['denominacion_titular_apm'].valid) {
          this.formVistaServiciosRegistrados.controls['denominacion_titular_apm'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['nombre_completo_apm'].valid) {
          this.formVistaServiciosRegistrados.controls['nombre_completo_apm'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['numero_ci_apm'].valid) {
          this.formVistaServiciosRegistrados.controls['numero_ci_apm'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['expedido_apm'].valid) {
          this.formVistaServiciosRegistrados.controls['expedido_apm'].markAllAsTouched();
          return
        }
        this.formVistaServiciosRegistrados.patchValue({
          representa: this.formVistaServiciosRegistrados.controls['denominacion_titular_apm'].value.toUpperCase()
        });
        this.formVistaServiciosRegistrados.patchValue({
          nombre_completo: this.formVistaServiciosRegistrados.controls['nombre_completo_apm'].value.toUpperCase()
        });
        this.formVistaServiciosRegistrados.patchValue({
          numero_ci: this.formVistaServiciosRegistrados.controls['numero_ci_apm'].value.toUpperCase()
        });

        this.registra_operacion = "SERVICIOS ACTOR PRODUCTIVO MINERO"; 
        this.serviciosFacade.obtenerCodificador().then((respuesta) => {
            this.listaServiciosCodificador = respuesta.lista;
            this.formVistaServiciosRegistrados.controls['sucursal'].setValue(this.formVistaServiciosRegistrados.controls['sucursal_apm'].value);
            this.formVistaServiciosRegistrados.controls['expedido'].setValue(this.formVistaServiciosRegistrados.controls['expedido_apm'].value);
        });
        break;
      }
      case 'encontrar_area_minera': {
        const codigo_unico = this.formVistaServiciosRegistrados.controls['codigo_unico'].value;
        const padron_nacional = this.formVistaServiciosRegistrados.controls['padron_nacional'].value;

        this.vistaAreasMinerasFacade.obtenerAreaMinera(padron_nacional, codigo_unico).then((respuesta) => {
          this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue(respuesta.objeto.denominacion);
          this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue(respuesta.objeto.codigo_unico);
          this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue(respuesta.objeto.padron_nacional);
          this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue(respuesta.objeto.id);
          this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido'].setValue(respuesta.objeto.tipo_area);
        });

        this.verPlandeTrabajo = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida = 'TieneVisualizacion';
        break;
      }
      case 'encontrar_area_minera_sc_a': {
        const codigo_unico = this.formVistaServiciosRegistrados.controls['codigo_unico_sc_a'].value;
        const padron_nacional = this.formVistaServiciosRegistrados.controls['padron_nacional_sc_a'].value;

        this.vistaAreasMinerasFacade.obtenerAreaMineraSinATE(padron_nacional, codigo_unico).then((respuesta) => {
          this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_sc_a'].setValue(respuesta.objeto.denominacion);
          this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_sc_a'].setValue(respuesta.objeto.codigo_unico);
          this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_sc_a'].setValue(respuesta.objeto.padron_nacional);
          this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_sc_a'].setValue(respuesta.objeto.id);
          this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_sc_a'].setValue(respuesta.objeto.tipo_area);
        }).catch(error => { alert("El Área Minera no pertenece a los tipos: (Contrato Minero, ATE por Cuadricula, Contrato Administrativo Minero - CAM, Licencia de Prospeccion y Exploración Otorgada, CAM por Adecuación, Área Revertida y Nacionalizada), por favor realizar nuevamente la busqueda haciendo click en 'Volver a buscar Área Minera'."); });

        this.verAreaMineraSc = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida_sc_a = 'TieneVisualizacion';
        break;
      }
      case 'encontrar_area_minera_g': {
        const codigo_unico = this.formVistaServiciosRegistrados.controls['codigo_unico_g'].value;
        const padron_nacional = this.formVistaServiciosRegistrados.controls['padron_nacional_g'].value;

        this.vistaAreasMinerasFacade.obtenerAreaMineraATE(padron_nacional, codigo_unico).then((respuesta) => {
          this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_g'].setValue(respuesta.objeto.denominacion);
          this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_g'].setValue(respuesta.objeto.codigo_unico);
          this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_g'].setValue(respuesta.objeto.padron_nacional);
          this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_g'].setValue(respuesta.objeto.id);
          this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_g'].setValue(respuesta.objeto.tipo_area);
        }).catch(error => { alert("El Área Minera no pertenece a los tipos: (ATE's por pertenencia o Área Revertida), por favor realizar nuevamente la busqueda haciendo click en 'Volver a buscar Área Minera'."); });

        this.verAreaMineraG = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida_g = 'TieneVisualizacion';
        break;
      }
      /*/case 'modificar': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        if (!this.formVistaServiciosRegistrados.valid) {
          this.formVistaServiciosRegistrados.markAllAsTouched();
          return;
        }
        vistaServiciosRegistrados = {
          ...this.formVistaServiciosRegistrados.value
        };
        this.accion.emit({
          accion,
          rolId: this.vistaServiciosRegistrados.id,
          vistaServiciosRegistrados
        });
        break;
      }*/
      case 'retornar_busqueda_area_minera': {
        this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');

        this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['tipo_area_minera_obenido'].setValue('');

        this.verPlandeTrabajo = 'TieneVisualizacion';
        this.verAreaMineraObtenida = 'NoTieneVisualizacion';
        break;
      }
      case 'retornar_busqueda_area_minera_sc_a': {
        this.formVistaServiciosRegistrados.controls['codigo_unico_sc_a'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_sc_a'].setValue('');

        this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_sc_a'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_sc_a'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_sc_a'].setValue('');
        this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_sc_a'].setValue('');
        this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_sc_a'].setValue('');

        this.verAreaMineraSc = 'TieneVisualizacion';
        this.verAreaMineraObtenida_sc_a = 'NoTieneVisualizacion';
        break;
      }
      case 'retornar_busqueda_area_minera_g': {
        this.formVistaServiciosRegistrados.controls['codigo_unico_g'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_g'].setValue('');

        this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_g'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_g'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_g'].setValue('');
        this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_g'].setValue('');
        this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_g'].setValue('');

        this.verAreaMineraG = 'TieneVisualizacion';
        this.verAreaMineraObtenida_g = 'NoTieneVisualizacion';
        break;
      }
      case 'cancelar_pn': {
        this.registra_operacion = 'PERSONA NATURAL';
        this.formVistaServiciosRegistrados.controls['sucursal_pn'].setValue('');
        this.formVistaServiciosRegistrados.controls['nombre_completo_pn'].setValue('');
        this.formVistaServiciosRegistrados.controls['numero_ci_pn'].setValue('');
        this.formVistaServiciosRegistrados.controls['expedido_pn'].setValue('');
        break;
      }
      case 'cancelar_apm': {
        this.registra_operacion = 'ACTOR PRODUCTIVO MINERO';
        this.formVistaServiciosRegistrados.controls['sucursal_apm'].setValue('');
        this.formVistaServiciosRegistrados.controls['denominacion_titular_apm'].setValue('');
        this.formVistaServiciosRegistrados.controls['nombre_completo_apm'].setValue('');
        this.formVistaServiciosRegistrados.controls['numero_ci_apm'].setValue('');
        this.formVistaServiciosRegistrados.controls['expedido_apm'].setValue('');
        break;
      }
      case 'cancelar_operacion': {
        this.router.navigate(['registro-menu']);
        break;
      }
    }
  }

  selectInput(e: any){
    let elemId: string = (e.target as Element).id;
    (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
    //this.onCheckboxChange(e, 'mantiene2');
  }

  onKeyUp(evento: any): void{ 
    this.costo_servicio_selec = 'Bs. ' + String(Number(this.costo_servicio.substring(4)) * Number(evento.target.value));
    this.costo_servicio_selec_numero = Number(this.costo_servicio.substring(4)) * Number(evento.target.value);
  }

  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
    this.dynamicSumaArray.splice(index, 1);
    /*******Suma de los totales de Servicios Seleccionados********/
    let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);

    if(sum === 0)
    {
      this.servicio_selec = false;
    }
    else
    {
      this.servicio_selec = true;
    }
  }

  agregarListaConAdicionales(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    if(this.verInformativo === 'TieneVisualizacion')
    {
      if(this.verCoordenadaUTM === 'TieneVisualizacion')
      {
        let zona:string = this.formVistaServiciosRegistrados.controls['zona'].value;
        let este:string = this.formVistaServiciosRegistrados.controls['este'].value;
        let norte:string = this.formVistaServiciosRegistrados.controls['norte'].value;
        let escala:string = this.formVistaServiciosRegistrados.controls['escala'].value;
        let orientacion:string = this.formVistaServiciosRegistrados.controls['orientacion'].value;
        if(zona === '' || zona === null)
        {
          alert("Debe seleccionar una zona.");
        }
        else if(este === '' || este === null)
        {
          alert("Debe registrar una coordenada Este.");
        }
        else if(norte === '' || norte === null)
        {
          alert("Debe registrar una coordenada Norte.");
        }
        else if(escala === '' || escala === null)
        {
          alert("Debe seleccionar una escala.");
        }
        else if(orientacion === '' || orientacion === null)
        {
          alert("Debe seleccionar una orientacion.");
        }
        else if(este.length < 6)
        {
          alert("Dege registrar 6 digitos para la coordenada este.");
        }
        else if(norte.length < 7)
        {
          alert("Dege registrar 7 digitos para la coordenada norte.");
        }
        else
        {
          this.registra_servicio = false;
          let requisitosSel = "Contar con coordenadas UTM y/o código de cuadrícula minera y/o código único., Fotocopia de carnet del solicitante.";
          let row = {Caso: "caso1", Tiene_Adicional: 'T', Datos_Adicionales: 'S/O', Informativo: 'Informativo', Zona: zona, Este: este, Norte: norte, Escala: escala, Orientacion: orientacion, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Tipo_Informativo: 'UTM'};
          this.dynamicArray.push(row);
          //this.registra_servicio = false;
          this.servicio_selec = true;
          //this.onCheckboxChange('elimina', 'elimina');
          this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
          /*******Suma de los totales de Servicios Seleccionados********/
          let sum = 0;
          for (let i = 0; i < this.dynamicSumaArray.length; i++) {
            sum += Number(this.dynamicSumaArray[i]);
          }
          this.dynamicTotalArray = [];
          this.dynamicTotalArray.push(sum);
          //this.dato1 = dato;
          this.verInformativo = 'NoTieneVisualizacion';
          this.verCoordenadaUTM = 'NoTieneVisualizacion';
          this.verCodigoCuadricula = 'NoTieneVisualizacion';
          this.verPlandeTrabajo = 'NoTieneVisualizacion';
          this.verAreaMineraObtenida = 'NoTieneVisualizacion';
          this.formVistaServiciosRegistrados.controls['zona'].setValue('');
          this.formVistaServiciosRegistrados.controls['este'].setValue('');
          this.formVistaServiciosRegistrados.controls['norte'].setValue('');
          this.formVistaServiciosRegistrados.controls['escala'].setValue('');
          this.formVistaServiciosRegistrados.controls['orientacion'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
          this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
          this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['tipo_area_minera_obenido'].setValue('');
          (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
        }
      }
      if(this.verCodigoCuadricula === 'TieneVisualizacion')
      {
        let codigo_cuadricula:string= this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].value;
        let escala_codigo_cuadricula:string = this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].value;
        let orientacion_codigo_cuadricula:string = this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].value;
        if(codigo_cuadricula === '' || codigo_cuadricula === null)
        {
          alert("Debe registrar un código de cuadricula");
        }
        else if(escala_codigo_cuadricula === '' || escala_codigo_cuadricula === null)
        {
          alert("Debe seleccionar una escala");
        }
        else if(orientacion_codigo_cuadricula === '' || orientacion_codigo_cuadricula === null)
        {
          alert("Debe seleccionar una orientacion");
        }
        else if(codigo_cuadricula.length < 11)
        {
          alert("Dege registrar 11 digitos para el código de cuadrícula.");
        }
        else
        {
          this.registra_servicio = false;
          let requisitosSel = "Contar con coordenadas UTM y/o código de cuadrícula minera y/o código único., Fotocopia de carnet del solicitante.";
          let row = {Caso: "caso1", Tiene_Adicional: 'T', Datos_Adicionales: 'S/O', Informativo: 'Informativo', Codigo_Cuadricula: codigo_cuadricula, Escala_Codigo_Cuadricula: escala_codigo_cuadricula, Orientacion_Codigo_Cuadricula: orientacion_codigo_cuadricula, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Tipo_Informativo: 'CODCUAD'};
          this.dynamicArray.push(row);
          //this.registra_servicio = false;
          this.servicio_selec = true;
          //this.onCheckboxChange('elimina', 'elimina');
          this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
          /*******Suma de los totales de Servicios Seleccionados********/
          let sum = 0;
          for (let i = 0; i < this.dynamicSumaArray.length; i++) {
            sum += Number(this.dynamicSumaArray[i]);
          }
          this.dynamicTotalArray = [];
          this.dynamicTotalArray.push(sum);
          //this.dato1 = dato;
          this.verInformativo = 'NoTieneVisualizacion';
          this.verCoordenadaUTM = 'NoTieneVisualizacion';
          this.verCodigoCuadricula = 'NoTieneVisualizacion';
          this.verPlandeTrabajo = 'NoTieneVisualizacion';
          this.verAreaMineraObtenida = 'NoTieneVisualizacion';
          this.formVistaServiciosRegistrados.controls['zona'].setValue('');
          this.formVistaServiciosRegistrados.controls['este'].setValue('');
          this.formVistaServiciosRegistrados.controls['norte'].setValue('');
          this.formVistaServiciosRegistrados.controls['escala'].setValue('');
          this.formVistaServiciosRegistrados.controls['orientacion'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
          this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
          this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
          this.formVistaServiciosRegistrados.controls['tipo_area_minera_obenido'].setValue('');
          (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
        }
      }
    }
    else
    {
      let denominacion_am = this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].value;
      let codigo_unico_am = this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].value;
      let padron_nacional_am = this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].value;
      let id_am = this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].value;
      let tipo_area_minera_am = this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido'].value;
      if(denominacion_am === '' || denominacion_am === null)
      {
        alert("Debe registrar un Área Minera Valida");
      }
      else if(codigo_unico_am === '' || codigo_unico_am === null)
      {
        alert("Debe registrar un Área Minera Valida");
      }
      else if(padron_nacional_am === '' || padron_nacional_am === null)
      {
        alert("Debe registrar un Área Minera Valida");
      }
      else if(id_am === '' || id_am === null)
      {
        alert("Debe registrar un Área Minera Valida");
      }
      else if(tipo_area_minera_am === '' || tipo_area_minera_am === null)
      {
        alert("Debe registrar un Área Minera Valida");
      }
      else
      {
        this.registra_servicio = false;
        let requisitosSel = "Contar con coordenadas UTM y/o código de cuadrícula minera y/o código único., Fotocopia de carnet del solicitante.";
        let row = {Caso: "caso3", Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Plan_Trabajo: 'Plan de Trabajo', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Denominacion_AM: denominacion_am, Codigo_Unico_AM: codigo_unico_am, Padron_Nacional_AM: padron_nacional_am, Id_AM: id_am, Tipo_Area_Minera_AM: tipo_area_minera_am, Tiene_AreaMinera: 'V'};
        this.dynamicArray.push(row);
        //this.registra_servicio = false;
        this.servicio_selec = true;
        //this.onCheckboxChange('elimina', 'elimina');
        this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
        /*******Suma de los totales de Servicios Seleccionados********/
        let sum = 0;
        for (let i = 0; i < this.dynamicSumaArray.length; i++) {
          sum += Number(this.dynamicSumaArray[i]);
        }
        this.dynamicTotalArray = [];
        this.dynamicTotalArray.push(sum);
        //this.dato1 = dato;
        this.verInformativo = 'NoTieneVisualizacion';
        this.verCoordenadaUTM = 'NoTieneVisualizacion';
        this.verCodigoCuadricula = 'NoTieneVisualizacion';
        this.verPlandeTrabajo = 'NoTieneVisualizacion';
        this.verAreaMineraObtenida = 'NoTieneVisualizacion';
        this.formVistaServiciosRegistrados.controls['zona'].setValue('');
        this.formVistaServiciosRegistrados.controls['este'].setValue('');
        this.formVistaServiciosRegistrados.controls['norte'].setValue('');
        this.formVistaServiciosRegistrados.controls['escala'].setValue('');
        this.formVistaServiciosRegistrados.controls['orientacion'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].setValue('');
        this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].setValue('');
        this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
        this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
        this.formVistaServiciosRegistrados.controls['tipo_area_minera_obenido'].setValue('');
        (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
      }
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  agregarListaGaceta1(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    //let edicion = this.formVistaServiciosRegistrados.controls['edicion'].value;
    let fecha_gaceta = this.formVistaServiciosRegistrados.controls['fecha_gaceta'].value;
    if(fecha_gaceta === '' || fecha_gaceta === null || fecha_gaceta === 'dd/mm/aaaa')
    {
      alert("Debe seleccionar la Fecha de la Gaceta");
    }
    else
    {
      this.registra_servicio = false;
      let row = {Tiene_Adicional: 'G', Datos_Adicionales: 'S/O', Fecha_Gaceta: fecha_gaceta, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Tipo_Gaceta: 'M-01'};
      this.dynamicArray.push(row);
      this.servicio_selec = true;
      //this.onCheckboxChange('elimina', 'elimina');
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
      //this.dato1 = dato;
      this.formVistaServiciosRegistrados.controls['fecha_gaceta'].setValue('');
      (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  agregarListaGaceta2(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    //let edicion = this.formVistaServiciosRegistrados.controls['edicion'].value;
    let departamento = this.formVistaServiciosRegistrados.controls['departamento_gaceta'].value;
    let tomo = this.formVistaServiciosRegistrados.controls['tomo'].value;
    let fecha_gaceta = this.formVistaServiciosRegistrados.controls['fecha_gaceta'].value;
    let denominacion_am_gaceta = this.formVistaServiciosRegistrados.controls['denominacion_area_minera_gaceta'].value;
    if(departamento === '' || departamento === null)
    {
      alert("Debe registrar el Departamento");
    }
    else if(tomo === '' || tomo === null)
    {
      alert("Debe registrar el tomo de la Gaceta");
    }
    else if(fecha_gaceta === '' || fecha_gaceta === null || fecha_gaceta === 'dd/mm/aaaa')
    {
      alert("Debe seleccionar la Fecha de la Gaceta");
    }
    else if(denominacion_am_gaceta === '' || denominacion_am_gaceta === null)
    {
      alert("Debe registrar la Denominacion del Área Minera");
    }
    else
    {
      this.registra_servicio = false;
      let row = {Tiene_Adicional: 'G', Datos_Adicionales: 'S/O', Departamento_Gaceta: departamento,  Tomo: tomo, Fecha_Gaceta: fecha_gaceta, Denominacion_Am_Gaceta: denominacion_am_gaceta, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Tipo_Gaceta: 'M-02'};
      this.dynamicArray.push(row);
      this.servicio_selec = true;
      //this.onCheckboxChange('elimina', 'elimina');
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
      //this.dato1 = dato;
      this.formVistaServiciosRegistrados.controls['departamento_gaceta'].setValue('');
      this.formVistaServiciosRegistrados.controls['tomo'].setValue('');
      this.formVistaServiciosRegistrados.controls['fecha_gaceta'].setValue('');
      this.formVistaServiciosRegistrados.controls['denominacion_area_minera_gaceta'].setValue('');
      (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  // agregarListaSinAdicionalesA(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
  //   this.registra_servicio = false;

  //   let requisitosSel = "";

  //   if(this.verInformativo === 'TieneVisualizacion')
  //   {
  //       let denominacion_am = this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].value;
  //       let codigo_unico_am = this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].value;
  //       let padron_nacional_am = this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].value;
  //       let id_am = this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].value;
  //       let tipo_area_minera_am = this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido'].value;
  //       let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Denominacion_AM: denominacion_am, Codigo_Unico_AM: codigo_unico_am, Padron_Nacional_AM: padron_nacional_am, Id_AM: id_am, Tipo_Area_Minera_AM: tipo_area_minera_am, Tiene_AreaMinera: 'V'};
  //       this.dynamicArray.push(row);
  //   }
  //   else
  //   {
  //     let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F'};
  //     this.dynamicArray.push(row);
  //   }

  //   this.servicio_selec = true;
  //   //this.onCheckboxChange('elimina', 'elimina');
  //   this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
  //   /*******Suma de los totales de Servicios Seleccionados********/
  //   let sum = 0;
  //   for (let i = 0; i < this.dynamicSumaArray.length; i++) {
  //     sum += Number(this.dynamicSumaArray[i]);
  //   }
  //   this.dynamicTotalArray = [];
  //   this.dynamicTotalArray.push(sum);
  //   //this.dato1 = dato;
  // }

  agregarListaSinAdicionalesB(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    this.registra_servicio = false;
    let requisitosSel = "";
    requisitosSel = "Fotocopia del estado de cuenta o proforma de pago correspondiente o fotocopia del formulario de pago de Patente Minera (Correspondiente a la última gestión)., Fotocopia del C.I. del titular, representante legal o apoderado., Fotocopia del testimonio de poder (si corresponde).";
    let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F'};
    this.dynamicArray.push(row);
    this.servicio_selec = true;
    //this.onCheckboxChange('elimina', 'elimina');
    this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
    /*******Suma de los totales de Servicios Seleccionados********/
    let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
    //this.dato1 = dato;
  }

  agregarListaSinAdicionalesC(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    let denominacion_am_g = this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_g'].value;
    let codigo_unico_am_g = this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_g'].value;
    let padron_nacional_am_g = this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_g'].value;
    let id_am_g = this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_g'].value;
    let tipo_area_minera_am_g = this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_g'].value;
    if(denominacion_am_g === '' || denominacion_am_g === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(codigo_unico_am_g === '' || codigo_unico_am_g === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(padron_nacional_am_g === '' || padron_nacional_am_g === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(id_am_g === '' || id_am_g === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(tipo_area_minera_am_g === '' || tipo_area_minera_am_g === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else
    {
      this.registra_servicio = false;
      let requisitosSel = "Fotocopia del Certificado de Trámite de Adecuación., Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión)., Fotocopia de C.I. del titular, representante legal o apoderado., Fotocopia del Testimonio de Poder (si corresponde).";
      let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Plan_Trabajo: 'No', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Denominacion_AM: denominacion_am_g, Codigo_Unico_AM: codigo_unico_am_g, Padron_Nacional_AM: padron_nacional_am_g, Id_AM: id_am_g, Tipo_Area_Minera_AM: tipo_area_minera_am_g, Tiene_AreaMinera: 'V'};
      this.dynamicArray.push(row);

      this.servicio_selec = true;
      //this.onCheckboxChange('elimina', 'elimina');
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
      //this.dato1 = dato;
      this.verAreaMineraObtenida_g = 'NoTieneVisualizacion';
      this.verAreaMineraG = 'TieneVisualizacion';
      this.formVistaServiciosRegistrados.controls['codigo_unico_g'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_g'].setValue('');

      this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_g'].setValue('');
      this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_g'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_g'].setValue('');
      this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_g'].setValue('');
      this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_g'].setValue('');
      (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  agregarListaSinAdicionalesD(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    let denominacion_am_sc_a = this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_sc_a'].value;
    let codigo_unico_am_sc_a = this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_sc_a'].value;
    let padron_nacional_am_sc_a = this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_sc_a'].value;
    let id_am_sc_a = this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_sc_a'].value;
    let tipo_area_minera_am_sc_a = this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_sc_a'].value;
    if(denominacion_am_sc_a === '' || denominacion_am_sc_a === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(codigo_unico_am_sc_a === '' || codigo_unico_am_sc_a === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(padron_nacional_am_sc_a === '' || padron_nacional_am_sc_a === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(id_am_sc_a === '' || id_am_sc_a === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else if(tipo_area_minera_am_sc_a === '' || tipo_area_minera_am_sc_a === null)
    {
      alert("Debe registrar un Área Minera Valida");
    }
    else
    {
      this.registra_servicio = false;
      let requisitosSel = "Fotocopia del Certificado de Inscripción en Registro Minero y/o Fotocopia del Certificado de Trámite de Adecuación., Fotocopia del Certificado de Constancia de Pago de Patente Minera (Correspondiente a la última gestión)., Fotocopia de C.I. del titular, representante Legal o apoderado., Fotocopia del Testimonio de Poder (si corresponde).";
      let row = {Caso: "caso6", Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Plan_Trabajo: 'No', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Denominacion_AM: denominacion_am_sc_a, Codigo_Unico_AM: codigo_unico_am_sc_a, Padron_Nacional_AM: padron_nacional_am_sc_a, Id_AM: id_am_sc_a , Tipo_Area_Minera_AM: tipo_area_minera_am_sc_a ,Tiene_AreaMinera: 'V'};
      this.dynamicArray.push(row);
      this.servicio_selec = true;
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
      //this.dato1 = dato;
      this.verAreaMineraObtenida_sc_a = 'NoTieneVisualizacion';
      this.verAreaMineraSc = 'TieneVisualizacion';
      this.formVistaServiciosRegistrados.controls['codigo_unico_sc_a'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_sc_a'].setValue('');

      this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido_sc_a'].setValue('');
      this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido_sc_a'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido_sc_a'].setValue('');
      this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido_sc_a'].setValue('');
      this.formVistaServiciosRegistrados.controls['tipo_area_minera_obtenido_sc_a'].setValue('');
      (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  sumatoriaTotal(costo: any){
    let total = 0;
    total += Number(costo);
    return total;
  }

  onChangeSeleccion(value: any) { 
    if (value.target.value !== '') {
      this.serviciosFacade.obtenerPorId(value.target.value).then((respuesta) => {
        this.costo_servicio_numero_n = Math.trunc(respuesta.objeto.costo);
        this.costo_servicio_selec_numero = Math.trunc(respuesta.objeto.costo);
        this.serviciosSeleccionado = respuesta.objeto;
        this.registra_servicio = true;
        this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
        this.grupo_servicio = respuesta.objeto.grupo;
        this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
        this.codigo_servicio = respuesta.objeto.codigo;
        this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
        this.id_servicio = respuesta.objeto.id;
      });
    } else {
      this.serviciosSeleccionado = null;
    } 
  } 
  /*onCheckboxChange(e: any, tipo: string) {
    const checkArray: FormArray = this.formVistaServiciosRegistrados.get('checkArray') as FormArray;
    if(tipo === 'elimina'){
       checkArray.clear();
    }
    else{
      if (e.target.checked) {
        checkArray.push(new FormControl(e.target.value));
        this.requisitos = checkArray.value;
      } 
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        });
        this.requisitos = checkArray.value;
      }
    }
  }*/

  resetInput(e: any){
    let elementId: string = (e.target as Element).id;
    (<HTMLInputElement>document.getElementById(elementId + '-src-file')).value = '';
    (<HTMLInputElement>document.getElementById(elementId + '-src-file-chk')).checked = false;
    //this.onCheckboxChange(e, 'elimina2');
  }

  cambioNumero(e: any){
    var dato_numero = Number(e);
    return dato_numero;
  }

  cambioEstadoA1(event:Event){
    const checkedTieneAreaMinera = (<HTMLInputElement>event.target).checked;
    if(checkedTieneAreaMinera)
    {
      this.verPlandeTrabajo = 'NoTieneVisualizacion';
      this.verInformativo = 'TieneVisualizacion';
      this.verAreaMineraObtenida = 'NoTieneVisualizacion';
      (<HTMLInputElement>document.getElementById('cbox_tiene_a2')).checked = false;

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
    }
    else
    {
      this.verPlandeTrabajo = 'NoTieneVisualizacion';
      this.verInformativo = 'NoTieneVisualizacion';
      this.verAreaMineraObtenida = 'NoTieneVisualizacion';
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');

    }
  }

  cambioEstadoA2(event:Event){
    const checkedTieneAreaMinera = (<HTMLInputElement>event.target).checked;
    if(checkedTieneAreaMinera)
    {
      this.verPlandeTrabajo = 'TieneVisualizacion';
      this.verInformativo = 'NoTieneVisualizacion';
      this.verAreaMineraObtenida = 'NoTieneVisualizacion';
      (<HTMLInputElement>document.getElementById('cbox_tiene_a1')).checked = false;
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
    }
    else
    {
      this.verPlandeTrabajo = 'NoTieneVisualizacion';
      this.verInformativo = 'NoTieneVisualizacion';
      this.verAreaMineraObtenida = 'NoTieneVisualizacion';
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');
    }
  }

  cambioEstadoB1(event:Event){
    const checkedTieneAreaMinera = (<HTMLInputElement>event.target).checked;
    if(checkedTieneAreaMinera)
    {
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'TieneVisualizacion';
      (<HTMLInputElement>document.getElementById('cbox_tiene_b2')).checked = false;

      this.formVistaServiciosRegistrados.controls['zona'].setValue('');
      this.formVistaServiciosRegistrados.controls['este'].setValue('');
      this.formVistaServiciosRegistrados.controls['norte'].setValue('');
      this.formVistaServiciosRegistrados.controls['escala'].setValue('');
      this.formVistaServiciosRegistrados.controls['orientacion'].setValue('');

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');

      this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
    }
    else
    {
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';

      this.formVistaServiciosRegistrados.controls['zona'].setValue('');
      this.formVistaServiciosRegistrados.controls['este'].setValue('');
      this.formVistaServiciosRegistrados.controls['norte'].setValue('');
      this.formVistaServiciosRegistrados.controls['escala'].setValue('');
      this.formVistaServiciosRegistrados.controls['orientacion'].setValue('');

      this.formVistaServiciosRegistrados.controls['codigo_unico'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional'].setValue('');

      this.formVistaServiciosRegistrados.controls['denominacion_area_minera_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['codigo_unico_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['padron_nacional_obtenido'].setValue('');
      this.formVistaServiciosRegistrados.controls['id_area_minera_obtenido'].setValue('');
    }
  }

  cambioEstadoB2(event:Event){
    const checkedTieneAreaMinera = (<HTMLInputElement>event.target).checked;
    if(checkedTieneAreaMinera)
    {
      this.verCodigoCuadricula = 'TieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';
      (<HTMLInputElement>document.getElementById('cbox_tiene_b1')).checked = false;

      this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].setValue('');
      this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].setValue('');
      this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].setValue('');
    }
    else
    {
      this.verCodigoCuadricula = 'NoTieneVisualizacion';
      this.verCoordenadaUTM = 'NoTieneVisualizacion';

      this.formVistaServiciosRegistrados.controls['codigo_cuadricula'].setValue('');
      this.formVistaServiciosRegistrados.controls['escala_codigo_cuadricula'].setValue('');
      this.formVistaServiciosRegistrados.controls['orientacion_codigo_cuadricula'].setValue('');
    }
  }

  onChange(event) {
    this.counter = this.counter + 1; 
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }

  mostrarModal(opciones?: any): void {
    this.modal = this.modalService.open(this.modalRegistro, {
      backdrop: 'static',
      size: 'lg',
      //...opciones
    });
  }
  
  cerrarModal(): void {
    this.modal.close();
  }
}
