<ng-container *ngIf="modoVisualizacion === 'ESQUEMA'">
    <app-plantilla-menu-contextual (operacion)="ejecutarOperacion($event)" (accion)="ejecutarAccion($event)">
        <div class="row">
            <div class="col-12">
                <span class="small d-block">Adj.:
                    <ng-container *ngFor="let adjunto of datosPresentacion.adjuntos">
                        <span class="d-block ml-3">{{ adjunto.nombre }}</span>
                    </ng-container>
                </span>
            </div>
        </div>
    </app-plantilla-menu-contextual>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'PRESENTACION'">
    <div class="row">
        <div class="col-12">
            <span class="small d-block">Adj.:
                <ng-container *ngFor="let adjunto of datosPresentacion.adjuntos">
                    <span class="d-block ml-3">{{ adjunto.nombre }}</span>
                </ng-container>
            </span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'REGISTRO'">
    <div *ngIf="datos.adjuntos.length > 0" class="row">
        <div class="col-12">
            <span class="small d-block">Adj.:
                <ng-container *ngFor="let adjunto of datos.adjuntos">
                    <span class="d-block ml-3">{{ adjunto.nombre }}</span>
                </ng-container>
            </span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modoVisualizacion === 'DOCUMENTO'">
    <div *ngIf="datos.adjuntos.length > 0" class="row">
        <div class="col-12">
            <span class="small d-block">Adj.:
                <ng-container *ngFor="let adjunto of datos.adjuntos">
                    <span class="d-block ml-3">{{ adjunto.nombre }}</span>
                </ng-container>
            </span>
        </div>
    </div>
</ng-container>

<ng-template #modalAdjuntos let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Adjuntos - Configuración de bloque</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form autocomplete="off" class="text-default">
                    <h3>Adjuntos</h3>
                    <div class="form-row justify-content-end">
                        <div class="form-group">
                            <button (click)="ejecutarAccion({accion: 'aplicar-configuracion'})" type="submit"
                                class="btn btn-primary mr-1" title="Aplicar">
                                Aplicar
                            </button>
                            <button (click)="ejecutarOperacion({operacion: 'cancelar'})" type="button"
                                class="btn btn-dark" title="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados
                                con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>