import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { OrdenServicioFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroOrdenServicio(state: AppState): OrdenServicioFilter {
  return state.correspondencia.listaOrdenServicio?.filtro;
}

function seleccionarPaginadoOrdenServicio(state: AppState): Paginado {
  return state.correspondencia.listaOrdenServicio?.paginado;
}

export const filtroOrdenServicioSelector = createSelector(
  obtenerState,
  seleccionarFiltroOrdenServicio
);
export const paginadoOrdenServicioSelector = createSelector(
  obtenerState,
  seleccionarPaginadoOrdenServicio
);
