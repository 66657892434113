<div class="container-fluid">
    <div class="row">
        <div class="col-md-8" style="height: 100%; overflow-y: scroll;">
            <app-correspondencia-contenido-formulario #contenido *ngIf="!documento?.tieneDocumentoAdjunto" [modoVisualizacion]="modoVisualizacion"
                [estado]="documento?.esBorrador ? 'BORRADOR' : ''"></app-correspondencia-contenido-formulario>
            <app-correspondencia-documento-vista-previa *ngIf="documento?.tieneDocumentoAdjunto" [estado]="documento?.esBorrador ? 'BORRADOR' : ''"></app-correspondencia-documento-vista-previa>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <div class="float-left">
                        <a href="" data-target="#sidebar" data-toggle="collapse"><i class="fa fa-bars"></i></a>
                    </div>
                    <div class="text-right">
                        <button [routerLink]="['/', 'correspondencia', 'bandeja-borradores']" type="button"
                            class="btn btn-dark" title="Volver">
                            Volver
                        </button>
                    </div>
                </div>
                <div class="card-body collapse show" id="sidebar">
                    <app-correspondencia-documento-enviar (accion)="ejecutarAccion($event)" [redireccion]="['/', 'correspondencia', 'bandeja-borradores']"></app-correspondencia-documento-enviar>
                    <hr>
                    <h3>Anexos</h3>
                    <app-correspondencia-documento-anexo (accion)="ejecutarAccion($event)"></app-correspondencia-documento-anexo>
                    <hr>
                    <h3>Documentos en esta hoja de ruta</h3>
                    <app-correspondencia-hoja-ruta-detalle></app-correspondencia-hoja-ruta-detalle>
                    <hr>
                    <h3>Seguimiento del documento</h3>
                    <app-correspondencia-documento-seguimiento></app-correspondencia-documento-seguimiento>
                    <hr>
                    <h3>Reasignación de hoja de ruta</h3>
                    <app-correspondencia-hoja-ruta-vincular></app-correspondencia-hoja-ruta-vincular>
                </div>
            </div>
        </div>
    </div>
</div>