import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutenticacionGuard } from './seguridad/guardias';
import {
  AutenticacionComponent,
  AutenticacionLdapComponent,
  ContenedorComponent,
  NoEncontradoComponent,
  PerfilComponent,
  RegistrarseComponent,
  TerminosComponent,
  RegistroApmComponent,
  MenuLateralComponent,
  RegistroServicioComponent,
  RegistroTerminadoComponent,
  GeneracionCptComponent,
  RegistroMenuComponent,
  RegistroGuiaComponent,
  RegistroFinComponent
} from './modulos/admin-lte3/componentes';
import {
  VistaServiciosRegistradosFormularioComponent
} from 'src/app/modulos/correspondencia/componentes/vista-servicios-registrados';

const routes: Routes = [
  //{ path: 'registro-apm', component: RegistroApmComponent },
  { path: 'registro-menu', component: RegistroMenuComponent },
  { path: "registro-servicio", component: RegistroServicioComponent },
  { path: 'registro-terminado', component: RegistroTerminadoComponent },
  { path: 'registro-guia', component: RegistroGuiaComponent },
  { path: 'generacion-cpt/:tramite', component: GeneracionCptComponent },
  { path: 'registro-fin', component: RegistroFinComponent },
  { path: 'autenticacion', component: AutenticacionComponent },
  { path: 'autenticacion/ldap', component: AutenticacionLdapComponent },
  { path: 'registrarse', component: RegistrarseComponent },
  { path: 'terminos-y-condiciones', component: TerminosComponent },
  {
    path: '',
    component: ContenedorComponent,
    canActivate: [AutenticacionGuard],
    children: [
      {
        path: 'perfil',
        component: PerfilComponent,
        data: {
          title: 'Perfil',
          urls: [{ title: 'Perfil', url: '/perfil' }]
        }
      }
    ]
  },
  {
    path: '',
    loadChildren: () =>
      import('./modulos/correspondencia/correspondencia.module').then(
        (m) => m.CorrespondenciaModule
      )
  },
  { path: '**', component: NoEncontradoComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
