<h5 class="text-muted m-0">{{ documento?.tipoDocumentoNombre }}</h5>
<h2 class="m-0">{{ documento?.cite }}</h2>
<h4 class="m-0">H.R. {{ documento?.hojaRutaNumero }}</h4>
<div class="form-row justify-content-end">
    <div class="form-group">
        <ng-container *ngIf="documento?.tieneContenido && !documentoAdjunto">
            <button (click)="ejecutarAccion({accion: 'recibir', id: documento?.id})" type="button"
                class="btn btn-warning mr-1" title="Recibir">
                Recibir
            </button>
        </ng-container>
    </div>
</div>
