<style type="text/css">
  /*stylo CCCS 15/06/2016*/
  .tabla_servicios {
      border-collapse: collapse;
      width: 100%;
  }

  .tabla_servicios td, .tabla_servicios th {
      border: 1px solid black;
      padding: 8px;
  }

  .tabla_servicios_botones {
      margin: 0px auto;
      border-collapse: collapse;
  }

  .tabla_servicios_botones td, .tabla_servicios_botones th {
      border: 0px solid black;
      padding: 8px;
  }
  .tabla_servicios_total {
      margin: 0px auto;
      border-collapse: collapse;
  }

  .tabla_servicios_total td, .tabla_servicios_total th {
      border: 1px solid #ddd;
      padding: 8px;
  }
  .input_boleta{
      margin-left:10px;
      float:left;
      width:150px;
  }
  .input_depositante{
      text-transform:uppercase;
      width:800px;
  }
  .light{
      color: white;
      font-weight: bolder;
  }
  .modal-body {
      height: 287px;
  }
  /*Fin stylo CCCS 15/06/2016*/
  .box.box-black>.box-title
  {
      background: rgb(4,3,23);
      background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(24,203,203,1) 100%, rgba(13,159,159,1) 100%);
      font-family: Times New Roman;
      font-size: 20px;
      letter-spacing: 0px;
      word-spacing: 0px;
      font-weight: bold;
      text-decoration: none;
      font-style: oblique;
      font-variant: normal;
      text-transform: none;
      color:white;
  }
  /*Estilo para div lado a lado*/
  #div1{
          width: 50%;
          float: left;
          position: relative;
  }
  #div2{
          width: 50%;
          float: right;
          position: relative;
  }
  table thead {
      color: #fff;
      background-color: rgb(44, 62, 88);
  }

  #div4{
      width: 40%;
  }
  #div5{
      width: 100%;
      float: left;
      position: relative;
  }
  #div6{
      width: 40%;
      float: right;
      position: relative;
  }
</style>

<div class="page-service" style="background-color: #212529; height: 1000px;">
  <div class="card card-outline card-primary">
      <div class="card-header text-center">
          <img class="img-fluid" src="assets/images/logo_AJAM.png" alt="logo">
      </div>
      <div class="card-header p-4 text-center">
          <div class="box box-black">
              <div class="box-title" style="height:150px; align-items: center; display: flex; justify-content: center;">
                  <h2><b>Se genero el medio de pago exitosamente</b></h2>
              </div>
          </div>
      </div>
      <div class="card-body" style="width:1250px;">
        <input type="button" class="btn btn-info" value="Salir de Pantalla" onclick="cerrarModal()"/>
      </div>
  </div>
</div>