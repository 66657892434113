import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import {
  Codificador,
  Paginado,
  Respuesta,
  RespuestaLista,
  RespuestaObjeto
} from '../../../comun/modelos';
import { ComunState } from '../../../comun/estados';
import * as ComunAcciones from '../../../comun/estados';

import { CorrespondenciaState } from '../estados';
import * as CorrespondenciaAcciones from '../estados/acciones';
import {
  filtroVistaServiciosRegistradosSelector,
  paginadoVistaServiciosRegistradosSelector
} from '../estados/selectores';
import { OrdenServicio, VistaServiciosRegistrados } from '../modelos';
import { VistaServiciosRegistradosFilter } from '../modelos/filtros';
import { VistaServiciosRegistradosService } from '../servicios';

import { ClasificacionService } from '../servicios';
import { NgLabelTemplateDirective } from '@ng-select/ng-select';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class VistaServiciosRegistradosFacade {
  filtro: VistaServiciosRegistradosFilter =
    new VistaServiciosRegistradosFilter();
  paginado: Paginado;

  get ComunState$(): Observable<ComunState> {
    return this.store.select('comun');
  }

  get CorrespondenciaState$(): Observable<CorrespondenciaState> {
    return this.store.select('correspondencia');
  }

  get Filtro$(): Observable<VistaServiciosRegistradosFilter> {
    return this.store.select(filtroVistaServiciosRegistradosSelector);
  }

  constructor(
    private store: Store<AppState>,
    private vistaServiciosRegistradosService: VistaServiciosRegistradosService,
    private clasificacionService: ClasificacionService
  ) {
    this.store
      .select(filtroVistaServiciosRegistradosSelector)
      .subscribe((filtro) => {
        if (filtro != null) {
          this.filtro = filtro;
        }
      });
    this.store
      .select(paginadoVistaServiciosRegistradosSelector)
      .subscribe((paginado) => {
        if (paginado != null) {
          this.paginado = paginado;
        }
      });
  }

  establecerFiltro(filtro: VistaServiciosRegistradosFilter): void {
    this.store.dispatch(
      CorrespondenciaAcciones.establecerFiltroVistaServiciosRegistrados({
        filtro: { ...filtro }
      })
    );
  }

  buscar(
    objeto: VistaServiciosRegistradosFilter,
    pagina: number,
    cantidad: number
  ): Promise<RespuestaLista<VistaServiciosRegistrados>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaServiciosRegistradosService
        .buscar(objeto, pagina, cantidad)
        .subscribe((respuesta: RespuestaLista<VistaServiciosRegistrados>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaVistaServiciosRegistrados({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerPorId(
    id: number
  ): Promise<RespuestaObjeto<VistaServiciosRegistrados>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaServiciosRegistradosService
        .obtenerPorId(id)
        .subscribe((respuesta: RespuestaObjeto<VistaServiciosRegistrados>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaServiciosRegistrados({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  guardar(
    objeto: VistaServiciosRegistrados,
    objeto_os: OrdenServicio[] = []
  ): Promise<RespuestaObjeto<VistaServiciosRegistrados>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    //objeto.nfactura = '1235645'
    //objeto.num_boleta1 = 1;
    //objeto.fecha_depos1 = new Date();
    //objeto.monto_depos1 = 260;
    //objeto.depos1 = 'dd';
    
    //console.log(objeto);

    //console.log(objeto_os);

    this.imprimir_proforma(objeto, objeto_os);

    return new Promise((resolve) => {
      this.vistaServiciosRegistradosService
        .guardar_os(objeto, objeto_os)
        .subscribe((respuesta: RespuestaObjeto<VistaServiciosRegistrados>) => {
          this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  validar(
    objeto: VistaServiciosRegistrados
  ): Promise<RespuestaObjeto<VistaServiciosRegistrados>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    
    return new Promise((resolve) => {
      this.vistaServiciosRegistradosService
        .validar(objeto)
        .subscribe((respuesta: RespuestaObjeto<VistaServiciosRegistrados>) => {
          if (respuesta.tipoRespuesta === 'Exito') {
            if (this.paginado) {
              this.buscar(
                this.filtro,
                this.paginado.pagina,
                this.paginado.registrosPorPagina
              );
            }
          }
          this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  imprimir_proforma(    
    objeto: VistaServiciosRegistrados,
    objeto_os: OrdenServicio[] = []
  ){
    const doc = new jsPDF({      
        orientation: 'portrait',
        unit: 'px',
        format: 'letter'
     });

    /*this.vistaServiciosRegistradosFacade.obtenerPorId(evento.id).then((respuesta) => {
      this.v_correlativo_servicio = respuesta.objeto.correlativo_servicio;
    });

    const c_correlativo_servicio = this.v_correlativo_servicio.toString();*/
    // this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
    //   ({ listaVistaServiciosRegistrados }) => {
    //     if (listaVistaServiciosRegistrados.lista) {
    //       if (listaVistaServiciosRegistrados.lista.length >= 0) {
    //         this.lista = listaVistaServiciosRegistrados.lista;
    //       }
    //     }
    //   }
    // );

    // const c_correlativo_servicio = this.lista.find(x => x.id == 10127).correlativo_servicio;

    // const c_remitente = this.lista.find(x => x.id == 10127).nombre_solicitante;
    // const c_telefono = '77754212';
    // const c_representante = this.lista.find(x => x.id == 10127).representa;

    // const solicitante_servicio_filter = new SolicitanteOrdenServicioFilter();
    // solicitante_servicio_filter.correlativo_servicio = c_correlativo_servicio;
    // this.solicitanteServicioFacade.buscar(solicitante_servicio_filter, 1, 1).then((respuesta) => {
    //   this.listaSOS = respuesta.lista;
    // });

    // const c_razon_social = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).razon_social;
    // const c_nit = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).nit;
    // const c_n_factura = this.listaSOS.find(x => x.correlativo_servicio == c_correlativo_servicio).n_factura;

    var fondo = new Image();
    fondo.src = 'assets/images/plantilla-02.png';
    doc.addImage(fondo, 'PNG', 0, 0, 460, 500);

    //let qrCodeData:string = 'Hola';

    QRCode.toDataURL('Autoridad Jurisdiccional Administrativa Minera', (err, qrCodeDataURL) => {
      if (err) {
        console.error(err);
        return;
      };
    
    doc.addImage(qrCodeDataURL, 'JPEG', 392, 8.2, 55, 55); // Ajusta las coordenadas y el tamaño

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 120},
        1: {cellWidth: 300}
      },
      body: [
        [
          {
            content: 'FECHA Y HORA: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: String(objeto.fecha),
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: 86
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 120},
        1: {cellWidth: 300}
      },
      body: [
        [
          {
            content: 'DEPARTAMENTAL O REGIONAL DONDE SE ENTREGARÁ EL SERVICIO SOLICITADO: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.sucursal,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 1
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      body: [
        [
          {
            content: 'DATOS PERSONALES DEL SOLICITANTE',
            styles: {
              halign: 'center',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#595B61'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 14
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 80},
        1: {cellWidth: 110},
        2: {cellWidth: 80},
        3: {cellWidth: 90},
      },
      body: [
        [
          {
            content: 'SOLICITANTE: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.nombre_completo,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          },
          {
            content: 'CI:',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.numero_ci,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 1
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 80},
        1: {cellWidth: 110},
        2: {cellWidth: 80},
        3: {cellWidth: 90},
      },
      body: [
        [
          {
            content: 'EXPEDIDO: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.expedido,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          },
          {
            content: 'TELF/CEL:',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.telefono,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 1
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 80},
        1: {cellWidth: 110},
        2: {cellWidth: 80},
        3: {cellWidth: 90},
      },
      body: [
        [
          {
            content: 'CORREO ELECTRÓNICO: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.correo_electronico,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          },
          {
            content: 'DIRECCION:',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.direccion,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 1
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 80},
        1: {cellWidth: 110},
        2: {cellWidth: 80},
        3: {cellWidth: 90},
      },
      body: [
        [
          {
            content: 'REPRESENTANTE: ',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.representa,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          },
          {
            content: 'TIPO DE ACTOR:',
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#489BAC'
            }
          },
          {
            content: objeto.tipo_actor_minero,
            styles: {
              halign: 'left',
              fontSize: 8,
              textColor: '#000000',
              font: 'times'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 1
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      body: [
        [
          {
            content: 'CUADRO DE SERVICIOS SOLICITADOS',
            styles: {
              halign: 'center',
              fontSize: 8,
              textColor: '#ffffff',
              fontStyle: 'bold',
              font: 'times',
              fillColor: '#595B61'
            }
          }
        ],
      ],
      theme: 'plain',
      startY: (doc as any).lastAutoTable.finalY + 14
    });

    autoTable(doc, {
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.30,
      columnStyles: {
        0: {cellWidth: 110},
        1: {cellWidth: 24},
        2: {cellWidth: 24},
        3: {cellWidth: 24}
      },
        head: [
          [
            {         
              content: '                                            SERVICIO                                          ',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: 'Precio Unitario \n(Bs.)',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: 'Cantidad',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: 'Sub Total \n(Bs.)',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            }
          ],
        ],
        theme: 'striped',
        headStyles:{
          fillColor: '#ddb340'
        },
        startY: (doc as any).lastAutoTable.finalY + 1
      });

      for(let i = 0 ; i < Object.keys(objeto_os).length ; i++){

      var str = objeto_os[i].descripcion_servicio;
      var splitted = str.split('- Grupo:');
        
      if(objeto_os[i].fk_servicio === 1 || objeto_os[i].fk_servicio === 2 || objeto_os[i].fk_servicio === 3 || objeto_os[i].fk_servicio === 4 || objeto_os[i].fk_servicio === 5 || objeto_os[i].fk_servicio === 6 || objeto_os[i].fk_servicio === 7 || objeto_os[i].fk_servicio === 8 || objeto_os[i].fk_servicio === 9 || objeto_os[i].fk_servicio === 10)
      {
        if(String(objeto_os[i].informativo) === 'Informativo')
        {
          if(objeto_os[i].coordenadas_utm === true)
          {
            autoTable(doc, {
              tableLineColor: [0, 0, 0],
              tableLineWidth: 0.30,
              columnStyles: {
                0: {cellWidth: 250},
                1: {cellWidth: 50},
                2: {cellWidth: 50},
                3: {cellWidth: 50}
              },
                body: [  
                [
                  {
                    content: splitted[0] + ' | Informativo: SI' + ' | Coordenatas UTM: SI' + ' | Zona: ' + objeto_os[i].zona + ' | coordenada Este: ' + objeto_os[i].este + ' | coordenada Norte: ' + objeto_os[i].norte + ' | Escala: ' + objeto_os[i].escala + ' | Orientación Hoja: ' + objeto_os[i].orientacion_hoja + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                    styles: {
                      halign: 'center',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].costo_unitario,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].cantidad,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].subtotal,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  }
                ]
                ],
                theme: 'striped',
                headStyles:{
                  fillColor: '#ddb340'
                },
                startY: (doc as any).lastAutoTable.finalY + 0
              });
          }
          else
          {
            autoTable(doc, {
              tableLineColor: [0, 0, 0],
              tableLineWidth: 0.30,
              columnStyles: {
                0: {cellWidth: 250},
                1: {cellWidth: 50},
                2: {cellWidth: 50},
                3: {cellWidth: 50}
              },
                body: [  
                [
                  {
                    content: splitted[0] + ' | Informativo: SI' + ' | Código de Cuadrícula: SI' + ' | Código de Cuadrícula: ' + objeto_os[i].codigo_cuadricula + ' | Escala: ' + objeto_os[i].escala + ' | Orientación Hoja: ' + objeto_os[i].orientacion_hoja + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                    styles: {
                      halign: 'center',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].costo_unitario,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].cantidad,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  },
                  {
                    content: objeto_os[i].subtotal,
                    styles: {
                      halign: 'right',
                      fontSize: 7,
                      textColor: '#000000',
                      font: 'times'
                    }
                  }
                ]
                ],
                theme: 'striped',
                headStyles:{
                  fillColor: '#ddb340'
                },
                startY: (doc as any).lastAutoTable.finalY + 0
              });
          }
        }
        else
        {
          autoTable(doc, {
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.30,
            columnStyles: {
              0: {cellWidth: 250},
              1: {cellWidth: 50},
              2: {cellWidth: 50},
              3: {cellWidth: 50}
            },
              body: [  
              [
                {
                  content: splitted[0] + ' | Plan de Trabajo: SI'  + ' | Denominación del Área Minera: ' + objeto_os[i].descripcion_area_minera + ' | Tipo Área Minera: ' + objeto_os[i].tipo_area_minera + ' | Código Único: ' + objeto_os[i].codigo_unico + ' | Padron Nacional: ' + objeto_os[i].padron_nacional + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                  styles: {
                    halign: 'center',
                    fontSize: 7,
                    textColor: '#000000',
                    font: 'times'
                  }
                },
                {
                  content: objeto_os[i].costo_unitario,
                  styles: {
                    halign: 'right',
                    fontSize: 7,
                    textColor: '#000000',
                    font: 'times'
                  }
                },
                {
                  content: objeto_os[i].cantidad,
                  styles: {
                    halign: 'right',
                    fontSize: 7,
                    textColor: '#000000',
                    font: 'times'
                  }
                },
                {
                  content: objeto_os[i].subtotal,
                  styles: {
                    halign: 'right',
                    fontSize: 7,
                    textColor: '#000000',
                    font: 'times'
                  }
                }
              ]
              ],
              theme: 'striped',
              headStyles:{
                fillColor: '#ddb340'
              },
              startY: (doc as any).lastAutoTable.finalY + 0
            });
        }
      }
      
      if(objeto_os[i].fk_servicio === 26 || objeto_os[i].fk_servicio === 27)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Denominación del Área Minera: ' + objeto_os[i].descripcion_area_minera + ' | Tipo Área Minera: ' + objeto_os[i].tipo_area_minera + ' | Código Único: ' + objeto_os[i].codigo_unico + ' | Padron Nacional: ' + objeto_os[i].padron_nacional + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

      if(objeto_os[i].fk_servicio === 39 || objeto_os[i].fk_servicio === 40 || objeto_os[i].fk_servicio === 41 || objeto_os[i].fk_servicio === 43 || objeto_os[i].fk_servicio === 44 || objeto_os[i].fk_servicio === 45 || objeto_os[i].fk_servicio === 46)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Correlativo de Informe: ' + objeto_os[i].correlativo_informe + ' | Correlativo de Hoja de Ruta: ' + objeto_os[i].correlativo_hoja_ruta + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

      if(objeto_os[i].fk_servicio === 28 || objeto_os[i].fk_servicio === 29 || objeto_os[i].fk_servicio === 37)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Denominación del Área Minera: ' + objeto_os[i].descripcion_area_minera + ' | Tipo Área Minera: ' + objeto_os[i].tipo_area_minera + ' | Código Único: ' + objeto_os[i].codigo_unico + ' | Padron Nacional: ' + objeto_os[i].padron_nacional + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

      if(objeto_os[i].fk_servicio === 34)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Fecha de Gaceta: ' + objeto_os[i].fecha_gaceta,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

      if(objeto_os[i].fk_servicio === 35)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Fecha de Gaceta: ' + objeto_os[i].fecha_gaceta + ' | Departamento: ' + objeto_os[i].departamento_gaceta + ' | Tomo: ' + objeto_os[i].tomo_gaceta + ' | Denominación del Área Minera: ' + objeto_os[i].denominacion_area_minera,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

      if(objeto_os[i].fk_servicio === 24 || objeto_os[i].fk_servicio === 25 || objeto_os[i].fk_servicio === 32)
      {
        autoTable(doc, {
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.30,
          columnStyles: {
            0: {cellWidth: 250},
            1: {cellWidth: 50},
            2: {cellWidth: 50},
            3: {cellWidth: 50}
          },
            body: [  
             [
              {
                content: splitted[0] + ' | Documentos a presentar: ' + objeto_os[i].requisitos,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].costo_unitario,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].cantidad,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              },
              {
                content: objeto_os[i].subtotal,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                  textColor: '#000000',
                  font: 'times'
                }
              }
             ]
            ],
            theme: 'striped',
            headStyles:{
              fillColor: '#ddb340'
            },
            startY: (doc as any).lastAutoTable.finalY + 0
          });
      }

    }


      // autoTable(doc, {
      //   tableLineColor: [0, 0, 0],
      //   tableLineWidth: 0.30,
      //   columnStyles: {
      //     0: {cellWidth: 250},
      //     1: {cellWidth: 50},
      //     2: {cellWidth: 50},
      //     3: {cellWidth: 50}
      //   },
      //     body: [  
      //      [
      //       {
      //         content: splitted[0],
      //         styles: {
      //           halign: 'center',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times'
      //         }
      //       },
      //       {
      //         content: objeto_os[i].costo_unitario,
      //         styles: {
      //           halign: 'right',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times'
      //         }
      //       },
      //       {
      //         content: objeto_os[i].cantidad,
      //         styles: {
      //           halign: 'right',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times'
      //         }
      //       },
      //       {
      //         content: objeto_os[i].subtotal,
      //         styles: {
      //           halign: 'right',
      //           fontSize: 7,
      //           textColor: '#000000',
      //           font: 'times'
      //         }
      //       }
      //      ]
      //     ],
      //     theme: 'striped',
      //     headStyles:{
      //       fillColor: '#ddb340'
      //     },
      //     startY: (doc as any).lastAutoTable.finalY + 0
      //   });
      // }

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        body: [
          [
            {
              content: 'COSTO TOTAL (Bs.):',
              styles: {
                halign: 'right',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: objeto_os[0].monto_total,
              styles: {
                halign: 'right',
                fontSize: 8,
                textColor: '#000000',
                font: 'times'
              }
            }
          ],
        ],
        theme: 'plain',
        startY: (doc as any).lastAutoTable.finalY + 1
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        body: [
          [
            {
              content: 'DATOS DE LA FACTURA',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#595B61'
              }
            }
          ],
        ],
        theme: 'plain',
        startY: (doc as any).lastAutoTable.finalY + 14
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        columnStyles: {
          0: {cellWidth: 80},
          1: {cellWidth: 110},
          2: {cellWidth: 80},
          3: {cellWidth: 90},
        },
        body: [
          [
            {
              content: 'NOMBRE/RAZON SOCIAL: ',
              styles: {
                halign: 'left',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: objeto.razon_social,
              styles: {
                halign: 'left',
                fontSize: 8,
                textColor: '#000000',
                font: 'times'
              }
            },
            {
              content: 'N.I.T.:',
              styles: {
                halign: 'left',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#489BAC'
              }
            },
            {
              content: objeto.nit,
              styles: {
                halign: 'left',
                fontSize: 8,
                textColor: '#000000',
                font: 'times'
              }
            }
          ],
        ],
        headStyles:{
          fillColor: '#ddb340'
        },
        startY: (doc as any).lastAutoTable.finalY + 1
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        body: [
          [
            {
              content: 'OBSERVACIONES',
              styles: {
                halign: 'center',
                fontSize: 8,
                textColor: '#ffffff',
                fontStyle: 'bold',
                font: 'times',
                fillColor: '#595B61'
              }
            }
          ],
        ],
        theme: 'plain',
        startY: (doc as any).lastAutoTable.finalY + 14
      });

      autoTable(doc, {
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.30,
        body: [
          [
            {
              content: objeto.observacion,
              styles: {
                halign: 'left',
                fontSize: 8,
                textColor: '#000000',
                font: 'times'
              }
            }
          ],
        ],
        theme: 'plain',
        startY: (doc as any).lastAutoTable.finalY + 1
      });

    doc.save('angular-demo.pdf');
    });
  }
  /*enerarDeuda(): Promise<RespuestaObjeto<any>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    
    return new Promise((resolve) => {
      this.vistaServiciosRegistradosService.generarDeuda()
        .subscribe((respuesta: RespuestaObjeto<any>) => {
          this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }*/

  // generarDeuda2(): Promise<RespuestaObjeto<any>> {
  //   this.store.dispatch(
  //     ComunAcciones.establecerProcesando({ procesando: true })
  //   );
    
  //   return new Promise((resolve) => {
  //     this.vistaServiciosRegistradosService.generarDeuda2()
  //       .subscribe((respuesta: RespuestaObjeto<any>) => {
  //         this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
  //         this.store.dispatch(
  //           ComunAcciones.establecerProcesando({ procesando: false })
  //         );
  //         resolve(respuesta);
  //       });
  //   });
  // }
  // obtenerEstadoCPT(
  // ): Promise<RespuestaObjeto<any>> {
  //   this.store.dispatch(
  //     ComunAcciones.establecerProcesando({ procesando: true })
  //   );
  //   return new Promise((resolve) => {
  //     this.vistaServiciosRegistradosService.obtenerEstadoCPT()
  //       .subscribe((respuesta: RespuestaObjeto<any>) => {
  //         this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
  //         this.store.dispatch(
  //           ComunAcciones.establecerProcesando({ procesando: false })
  //         );
  //         resolve(respuesta);
  //       });
  //   });
  // }
}
