import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { FormularioRecepcion } from '../../modelos';
import { FormularioRecepcionFilter } from '../../modelos/filtros';

export const establecerFiltroFormularioRecepcion = createAction(
  '[CORRESPONDENCIA] Establecer filtro FORMULARIO RECEPCION',
  props<{ filtro: FormularioRecepcionFilter }>()
);
export const establecerListaFormularioRecepcion = createAction(
  '[CORRESPONDENCIA] Establecer lista FORMULARIO RECEPCION',
  props<{ lista: FormularioRecepcion[]; paginado: Paginado }>()
);
export const establecerFormularioRecepcion = createAction(
  '[CORRESPONDENCIA] Establecer objeto FORMULARIO RECEPCION',
  props<{ objeto: FormularioRecepcion }>()
);
