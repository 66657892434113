<div class="card card-default collapsed-card card-no-shadow">
    <div class="card-header">
        <h3 class="card-title cursor-pointer" data-card-widget="collapse">
            <strong class="text-muted">FILTROS</strong>
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <form (ngSubmit)="ejecutarAccion()" [formGroup]="formBuscador">
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="d-block">Codigo Rol</label>
                    <input type="text" class="form-control" formControlName="codigo" />
                </div>
                <div class="form-group col-md-4">
                    <label class="d-block">Nombre Rol</label>
                    <input type="text" class="form-control" formControlName="nombre" />
                </div>
                <div class="form-group col-md-4">
                    <label class="d-block">Grupo Id</label>
                    <input type="text" class="form-control" formControlName="grupoId" />
                </div>
                <!-- <div class="form-group col-md-2">
                    <label class="d-block">Activo</label>
                    <ng-select [clearable]="false" formControlName="estaActivo">
                        <ng-option value="">TODOS</ng-option>
                        <ng-option value="true">ACTIVOS</ng-option>
                        <ng-option value="false">NO ACTIVOS</ng-option>
                    </ng-select>
                </div> -->
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button type="submit" class="btn btn-primary mr-1" >Buscar</button>
                    <button type="button" (click)="limpiar()" class="btn btn-dark" >Limpiar</button>
                </div>
            </div>
        </form>
    </div>
    <!-- /.card-body -->
</div>
<!-- /.card -->
