export class ActualizacionPatente {
  id?: number;
  fk_regsitro_diario: number;
  fk_area_minera: number;
  numero_comprobante_original: number;
  fecha_pago_original: Date;
  resultado_revision: string;
  observacion_revision: string;
  fk_asignacion_cargo_creador: number;
  operacion_creador: string;
  fecha_creacion: string;
  fk_asignacion_cargo_modificador: number;
  operacion_modificador: string;
  fecha_modificacion: Date;
  fk_boes: number;
  banco_original: string;
  datos_creador: string;
  datos_modificador: string;

  sePuedeModificar?: boolean;
  sePuedeEliminar?: boolean;
}
