import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PaginadorComponent } from 'src/app/comun/componentes';
import { Paginado } from 'src/app/comun/modelos';

import { VistaAreasMineras } from '../../../modelos';
import { VistaAreasMinerasFilter } from '../../../modelos/filtros';
import { VistaAreasMinerasFacade, VistaRegistroDiarioSipagoFacade } from '../../../fachadas';
import { NumberSymbol } from '@angular/common';

@Component({
  selector: 'app-vista-areas-mineras-digitalizacion-lista',
  templateUrl: './vista-areas-mineras-digitalizacion-lista.component.html',
  styleUrls: []
})
export class VistaAreasMinerasDigitalizacionListaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('modalVistaAreasMineras') modalVistaAreasMineras: NgbModal;
  @ViewChild(PaginadorComponent) paginador: PaginadorComponent;

  suscripcion = new Subscription();
  filtro: VistaAreasMinerasFilter = new VistaAreasMinerasFilter();
  tipoOperacion: string;

  vistaAreasMineras: VistaAreasMineras = new VistaAreasMineras();
  lista: VistaAreasMineras[];

  id_area_minera: number;

  modalTitulo: string;
  modal: NgbModalRef;

  constructor(
    private vistaAreasMinerasFacade: VistaAreasMinerasFacade,
    private vistaRegistroDiarioSipagoFacade: VistaRegistroDiarioSipagoFacade,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaAreasMinerasFacade.CorrespondenciaState$.subscribe(
        ({ listaVistaAreasMineras, vistaAreasMineras }) => {
          if (listaVistaAreasMineras.lista) {
            if (listaVistaAreasMineras.lista.length >= 0) {
              this.lista = listaVistaAreasMineras.lista;
              if (listaVistaAreasMineras.paginado && this.paginador) {
                this.paginador.mostrarPaginador = true;
                this.paginador.valores = new Paginado(
                  listaVistaAreasMineras.paginado.totalRegistros,
                  listaVistaAreasMineras.paginado.registrosPorPagina,
                  listaVistaAreasMineras.paginado.totalPaginas,
                  listaVistaAreasMineras.paginado.pagina
                );
              }
            }
          }
          if (vistaAreasMineras) {
            this.vistaAreasMineras = vistaAreasMineras;
          }
        }
      )
    );
    this.suscripcion.add(
      this.vistaAreasMinerasFacade.Filtro$.subscribe((filtro) => {
        if (filtro && this.paginador) {
          this.filtro = filtro;
          this.vistaAreasMinerasFacade.buscar(
            this.filtro,
            1,
            this.paginador.registrosPorPagina
          );
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.paginar();
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    const vistaAreasMinerasTitulo = 'vistaAreasMineras';
    switch (evento.operacion) {
      case 'detalle': {
        this.tipoOperacion = 'detalle';
        this.vistaAreasMinerasFacade.obtenerPorId(evento.id);
        this.modalTitulo = 'Ver detalles de ' + vistaAreasMinerasTitulo;
        this.mostrarModal();
        break;
      }
      case 'detalle-patente-minera': {
        this.tipoOperacion = 'detalle-patente-minera';
        this.id_area_minera = evento.id;
        this.modalTitulo = 'Ver detalles de Patentes Mineras';
        this.mostrarModal();
        break;
      }
    }
  }

  ejecutarAccion(evento: any): void {
    switch (evento.accion) {
      case 'buscar': {
        this.vistaAreasMinerasFacade.establecerFiltro(evento.vistaAreasMineras);
        break;
      }
      case 'cancelar': {
        this.cerrarModal();
        break;
      }
    }
  }

  paginar(): void {
    if (this.paginador) {
      this.vistaAreasMinerasFacade.buscar(
        this.filtro,
        this.paginador.paginaActual,
        this.paginador.registrosPorPagina
      );
    }
  }

  mostrarModal(opciones?: any): void {
    this.modal = this.modalService.open(this.modalVistaAreasMineras, {
      backdrop: 'static',
      size: 'xl',
      ...opciones
    });
  }

  cerrarModal(): void {
    this.modal.close();
  }
}
