<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-correspondencia-plantilla-buscador [objeto]="filtro" (operacion)="ejecutarOperacion($event)">
                </app-correspondencia-plantilla-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th>TIPO DOCUMENTO</th>
                                <th class="td-auto">VERSION</th>
                                <th class="td-auto">FECHA</th>
                                <th class="td-auto">ACTIVA</th>
                                <th class="td-auto">OPERACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="align-middle">{{ item.tipoDocumentoNombre }}</td>
                                <td class="align-middle">{{ item.version }}</td>
                                <td class="align-middle">{{ item.fecha | date: 'dd/MM/yyyy': 'UTC' }}</td>
                                <td class="align-middle text-center">
                                    <i *ngIf="item.estaActiva" class="fas fa-check-circle fa-lg text-success"></i>
                                    <i *ngIf="!item.estaActiva" class="fas fa-times-circle fa-lg text-danger"></i>
                                </td>
                                <td class="align-middle">
                                    <i (click)="cargarControl('detalle', item.id)"
                                        class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Detalle"></i>
                                    <i *ngIf="item.sePuedeModificar"
                                        [routerLink]="['/correspondencia/plantillas',item.id,'modificar']"
                                        class="far fa-edit fa-lg text-info mr-1 cursor-pointer" title="Modificar"></i>
                                    <i *ngIf="item.sePuedeEliminar" (click)="cargarControl('eliminar', item.id)"
                                        class="far fa-trash-alt fa-lg text-danger mr-1 cursor-pointer"
                                        title="Eliminar"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-right mt-3">
                    <a [routerLink]="['/correspondencia/plantillas/crear']" class="btn btn-primary">
                        Nuevo registro
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalPlantilla let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-plantilla-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion"
                (operacion)="ejecutarOperacion($event)"></app-correspondencia-plantilla-formulario>
            <app-correspondencia-plantilla-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (operacion)="ejecutarOperacion($event)"></app-correspondencia-plantilla-detalle>
            <app-correspondencia-plantilla-formulario *ngSwitchCase="'modificar'" [tipoOperacion]="tipoOperacion"
                (operacion)="ejecutarOperacion($event)"></app-correspondencia-plantilla-formulario>
            <app-correspondencia-plantilla-detalle *ngSwitchCase="'eliminar'" [tipoOperacion]="tipoOperacion"
                (operacion)="ejecutarOperacion($event)"></app-correspondencia-plantilla-detalle>
        </div>
    </div>
    <div class="modal-footer">
        <img src="assets/images/favicon.ico" width="32"> ADSIB
    </div>
</ng-template>