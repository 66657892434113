<div class="row">
    <div class="col-12">
        <form [formGroup]="formContacto" autocomplete="off" class="text-default">
            <div class="form-row">
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Entidad:</label>
                    <input type="text" class="form-control" formControlName="entidad" />
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Unidad organizacional:</label>
                    <input type="text" class="form-control" formControlName="uniOrganizacional" />
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Nombre:</label>
                    <input type="text" class="form-control" formControlName="nombre" />
                </div>
                <div class="col-md-12 form-group mb-2">
                    <label><span class="required-field-indicator"></span> Puesto:</label>
                    <input type="text" class="form-control" formControlName="puesto" />
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button (click)="ejecutarAccion(tipoOperacion)" type="submit" class="btn btn-primary mr-1"
                        [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="button" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                        <span class="required-field-indicator"></span></em>
                </div>
            </div>
        </form>
    </div>
</div>
