import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import {
  Codificador,
  Paginado,
  Respuesta,
  RespuestaLista,
  RespuestaObjeto
} from '../../../comun/modelos';
import { ComunState } from '../../../comun/estados';
import * as ComunAcciones from '../../../comun/estados';

import { CorrespondenciaState } from '../estados';
import * as CorrespondenciaAcciones from '../estados/acciones';
import {
  filtroAreaMineraSelector,
  paginadoAreaMineraSelector
} from '../estados/selectores';
import { AreaMinera } from '../modelos';
import { AreaMineraFilter } from '../modelos/filtros';
import { AreaMineraService } from '../servicios';

import { ClasificacionService } from '../servicios';

@Injectable({
  providedIn: 'root'
})
export class AreaMineraFacade {
  filtro: AreaMineraFilter = new AreaMineraFilter();
  paginado: Paginado;

  get ComunState$(): Observable<ComunState> {
    return this.store.select('comun');
  }

  get CorrespondenciaState$(): Observable<CorrespondenciaState> {
    return this.store.select('correspondencia');
  }

  get Filtro$(): Observable<AreaMineraFilter> {
    return this.store.select(filtroAreaMineraSelector);
  }

  constructor(
    private store: Store<AppState>,
    private areaMineraService: AreaMineraService,
    private clasificacionService: ClasificacionService
  ) {
    this.store.select(filtroAreaMineraSelector).subscribe((filtro) => {
      if (filtro != null) {
        this.filtro = filtro;
      }
    });
    this.store
      .select(paginadoAreaMineraSelector)
      .subscribe((paginado) => {
        if (paginado != null) {
          this.paginado = paginado;
        }
      });
  }

  establecerFiltro(filtro: AreaMineraFilter): void {
    this.store.dispatch(
      CorrespondenciaAcciones.establecerFiltroAreaMinera({
        filtro: { ...filtro }
      })
    );
  }

  buscar(
    objeto: AreaMineraFilter,
    pagina: number,
    cantidad: number
  ): Promise<RespuestaLista<AreaMinera>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.areaMineraService
        .buscar(objeto, pagina, cantidad)
        .subscribe((respuesta: RespuestaLista<AreaMinera>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaAreaMinera({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerPorId(id: number): Promise<RespuestaObjeto<AreaMinera>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.areaMineraService
        .obtenerPorId(id)
        .subscribe((respuesta: RespuestaObjeto<AreaMinera>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerAreaMinera({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerCodificador(): Promise<RespuestaLista<Codificador>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.areaMineraService
        .obtenerCodificador()
        .subscribe((respuesta: RespuestaLista<Codificador>) => {
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

}
