import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import {
  Codificador,
  Paginado,
  Respuesta,
  RespuestaLista,
  RespuestaObjeto
} from '../../../comun/modelos';
import { ComunState } from '../../../comun/estados';
import * as ComunAcciones from '../../../comun/estados';

import { CorrespondenciaState } from '../estados';
import * as CorrespondenciaAcciones from '../estados/acciones';
import {
  filtroVistaRegistroDiarioSipagoSelector,
  paginadoVistaRegistroDiarioSipagoSelector
} from '../estados/selectores';
import { VistaRegistroDiarioSipago } from '../modelos';
import { VistaRegistroDiarioSipagoFilter } from '../modelos/filtros';
import { VistaRegistroDiarioSipagoService } from '../servicios';

import { ClasificacionService } from '../servicios';

@Injectable({
  providedIn: 'root'
})
export class VistaRegistroDiarioSipagoFacade {
  filtro: VistaRegistroDiarioSipagoFilter =
    new VistaRegistroDiarioSipagoFilter();
  paginado: Paginado;

  get ComunState$(): Observable<ComunState> {
    return this.store.select('comun');
  }

  get CorrespondenciaState$(): Observable<CorrespondenciaState> {
    return this.store.select('correspondencia');
  }

  get Filtro$(): Observable<VistaRegistroDiarioSipagoFilter> {
    return this.store.select(filtroVistaRegistroDiarioSipagoSelector);
  }

  constructor(
    private store: Store<AppState>,
    private vistaRegistroDiarioSipagoService: VistaRegistroDiarioSipagoService,
    private clasificacionService: ClasificacionService
  ) {
    this.store
      .select(filtroVistaRegistroDiarioSipagoSelector)
      .subscribe((filtro) => {
        if (filtro != null) {
          this.filtro = filtro;
        }
      });
    this.store
      .select(paginadoVistaRegistroDiarioSipagoSelector)
      .subscribe((paginado) => {
        if (paginado != null) {
          this.paginado = paginado;
        }
      });
  }

  establecerFiltro(filtro: VistaRegistroDiarioSipagoFilter): void {
    this.store.dispatch(
      CorrespondenciaAcciones.establecerFiltroVistaRegistroDiarioSipago({
        filtro: { ...filtro }
      })
    );
  }

  buscar(
    objeto: VistaRegistroDiarioSipagoFilter,
    pagina: number,
    cantidad: number
  ): Promise<RespuestaLista<VistaRegistroDiarioSipago>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaRegistroDiarioSipagoService
        .buscar(objeto, pagina, cantidad)
        .subscribe((respuesta: RespuestaLista<VistaRegistroDiarioSipago>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaVistaRegistroDiarioSipago({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerPorId(
    id: number
  ): Promise<RespuestaObjeto<VistaRegistroDiarioSipago>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaRegistroDiarioSipagoService
        .obtenerPorId(id)
        .subscribe((respuesta: RespuestaObjeto<VistaRegistroDiarioSipago>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaRegistroDiarioSipago({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  guardar(
    objeto: VistaRegistroDiarioSipago
  ): Promise<RespuestaObjeto<VistaRegistroDiarioSipago>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    console.log(objeto);
    return new Promise((resolve) => {
      this.vistaRegistroDiarioSipagoService
        .guardar(objeto)
        .subscribe((respuesta: RespuestaObjeto<VistaRegistroDiarioSipago>) => {
          if (respuesta.tipoRespuesta === 'Exito') {
            if (this.paginado) {
              this.buscar(
                this.filtro,
                this.paginado.pagina,
                this.paginado.registrosPorPagina
              );
            }
          }
          this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }
}
