import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import {
  Codificador,
  Paginado,
  Respuesta,
  RespuestaLista,
  RespuestaObjeto
} from '../../../comun/modelos';
import { ComunState } from '../../../comun/estados';
import * as ComunAcciones from '../../../comun/estados';

import { CorrespondenciaState } from '../estados';
import * as CorrespondenciaAcciones from '../estados/acciones';
import {
  filtroVistaPatentesMinerasInactivosSelector,
  paginadoVistaPatentesMinerasInactivosSelector
} from '../estados/selectores';
import { VistaPatentesMinerasInactivos } from '../modelos';
import { VistaPatentesMinerasInactivosFilter } from '../modelos/filtros';
import { VistaPatentesMinerasInactivosService } from '../servicios';

import { ClasificacionService } from '../servicios';

@Injectable({
  providedIn: 'root'
})
export class VistaPatentesMinerasInactivosFacade {
  filtro: VistaPatentesMinerasInactivosFilter =
    new VistaPatentesMinerasInactivosFilter();
  paginado: Paginado;

  get ComunState$(): Observable<ComunState> {
    return this.store.select('comun');
  }

  get CorrespondenciaState$(): Observable<CorrespondenciaState> {
    return this.store.select('correspondencia');
  }

  get Filtro$(): Observable<VistaPatentesMinerasInactivosFilter> {
    return this.store.select(filtroVistaPatentesMinerasInactivosSelector);
  }

  constructor(
    private store: Store<AppState>,
    private vistaPatentesMinerasInactivosService: VistaPatentesMinerasInactivosService,
    private clasificacionService: ClasificacionService
  ) {
    this.store
      .select(filtroVistaPatentesMinerasInactivosSelector)
      .subscribe((filtro) => {
        if (filtro != null) {
          this.filtro = filtro;
        }
      });
    this.store
      .select(paginadoVistaPatentesMinerasInactivosSelector)
      .subscribe((paginado) => {
        if (paginado != null) {
          this.paginado = paginado;
        }
      });
  }

  establecerFiltro(filtro: VistaPatentesMinerasInactivosFilter): void {
    this.store.dispatch(
      CorrespondenciaAcciones.establecerFiltroVistaPatentesMinerasInactivos({
        filtro: { ...filtro }
      })
    );
  }

  buscar(
    objeto: VistaPatentesMinerasInactivosFilter,
    pagina: number,
    cantidad: number
  ): Promise<RespuestaLista<VistaPatentesMinerasInactivos>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaPatentesMinerasInactivosService
        .buscar(objeto, pagina, cantidad)
        .subscribe((respuesta: RespuestaLista<VistaPatentesMinerasInactivos>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaVistaPatentesMinerasInactivos({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  obtenerPorId(
    id: number
  ): Promise<RespuestaObjeto<VistaPatentesMinerasInactivos>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaPatentesMinerasInactivosService
        .obtenerPorId(id)
        .subscribe((respuesta: RespuestaObjeto<VistaPatentesMinerasInactivos>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerVistaPatentesMinerasInactivos({
              objeto: { ...respuesta.objeto }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  guardar(
    objeto: VistaPatentesMinerasInactivos
  ): Promise<RespuestaObjeto<VistaPatentesMinerasInactivos>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    console.log(objeto);
    return new Promise((resolve) => {
      this.vistaPatentesMinerasInactivosService
        .guardar(objeto)
        .subscribe((respuesta: RespuestaObjeto<VistaPatentesMinerasInactivos>) => {
          if (respuesta.tipoRespuesta === 'Exito') {
            if (this.paginado) {
              this.buscar(
                this.filtro,
                this.paginado.pagina,
                this.paginado.registrosPorPagina
              );
            }
          }
          this.store.dispatch(ComunAcciones.establecerRespuesta({ respuesta }));
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }

  //  Patente Mineras por Id Area Minera
  obtenerVistaPatnetesMinerasInactivosPorIdAreaMinera(
    IdAreaMinera: number
  ): Promise<RespuestaLista<VistaPatentesMinerasInactivos>> {
    this.store.dispatch(
      ComunAcciones.establecerProcesando({ procesando: true })
    );
    return new Promise((resolve) => {
      this.vistaPatentesMinerasInactivosService
        .obtenerVistaPatnetesMinerasInactivosPorIdAreaMinera(IdAreaMinera)
        .subscribe((respuesta: RespuestaLista<VistaPatentesMinerasInactivos>) => {
          this.store.dispatch(
            CorrespondenciaAcciones.establecerListaVistaPatentesMinerasInactivos({
              lista: [...respuesta.lista],
              paginado: { ...respuesta.paginado }
            })
          );
          this.store.dispatch(
            ComunAcciones.establecerProcesando({ procesando: false })
          );
          resolve(respuesta);
        });
    });
  }
}
