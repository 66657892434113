import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { GeneraCertificadoVigenciaFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroGeneraCertificadoVigencia(state: AppState): GeneraCertificadoVigenciaFilter {
  return state.correspondencia.listaGeneraCertificadoVigencia?.filtro;
}

function seleccionarPaginadoGeneraCertificadoVigencia(state: AppState): Paginado {
  return state.correspondencia.listaGeneraCertificadoVigencia?.paginado;
}

export const filtroGeneraCertificadoVigenciaSelector = createSelector(
  obtenerState,
  seleccionarFiltroGeneraCertificadoVigencia
);
export const paginadoGeneraCertificadoVigenciaSelector = createSelector(
  obtenerState,
  seleccionarPaginadoGeneraCertificadoVigencia
);
