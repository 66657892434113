import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios, OrdenServicio, VistaAreasMineras, SolicitanteOrdenServicio } from '../../../modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade, VistaAreasMinerasFacade, SolicitanteOrdenServicioFacade } from '../../../fachadas';

import { SeguridadFacade } from 'src/app/seguridad/fachadas';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-correspondencia-vista-servicios-registrados-formulario',
  templateUrl: './vista-servicios-registrados-formulario.component.html',
  styles: []
})
export class VistaServiciosRegistradosFormularioComponent
  implements OnInit, OnDestroy
{
  @Input() redireccion: string[];
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formVistaServiciosRegistrados: FormGroup;
  botonOperacion: string;

  listaServiciosSeleccionados: OrdenServicio[] = [];
  results: any[] = [];
  
  registra_servicio: boolean;
  codigo_descripcion: string;
  grupo_servicio: string;
  costo_servicio: string;
  codigo_servicio: string;
  id_servicio: number;

  costo_servicio_numero_n: number;
  costo_servicio_selec_numero: number;
  servicio_selec: boolean;
  codigo_descripcion_selec: string;
  grupo_servicio_selec: string;
  costo_servicio_selec: string;
  nuevo_costo_servicio_select: string;
  codigo_servicio_selec: string;
  id_servicio_selec: number;

  vistaServiciosRegistrados: VistaServiciosRegistrados;
  serviciosSeleccionado: Servicios;
  listaServiciosCodificador: Codificador[];

  requisitos: string;
  sumatoriaServiciosTotal: number;

  fecha: Date;
  /**/
  //dato1: string;
  //sumaTotal: number;
  /**/

  dynamicArray: any [] = [];
  dynamicSumaArray = [];
  dynamicTotalArray = [];
  archivo: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    public seguridadFacade: SeguridadFacade,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private serviciosFacade: ServiciosFacade,
    private toastrService: ToastrService
  ) {
    if (!this.vistaServiciosRegistrados) {
      this.vistaServiciosRegistrados = new VistaServiciosRegistrados();
    }

    this.formVistaServiciosRegistrados = this.fb.group({
      /*correlativo_servicio: ['', Validators.required],
      nombre_solicitante: ['', Validators.required],
      representa: ['', Validators.required]*/
      checkArray: this.fb.array([], [Validators.required]),
      correlativo_servicio: ['n'],
      atender_dccm: [true],
      nombre_solicitante: ['n'],
      idhr: [1],
      cantidad_servicios: [1],
      servicios_seleccionados: ['0'],
      impreso: ['n'],
      fecha: ['', Validators.required],
      nombre_completo: ['', Validators.required],
      numero_ci: ['', Validators.required],
      expedido: ['', Validators.required],
      telefono: ['', Validators.required],
      representa: ['', Validators.required],
      tipo_actor_minero: ['', Validators.required],
      razon_social: ['', Validators.required],
      nit: ['', Validators.required],
      observacion: [''],
      correo_electronico: ['', Validators.required],
      direccion: ['', Validators.required],

      correlativo_informe: [''],
      correlativo_hoja_ruta: ['']
    });
  }

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ vistaServiciosRegistrados }) => {
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
            /*if (this.tipoOperacion === 'modificar' && this.vistaServiciosRegistrados.id) {
            this.formVistaServiciosRegistrados.setValue({
              correlativo_servicio: this.vistaServiciosRegistrados.correlativo_servicio,
              nombre_solicitante: this.vistaServiciosRegistrados.nombre_solicitante,
              representa: this.vistaServiciosRegistrados.representa
            });
          }*/
          }
        }
      )
    );

    this.fecha = new Date();

    this.registra_servicio = false;
    this.servicio_selec = false;
    this.requisitos = '';

    this.tipoOperacion = "crear";

    switch (this.tipoOperacion) {
      case 'crear':
        this.botonOperacion = 'Guardar';
        this.serviciosFacade.obtenerCodificador().then((respuesta) => {
          this.listaServiciosCodificador = respuesta.lista;
        });
        break;
      /*case 'modificar':
        this.botonOperacion = 'Modificar';
        break;*/
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      /*case 'seleccionar-servicios': {
        if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.costo_servicio_numero_n = Math.trunc(respuesta.objeto.costo);
            this.costo_servicio_selec_numero = Math.trunc(respuesta.objeto.costo);
            this.serviciosSeleccionado = respuesta.objeto;
            this.registra_servicio = true;
            this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio = respuesta.objeto.grupo;
            this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio = respuesta.objeto.codigo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
            this.id_servicio = respuesta.objeto.id;
          });
        } else {
          this.serviciosSeleccionado = null;
        }
        break;
      }*/
      case 'cancelar-lista': {
        this.registra_servicio = false;
        (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
        break;
      }
      // case 'adjunto-seleccionado': {
      //   let elemId: string = (evento.input.target as Element).id;
      //   (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
      //   break;
      // }
      /*case 'agregar-lista': {
        this.registra_servicio = false;
        this.dynamicArray.push({ firstName: 'hola' });
        this.servicio_selec = true;*/
        /*if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.codigo_descripcion_selec = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio_selec = respuesta.objeto.grupo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio_selec = respuesta.objeto.codigo;
            this.id_servicio_selec = respuesta.objeto.id;
            this.servicio_selec = true;
          });
        }*/
        /*break;
      }*/
    }
  }

  ejecutarAccion(accion: string): void {
    let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        //if (!this.formVistaServiciosRegistrados.valid) {
        if(!this.formVistaServiciosRegistrados.controls['nombre_completo'].valid) {
          this.formVistaServiciosRegistrados.controls['nombre_completo'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['numero_ci'].valid) {
          this.formVistaServiciosRegistrados.controls['numero_ci'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['expedido'].valid) {
          this.formVistaServiciosRegistrados.controls['expedido'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['telefono'].valid) {
          this.formVistaServiciosRegistrados.controls['telefono'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['representa'].valid) {
          this.formVistaServiciosRegistrados.controls['representa'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].valid) {
          this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['razon_social'].valid) {
          this.formVistaServiciosRegistrados.controls['razon_social'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['nit'].valid) {
          this.formVistaServiciosRegistrados.controls['nit'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['observacion'].valid) {
          this.formVistaServiciosRegistrados.controls['observacion'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['correo_electronico'].valid) {
          this.formVistaServiciosRegistrados.controls['correo_electronico'].markAllAsTouched();
          return
        }
        if(!this.formVistaServiciosRegistrados.controls['direccion'].valid) {
          this.formVistaServiciosRegistrados.controls['direccion'].markAllAsTouched();
          return
        }

        vistaServiciosRegistrados.fecha = this.formVistaServiciosRegistrados.controls['fecha'].value; 
        vistaServiciosRegistrados.nombre_completo = this.formVistaServiciosRegistrados.controls['nombre_completo'].value;
        vistaServiciosRegistrados.numero_ci = this.formVistaServiciosRegistrados.controls['numero_ci'].value;
        vistaServiciosRegistrados.expedido = this.formVistaServiciosRegistrados.controls['expedido'].value;
        vistaServiciosRegistrados.telefono = this.formVistaServiciosRegistrados.controls['telefono'].value;
        vistaServiciosRegistrados.nombre_solicitante = this.formVistaServiciosRegistrados.controls['nombre_completo'].value;
        vistaServiciosRegistrados.representa = this.formVistaServiciosRegistrados.controls['representa'].value;
        vistaServiciosRegistrados.tipo_actor_minero = this.formVistaServiciosRegistrados.controls['tipo_actor_minero'].value;
        vistaServiciosRegistrados.razon_social = this.formVistaServiciosRegistrados.controls['razon_social'].value;
        vistaServiciosRegistrados.nit = this.formVistaServiciosRegistrados.controls['nit'].value;
        vistaServiciosRegistrados.observacion = this.formVistaServiciosRegistrados.controls['observacion'].value;
        vistaServiciosRegistrados.correo_electronico = this.formVistaServiciosRegistrados.controls['correo_electronico'].value;
        vistaServiciosRegistrados.direccion = this.formVistaServiciosRegistrados.controls['direccion'].value;
        vistaServiciosRegistrados.atender_dccm = true;
        vistaServiciosRegistrados.correlativo_servicio = "n";
        vistaServiciosRegistrados.idhr = 1;
        vistaServiciosRegistrados.cantidad_servicios = 1;
        vistaServiciosRegistrados.servicios_seleccionados = "0";
        vistaServiciosRegistrados.impreso = "n";
        vistaServiciosRegistrados.modo_registro = 'INTERNO';

        //vistaServiciosRegistrados.correlativo_informe = this.formVistaServiciosRegistrados.controls['correlativo_informe'].value;
        //vistaServiciosRegistrados.correlativo_hoja_ruta = this.formVistaServiciosRegistrados.controls['correlativo_hoja_ruta'].value;

        const total_registros = Number(Object.keys(this.dynamicArray).length);

        for(let i = 0 ; i < total_registros ; i++){
          //console.log(this.heroesList[i].name);
          const valor_i = Number(i);
          const servicioSel = new OrdenServicio();
          servicioSel.requisitos = this.dynamicArray[valor_i].Requisitos_Sel;
          /*"Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.";*/
          servicioSel.descripcion_servicio = this.dynamicArray[valor_i].Servicio_Grupo; 
          servicioSel.fk_area_minera = 0;
          servicioSel.descripcion_area_minera = "";
          servicioSel.fk_servicio = this.cambioNumero(this.dynamicArray[valor_i].Id_Servicio_Sel);
          servicioSel.costo_unitario = this.cambioNumero(this.dynamicArray[valor_i].Costo_Servicio_Numero);
          servicioSel.cantidad = this.cambioNumero(this.dynamicArray[valor_i].Cantidad);
          servicioSel.subtotal = this.cambioNumero(this.dynamicArray[valor_i].Costo_Serv_Sel_Numero);
          servicioSel.monto_total = this.cambioNumero(this.dynamicTotalArray[0]);
          servicioSel.grupo = this.dynamicArray[valor_i].Grupo;
          servicioSel.plan_trabajo = true;
          servicioSel.escala_variable = true;
          servicioSel.orientacion_variable = true;
          this.listaServiciosSeleccionados.push(servicioSel);
        }

        this.vistaServiciosRegistradosFacade
          .guardar(vistaServiciosRegistrados, this.listaServiciosSeleccionados)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {
              window.open(respuesta.mensaje,'_blank');
              location.reload();   
            }
          });
        break;
      }
      /*/case 'modificar': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        if (!this.formVistaServiciosRegistrados.valid) {
          this.formVistaServiciosRegistrados.markAllAsTouched();
          return;
        }
        vistaServiciosRegistrados = {
          ...this.formVistaServiciosRegistrados.value
        };
        this.accion.emit({
          accion,
          rolId: this.vistaServiciosRegistrados.id,
          vistaServiciosRegistrados
        });
        break;
      }*/
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

  onChangeSeleccion(value: any) { 
    if (value.target.value !== '') {
      this.serviciosFacade.obtenerPorId(value.target.value).then((respuesta) => {
        this.costo_servicio_numero_n = Math.trunc(respuesta.objeto.costo);
        this.costo_servicio_selec_numero = Math.trunc(respuesta.objeto.costo);
        this.serviciosSeleccionado = respuesta.objeto;
        this.registra_servicio = true;
        this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
        this.grupo_servicio = respuesta.objeto.grupo;
        this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
        this.codigo_servicio = respuesta.objeto.codigo;
        this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
        this.id_servicio = respuesta.objeto.id;
      });
    } else {
      this.serviciosSeleccionado = null;
    } 
  } 

  selectInput(e: any){
    let elemId: string = (e.target as Element).id;
    (<HTMLInputElement>document.getElementById(elemId + '-chk')).checked = true;
    this.onCheckboxChange(e, 'mantiene2');
  }

  onKeyUp(evento: any): void{ 
    this.costo_servicio_selec = 'Bs. ' + String(Number(this.costo_servicio.substring(4)) * Number(evento.target.value));
    this.costo_servicio_selec_numero = Number(this.costo_servicio.substring(4)) * Number(evento.target.value);
  }

  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
    this.dynamicSumaArray.splice(index, 1);
    /*******Suma de los totales de Servicios Seleccionados********/
    let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);
  }

  agregarListaSinAdicionalesA(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    let correlativo_informe = this.formVistaServiciosRegistrados.controls['correlativo_informe'].value;
    let correlativo_hoja_ruta = this.formVistaServiciosRegistrados.controls['correlativo_hoja_ruta'].value;
    if(correlativo_informe === '' || correlativo_informe === null)
    {
      alert("Debe registrar el Correlativo del Informe");
    }
    else if(correlativo_hoja_ruta === '' || correlativo_hoja_ruta === null)
    {
      alert("Debe registrar el Correlativo de la Hoja de Ruta");
    }
    else
    {
      this.registra_servicio = false;
      let requisitosSel = "";
      requisitosSel = "Fotocopia del estado de cuenta o proforma de pago correspondiente o fotocopia del formulario de pago de Patente Minera (Correspondiente a la última gestión)., Fotocopia del C.I. del titular, representante legal o apoderado., Fotocopia del testimonio de poder (si corresponde).";
      let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Correlativo_Informe: correlativo_informe, Correlativo_Hoja_Ruta: correlativo_hoja_ruta};
      this.dynamicArray.push(row);
      this.servicio_selec = true;
      //this.onCheckboxChange('elimina', 'elimina');
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
    }
    //this.dato1 = dato;
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  agregarListaSinAdicionalesB(servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, costoServSelNumero: any, costoServicioNumero: any, grupo: any, idServicioSel: any): void{
    let correlativo_informe = this.formVistaServiciosRegistrados.controls['correlativo_informe'].value;
    let correlativo_hoja_ruta = this.formVistaServiciosRegistrados.controls['correlativo_hoja_ruta'].value;
    if(correlativo_informe === '' || correlativo_informe === null)
    {
      alert("Debe registrar el Correlativo del Informe");
    }
    else if(correlativo_hoja_ruta === '' || correlativo_hoja_ruta === null)
    {
      alert("Debe registrar el Correlativo de la Hoja de Ruta");
    }
    else
    {
      this.registra_servicio = false;
      let requisitosSel = "";
      requisitosSel = "Solicitud de Autoridad Competente.";
      let row = {Tiene_Adicional: 'F', Datos_Adicionales: 'S/O', Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel, Costo_Serv_Sel_Numero: costoServSelNumero, Costo_Servicio_Numero: costoServicioNumero, Grupo: grupo, Id_Servicio_Sel: idServicioSel, Tiene_AreaMinera: 'F', Correlativo_Informe: correlativo_informe, Correlativo_Hoja_Ruta: correlativo_hoja_ruta};
      this.dynamicArray.push(row);
      this.servicio_selec = true;
      //this.onCheckboxChange('elimina', 'elimina');
      this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
      /*******Suma de los totales de Servicios Seleccionados********/
      let sum = 0;
      for (let i = 0; i < this.dynamicSumaArray.length; i++) {
        sum += Number(this.dynamicSumaArray[i]);
      }
      this.dynamicTotalArray = [];
      this.dynamicTotalArray.push(sum);
      //this.dato1 = dato;
    }
    (<HTMLInputElement>document.getElementById('serviciosSeleccionado')).value = "";
  }

  sumatoriaTotal(costo: any){
    let total = 0;
    total += Number(costo);
    return total;
  }

  onCheckboxChange(e: any, tipo: string) {
    const checkArray: FormArray = this.formVistaServiciosRegistrados.get('checkArray') as FormArray;
    if(tipo === 'elimina'){
       checkArray.clear();
    }
    else if(tipo === 'elimina2'){
      let i: number = 0;
      let elementId: string = (e.target as Element).id;
      checkArray.controls.forEach((item: any) => {
        if (item.value == (<HTMLInputElement>document.getElementById(elementId + '-src-file-chk')).value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
      this.requisitos = checkArray.value;
    }
    else if(tipo === 'mantiene2'){
      let elementId: string = (e.target as Element).id;
      let chkvalue = (<HTMLInputElement>document.getElementById(elementId + '-chk')).checked;
      if (chkvalue) {
        checkArray.push(new FormControl((<HTMLInputElement>document.getElementById(elementId + '-chk')).value));
        this.requisitos = checkArray.value;
      } 
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == (<HTMLInputElement>document.getElementById(elementId + '-chk')).value) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        });
        this.requisitos = checkArray.value;
      }
    }
    else{
      if (e.target.checked) {
        checkArray.push(new FormControl(e.target.value));
        this.requisitos = checkArray.value;
      } 
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        });
        this.requisitos = checkArray.value;
      }
    }
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
  }

  resetInput(e: any){
    let elementId: string = (e.target as Element).id;
    (<HTMLInputElement>document.getElementById(elementId + '-src-file')).value = '';
    (<HTMLInputElement>document.getElementById(elementId + '-src-file-chk')).checked = false;
    this.onCheckboxChange(e, 'elimina2');
  }

  cambioNumero(e: any){
    var dato_numero = Number(e);
    return dato_numero;
  }

}