<div class="row">
    <div class="col-md-12">
        <ul class="list-unstyled">
            <li *ngFor="let item of hojaRuta?.listaDocumento">
                <div style="display: flex; flex-direction: row;" class="mb-2">
                    <!-- <i class="fas fa-user-circle fa-4x mr-2 text-muted"></i> -->
                    <i class="far fa-fw fa-file-pdf fa-2x mt-2 mr-1 text-muted"></i>
                    <div>
                        <span class="d-block">
                            <strong>{{item.tipoDocumentoNombre}}:</strong> {{item.cite}} 
                            <a *ngIf="documento.id !== item.id" href="#" class="btn-link"
                                [routerLink]="['/correspondencia/documentos', item.id, 'detalle']"><i class="fas fa-external-link-alt"></i></a>
                        </span>
                        <span class="d-block">
                            <strong>Referencia:</strong> {{item.referencia}}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>