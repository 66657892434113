import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { ServiciosFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroServicios(state: AppState): ServiciosFilter {
  return state.correspondencia.listaServicios?.filtro;
}

function seleccionarPaginadoServicios(state: AppState): Paginado {
  return state.correspondencia.listaServicios?.paginado;
}

export const filtroServiciosSelector = createSelector(
  obtenerState,
  seleccionarFiltroServicios
);
export const paginadoServiciosSelector = createSelector(
  obtenerState,
  seleccionarPaginadoServicios
);
