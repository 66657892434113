<div class="mx-auto" style="margin: 0; padding: 0; width: 220mm; height: 40px;">
    <div>
        <span class="d-block mb-2">
            <ng-container>
                <i (click)="ejecutarAccion({accion: 'duplicar', id: documento?.id})"
                    class="fas fa-copy fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Duplicar documento"></i>
            </ng-container>
            <ng-container *ngIf="tipoDocumento?.remitente === 'EXTERNO'">
                <i (click)="ejecutarAccion({accion: 'descargar', id: documento?.id})"
                    class="fas fa-file fa-2x text-muted ml-2 mt-2 cursor-pointer" title="Descargar hoja de registro"></i>
            </ng-container>
            <ng-container *ngIf="!documentoAdjuntoBase64">
                <span class="float-left text-left">
                    <h3><span class="badge badge-danger">No se ha encontrado el documento</span></h3>
                </span>
            </ng-container>
            <ng-container *ngIf="estado">
                <span class="float-right text-right">
                    <h3><span class="badge badge-secondary">{{ estado }}</span></h3>
                </span>
            </ng-container>
        </span>
    </div>
</div>

