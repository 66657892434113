<div class="card">
    <div class="card-header p-2">
        <h3><span class="lstick"></span>Seguridad de la cuenta</h3>
    </div><!-- /.card-header -->
    <div class="card-body">
        <form [formGroup]="formContrasenia" autocomplete="off" class="form-horizontal">
            <div class="form-group row">
                <label for="contrasenia" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                    Contraseña actual</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" id="contrasenia" formControlName="contrasenia">
                </div>
            </div>
            <div class="form-group row">
                <label for="nuevaContrasenia" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                    Nueva contraseña</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" id="nuevaContrasenia" formControlName="nuevaContrasenia">
                </div>
            </div>
            <div class="form-group row">
                <label for="confirmaContrasenia" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                    Confirme su nueva contraseña</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" id="confirmaContrasenia" formControlName="confirmaContrasenia">
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-sm-2 col-sm-10">
                    <button (click)="actualizar()" type="submit" class="btn btn-dark float-right">Cambiar
                        contraseña</button>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12">
                    <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                        <span class="required-field-indicator"></span></em>
                </div>
            </div>
        </form>
    </div><!-- /.card-body -->
</div>
<!-- /.card -->