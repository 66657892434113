import {   
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { BrowserModule } from '@angular/platform-browser'

import { SeguridadFacade } from 'src/app/seguridad/fachadas';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios } from 'src/app/modulos/correspondencia/modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade } from 'src/app/modulos/correspondencia/fachadas';

@Component({
  selector: 'app-admin-lte3-registro-apm',
  templateUrl: './registro-apm.component.html',
  styles: [],
})

export class RegistroApmComponent implements OnInit, OnDestroy {
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  formVistaServiciosRegistrados: FormGroup;

  botonOperacion: string;

  registra_servicio: boolean;
  codigo_descripcion: string;
  grupo_servicio: string;
  costo_servicio: string;
  codigo_servicio: string;
  id_servicio: number;

  servicio_selec: boolean;
  codigo_descripcion_selec: string;
  grupo_servicio_selec: string;
  costo_servicio_selec: string;
  nuevo_costo_servicio_select: string;
  codigo_servicio_selec: string;
  id_servicio_selec: number;

  vistaServiciosRegistrados: VistaServiciosRegistrados;
  serviciosSeleccionado: Servicios;
  listaServiciosCodificador: Codificador[];

  requisitos: string;
  sumatoriaServiciosTotal: number;

  dynamicArray = [];
  dynamicSumaArray = [];
  dynamicTotalArray = [];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private serviciosFacade: ServiciosFacade,
  ) {
    if (!this.vistaServiciosRegistrados) {
      this.vistaServiciosRegistrados = new VistaServiciosRegistrados();
    }
  }

  ngOnInit(): void {
    this.suscripcion.add(
      this.vistaServiciosRegistradosFacade.CorrespondenciaState$.subscribe(
        ({ vistaServiciosRegistrados }) => {
          if (vistaServiciosRegistrados) {
            this.vistaServiciosRegistrados = vistaServiciosRegistrados;
          }
        }
      )
    );

    this.registra_servicio = false;
    this.servicio_selec = false;
    this.requisitos = "0";

    // this.tipoOperacion = 'crear';

    // switch (this.tipoOperacion) {
    //   case 'crear':
    //     this.botonOperacion = 'Guardar';
        this.serviciosFacade.obtenerCodificador().then((respuesta) => {
          this.listaServiciosCodificador = respuesta.lista;
        });
        // break;
    // }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  ejecutarOperacion(evento: any): void {
    switch (evento.operacion) {
      case 'seleccionar-servicios': {
        if (evento.id !== '') {
          this.serviciosFacade.obtenerPorId(evento.id).then((respuesta) => {
            this.serviciosSeleccionado = respuesta.objeto;
            this.registra_servicio = true;
            this.codigo_descripcion = respuesta.objeto.codigo + '-' + respuesta.objeto.descripcion;
            this.grupo_servicio = respuesta.objeto.grupo;
            this.costo_servicio = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));
            this.codigo_servicio = respuesta.objeto.codigo;
            this.costo_servicio_selec = 'Bs. ' + String(Math.trunc(respuesta.objeto.costo));;
            this.id_servicio = respuesta.objeto.id;
          });
        } else {
          this.serviciosSeleccionado = null;
        }
        break;
      }
      case 'cancelar-lista': {
        this.registra_servicio = false;
        break;
      }
    }
  }

  ejecutarAccion(accion: string): void {
    let vistaServiciosRegistrados = new VistaServiciosRegistrados();
    switch (accion) {
      case 'crear': {
        FuncionesHelper.limpiarEspacios(this.formVistaServiciosRegistrados);
        if (!this.formVistaServiciosRegistrados.valid) {
          this.formVistaServiciosRegistrados.markAllAsTouched();
          return;
        }
        vistaServiciosRegistrados = {
          ...this.formVistaServiciosRegistrados.value
        };
        this.vistaServiciosRegistradosFacade
          .guardar(vistaServiciosRegistrados)
          .then((respuesta) => {
            if (respuesta.tipoRespuesta === 'Exito') {

            }
          });
        break;
      }
      case 'cancelar': {
        this.accion.emit({
          accion
        });
        break;
      }
    }
  }

  onKeyUp(evento: any): void{ 
    this.costo_servicio_selec = 'Bs. ' + String(Number(this.costo_servicio.substring(4)) * Number(evento.target.value));
  }

  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
    this.dynamicSumaArray.splice(index, 1);
    /*******Suma de los totales de Servicios Seleccionados********/
    let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);
  }

  agregarLista(datosAdicionales: any, zona: any, este: any, norte: any, escala: any, orientacion: any, servicioGrupo: any, costoServ: any, cantidad: any, costoServSel: any, requisitosSel: any): void{
    this.registra_servicio = false;
    let row = {Datos_Adicionales: datosAdicionales, Zona: zona, Este: este, Norte: norte, Escala: escala, Orientacion: orientacion, Servicio_Grupo: servicioGrupo, Costo_Serv: costoServ, Cantidad: cantidad, Costo_Serv_Sel: costoServSel, Requisitos_Sel: requisitosSel};
    this.dynamicArray.push(row);
    this.servicio_selec = true;
    this.onCheckboxChange('elimina', 'elimina');
    this.dynamicSumaArray.push(Number(costoServSel.substring(4)));
    /*******Suma de los totales de Servicios Seleccionados********/
    let sum = 0;
    for (let i = 0; i < this.dynamicSumaArray.length; i++) {
      sum += Number(this.dynamicSumaArray[i]);
    }
    this.dynamicTotalArray = [];
    this.dynamicTotalArray.push(sum);
    //this.dato1 = dato;
  }

  sumatoriaTotal(costo: any){
    let total = 0;
    total += Number(costo);
    return total;
  }

  onCheckboxChange(e: any, tipo: string) {
    const checkArray: FormArray = this.formVistaServiciosRegistrados.get('checkArray') as FormArray;
    if(tipo === 'elimina'){
       checkArray.clear();
    }
    else{
      if (e.target.checked) {
        checkArray.push(new FormControl(e.target.value));
        this.requisitos = checkArray.value;
      } 
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        });
        this.requisitos = checkArray.value;
      }
    }
  }

}
