<div class="card">
    <div class="card-body">
        <div style="text-align: center" >
            <button (click)="ejecutarOperacion({operacion: 'crear'})" style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn btn-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg> Crear Nuevo Formulario de Verificacion<br>
                    Documental para Persona Natural
            </button>
            
            <button (click)="ejecutarOperacion({operacion: 'crear2'})" type="button" style="margin-left: 10px; margin-bottom: 10px;" class="btn btn-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg> Crear Nuevo Formulario de Verificacion<br>
                    Documental para Persona Jurididica
            </button>
          
        </div>
        <div class="row">
        <br>       
        <h2> Listado de Formularios de Verificacion Documental</h2>
        <br>
            <div class="col-md-12">
                <app-correspondencia-formulario-recepcion-buscador [objeto]="filtro" (accion)="ejecutarAccion($event)">
                </app-correspondencia-formulario-recepcion-buscador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped table-hover border mt-4 ">
                        <thead class="thead-dark">
                            <tr>
                                <th>CORRELATIVO</th>
                                <th>TIPO PERSONA</th>
                                <th>FECHA EMISION</th>
                                <th>DOCUMENTOS PRESENTADOS</th>
                                <th>EMITE NOTA EXTERNA</th>
                                <th>FECHA DE VERIFICACION</th>
                                <th class="td-auto">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="td-auto align-middle">{{ item.correlativo }}</td>
                                <td class="align-middle">{{ item.tipo_persona }}</td>
                                <td class="align-middle">{{ item.fecha_creacion }}</td>
                                <td class="align-middle">{{ item.documentos_generados }}</td>
                                <td class="align-middle">{{ item.tipo_operacion_fvd }}</td>
                                <td class="align-middle">{{ item.fecha_verificacion_documental | date:'yyyy-MM-dd'}}</td>
                                <td class="align-middle">
                                    <i (click)="ejecutarOperacion({operacion: 'detalle', id: item.id})"
                                        class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Detalle"></i>
                                    <i (click)="ejecutarOperacion({operacion: 'modificar', id: item.id})"
                                        class="far fa-file-alt fa-lg text-info mr-1 cursor-pointer" title="Modificar"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-paginador (paginar)="paginar()"></app-paginador>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-right mt-3">
                    <input type="button" value="Nuevo registro" class="btn btn-primary"
                        (click)="ejecutarOperacion({operacion: 'crear'})">
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalFormularioRecepcion let-modal let-c="close" let-d="dismiss" >
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitulo }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="tipoOperacion">
            <app-correspondencia-formulario-recepcion-formulario *ngSwitchCase="'crear'" [tipoOperacion]="tipoOperacion" [validacion]="validacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-formulario-recepcion-formulario>
                <app-correspondencia-formulario-recepcion-formulario *ngSwitchCase="'crear2'" [tipoOperacion]="tipoOperacion" [validacion]="validacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-formulario-recepcion-formulario>
            <app-correspondencia-formulario-recepcion-detalle *ngSwitchCase="'detalle'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-formulario-recepcion-detalle>
            <app-correspondencia-formulario-recepcion-formulario *ngSwitchCase="'modificar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-formulario-recepcion-formulario>
            <app-correspondencia-formulario-recepcion-detalle *ngSwitchCase="'eliminar'" [tipoOperacion]="tipoOperacion"
                (accion)="ejecutarAccion($event)"></app-correspondencia-formulario-recepcion-detalle>
        </div>
    </div>
    <div class="modal-footer">
        AJAM
    </div>
</ng-template>
