import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { AreaMineraFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroAreaMinera(state: AppState): AreaMineraFilter {
  return state.correspondencia.listaAreaMinera?.filtro;
}

function seleccionarPaginadoAreaMinera(state: AppState): Paginado {
  return state.correspondencia.listaAreaMinera?.paginado;
}

export const filtroAreaMineraSelector = createSelector(
  obtenerState,
  seleccionarFiltroAreaMinera
);
export const paginadoAreaMineraSelector = createSelector(
  obtenerState,
  seleccionarPaginadoAreaMinera
);
