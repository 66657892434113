<div class="hold-transition register-page">
    <div class="register-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <img class="img-fluid" src="assets/images/logo-adsib.png" alt="LOGO">
            </div>
            <div class="card-body">
                <p class="login-box-msg">{{ denominacion }}</p>
                <form (ngSubmit)="registrarse()" [formGroup]="formRegistro" autocomplete="off">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Nombre completo" formControlName="nomPublico">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Correo electrónico" formControlName="correoElectronico">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input (input)="reestablecerContrasenia()" type="password" class="form-control" placeholder="Contraseña" formControlName="contrasenia">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Confirme su contraseña" formControlName="confirmaContrasenia">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="terminosObligatorios" class="col-12">
                            <div class="icheck-primary small">
                                <input type="checkbox" id="terminos" name="terminos" value="Acepto" formControlName="terminos">
                                <label for="terminos">
                                    &nbsp;Acepto los <a [routerLink]="['/terminos-y-condiciones']">términos y condiciones</a> de uso
                                </label>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" class="btn btn-dark btn-block" [disabled]="procesando">
                                Registrarse <i *ngIf="procesando" class="fa fa-spinner fa-spin"></i></button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <div class="mt-3">
                    <a [routerLink]="['/autenticacion']" class="text-center">Tengo una cuenta</a>
                </div>
            </div>
            <!-- /.form-box -->
        </div><!-- /.card -->
    </div>
    <!-- /.register-box -->
</div>