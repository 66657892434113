import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';

import { FuncionesHelper } from 'src/app/comun/auxiliares';
import { Codificador } from 'src/app/comun/modelos';

import { VistaServiciosRegistrados, Servicios, VistaRegistroDiarioSipago, OrdenServicio } from 'src/app/modulos/correspondencia/modelos';
import { VistaServiciosRegistradosFacade, ServiciosFacade } from 'src/app/modulos/correspondencia/fachadas';

import { SeguridadFacade } from 'src/app/seguridad/fachadas';
//import { check } from 'prettier';
//import { SERVFAIL } from 'dns';

@Component({
  selector: 'app-admin-lte3-registro-terminado',
  templateUrl: './registro-terminado.component.html',
  styles: []
})
export class RegistroTerminadoComponent
  implements OnInit, OnDestroy
{
  @Input() public tipoOperacion: string;
  @Output() accion = new EventEmitter<any>();

  suscripcion = new Subscription();

  botonOperacion: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    public seguridadFacade: SeguridadFacade,
    private fb: FormBuilder,
    private vistaServiciosRegistradosFacade: VistaServiciosRegistradosFacade,
    private serviciosFacade: ServiciosFacade,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {

    this.seguridadFacade.iniciarSesionExterna(
      'ccampuzano',
      '123456789'
    );

  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

}
