<div class="row">
    <div class="col-12">
        <form>
            <div class="form-row">
                <div class="col-md-4 form-group mb-2">
                    <label class="d-block">Tipo:</label>
                    <span>{{ parametro.tipo }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 form-group mb-2">
                    <label class="d-block">Orden:</label>
                    <span>{{ parametro.orden }}</span>
                </div>
                <div class="col-md-6 form-group mb-2">
                    <label class="d-block">Valor:</label>
                    <span>{{ parametro.valor }}</span>
                </div>
                <div class="col-md-6 form-group mb-2">
                    <label class="d-block">Texto:</label>
                    <span>{{ parametro.texto }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 form-group mb-2">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="parametro_estaActivo" [checked]="parametro.estaActivo" disabled>
                        <label class="custom-control-label" for="parametro_estaActivo">esta activo</label>
                    </div>
                </div>
            </div>
            <div class="form-row justify-content-end">
                <div class="form-group">
                    <button *ngIf="tipoOperacion !== 'detalle'" (click)="ejecutarAccion(tipoOperacion)" type="submit"
                        class="btn btn-primary mr-1" [title]="botonOperacion">
                        {{botonOperacion}}
                    </button>
                    <button (click)="ejecutarAccion('cancelar')" type="submit" class="btn btn-dark" title="Cancelar">
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
