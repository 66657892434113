import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { VistaRegistroDiarioSipagoFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroVistaRegistroDiarioSipago(
  state: AppState
): VistaRegistroDiarioSipagoFilter {
  return state.correspondencia.listaVistaRegistroDiarioSipago?.filtro;
}

function seleccionarPaginadoVistaRegistroDiarioSipago(
  state: AppState
): Paginado {
  return state.correspondencia.listaVistaRegistroDiarioSipago?.paginado;
}

export const filtroVistaRegistroDiarioSipagoSelector = createSelector(
  obtenerState,
  seleccionarFiltroVistaRegistroDiarioSipago
);
export const paginadoVistaRegistroDiarioSipagoSelector = createSelector(
  obtenerState,
  seleccionarPaginadoVistaRegistroDiarioSipago
);
