import { createSelector } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { Paginado } from 'src/app/comun/modelos';

import { SolicitanteOrdenServicioFilter } from '../../modelos/filtros';

function obtenerState(state: AppState): AppState {
  return state;
}

function seleccionarFiltroSolicitanteOrdenServicio(state: AppState): SolicitanteOrdenServicioFilter {
  return state.correspondencia.listaSolicitanteOrdenServicio?.filtro;
}

function seleccionarPaginadoSolicitanteOrdenServicio(state: AppState): Paginado {
  return state.correspondencia.listaSolicitanteOrdenServicio?.paginado;
}

export const filtroSolicitanteOrdenServicioSelector = createSelector(
  obtenerState,
  seleccionarFiltroSolicitanteOrdenServicio
);
export const paginadoSolicitanteOrdenServicioSelector = createSelector(
  obtenerState,
  seleccionarPaginadoSolicitanteOrdenServicio
);
