import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-lte3-recuperar-contrasenia',
  templateUrl: './recuperar-contrasenia.component.html',
  styles: []
})
export class RecuperarContraseniaComponent {
  constructor() {
    //
  }
}
