import { createAction, props } from '@ngrx/store';

import { Paginado } from 'src/app/comun/modelos';

import { AreaMinera } from '../../modelos';
import { AreaMineraFilter } from '../../modelos/filtros';

export const establecerFiltroAreaMinera = createAction(
  '[CORRESPONDENCIA] Establecer filtro AREA_MINERA',
  props<{ filtro: AreaMineraFilter }>()
);
export const establecerListaAreaMinera = createAction(
  '[CORRESPONDENCIA] Establecer lista AREA_MINERA',
  props<{ lista: AreaMinera[]; paginado: Paginado }>()
);
export const establecerAreaMinera = createAction(
  '[CORRESPONDENCIA] Establecer objeto AREA_MINERA',
  props<{ objeto: AreaMinera }>()
);
