<style type="text/css">
    .tabla_servicios {
        border-collapse: collapse;
        width: 100%;
    }

    .tabla_servicios td, .tabla_servicios th {
        border: 1px solid black;
        padding: 8px;
    }

    .tabla_servicios_botones {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_botones td, .tabla_servicios_botones th {
        border: 0px solid black;
        padding: 8px;
    }
    .tabla_servicios_total {
        margin: 0px auto;
        border-collapse: collapse;
    }

    .tabla_servicios_total td, .tabla_servicios_total th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .input_boleta{
        margin-left:10px;
        float:left;
        width:150px;
    }
    .input_depositante{
        text-transform:uppercase;
        width:800px;
    }
    .light{
        color: white;
        font-weight: bolder;
    }
    .modal-body {
        height: 287px;
    }
    /*Fin stylo CCCS 15/06/2016*/
    .box.box-black>.box-title
    {
        background: rgb(4,3,23);
        background: linear-gradient(180deg, rgba(4,3,23,1) 0%, rgba(12,88,115,1) 100%, rgba(102,142,149,1) 100%);
        font-family: Times New Roman;
        font-size: 20px;
        letter-spacing: 0px;
        word-spacing: 0px;
        font-weight: bold;
        text-decoration: none;
        font-style: oblique;
        font-variant: normal;
        text-transform: none;
        color:white;
    }
    /*Estilo para div lado a lado*/
    #div1{
            width: 50%;
            float: left;
            position: relative;
    }
    #div2{
            width: 50%;
            float: right;
            position: relative;
    }
    table thead {
        color: #fff;
        background-color: rgb(44, 62, 88);
    }
</style>

<div class="login-page">
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <img class="img-fluid" src="assets/images/logo_AJAM.png" alt="logo">
            </div>
            <div class="card-header p-4 text-center">
                <h4><b>Registrese para solicitar uno o varios Servicios</b></h4>
            </div>
            <div class="card-body" style="width:1250px;">
                <p class="login-box-msg"></p>
                <form autocomplete="off">
                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="bi bi-calendar2-week"></i> DATOS PERSONALES DEL SOLICITANTE
                                </div>
                                <div class="box-content">

                                    <div class="input-group mb-3">
                                        <label for="nombreCompleto" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Nombre Completo</label>
                                        <input type="input" class="form-control" id="nombreCompleto" formControlName="nombreCompleto">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fa fa-question-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="ci" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            CI</label>
                                        <input type="input" class="form-control" id="ci" formControlName="ci">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fa fa-question-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="expedido" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Expedido</label>
                                            <select name="expedido" class="form-control">
                                                <option value="">[Elija una opción]</option>
                                                <option value="LA PAZ">LA PAZ</option>
                                                <option value="COCHABAMBA">COCHABAMBA</option>
                                                <option value="SANTA CRUZ">SANTA CRUZ</option>
                                                <option value="PANDO">PANDO</option>
                                                <option value="BENI">BENI</option>
                                                <option value="ORURO">ORURO</option>
                                                <option value="POTOSI">POTOSI</option>
                                                <option value="CHUQUISACA">CHUQUISACA</option>
                                                <option value="TARIJA">TARIJA</option>
                                                <option value="EXTERNO">EXTERNO</option>
                                            </select>
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="fa fa-question-circle"></span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="telefono" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Telefono</label>
                                            <input type="input" class="form-control" id="telefono" formControlName="telefono">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="fa fa-question-circle"></span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="representa" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Representa a</label>
                                            <input type="input" class="form-control" id="representa" formControlName="representa">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="fa fa-question-circle"></span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="tipoActorMinero" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Tipo Actor Minero</label>
                                            <select name="tipo_actor_minero" class="form-control">
                                                <option value="">[Elija una opción]</option>
                                                <option value="PERSONA NATURAL">PERSONA NATURAL</option>
                                                <option value="COOPERATIVA">COOPERATIVA</option>
                                                <option value="EMPRESA">EMPRESA</option>
                                                <option value="ESTATAL">ESTATAL</option>
                                            </select>
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="fa fa-question-circle"></span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label for="tieneAreaMinera" class="col-sm-4 col-form-label"><span class="required-field-indicator"></span>
                                            Tiene identificada el o las Áreas Mineras</label>
                                            <select name="tipo_actor_minero" class="form-control">
                                                <option value="">[Elija una opción]</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                            </select>
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="fa fa-question-circle"></span>
                                                </div>
                                            </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>    


<!---------------------------------------------------------Contenedor de los servicios a ser seleccionados--------------------------------------------------------------->
                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> SERVICIOS DEL CATASTRO
                                </div>
                                <div class="box-content">

                                    <ng-container>
                                        <div class="col-md-6 form-group mb-2">
                                            <label>Escriba y selecciones el/los servicio/s requeridos/s:</label>
                                            <div class="col-md-20 form-group mb-20" style="width:1000px;">
                                                <!-- <select (change)="ejecutarOperacion({operacion: 'seleccionar-servicios', id: $event})"
                                                    [clearable]="true" formControlName="serviciosSeleccionado">
                                                    <option value="">[Elija una opción]</option>
                                                    <option *ngFor="let item of listaServiciosCodificador" [value]="item.id">
                                                        {{ item.nombre }}</option>
                                                </select> -->
                                                <select formControlName="serviciosSeleccionado">
                                                    <option *ngFor="let item of listaServiciosCodificador" [value]="item.id">
                                                        {{ item.nombre }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div id="parametrosServicio" *ngIf="registra_servicio === true">
                                        <table class="tabla_servicios">
                                            <tr>
                                                <th width="250px">A seleccionado el siguiente servicio: </th>
                                                <td>
                                                    <span id="nombre_servicio" style="color:blue">
                                                        {{ codigo_descripcion }}
                                                        <input #servicio_grupo type="hidden" class="form-control" name="servicioGrupo" style="width:100px;" value="Servicio: {{ codigo_descripcion }} - Grupo: {{ grupo_servicio }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Grupo Servicio: </th>
                                                <td>
                                                    <span id="grupo" style="color:blue">
                                                        {{ grupo_servicio }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Y tiene el siguiente Costo Unitario: </th>
                                                <td>
                                                    <span id="costo_unitario" style="color:green">
                                                        {{ costo_servicio }}
                                                        <input #costo_serv type="hidden" class="form-control" name="costo_serv" style="width:100px;" value="{{ costo_servicio }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Escriba la cantidad requerida del Servicio: </th>
                                                <td>
                                                    <input #cantidad type="text" (keyup)="onKeyUp($event)" class="form-control" formControlName="cantidad" name="cantidad" style="width:100px;" value="1"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Calculado:</th>
                                                <td>
                                                    <span id="costo_calculado" style="color:red">
                                                        {{ costo_servicio_selec }}
                                                        <input #costo_serv_sel type="hidden" class="form-control" name="costo_serv_sel" style="width:100px;" value="{{ costo_servicio_selec }}"/>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Requisitos: </th>
                                                <td>
                                                    <div *ngIf="grupo_servicio === 'GRUPO 1' && (codigo_servicio === 'SC-03' || codigo_servicio === 'SC-04' || codigo_servicio === 'SC-05' || codigo_servicio === 'SC-06' || codigo_servicio === 'SC-07' || codigo_servicio === 'SC-08' || codigo_servicio === 'SC-09' || codigo_servicio === 'SC-10' || codigo_servicio === 'SC-11' || codigo_servicio === 'SC-12(A)')">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Contar con coordenadas UTM.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Contar con coordenadas UTM." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Código de cuadrícula minera.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Código de cuadrícula minera." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Orientación mediante la denominación de áreas mineras aledañas.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Orientación mediante la denominación de áreas mineras aledañas." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de Carnet de Identidad.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de Carnet de Identidad." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Nota de Solicitud.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Nota de Solicitud." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota 1: </strong>Las coordenadas UTM WGS-84 o código de cuadrícula o denominación del área minera, corresponderá al centro de hoja.</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota 2: </strong>La Relación Planimétrica para el Plan de trabajo (SC-05), no es limitativa, pudiendo el solicitante requerir cualquier otro tipo de relación planimétrica del Catálogo de Servicios.</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="grupo_servicio === 'GRUPO 1' && (codigo_servicio === 'SC-12(B)' || codigo_servicio === 'SC-13' || codigo_servicio === 'SC-14' || codigo_servicio === 'SC-15' || codigo_servicio === 'SC-16' || codigo_servicio === 'SC-17' || codigo_servicio === 'SC-18' || codigo_servicio === 'SC-19' || codigo_servicio === 'SC-20' || codigo_servicio === 'SC-21' || codigo_servicio === 'SC-22' || codigo_servicio === 'SC-23' || codigo_servicio === 'SC-24')">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Solicitar proforma en Plataforma de cada oficina Regional, Departamental o Nacional de la AJAM.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Solicitar proforma en Plataforma de cada oficina Regional, Departamental o Nacional de la AJAM." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de Carnet de Identidad.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de Carnet de Identidad." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Nota de Solicitud.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Nota de Solicitud.." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="grupo_servicio === 'GRUPO 2' || grupo_servicio === 'GRUPO 3'">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de Certificado de Registro Minero.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de Certificado de Registro Minero." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de carnet del apoderado o representante legal.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de carnet del apoderado o representante legal." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia del Testimonio de Poder (si corresponde).</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia del Testimonio de Poder (si corresponde)." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota 1: </strong>Deberá señalar la Inscripción solicitada en el servicio con la presentación de una fotocopia simple de la Inscripción original.</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota 2: </strong>En caso de requerir una copia simple o legalizada del 'Certificado de Inscripción en Registro Minero (Conforme a la Ley N° 535)', deberá solicitar el servicio denominado 'Duplicado del Certificado de Inscripción en el Registro Minero'.</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="grupo_servicio === 'GRUPO 4' && (codigo_servicio === 'SC-01' || codigo_servicio === 'SC-02')">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de Certificado de Registro Minero.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de Certificado de Registro Minero." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de carnet del apoderado o representante legal.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de carnet del apoderado o representante legal." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia del Testimonio de Poder (si corresponde).</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia del Testimonio de Poder (si corresponde)." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <span class="Estilo5"><strong>Nota: </strong>Los nuevos derechos mineros otorgados deben solicitar, en primera instancia, el Plano Definitivo Original.</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="grupo_servicio === 'GRUPO 4' && (codigo_servicio === 'G-01' || codigo_servicio === 'G-02')">
                                                        <span id="requisitos">
                                                            <table width="100%" cellpadding="8" border="2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de Certificado de Registro Minero.</span>
                                                                        </td>
                                                                        <td style="text-align: center;" width="50px">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de Certificado de Registro Minero." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de pago de patente minera habilitada o Proforma de Estado de Cuenta, correspondiente a la &uacute;ltima gesti&oacute;n." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia de carnet del apoderado o representante legal.</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia de carnet del apoderado o representante legal." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="Estilo4">Fotocopia del Testimonio de Poder (si corresponde).</span>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" name="checkbox[]" value="Fotocopia del Testimonio de Poder (si corresponde)." (change)="onCheckboxChange($event, 'mantiene')">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </span>
                                                    </div>
                                                    <input #requisitos_sel type="hidden" name="requisitos_sel" id="requisitos_sel" value="{{ requisitos }}">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Tiene identificada el &Aacute;rea Minera: </th>
                                                <td><div id="cbox_a"><input type="checkbox" id="cbox_tiene" name="cbox_tiene" value="second_checkbox"></div></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <div id="contenido2">
                                                        <table id="tab_parm2" class="tabla_servicios">
                                                            <tr>
                                                                <td colspan="5"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div id="contenido1">
                                                        <table class="tabla_servicios">
                                                            <tr>
                                                                <th width="46">Zona:</th>
                                                                <td>
                                                                    <select #zona name="zona" class="form-control">
                                                                        <option value="">[Elija una opción]</option>
                                                                        <option value="19">19</option>
                                                                        <option value="20">20</option>
                                                                        <option value="21">21</option>
                                                                    </select>
                                                                </td>
                                                                <th width="46">Este:</th>
                                                                <td><input #este type="text" class="numeros" name="este" id="este_controlador" maxlength="6" class="numeros" style="width:80px;" /></td>
                                                                <th width="48">Norte:</th>
                                                                <td><input #norte type="text" class="numeros" name="norte" id="norte_controlador" maxlength="7" class="numeros" style="width:80px;" /></td>
                                                                <th width="46" bordercolor="#000000">Escala:</th>
                                                                <td>
                                                                    <select #escala name="escala" class="form-control">
                                                                        <option value="">[Elija una opción]</option>
                                                                        <option value="1:10:000">1:10:000</option>
                                                                        <option value="1:20:000">1:20:000</option>
                                                                        <option value="1:50:000">1:50:000</option>
                                                                    </select>
                                                                </td>
                                                                <th width="121">Orientación Hoja: </th>
                                                                <td>
                                                                    <select #orientacion name="orientacion" class="form-control">
                                                                        <option value="">[Elija una opción]</option>
                                                                        <option value="VERTICAL">VERTICAL</option>
                                                                        <option value="1HORIZONTAL">HORIZONTAL</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="tabla_servicios">
                                                            <tr>
                                                                <td width="200"> </td>
                                                                <td style="text-align: center;">
                                                                    <div class="control-group" style="margin-bottom: 0px;">
                                                                        <label class="control-label">Plan de Trabajo:</label>
                                                                        <div class="controls">
                                                                            <label class="checkbox inline">
                                                                                <input type="checkbox" id="cbox_plan_trabajo" name="cbox_plan_trabajo" value="SI">
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <div class="control-group" style="margin-bottom: 0px;">
                                                                        <label class="control-label">Escala Variable:</label>
                                                                        <div class="controls">
                                                                            <label class="checkbox inline">
                                                                                <input type="checkbox" id="cbox_escala_variable" name="cbox_escala_variable" value="SI">
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <div class="control-group" style="margin-bottom: 0px;">
                                                                        <label class="control-label">Orientación Variable:</label>
                                                                        <div class="controls">
                                                                            <label class="checkbox inline">
                                                                                <input type="checkbox" id="cbox_orientacion_variable" name="cbox_orientacion_variable" value="SI">
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td width="200"> </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Datos Adicionales: </th>
                                                <td><textarea #datos_adicionales_controlador id="datos_adicionales_controlador" name="datos_adicionales_controlador" rows="10" cols="50" style="width: 882px; height: 51px;"></textarea></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <table class="tabla_servicios_botones">
                                                        <tr>
                                                            <td width="5"><input type="button" (click)="ejecutarOperacion({operacion: 'cancelar-lista', id: id_servicio})" id="cancelar_lista" class="btn btn-warning" value="Cancelar"></td>
                                                            <td width="8"><input type="button" (click)="agregarLista(datos_adicionales_controlador.value, zona.value, este.value, norte.value, escala.value, orientacion.value, servicio_grupo.value, costo_serv.value, cantidad.value, costo_serv_sel.value, requisitos_sel.value)" id="agregar_lista" class="btn btn-primary" value="Agregar"></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row-fluid">
                        <div class="span12">
                            <div class="box box-black">
                                <div class="box-title">
                                    <i class="icon-table"></i> CUADRO DE SERVICIOS SOLICITADOS
                                </div>
                                <div class="box-content">
                                    <div id="lista_servicios">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width='calc(100% - 1.6*1em - 1.6*1px)' class='estilo1'>
                                                        <font color="#fff">Servicio</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Precio Unitario</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Cantidad</font>
                                                    </th>
                                                    <th width='100' class='estilo1'>
                                                        <font color="#fff">Sub Total</font>
                                                    </th>
                                                    <th width='200' class='estilo1'>
                                                        <font color="#fff">Opciones</font>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="contenido_servicios">
                                                <tr>
                                                    <ng-container *ngIf="servicio_selec === false">
                                                        <td colspan='5'>No se han seleccionado servicios.</td>
                                                    </ng-container>
                                                </tr>
                                                <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                                    <td>
                                                        <table class="tabla_servicios">
                                                            <tbody>
                                                                <tr>
                                                                    <th>
                                                                        {{ dynamicArray[i].Servicio_Grupo }} 
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {{ dynamicArray[i].Requisitos_Sel }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table width="100%" border="1">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Zona:</td>
                                                                                    <td>{{ dynamicArray[i].Zona }}</td>
                                                                                    <td>Este:</td>
                                                                                    <td>{{ dynamicArray[i].Este }}</td>
                                                                                    <td>Norte:</td>
                                                                                    <td>{{ dynamicArray[i].Norte }}</td>
                                                                                    <td>Escala:</td>
                                                                                    <td>{{ dynamicArray[i].Escala }}</td>
                                                                                    <td>Orientaciòn Hoja:</td>
                                                                                    <td>{{ dynamicArray[i].Orientacion }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Datos Adicionales: {{ dynamicArray[i].Datos_Adicionales }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <input type="hidden" name="tiene_am_check[]" value="false">
                                                        <input type="hidden" name="area_minera_seleccionada[]" value="">
                                                        <input type="hidden" name="area_minera_descrita[]" value="">
                                                        <input type="hidden" name="tipo_servicio[]" value="SC-03-Relación Planimétrica, papel indeformable (tamaño carta). ">
                                                        <input type="hidden" name="requisitos_seleccionados[]" value="Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.">
                                                        <input type="hidden" name="zona_seleccionada[]" value="20">
                                                        <input type="hidden" name="este_seleccionada[]" value="1111">
                                                        <input type="hidden" name="norte_seleccionada[]" value="111">
                                                        <input type="hidden" name="escala_seleccionada[]" value="1:20:000">
                                                        <input type="hidden" name="orientacion_seleccionada[]" value="HORIZONTAL">
                                                        <input type="hidden" name="plan_trabajo_seleccionada[]" value="false">
                                                        <input type="hidden" name="escala_variable_seleccionada[]" value="false">
                                                        <input type="hidden" name="orientacion_variable_seleccionada[]" value="false">
                                                        <input type="hidden" name="datos_adicionales_registrados[]" value="">
                                                        <input type="hidden" name="grupo_servicio[]" value="GRUPO 1">
                                                    </td>
                                                    <td>
                                                        {{ dynamicArray[i].Cantidad }}
                                                        <input type="hidden" name="cantidad_registrada[]" value="1">
                                                    </td>
                                                    <td> 
                                                        {{ dynamicArray[i].Costo_Serv }}
                                                        <input type="hidden" name="costo_unitario_registrado[]" value=" Bs. 120">
                                                    </td>
                                                    <td class="costo_calculado">
                                                        {{ dynamicArray[i].Costo_Serv_Sel }}
                                                        <input type="hidden" name="costo_calculado_registrado[]" value="Bs. 120">
                                                    </td>
                                                    <td>
                                                        <input type="hidden" name="ides[]" value="1">
                                                        <button type="button" (click)="deleteRow(i)" class="btn btn-danger quitar_servicio" value="3"><i class="icon-remove-circle"></i> Quitar Servicio </button>
                                                    </td>
                                                </tr>
                                                <!-- <tr id="fila-3" class="filas">
                                                    <ng-container *ngIf="servicio_selec === true">
                                                        <td>
                                                            <table class="tabla_servicios">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>
                                                                            {{ codigo_descripcion_selec }} 
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <table width="100%" border="1">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Zona:</td>
                                                                                        <td>20</td>
                                                                                        <td>Este:</td>
                                                                                        <td>1111</td>
                                                                                        <td>Norte:</td>
                                                                                        <td>111</td>
                                                                                        <td>Escala:</td>
                                                                                        <td>1:20:000</td>
                                                                                        <td>Orientaciòn Hoja:</td>
                                                                                        <td>HORIZONTAL</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Datos Adicionales: 
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <input type="hidden" name="tiene_am_check[]" value="false">
                                                            <input type="hidden" name="area_minera_seleccionada[]" value="">
                                                            <input type="hidden" name="area_minera_descrita[]" value="">
                                                            <input type="hidden" name="tipo_servicio[]" value="SC-03-Relación Planimétrica, papel indeformable (tamaño carta). ">
                                                            <input type="hidden" name="requisitos_seleccionados[]" value="Contar con coordenadas UTM. Código de cuadrícula minera. Orientación mediante la denominación de áreas mineras aledañas. Fotocopia de Carnet de Identidad. Nota de Solicitud.">
                                                            <input type="hidden" name="zona_seleccionada[]" value="20">
                                                            <input type="hidden" name="este_seleccionada[]" value="1111">
                                                            <input type="hidden" name="norte_seleccionada[]" value="111">
                                                            <input type="hidden" name="escala_seleccionada[]" value="1:20:000">
                                                            <input type="hidden" name="orientacion_seleccionada[]" value="HORIZONTAL">
                                                            <input type="hidden" name="plan_trabajo_seleccionada[]" value="false">
                                                            <input type="hidden" name="escala_variable_seleccionada[]" value="false">
                                                            <input type="hidden" name="orientacion_variable_seleccionada[]" value="false">
                                                            <input type="hidden" name="datos_adicionales_registrados[]" value="">
                                                            <input type="hidden" name="grupo_servicio[]" value="GRUPO 1">
                                                        </td>
                                                        <td>
                                                            1
                                                            <input type="hidden" name="cantidad_registrada[]" value="1">
                                                        </td>
                                                        <td> 
                                                            {{ costo_servicio_selec }}
                                                            <input type="hidden" name="costo_unitario_registrado[]" value=" Bs. 120">
                                                        </td>
                                                        <td class="costo_calculado">
                                                            {{ costo_servicio_selec }}
                                                            <input type="hidden" name="costo_calculado_registrado[]" value="Bs. 120">
                                                        </td>
                                                        <td>
                                                            <input type="hidden" name="ides[]" value="1">
                                                            <button type="button" class="btn btn-danger quitar_servicio" value="3"><i class="icon-remove-circle"></i> Quitar Servicio </button>
                                                        </td>
                                                    </ng-container>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                        <table id="tabla_total" class="tabla_servicios_total">
                                          <thead>
                                            <tr>
                                                <td width="40px" nowrap class='estilo1'><font color="#fff">Costo total: </font></td>
                                                <td width="200px" align="center"><font color="#fff">Bs. {{ dynamicTotalArray[0] }}</font></td>
                                            </tr>
                                          </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>
<!---------------------------------------------------------Fin Contenedor de los servicios a ser seleccionados--------------------------------------------------------------->

                    <div class="row">
                        <div class="col-12">
                            <em class="small"><i class="fas fa-info-circle"></i> Los campos obligatorios están marcados con
                                <span class="required-field-indicator"></span></em>
                        </div>
                    </div>
                    <div class="row" style="justify-content: center;">
                        <div class="col-4">
                            <button type="submit" class="btn btn-dark btn-block">
                                Registrarse </button>
                        </div>
                    </div>
                </form>
                <div class="mt-3">
                    <p class="mb-0">
                    </p>
                </div>
            </div>
        </div>
</div>